import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Container } from 'react-grid-system';
import ImageTopDefault from '../components/img/FES_background.jpg';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ButtonFES from '../components/objects/button';
import SEO from '../components/objects/seo';
import ContactMember from '../components/objects/contactMembers';
import PostResume from '../components/objects/postResume';
import ReactGA from 'react-ga';
import { Pagination } from 'antd';
class Comite extends React.Component {
  state = {
    comites: [],
    members: [],
    documents: [],
    contactInfo: [],
    newsComite: [],
    documentFes: [],
    status: 0,
    current: 1,
    minValue: 0,
    maxValue: 6,
    newsActual: [],
  };
  handleChange1 = (value) => {
    if (value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 6,
        current: 1,
      });
    } else {
      this.setState({
        minValue: value * 6 - 6,
        maxValue: value * 6,
        current: value,
      });
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    const permalink = this.props.match.params.permalink;
    let formData = new FormData();
    formData.append('permalink', permalink);

    //Call to webservice to get the new
    axios.post('/api/comites_get_by_permalink', formData).then((res) => {
      this.setState({
        comites: res.data.records,
      });
      var comiteID = res.data.records[0].id;
      var statusComite = res.data.records[0].active;

      if (+statusComite === 1) {
        this.setState({
          status: 1,
        });
      } else {
        this.setState({
          status: 0,
        });
      }
      var formDataMembers = new FormData();
      formDataMembers.append('id_comite', comiteID);
      axios
        .post('/api/comites_members_get', formDataMembers)

        .then((res) => {
          this.setState({
            members: res.data.records,
          });
        });
      var formDataDocuments = new FormData();
      formDataDocuments.append('id_comite', comiteID);
      axios
        .post('/api/comites_documents_get', formDataDocuments)
        .then((res) => {
          this.setState({
            documents: res.data.records,
          });
        })
        .catch(function (error) {
          console.log("comites_documents_get error")
          console.log(error)
        });

      var formDataContact = new FormData();
      formDataContact.append('id_comite', comiteID);
      axios
        .post('/api/comites_contact_get', formDataContact)
        .then((res) => {
          this.setState({
            contactInfo: res.data.records,
          });
        })
        .catch(function (error) {
          console.log("comites_contact_get error")
          console.log(error)
        });

      var formComiteNews = new FormData();
      formComiteNews.append('id_comite', comiteID);
      axios
        .post('/api/comites_featured_news_get', formComiteNews)
        .then((res) => {
          if (res.data.records) {
            this.setState({
              newsComite: res.data.records,
            });
          }
        })
        .catch(function (error) {
          console.log("comites_featured_news_get error")
          console.log(error)
        });
      let formData = new FormData();
      formData.append('id_comite', comiteID);
      axios
        .post('/api/comites_docs_by_fes_get_by_id_comite', formData)
        .then((res) => {
          this.setState({
            documentFes: res.data.records,
          });
        })
        .catch((error) => {
          console.log("comites_docs_by_fes_get_by_id_comite error")
          console.log(error)
        });
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    window.scrollTo(0, 0);
    const permalink = this.props.match.params.permalink;

    if (this.props.match.params !== prevProps.match.params) {
      let formData = new FormData();
      formData.append('permalink', permalink);

      //Call to webservice to get the new
      axios
        .post('/api/comites_get_by_permalink', formData)
        .then((res) => {
          this.setState({
            comites: res.data.records,
          });
          var comiteID = res.data.records[0].id;
          var statusComite = res.data.records[0].active;
          if (+statusComite === 1) {
            this.setState({
              status: 1,
            });
          } else {
            this.setState({
              status: 0,
            });
          }
          var formDataMembers = new FormData();
          formDataMembers.append('id', comiteID);
          axios
            .post('/api/comites_members_get', formDataMembers)
            .then((res) => {
              this.setState({
                members: res.data.records,
              });
            });
          var formDataDocuments = new FormData();
          formDataDocuments.append('id_comite', comiteID);
          axios
            .post('/api/comites_documents_get', formDataDocuments)
            .then((res) => {
              this.setState({
                documents: res.data.records,
              });
            })
            .catch(function (error) {
              console.log("comites_documents_get error")
              console.log(error)
            });
          var formDataContact = new FormData();
          formDataContact.append('id_comite', comiteID);
          axios
            .post('/api/comites_contact_get', formDataContact)
            .then((res) => {
              this.setState({
                contactInfo: res.data.records,
              });
            })
            .catch(function (error) {
              console.log("comites_contact_get error")
              console.log(error)
            });
          var formComiteNews = new FormData();
          formComiteNews.append('id_comite', comiteID);
          axios
            .post('/api/comites_featured_news_get', formComiteNews)
            .then((res) => {
              if (res.data.records) {
                this.setState({
                  newsComite: res.data.records,
                });
              }
            });
        })
        .catch(function (error) {
          console.log("comites_featured_news_get error")
          console.log(error)
        });
    }
  }

  render() {
    const { comites } = this.state;
    const { members } = this.state;
    const { documents } = this.state;
    const { contactInfo } = this.state;

    const { documentFes } = this.state;
    const sortMembers = members && members.length > 0 && members.sort((a, b) => b.id_rol - a.id_rol);
    const membersDirectives = sortMembers && sortMembers.length > 0 && sortMembers.map((member, index) => {
      if (+member.id_rol !== 1) {
        return (
          <Col sm={4} xs={12} key={'dir' + index}>
            <ContactMember
              rol_name={member.rol_name}
              name={member.name + ' ' + member.lastname}
            ></ContactMember>
          </Col>
        );
      } else {
        return null;
      }
    });
    const sortMembersBasic = members && members.length > 0 && members.sort((a, b) =>
      a.lastname.localeCompare(b.lastname)
    );
    const membersBasic = sortMembersBasic && sortMembersBasic.length > 0 && sortMembersBasic.map((member, index) => {
      if (+member.id_rol === 1) {
        return (
          <Col sm={4} xs={12} key={'base' + member.id + index}>
            <div className="memberComite">
              <p>
                <FontAwesomeIcon icon={Icons.faUserTie} size="1x" />{' '}
                {member.name + ' ' + member.lastname}
              </p>
            </div>
          </Col>
        );
      } else {
        return null;
      }
    });
    const documentButtons = documents && documents.length > 0 && documents.map((document) => {
      if (this.state.status === 1) {
        if (document.id) {
          if (
            document.doc_1 === '' &&
            document.doc_2 === '' &&
            document.doc_3 === '' &&
            document.doc_4 === '' &&
            document.doc_5 === ''
          ) {
            return null;
          } else {
            return (
              <div key={document.id}>
                <h2>Documentos</h2>
                <ButtonFES
                  button_link={document.doc_1}
                  button_class="button width-100"
                  button_text={document.btn_text_1}
                  icon="a"
                ></ButtonFES>
                <ButtonFES
                  button_link={document.doc_2}
                  button_class="button width-100"
                  button_text={document.btn_text_2}
                  icon="a"
                ></ButtonFES>
                <ButtonFES
                  button_link={document.doc_3}
                  button_class="button width-100"
                  button_text={document.btn_text_3}
                  icon="a"
                ></ButtonFES>
                <ButtonFES
                  button_link={document.doc_4}
                  button_class="button width-100"
                  button_text={document.btn_text_4}
                  icon="a"
                ></ButtonFES>
                <ButtonFES
                  button_link={document.doc_5}
                  button_class="button width-100"
                  icon="a"
                  button_text={document.btn_text_5}
                ></ButtonFES>
              </div>
            );
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    });

    const documentFESbuttons = documentFes && documentFes.length > 0 && documentFes.map((df, index) => {
      return (
        <ButtonFES
          key={index}
          button_link={df.doc}
          button_class="button width-100"
          button_text={df.doc_name}
          icon="a"
        ></ButtonFES>
      );
    });

    const contactInformation = contactInfo && contactInfo.length > 0 && contactInfo.map((contactIn) => {
      if (this.state.status === 1) {
        return (
          <div key={contactIn.id}>
            <h2>Contacto</h2>
            <div>
              <p>
                <FontAwesomeIcon icon={Icons.faUser} size="1x" />{' '}
                {!contactIn.name ? 'No disponible' : contactIn.name}
              </p>
              <p>
                <FontAwesomeIcon icon={Icons.faPhone} size="1x" />{' '}
                {!contactIn.phone ? 'No disponible' : contactIn.phone}
              </p>
              <p>
                <FontAwesomeIcon icon={Icons.faEnvelope} size="1x" />{' '}
                {!contactIn.email ? 'No disponible' : contactIn.email}
              </p>
              <p>
                {!contactIn.ig ? null : (
                  <a href={contactIn.ig}>
                    <FontAwesomeIcon
                      icon={fab.faInstagram}
                      size="2x"
                      className="socialIconsComite"
                    />
                  </a>
                )}
                {!contactIn.fb ? null : (
                  <a href={contactIn.fb}>
                    <FontAwesomeIcon
                      icon={fab.faFacebookF}
                      size="2x"
                      className="socialIconsComite"
                    />
                  </a>
                )}
                {!contactIn.tw ? null : (
                  <a href={contactIn.tw}>
                    <FontAwesomeIcon
                      icon={fab.faTwitter}
                      size="2x"
                      className="socialIconsComite"
                    />
                  </a>
                )}
              </p>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
    const titleAlone = comites && comites.length > 0 && comites.map((comite) => {
      return (
        <Container key={comite.id}>
          <Row>
            <Col sm={12}>
              <h2>{comite.title}</h2>
            </Col>
          </Row>
        </Container>
      );
    });

    const newsInComite = this.state.newsComite !== undefined && this.state.newsComite.length > 0 && this.state.newsComite
      .sort(function (a, b) {
        return new Date(b.published_date) - new Date(a.published_date);
      })
      .slice(+this.state.minValue, +this.state.maxValue)
      .map((news, index) => {
        return (
          <Col lg={4} sm={6} xs={12} key={news.id}>
            <PostResume
              key={index}
              title={news.title}
              categories={news.categories}
              img={news.img}
              link={news.permalink}
              short_description={news.short_description}
              meta_description={news.meta_description}
              date={news.published_date}
            ></PostResume>
          </Col>
        );
      });
    const MetaTagsSeo = comites && comites.length > 0 && comites.map((comite) => {
      return (
        <SEO
          title={comite.meta_title}
          cover_image={comite.img}
          description={comite.meta_description}
          key={'SEO' + comite.id}
        ></SEO>
      );
    });
    const title = comites && comites.length > 0 && comites.map((comite) => {
      if (!comite.cover_image) {
        return (
          <Container
            key={comite.id}
            className="width-100 max-width-100 backgroundImage backgroundDark"
            style={{
              background: `url('${comite.img ? comite.img : ImageTopDefault}')`,
            }}
          >
            <Row className="max-width-site">
              <Col sm={12}>
                <div className="leftTitle">
                  <h1 className="title-comites">{comite.title}</h1>
                  <h6 className="subtitleComite textWhite">
                    {comite.subtitle}
                  </h6>
                  <ButtonFES
                    button_text="¡AFÍLIATE A ESTE COMITÉ!"
                    button_class="button zoom-on-hover"
                    button_link="/afiliate"
                  ></ButtonFES>
                </div>
              </Col>
            </Row>
          </Container>
        );
      } else {
        return (
          <Container
            className="width-100 max-width-100 backgroundImage noticeTitle backgroundDark"
            style={{ background: `url('${comite.cover_image}')` }}
          >
            <Row className="max-width-site">
              <Col sm={12}>
                <div className="leftTitle">
                  <h1>{comite.title}</h1>
                  <h6 className="subtitleComite textWhite">
                    {comite.subtitle}
                  </h6>
                  <ButtonFES
                    button_text="¡AFÍLIATE A ESTE COMITÉ!"
                    button_class="button zoom-on-hover"
                    button_link="#"
                  ></ButtonFES>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }
    });
    const comite = comites && comites.length > 0 && comites.map((comite) => {
      if (+comite.active === 1 || +comite.active === 2) {
        return (
          <div key={comite.id}>
            <Row>
              <Col sm={12}>
                <div className="inner">
                  {+comite.active === 2 ? (
                    <div className="alert alert-warning" role="alert">
                      <p>En construcción</p>
                    </div>
                  ) : null}
                  <Tabs>
                    <TabList>
                      <Tab>Inicio</Tab>
                      <Tab>Equipo y miembros</Tab>
                      <Tab>Noticias</Tab>
                      <Tab>Más Información</Tab>
                      <Tab hidden>Cómo afiliarse</Tab>
                    </TabList>
                    <TabPanel>
                      <div
                        dangerouslySetInnerHTML={{ __html: comite.content }}
                      />
                    </TabPanel>
                    <TabPanel>
                      <h5> Equipo Directivo</h5>
                      <div className="separator separatorGrad"></div>
                      <Container>
                        <Row className="memberBlock">
                          {membersDirectives.length === 0
                            ? 'No hay Equipo Directivo'
                            : membersDirectives}
                        </Row>
                      </Container>
                      <h5>Miembros</h5>
                      <div className="separator separatorGrad"></div>
                      <Container>
                        <Row className="memberBlock">
                          {membersBasic.length === 0
                            ? 'No hay miembros'
                            : membersBasic}
                        </Row>
                      </Container>
                    </TabPanel>
                    <TabPanel>
                      <Row>
                        {newsInComite.length === 0
                          ? 'No hay noticias'
                          : newsInComite}
                        <Col sm={12} xs={12}>
                          <div className="center"></div>
                          <div>
                            <Pagination
                              key={this.state.newsComite}
                              defaultCurrent={1}
                              current={this.state.current}
                              pageSize={6}
                              onChange={this.handleChange1}
                              total={+this.state.newsComite.length}
                            />
                          </div>
                        </Col>
                      </Row>
                    </TabPanel>
                    <TabPanel>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: !comite.more_info_html
                            ? 'No hay más información'
                            : comite.more_info_html,
                        }}
                      />
                    </TabPanel>
                    <TabPanel>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: !comite.how_to_join_html
                            ? 'No hay más información'
                            : comite.how_to_join_html,
                        }}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
        );
      } else {
        return <p>El comite elegido se encuentra deshabilitado</p>;
      }
    });

    return (
      <section>
        {MetaTagsSeo}
        {title}
        <hr className="whiteSpaceMedium"></hr>
        <Container>
          <Row>
            <Col lg={9} sm={12}>
              <Row>
                <Col sm={12}>
                  <p>
                    <Link
                      to="/actividades/comites-de-investigacion"
                      className="goBacklink"
                    >
                      <FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" />{' '}
                      Volver a comites
                    </Link>
                  </p>
                </Col>
                <Col sm={12}>{titleAlone}</Col>
                <Col sm={12}>{comite}</Col>
              </Row>
            </Col>
            <Col lg={3} sm={12}>
              {contactInformation}
              {documentButtons}
              <ButtonFES
                button_text="¡AFÍLIATE A ESTE COMITÉ!"
                button_class="button zoom-on-hover"
                button_link="/afiliate"
              ></ButtonFES>
              {this.state.documentFes.length ? (
                <div>
                  {' '}
                  <h2>Anuncios FES</h2>
                  {documentFESbuttons}
                </div>
              ) : null}
              <div hidden>
                <h2>Destacados FES</h2>
                <p>Aqui las noticias FES</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Comite;
