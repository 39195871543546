import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Container } from "react-grid-system";
import ImageTopDefault from "../components/img/FES_background.jpg";
import ImageDefault from "../components/img/Fes.png";
import { Link } from "react-router-dom";

class AsociacionesInsituciones extends React.Component {
	state = {
		comites: [],
		listaComites: [],
	};

	componentDidMount() {
		//Call to webservice to get the new
		axios.get("/api/comites_general_get").then((res) => {
			//console.log(res);
			this.setState({
				comites: res.data.records.slice(0, 1),
			});
		});

		//Call to webservice to get the new
		axios.get("/api/get_all_news").then((res) => {
			//console.log(res);
			this.setState({
				listaComites: res.data.records.slice(0, 35),
			});
		});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params !== prevProps.match.params) {
			axios.get("/api/comites_general_get").then((res) => {
				//console.log(res);
				this.setState({
					comites: res.data.records.slice(0, 1),
				});
			});
		}
	}

	render() {
		const { comites } = this.state;
		const { listaComites } = this.state;
		const defaultImage = comites.map((comites) => {
			if (!comites.img) {
				return (
					<div>
						<img className="width-100" src={`${ImageDefault}`} alt="alt"></img>
					</div>
				);
			} else {
				return (
					<div>
						<img className="width-100" src={`${comites.img}`} alt="alt"></img>
					</div>
				);
			}
		});
		const title = comites.map((comite) => {
			if (!comite.img) {
				return (
					<Container
						className="width-100 max-width-100 backgroundImage noticeTitle backgroundDark"
						style={{ background: `url('${ImageTopDefault}')` }}
					>
						<Row className="max-width-site">
							<Col sm={12}>
								<div className="centerTitleBlog">
									<h1 className="center">{comite.title}</h1>
									<h6 className="subtitle center textWhite">
										{comite.subtitle}
									</h6>
									<p className="center breadcrumbNotice">
										Inicio > Comités de investigación
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				);
			} else {
				return (
					<Container
						className="width-100 max-width-100 backgroundImage noticeTitle backgroundDark"
						style={{ background: `url('${comite.img}')` }}
					>
						<Row className="max-width-site">
							<Col sm={12}>
								<div className="centerTitleBlog">
									<h1 className="center">{comite.title}</h1>
									<h6 className="subtitle center textWhite">
										{comite.subtitle}
									</h6>
									<p className="center breadcrumbNotice">Inicio > Comites </p>
								</div>
							</Col>
						</Row>
					</Container>
				);
			}
		});

		const comite = comites.map((comite) => {
			return (
				<div key={comite.id}>
					<Row>
						<Col sm={12} lg={6}>
							<div className="inner">
								<h2>{comite.title}</h2>
								<div
									dangerouslySetInnerHTML={{ __html: comite.desc_generic }}
								/>
								<button className="button">¿Cómo unirse a un Comité?</button>
							</div>
						</Col>
						<Col sm={12} lg={6}>
							{defaultImage}
						</Col>
					</Row>
				</div>
			);
		});
		const listadocomite = listaComites.map((comite) => {
			return (
				<Col key={comite.id} sm={4} xs={12} className="comiteLevel01">
					<div className="comiteBox">
						<div className="inner">
							<Row>
								<Col sm={4} xs={12} className="textColorSecondary">
									<FontAwesomeIcon icon={Icons.faBookmark} size="2x" />
								</Col>
								<Col sm={8} xs={12}>
									<h4>{comite.title.substring(0, 14)}</h4>
								</Col>
							</Row>
							<p>
								<Link to={`/comite${comite.permalink}`}>Explorar Comite</Link>
							</p>
						</div>
					</div>
				</Col>
			);
		});
		const listadoGrupo = listaComites.map((comite) => {
			return (
				<Col key={comite.id} sm={4} xs={12} className="comiteLevel01">
					<div className="comiteBox">
						<div className="inner">
							<Row>
								<Col sm={4} xs={12} className="textColorSecondary">
									<FontAwesomeIcon icon={Icons.faBookmark} size="2x" />
								</Col>
								<Col sm={8} xs={12}>
									<h4>{comite.title.substring(0, 14)}</h4>
								</Col>
							</Row>
							<p>
								<Link to={`/comite${comite.permalink}`}>Explorar Comite</Link>
							</p>
						</div>
					</div>
				</Col>
			);
		});
		const secondSectionComite = comites.map((comite) => {
			return (
				<Row>
					<Col sm={12} className="center">
						<h2>Guía de funcionamiento</h2>
						<p>
							Reúnen a sociólogos individuales en líneas de investigación
							comunes reconocidas como especialidades de la sociología
						</p>
					</Col>
					<Col sm={6} xs={12}>
						<h3>Titulo de esta columna</h3>
						<p>{comite.desc_guide}</p>
					</Col>
					<Col sm={6} xs={12}>
						<h3>Documentacion</h3>
						<button type="button" className="collapsible noCollapsed">
							¿ Que son los comites de investigación?
						</button>
						<div className="content_collapsible">
							<p className="text_descriptions">{comite.desc_us}</p>
						</div>
						<button type="button" className="collapsible noCollapsed">
							Normas de funcionamiento
						</button>
						<div className="content_collapsible">
							<p className="text_descriptions">{comite.desc_rules}</p>
						</div>
						<button type="button" className="collapsible noCollapsed">
							Servicio de la FES
						</button>
						<div className="content_collapsible">
							<p className="text_descriptions">{comite.desc_services}</p>
						</div>
						<button type="button" className="collapsible noCollapsed">
							Afiliación
						</button>
						<div className="content_collapsible">
							<p className="text_descriptions">{comite.desc_membership}</p>
						</div>
					</Col>
					<Col sm={12}>
						<hr className="whiteSpace"></hr>
						<div className="center">
							<h4 className="titleItalic">En el FES</h4>
							<h2>GRUPOS DE TRABAJO</h2>
							<p>
								Reúnen a sociólogos individuales en líneas de investigación
								comunes reconocidas como especialidades de la sociología
							</p>
							<p>
								Reúnen a sociólogos individuales en líneas de investigación
								comunes reconocidas como especialidades de la sociología.
								Constituyen uno de los núcleos básicos para el desarrollo de la
								actividad que tiene encomendada la FES como sociedad científica.
							</p>
							<p>
								La estructura del Congreso Español de Sociología se realiza
								básicamente de acuerdo con la composición de los comités.
								También tienen autonomía para desarrollar las actividades entre
								congresos que estimen oportunas, como reuniones en torno a la
								especialidad, publicaciones, talleres, escuelas de doctorado o
								actividades de divulgación.
							</p>
							<p>
								Reflejan la estructura de especialidades de la sociología de una
								manera homologada con las asociaciones científicas
								internacionales como ISA y ESA y otras asociaciones nacionales
								de sociología. La cantidad y denominación de los comités puede
								variar con el tiempo de acuerdo con la evolución de las
								especialidades de la sociología y su desarrollo en España.
							</p>
							<p>
								Los comités de investigación son miembros colectivos de la FES
								(TIPO D). Los presidentes de todos los comités de investigación
								forman el Consejo Científico de la FES, con funciones de
								representación y asesoramiento en el desarrollo de la sociología
								como disciplina cientítica. En la actualidad tiene 5
								representantes en la Asamblea Federal.
							</p>
						</div>
					</Col>
				</Row>
			);
		});
		return (
			<section>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>
						<Col>
							<h2 className="titleItalic">En el FES</h2>
						</Col>
						<Col sm={12}>{comite}</Col>
						<Col>
							<h2 className="center">Listado de comites</h2>
						</Col>
					</Row>
					<Row className="comiteListing">{listadocomite}</Row>
					{secondSectionComite}
					<Row>{listadoGrupo}</Row>
				</Container>
			</section>
		);
	}
}

export default AsociacionesInsituciones;
