import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../components/objects/topImageTitle";
import SEO from "../components/objects/seo";
import MultimediaResume from "../components/objects/multimediaResume";
import ReactGA from "react-ga";
import { Pagination } from "antd";
class ActualidadCategoria extends React.Component {
	constructor() {
		super();
		this.state = {
			news: [],
			newsList: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			minValue: 0,
			maxValue: 9,
			current: 1,
		};
	}
	handleChange = (value) => {
		console.log(value);
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 9,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 9 - 9,
				maxValue: value * 9,
				current: value,
			});
		}
	};
	componentDidMount() {
		//Call to webservice to get all news
		const { id } = this.props.match.params;
		let formData = new FormData();
		formData.append("id_categ", id);
		axios.post("/api/multimedia_get_by_id_categ", formData).then((res) => {
			this.setState({
				news: res.data.records,
			});
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const notWantedCategories = global.notWantedCategoriesMulti;
		var GoodNews = [];
		this.state.news.map((ne, index) => {
			var idcat = ne.categories.map((categories) => categories.id);

			if (notWantedCategories.some((r) => idcat.includes(r))) {
				let intersection = notWantedCategories.filter((x) => idcat.includes(x));
				if (intersection.length !== idcat.length) {
					GoodNews.push(ne);
					return null;
				}
				return null;
			} else {
				GoodNews.push(ne);
				return null;
			}
		});

		const title = (
			<TopImageTitle
				key="0001"
				key_id="00003"
				title="Multimedia"
				cover_image=""
				subtitle="Listado de noticias de Multimedia"
				bread01="Multimedia"
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="Conoce toda la actualidad del FES"
				description="Todas las noticias del FES"
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);

		const newsList = this.state.news
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((news, index) => {
				var idcat = news.categories.map((categories) => categories.id);
				//Array de categorias que no queremos mostrar
				const catWant = ["6"];
				if (catWant.some((r) => idcat.includes(r)) || idcat.length < 1) {
					if (+news.display === 0) {
						return (
							<Col
								lg={4}
								sm={6}
								key={news.id}
								className={
									"new-cat d-flex category-filter category-" +
									news.categories.map((categories) => categories.id)
								}
							>
								<MultimediaResume
									key={index}
									title={news.title}
									img={news.cover_image}
									categories={news.categories}
									link={news.permalink}
									short_description={news.short_description}
									date={news.published_date}
									meta_description={news.meta_description}
								></MultimediaResume>
							</Col>
						);
					}
					return null;
				} else {
					return null;
				}
			});
		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>{newsList}</Row>
					<Row>
						{" "}
						<div>
							<div>
								<Pagination
									key={this.state.news}
									defaultCurrent={1}
									current={this.state.current}
									pageSize={9}
									onChange={this.handleChange}
									total={this.state.news.length}
								/>
							</div>
						</div>
					</Row>
				</Container>
			</section>
		);
	}
}

export default ActualidadCategoria;
