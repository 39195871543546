import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../components/objects/topImageTitle";
import SEO from "../components/objects/seo";
import ActualidadResume from "../components/objects/actualidadResume";
import { Redirect } from "react-router-dom";
import { Pagination } from "antd";
class HistoricoActualidad extends React.Component {
	constructor() {
		super();
		this.state = {
			filterCat: "",
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
			minValue: 0,
			maxValue: 9,
			current: 1,
			currentTodos: [],
			_news: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	changeFilterCat = (e) => {
		//var catSelected = e.target.value;
		const wanted = [e.target.value];
		var GoodNews = [];
		this.state.news.map((ne, index) => {
			var idcat = ne.categories.map((categories) => categories.id);

			if (wanted.some((r) => idcat.includes(r))) {
				GoodNews.push(ne);
				return null;
			} else {
				//GoodNews.push(ne);
				return null;
			}
		});

		let _news = GoodNews;
		/*if (catSelected) {
			_news = _news.filter(
				(item) =>
					+item.categories.map((categories) => categories.id) === +catSelected
			);
		}*/

		this.setState({
			filterCat: e.target.value,
			minValue: 0,
			maxValue: 9,
			current: 1,
			activeId: e.target.value,
			_news: e.target.value === "" ? this.state.news : _news,
		});
	};
	handleChange = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 9,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 9 - 9,
				maxValue: value * 9,
				current: value,
			});
		}
	};
	componentDidMount() {
		let formData = new FormData();
		const { year } = this.props.match.params;
		formData.append("year", year);
		//Call to webservice to get the new

		axios
			.post("/api/topicality_get_by_year", formData)
			.then((res) => {
				console.log(res);
				this.setState({
					news: res.data.records,
					_news: res.data.records,
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					statusPage: 1,
				});
			});
		axios
			.get("/api/topicality_get_years")
			.then((res) => {
				//console.log(res);
				this.setState({
					years: res.data.records.reverse(),
				});
			})
			.catch((error) => {});
		//Call to webservice to get all news
		axios.get("/api/topicality_categories_get_all").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const { year } = this.props.match.params;
		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();

			formData.append("year", year);
			//Call to webservice to get the new

			axios
				.post("/api/topicality_get_by_year", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						news: res.data.records,
						_news: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
			axios
				.get("/api/topicality_get_years")
				.then((res) => {
					//console.log(res);
					this.setState({
						years: res.data.records.reverse(),
					});
				})
				.catch((error) => {});
			//Call to webservice to get all news
			axios.get("/api/topicality_categories_get_all").then((res) => {
				//console.log(res);
				this.setState({
					//Just peak the last 3
					categories: res.data.records,
				});
			});
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.match.params !== this.props.match.params) {
			window.scrollTo(0, 0);
			//var url = window.location.href.split("/");
			//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
			const { year } = this.props.match.params;
			//fetch(`noticia/${permalink}`);

			let formData = new FormData();

			formData.append("year", year);
			//Call to webservice to get the new

			axios
				.post("/api/topicality_get_by_year", formData)
				.then((res) => {
					this.setState({
						news: res.data.records,
						_news: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
			axios
				.get("/api/topicality_get_years")
				.then((res) => {
					this.setState({
						years: res.data.records.reverse(),
					});
				})
				.catch((error) => {});
			//Call to webservice to get all news
			axios.get("/api/topicality_categories_get_all").then((res) => {
				this.setState({
					//Just peak the last 3
					categories: res.data.records,
				});
			});
		}
	}
	render() {
		const actualYear = this.props.match.params.year;
		const { statusPage } = this.state;
		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const { years } = this.state;
		//const { news } = this.state;

		//let news = this.state.news;

		const { categories } = this.state;
		//const { news } = this.state;

		const yearList = years.map((year) => {
			return (
				<li key={year.year}>
					<a href={"/historico-actualidad/" + year.year}>{year.year}</a>
				</li>
			);
		});
		const notWantedCategories = ["51"];
		const categoriesList = categories.length ? (
			categories
				.sort(
					(a, b) =>
						(+a.order_cat === 0) - (+b.order_cat === 0) ||
						+(+a.order_cat > +b.order_cat) ||
						-(+a.order_cat < +b.order_cat)
				)
				.map((categories) => {
					if (notWantedCategories.some((r) => categories.id.includes(r))) {
						return null;
					} else {
						return (
							<Col
								lg={4}
								sm={6}
								key={categories.id}
								className={"categories-button category-" + categories.id}
							>
								<button
									className={
										"filterCategories " +
										(this.state.activeId === categories.id && "button")
									}
									onClick={this.changeFilterCat}
									value={categories.id}
								>
									{categories.name}
								</button>
							</Col>
						);
					}
				})
		) : (
			<div className="center">No hay categorias</div>
		);
		const newsList = this.state._news
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((news, index) => {
				var idcat = news.categories.map((categories) => categories.id);
				if (notWantedCategories.some((r) => idcat.includes(r))) {
					return null;
				} else {
					return (
						<Col
							lg={4}
							sm={6}
							key={news.id}
							className={
								"new-cat d-flex category-filter category-" +
								news.categories.map((categories) => categories.id)
							}
						>
							<ActualidadResume
								key={index}
								title={news.title}
								img={news.cover_image}
								categories={news.categories}
								link={news.permalink}
								short_description={news.short_description}
								date={news.published_date}
								meta_description={news.meta_description}
							></ActualidadResume>
						</Col>
					);
				}
			});

		const title = (
			<TopImageTitle
				title={"Historico de noticias " + actualYear}
				subtitle="Noticias Archivadas"
				bread01={"Historico de noticias " + actualYear}
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="Conoce toda la actualidad del FES"
				description="Todas las noticias del FES"
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);

		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col sm={9} xs={12}>
							{" "}
							<Col sm={12}>
								<h5 className="center">Selecciona una categoría</h5>
							</Col>
							<Row>{categoriesList} </Row>
							<Row>
								<Col lg={4} sm={2}>
									<hr></hr>
								</Col>
								<Col lg={4} sm={8} className={"categories-button category-"}>
									<button
										className={"filterCategories cleanFilter false "}
										onClick={this.changeFilterCat}
										value={""}
									>
										Ver todas las categorias
									</button>
								</Col>
								<Col lg={4} sm={2}>
									<hr></hr>
								</Col>
							</Row>
							<Row className="hideInDesktop">
								<Col lg={12} sm={12}>
									<h4 className="center" style={{ marginTop: "10px" }}>
										Buscador
									</h4>
									<form
										className="formSearchSlider"
										action="/buscador"
										method="GET"
									>
										<div className="searchSlider">
											<input
												className="searchBarHome width-100 searchInNews"
												type="text"
												placeholder=""
												name="s"
												autoComplete="off"
											/>
											<br></br>
											<input
												className="button width-100"
												type="submit"
												value="Buscar"
											/>
										</div>
									</form>
								</Col>
							</Row>
							<Row className="listCategory">{newsList}</Row>
							<div>
								<Pagination
									key={this.state._news}
									defaultCurrent={1}
									current={this.state.current}
									pageSize={9}
									onChange={this.handleChange}
									total={this.state._news.length}
								/>
							</div>
						</Col>
						<Col sm={3} xs={12}>
							<h2>Buscador</h2>
							<form
								className="formSearchSlider"
								action="/buscador"
								method="GET"
							>
								<div className="searchSlider">
									<input
										className="searchBarHome width-100 searchInNews"
										type="text"
										placeholder=""
										name="s"
										autoComplete="off"
									/>
									<br></br>
									<input
										className="button width-100"
										type="submit"
										value="Buscar"
									/>
								</div>
							</form>
							<h2>Histórico</h2>
							<div className="yearSidebar">
								<ul>{yearList}</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default HistoricoActualidad;
