import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../style/styles_top3.css";

class CategoriesConferenciasEventos extends Component {
	state = {
		categories: [],
	};
	componentDidMount() {
		//Call to webservice to get all news
		axios.get("/api/lectures_categories_get_all").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
	}

	render() {
		const { categories } = this.state;
		const listCategory = categories.length ? (
			categories.map((categories, index) => {
				return (
					<li key={"cat" + index}>
						<div className="numberListing">
							{" "}
							{("0" + (index + 1)).slice(-2)}
						</div>
						<Link to={"/categoria-actividades-fes/" + categories.id}>
							{categories.name}
						</Link>
					</li>
				);
			})
		) : (
			<div className="center">No hay post</div>
		);
		return (
			<div className="categoryListing">
				<h5 className="titleSidebar">Menú de categorias</h5>
				<div>
					<ul className="sidebarListingNumber">{listCategory}</ul>
				</div>
			</div>
		);
	}
}

export default CategoriesConferenciasEventos;
