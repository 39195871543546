import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import ReactGA from "react-ga";
import Loader from "react-loader-spinner";
import { Divider, Pagination } from "antd";

class Search extends Component {
	constructor(props) {
		super();
		this.state = {
			searching: "",
			getSearch: 2,
			minValue: 0,
			maxValue: 9,
			current: 1,
			allData: [],
		};
	}

	componentDidMount() {
		//Call to webservice to get the new
		const query = new URLSearchParams(this.props.location.search);
		const search = query.get("s");
		if (search) {
			this.setState({
				searching: search,
				getSearch: 1,
			});
			(async () => {
				await this.getSearch();
			})();
		} else {
			console.log("No hay busqueda");
		}

		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	handleChange = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 9,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 9 - 9,
				maxValue: value * 9,
				current: value,
			});
		}
	};
	getSearch = async () => {
		const query = new URLSearchParams(this.props.location.search);
		const search = query.get("s");
		let formData = new FormData();

		formData.append("text", search);
		axios
			.post("/api/searcher", formData)
			.then((res) => {
				this.setState({
					allData: res.data.records,
					getSearch: 0,
				});
			})
			.catch((error) => {
				this.setState({
					getSearch: 3,
				});
			});
	};

	render() {
		const MetaTagsSeo = <SEO title="Buscador FES" cover_image=""></SEO>;

		const { getSearch } = this.state;

		const title = (
			<TopImageTitle
				title="Buscador"
				cover_image=""
				subtitle="Esperamos que encuentre lo que está buscando"
				id_parentMenu={null}
			></TopImageTitle>
		);

		const SearchData = this.state.allData
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((data, index) => {
				return (
					<div className="searchItem" key={index}>
						<div className="searchInfo">
							<h4 className="titulobusqueda">{data.title}</h4>
							<p>{data.short_description}</p>
						</div>
						<div className="searchLink">
							<a
								className="button"
								target="_blank"
								rel="noopener noreferrer"
								href={
									+data.news_type === 1
										? "/noticia" + data.permalink
										: +data.news_type === 2
										? "/actualidad" + data.permalink
										: +data.news_type === 3
										? "/multimedia" + data.permalink
										: +data.news_type === 4
										? "/conferencias-eventos" + data.permalink
										: "/buscador"
								}
							>
								Ver contenido
							</a>
						</div>
					</div>
				);
			});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>

				<Container>
					<Row>
						<Col lg={12} md={12}>
							{getSearch === 1 ? (
								<div className="centerloader">
									<Loader
										type="Oval"
										color="#00BFFF"
										height={100}
										width={100}
									/>
									<h3>
										Buscando resultados con <b>{this.state.searching}</b>...
									</h3>
								</div>
							) : getSearch === 2 ? (
								<form
									className="formSearchSlider"
									action="/buscador"
									method="GET"
								>
									<div className="searchSlider">
										<input
											className="searchBarHome"
											type="text"
											placeholder="¿Qué estás buscando?"
											name="s"
										/>
										<input
											className="button buttonSearchSlider"
											type="submit"
											value="Consiguelo Aquí"
										/>
									</div>
								</form>
							) : (
								<div>
									<form
										className="formSearchSlider"
										action="/buscador"
										method="GET"
									>
										<div className="searchSlider">
											<input
												className="searchBarHome"
												type="text"
												placeholder="¿Qué estás buscando?"
												name="s"
											/>
											<input
												className="button buttonSearchSlider"
												type="submit"
												value="Buscar"
											/>
										</div>
									</form>
									<p>
										Estos son los resultados para la búsqueda{" "}
										<span className="searchWord">
											<b>{this.state.searching}</b>:
										</span>
									</p>
								</div>
							)}
						</Col>
					</Row>
					<Row>
						<Col lg={12} md={12}>
							{this.state.allData.length < 1 && getSearch === 3
								? "No hay resultados"
								: ""}

							{SearchData}
						</Col>
						<Col>
							<div>
								{getSearch === 2 ? (
									""
								) : (
									<Pagination
										key={this.state.allData}
										defaultCurrent={1}
										current={this.state.current}
										pageSize={9}
										onChange={this.handleChange}
										total={this.state.allData.length}
									/>
								)}
							</div>
						</Col>
					</Row>
				</Container>
				<Divider></Divider>
			</div>
		);
	}
}
export default Search;
