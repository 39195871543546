import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import SidebarProfile from './components/sidebar';
import TopImageTitle from '../../components/objects/topImageTitle';
import '../UserProfiles/AdminFromProfile/styles.css';
import { Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Candidature from './Comite-votes/candidature';
import VotationActive from './Comite-votes/votacion';
import BetCanVot from './Comite-votes/betCanVot';
import SEO from '../../components/objects/seo';
//import TabUser from "./Components/tabUsers";

class VotesComiteEjecutivo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: [],
      idComite: this.props.match.params.id,
      key: 'resumenComite',
      fetchingAdminOrder: [],
      userOrders: [],
      userComitesAdmin: [],
      comiteRoles: [],
      comitesMembers: [],
      news: [],
      comiteContactData: [],
      content: '',
      editNews: false,
      isAdmin: false,
      visibleModalRol: false,
      fetchUserOrders: true,
      fetchInformation: true,
      informationEvent: '',
      userInfoFetch: true,
      phase: '',
      idVotation: '0,',
      fetchGeneralInfo: false,
      candidature_init: '',
      candidature_end: '',
      vote_init: '',
      vote_end: '',
      candidatureComiteExecutive: 0,
      comitesUserGet: '',
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
  }
  forceUpdateHandler() {
    this.forceUpdate();
  }
  handleSelect(key) {
    this.setState({ key });
  }
  checkTokken = async () => {
    const jwt = localStorage.getItem('access_token');
    if (!jwt) {
      this.setState.islogged = 0;
    } else {
      this.setState.islogged = 1;
      let formData = new FormData();
      formData.append('jwt', jwt);
      await axios
        .post('/api/validate_token', formData)
        .then((res) => {
          this.setState({
            userInfo: res.data.data,
            userInfoFetch: false,
          });
        })
        .catch((error) => {
          this.setState({
            statusPage: 1,
            userInfoFetch: false,
          });
        });
    }
  };
  getComitesUserActual = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id', userId);

    await axios
      .post('/api/users_comites_current_year_get_by_id', formData)
      .then((res) => {
        this.setState({
          comitesUserGet: res.data.records,
        });
      })
      .catch((error) => {});
  };
  getInformationComiteExecutive = async () => {
    await axios
      .get('/api/votes_ce_config_valid_dates_get')
      .then((res) => {
        this.setState({
          candidatureComiteExecutive: 1,
        });
      })
      .catch((error) => {
        this.setState({
          candidatureComiteExecutive: 0,
        });
      });
  };
  getComitesAdmin = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    this.setState({
      fetchingAdminOrder: true,
    });
    formData.append('id', userId);
    await axios
      .post('/api/users_comites_admin_get_by_id', formData)
      .then((res) => {
        this.setState({
          userComitesAdmin: res.data.records,
          fetchingAdminOrder: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingAdminOrder: false,
        });
      });
  };
  getOrdersUser = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id_user', userId);
    await axios
      .post('/api/payment_get_by_id_user', formData)
      .then((res) => {
        this.setState({
          fetchUserOrders: false,
          userOrders: res.data.records,
        });
      })
      .catch((error) => {
        this.setState({
          fetchUserOrders: false,
        });
      });
  };
  checkDate = async () => {
    this.setState({
      fetchInformation: true,
    });
    await axios
      .post('/api/votes_ce_config_valid_dates_get')

      .then((res) => {
        this.setState({
          phaseComite: 1,
          idVotation: res.data.records[res.data.records.length - 1].id,
          candidature_init: this.dateFormat(
            res.data.records[res.data.records.length - 1].candidature_init
          ),
          candidature_end: this.dateFormat(
            res.data.records[res.data.records.length - 1].candidature_end
          ),
          instructions:
            res.data.records[res.data.records.length - 1].instructions,
          title: res.data.records[res.data.records.length - 1].title,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchInformation: false,
        });
        this.props.history.push('/perfil');
      });
  };
  dateFormat(date) {
    date = date.split(' ')[0];
    var yyyy = date.split('-')[0];
    var mmmm = date.split('-')[1];
    var dddd = date.split('-')[2];
    date = dddd + '-' + mmmm + '-' + yyyy;
    return date;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    (async () => {
      await this.checkTokken();
      await this.getComitesAdmin();
      await this.getOrdersUser();
      await this.checkDate();
      await this.getInformationComiteExecutive();
      await this.getComitesUserActual();
    })();
    if (this.props.history.location.state) {
      this.setState({
        key: this.props.history.location.state.key,
      });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params !== prevProps.match.params) {
      (async () => {
        await this.checkTokken();
        await this.getComitesAdmin();
        await this.getOrdersUser();
        await this.checkDate();
      })();
      if (this.props.history.location.state) {
        this.setState({
          key: this.props.history.location.state.key,
        });
      }
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      location: newProps.location,
    });
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reiniciar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { fetchingAdminOrder } = this.state;
    const { userInfo } = this.state;
    const { userOrders } = this.state;
    const { userComitesAdmin } = this.state;
    const { fetchInformation } = this.state;

    const { phaseComite } = this.state;
    const { idVotation } = this.state;
    const { userInfoFetch } = this.state;

    const { fetchUserOrders } = this.state;

    const allLoad =
      fetchUserOrders === false &&
      fetchInformation === false &&
      userInfoFetch === false;
    const sidebar = !fetchingAdminOrder ? (
      <SidebarProfile
        userInfo={userInfo}
        userOrders={userOrders}
        userComitesAdmin={userComitesAdmin}
        votationActive={this.state.phase}
        votationActiveAssembly={this.state.phaseAssembly}
        {...this.state}
      ></SidebarProfile>
    ) : (
      <p>Cargando</p>
    );

    const title = (
      <TopImageTitle
        title="Elecciones"
        subtitle="Comité Ejecutivo"
      ></TopImageTitle>
    );

    return (
      <div>
        <SEO title="Elecciones comités" key={'SEO'}></SEO>
        <hr className="whiteSpace"></hr>
        {title}

        <section>
          <Container>
            <Row>
              <Col sm={12}>
                <p>
                  <Link to="/perfil" className="goBacklink">
                    <FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" />{' '}
                    Volver al perfil
                  </Link>
                </p>
              </Col>
              <Col lg={3} sm={12}>
                {sidebar}
              </Col>
              {allLoad === false ? (
                <div className="centerloader">
                  <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  />
                  <h3>Cargando información</h3>
                </div>
              ) : (
                <Col lg={9} sm={12}>
                  {+phaseComite === 1 ? (
                    <Candidature
                      idVotation={idVotation}
                      idUser={userInfo.id}
                      allinfoUser={userInfo}
                      emailUser={userInfo.email}
                      dateBegin={this.state.candidature_init}
                      dateEnd={this.state.candidature_end}
                      id_vote={this.state.idVotation}
                      instruciones={this.state.instructions}
                    ></Candidature>
                  ) : (
                    ''
                  )}
                  {+phaseComite === 2 ? (
                    <BetCanVot
                      idVotation={idVotation}
                      idUser={userInfo.id}
                      emailUser={userInfo.email}
                      allinfoUser={userInfo}
                      dateBegin={this.state.candidature_end}
                      dateEnd={this.state.vote_init}
                      id_vote={this.state.idVotation}
                      instruciones={this.state.instructions}
                    ></BetCanVot>
                  ) : (
                    ''
                  )}
                  {+phaseComite === 3 ? (
                    <VotationActive
                      idVotation={idVotation}
                      idUser={userInfo.id}
                      emailUser={userInfo.email}
                      allinfoUser={userInfo}
                      dateBegin={this.state.vote_init}
                      dateEnd={this.state.vote_end}
                      id_vote={this.state.idVotation}
                      instruciones={this.state.instructions}
                    ></VotationActive>
                  ) : (
                    ''
                  )}
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default withRouter(VotesComiteEjecutivo);
