import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../components/objects/topImageTitle";
import SEO from "../components/objects/seo";
import ActividadesResume from "../components/objects/actividadesResume";
import ReactGA from "react-ga";
class categoriaActividadesFES extends React.Component {
	constructor() {
		super();
		this.state = {
			news: [],
			newsList: [],
		};
	}

	componentDidMount() {
		//Call to webservice to get all news
		this.getNews();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	getNews = async () => {
		const { id } = this.props.match.params;
		let formData = new FormData();
		formData.append("id_categ", id);
		axios.post("/api/lectures_get_by_id_categ", formData).then((res) => {
			this.setState({
				news: res.data.records,
			});
		});
	};
	render() {
		const title = (
			<TopImageTitle
				key="0001"
				key_id="00003"
				title="Formación y Eventos"
				cover_image=""
				bread01="Formación y Eventos"
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="Conoce toda la actualidad del FES"
				description="Todas las noticias del FES"
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);

		const { news } = this.state;
		const newsList = news.length ? (
			news.map((news, index) => {
				if (+news.display === 0) {
					return (
						<Col
							lg={4}
							sm={6}
							key={news.id}
							className={
								"new-cat d-flex category-filter category-" +
								news.categories.map((categories) => categories.id)
							}
						>
							<ActividadesResume
								key={index}
								title={news.title}
								img={news.cover_image}
								categories={news.categories}
								link={news.permalink}
								short_description={news.short_description}
								meta_description={news.meta_description}
								published_date={news.published_date}
							></ActividadesResume>
						</Col>
					);
				}
				return null;
			})
		) : (
			<div className="center">
				{" "}
				Estamos trabajando en la programación de nuevas actividades. Os
				mantendremos informados.
			</div>
		);
		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>{newsList}</Row>
				</Container>
			</section>
		);
	}
}

export default categoriaActividadesFES;
