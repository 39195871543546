import React from "react";




const Agenda = () => {
 return <div className="margin-header"> 

<p>Esto es el cuerpo de la pagina de Agenda</p>

    </div>;
}
;

export default Agenda;
