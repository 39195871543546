import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../components/objects/topImageTitle";
import SEO from "../components/objects/seo";
import EscuelaDoctoradoResume from "../components/objects/escuelaDoctoradoResume";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

class HistoricoEscuelaDoctorado extends React.Component {
	constructor() {
		super();
		this.state = {
			filterCat: "",
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	changeFilterCat = (e) => {
		this.setState({
			filterCat: e.target.value.substring(9, 20),
			currentPage: 1,
			activeId: e.target.value.substring(9, 20),
		});
	};

	componentDidMount() {
		let formData2 = new FormData();
		formData2.append("type", 1);
		axios
			.post("/api/activities_get_all_by_type", formData2)
			.then((res) => {
				//console.log(res);
				this.setState({
					news: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";

		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData2 = new FormData();
			formData2.append("type", 1);
			axios
				.post("/api/activities_get_all_by_type", formData2)
				.then((res) => {
					//console.log(res);
					this.setState({
						news: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.match.params !== this.props.match.params) {
			window.scrollTo(0, 0);
			//var url = window.location.href.split("/");
			//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";

			let formData2 = new FormData();
			formData2.append("type", 1);
			axios
				.post("/api/activities_get_all_by_type", formData2)
				.then((res) => {
					//console.log(res);
					this.setState({
						news: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	}
	render() {
		//const { news } = this.state;
		let _news = this.state.news;
		const { currentPage, todosPerPage } = this.state;
		//let news = this.state.news;
		const indexOfLastTodo = currentPage * todosPerPage;

		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		//const { news } = this.state;

		const currentTodos = _news.slice(indexOfFirstTodo, indexOfLastTodo);

		const newsList = currentTodos.map((news, index) => {
			return (
				<Col
					sm={4}
					key={news.id}
					className={
						"new-cat d-flex category-filter category-" +
						news.categories.map((categories) => categories.id)
					}
				>
					<EscuelaDoctoradoResume
						key={index}
						title={news.title}
						img={news.cover_image}
						categories={news.categories}
						link={news.permalink}
						short_description={news.short_description}
						meta_description={news.meta_description}
						date={news.published_date}
					></EscuelaDoctoradoResume>
				</Col>
			);
		});

		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(_news.length / todosPerPage); i++) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number) => {
			return (
				<span key={number} id={number} onClick={this.handleClick}>
					{number}
				</span>
			);
		});

		const title = (
			<TopImageTitle
				title={"Histórico de Escuela de Doctorado FES "}
				subtitle=""
				customBread="Escuela de Doctorado"
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="Conoce Escuela de Doctorado del  FES"
				description="Conoce Escuela de Doctorado del  FES"
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);

		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col sm={12}>
							<p>
								<Link
									to="/actividades/formacion/escuela-de-doctorado"
									className="goBacklink"
								>
									<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver
									a Escuela de Doctorado
								</Link>
							</p>
						</Col>
						<Col sm={12} xs={12}>
							{" "}
							<Row className="listCategory">{newsList}</Row>
							<div className="center">
								<div id="page-numbers">{renderPageNumbers}</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default HistoricoEscuelaDoctorado;
