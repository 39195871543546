import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Modal from 'antd/lib/modal/Modal';

//import { Link } from 'react-router-dom';
class Candidature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidatures: [],
      candidatureDocRef: '',
      candidatureInfo: [],
      candidatureAlreadySent: true,
      candidatureAvailiable: [],
      titleCandidatureSent: '',
      dateCandidatureSent: '',
      colectivePresident: 0,
      comitePresident: 0,
      checkCandidature: 0,
      colectiveType: '',
      idChooseVote: '',
      visibleModalPresentarCandidatura: false,
      visibleModalPresentarCandidaturaSocio: false,
      visibleModalRetirarCandidatura: false,
      userExtraData: '',
      userisColective: 0,
      upload: '',
    };
  }

  getInformation = async () => {
    await axios
      .get('/api/votes_ce_config_valid_dates_get')
      .then((res) => {
        this.setState({
          candidatureInfo: res.data.records,
          candidatureDocRef: res.data.records[res.data.records.length - 1].doc,
        });
      })
      .catch((error) => {});
  };
  getUserExtraInfo = (id) => {
    let formData = new FormData();
    formData.append('id', id);
    axios
      .post('/api/users_extra_data_get', formData)
      .then((res) => {
        this.setState({
          userExtraData: res.data.records[0],
          userisColective: 1,
        });
      })
      .catch((error) => {});
  };
  checkCandidature = async () => {
    let formData = new FormData();

    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('id_votes', this.props.id_vote);
    await axios
      .post('/api/votes_ce_check_user_voted', formData)
      .then((res) => {
        this.setState({
          candidatureAlreadySent: false,
        });
      })
      .catch((error) => {
        this.setState({
          candidatureAlreadySent: true,
        });
      });
  };

  componentDidMount() {
    (async () => {
      await this.getInformation();
      await this.checkCandidature();
      await this.getUserExtraInfo(this.props.allinfoUser.id);
    })();
  }

  cancelCandidature = () => {
    this.setState({
      visibleModalPresentarCandidaturaSocio: false,
      visibleModalPresentarCandidatura: false,
    });
  };
  PresentarCandidaturaSocio = (event) => {
    event.preventDefault();

    this.setState({
      visibleModalPresentarCandidaturaSocio: true,
    });
  };

  PresentarCandidaturaSocioEnviar = (event) => {
    event.preventDefault();

    var dni = event.target.dniUser.value;
    var name = event.target.nameUser.value;
    var lastname = event.target.lastNameUser.value;
    var email = event.target.emailUser.value;
    var phone = event.target.mobileUser.value;
    var job = '';
    var type = this.state.idChooseVote;
    var doc = event.target.docCan.files[0];
    var doc_1 = '';
    var doc_2 = event.target.aval.files[0];
    if (!this.state.upload || this.state.upload === 'undefined') {
      doc_1 = '';
    } else {
      doc_1 = event.target.justificante.files[0];
    }
    let formData1 = new FormData();
    formData1.append('dni', event.target.dniUser.value);
    formData1.append('name', event.target.nameUser.value);
    formData1.append('lastname', event.target.lastNameUser.value);
    if (+this.state.idChooseVote === 1) {
      formData1.append('job', event.target.job.value);
      job = event.target.job.value;
    } else {
      formData1.append('job', ' ');
      job = ' ';
    }
    if (+job === 0 && +this.state.idChooseVote === 1) {
      alert('Por favor seleccione un Cargo para continuar');
    } else if (!doc_2) {
      alert('Por favor adjunte aval');
    } else {
      formData1.append('phone', event.target.mobileUser.value);
      formData1.append('id_user', this.props.idUser);

      axios
        .post('/api/votes_ce_candidates_update_user', formData1)
        .then((res) => {
          this.PresentarCandidatura(
            dni,
            name,
            lastname,
            email,
            phone,
            job,
            type,
            doc,
            doc_1,
            doc_2
          );
        })

        .catch((error) => {
          console.log(error.response);
          alert(
            'Hemos encontrado un problema y su candidatura no se ha enviado.'
          );
        });
    }

    /*
		let formData = new FormData();
		formData.append("id_votes", this.props.id_vote);
		formData.append("id_user", this.props.idUser);
		formData.append("doc", event.target.docCan.files[0]);

		if (
			+this.props.allinfoUser.id_type === 2 ||
			+this.props.allinfoUser.id_type === 3
		) {
			formData.append("confirmation", 1);
			formData.append("doc_1", "");
		} else {
			formData.append("doc_1", event.target.justificante.files[0]);
			formData.append("confirmation", 0);
		}
		formData.append("dni", event.target.dniUser.value);
		formData.append("username", event.target.nameUser.value);
		formData.append("lastname", event.target.lastNameUser.value);
		formData.append("email", event.target.emailUser.value);
		formData.append("phone", event.target.mobileUser.value);
		formData.append("job", event.target.job.user);
		formData.append("type", this.state.idChooseVote);

		axios
			.post("/api/votes_ce_candidates_set", formData)
			.then((res) => {
				alert("Ha enviado su candidatura correctamente.");
				window.location.reload();
			})

			.catch((error) => {
				console.log(error.response);
				alert(
					"Hemos encontrado un problema y su candidatura no se ha enviado."
				);
			});
			*/
  };
  PresentarCandidatura = (
    dni,
    name,
    lastname,
    email,
    phone,
    job,
    type,
    doc,
    doc_1,
    doc_2
  ) => {
    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.idUser);
    formData.append('doc', doc);

    if (+this.props.allinfoUser.id_type === 2) {
      formData.append('confirmation', 2);
      formData.append('doc_1', doc_1);
    } else {
      formData.append('doc_1', doc_1);
      formData.append('confirmation', 2);
    }

    formData.append('dni', dni);
    formData.append('username', name);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('job', job);
    formData.append('type', this.state.idChooseVote);
    formData.append('doc_2', doc_2);

    axios
      .post('/api/votes_ce_candidates_set', formData)
      .then((res) => {
        alert('Ha enviado su candidatura correctamente.');
        window.location.reload();
      })

      .catch((error) => {
        console.log(error.response);
        alert(
          'Hemos encontrado un problema y su candidatura no se ha enviado.'
        );
      });
  };

  chooseVote(idVote) {
    this.setState({
      idChooseVote: idVote,
    });
  }
  PresentarCandidaturaUpload = (event) => {
    event.preventDefault();
    let formData = new FormData();

    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user_sponsor', this.props.allinfoUser.id);
    formData.append('dni', this.props.allinfoUser.nif);
    formData.append('username', this.props.allinfoUser.name);
    formData.append('lastname', this.props.allinfoUser.lastname);
    formData.append('type', 2);
    formData.append('email', this.props.allinfoUser.email);
    formData.append('phone', this.props.allinfoUser.phone);
    formData.append('job', this.props.allinfoUser.job);

    axios
      .post('/api/votes_a_candidates_set', formData)
      .then((res) => {
        alert('Ha enviado su candidatura correctamente.');
        this.setState({ visibleModalPresentarCandidaturaSocio: false });
        window.location.reload();
      })

      .catch((error) => {
        console.log(error.response);
        this.setState({ visibleModalPresentarCandidaturaSocio: false });
        alert(
          'Hemos encontrado un error o es posible que ya haya presentado su candidtura.'
        );
      });
  };
  revokeCandidature = (event) => {
    event.preventDefault();
    let formData = new FormData();

    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('email', this.props.allinfoUser.email);
    formData.append('name', this.props.allinfoUser.name);
    formData.append('dni', this.props.allinfoUser.nif);
    formData.append('phone', this.props.allinfoUser.phone);
    formData.append('confirmation', 0);

    axios
      .post('/api/votes_ce_candidates_update_status', formData)
      .then((res) => {
        alert('Se ha retirado tu candidatura.');
        window.location.reload();
      })

      .catch((error) => {
        alert('Hemos encontrado un error y no se ha retirado su candidatura.');
      });
  };
  revokeCanidatureModal = (event) => {
    event.preventDefault();
    this.setState({
      visibleModalRetirarCandidatura: true,
    });
  };
  cancelCandidature = () => {
    this.setState({
      visibleModalRetirarCandidatura: false,
    });
  };
  render() {
    console.log(this.state.upload);
    const formCandiatureIndividual = (
      <Form
        onSubmit={this.PresentarCandidaturaSocioEnviar}
        className="BoxComitePresentCan"
      >
        <h5>CANDIDATURA INDIVIDUAL</h5>
        <Row>
          <Col lg={6} sm={12} hidden>
            <Form.Group controlId="typeCandidature">
              <Form.Control
                type="text"
                name="typeCandidature"
                defaultValue={this.state.colectiveType}
              />
            </Form.Group>
          </Col>{' '}
          <Col lg={6} sm={12}>
            <Form.Group controlId="nameUser">
              <Form.Label>Nombre*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nameUser"
                required
                defaultValue={
                  +this.state.userisColective === 1
                    ? this.state.userExtraData.name1
                    : this.props.allinfoUser.name
                }
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="lastNameUser">
              <Form.Label>Apellidos*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellidos"
                name="lastNameUser"
                required
                defaultValue={
                  +this.state.userisColective === 1
                    ? this.state.userExtraData.lastname1
                    : this.props.allinfoUser.lastname
                }
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="dniUser" className="fieldUnderText">
              <Form.Label>DNI*</Form.Label>
              <Form.Control
                required
                type="text"
                name="dniUser"
                placeholder="DNI"
                defaultValue={this.props.allinfoUser.nif}
              />
            </Form.Group>
            <p className="smallTextExplanation">
              El DNI debe estar formado solo por letras y números. No puede
              contener caractéres especiales o espacios en blanco.
            </p>
          </Col>
          <Col>
            <Form.Group controlId="emailUser">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                required
                type="email"
                name="emailUser"
                readOnly="true"
                placeholder="email"
                defaultValue={this.props.allinfoUser.email}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="phoneMobileUser">
              <Form.Label>Teléfono*</Form.Label>
              <Form.Control
                type="tel"
                name="mobileUser"
                required
                placeholder="Telefono"
                defaultValue={this.props.allinfoUser.phone}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="job">
              <Form.Label>
                Cargo para el que presenta su candidatura*
              </Form.Label>
              <Form.Control as="select" id="dropJob" required name="job">
                <option value="0">Seleccione...</option>
                <option value="Presidencia">Presidencia</option>
                <option value="Vicepresidencia">Vicepresidencia</option>
                <option value="Secretaría ejecutiva">
                  Secretaría ejecutiva
                </option>
                <option value="Tesorería">Tesorería</option>
                <option value="Vocalia">Vocalia</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="docCan">
              <Form.Label>Suba su candidatura*</Form.Label>
              <Form.Control
                onChange={(e) => console.log(e.target.files)}
                type="file"
                name="docCan"
                placeholder="Fichero a subir"
                required
              />
            </Form.Group>
          </Col>
          {+this.props.allinfoUser.id_type !== 2 ? (
            <Col lg={12} sm={12}>
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Group controlId="justificante">
                    <Form.Label>
                      Justificante de pertenencia a asociación territorial*
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setState({ upload: e.target.files[0] })
                      }
                      type="file"
                      name="justificante"
                      placeholder="Fichero a subir"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          ) : (
            ''
          )}
          <Col sm={12} xs={12}>
            <hr></hr>
            <h5>Aval*</h5>
            <Form.Group controlId="aval">
              <Form.Label>
                Suba los avales requeridos (agrupe los avales en un único
                documento)
              </Form.Label>
              <Form.Control
                onChange={(e) => console.log(e.target.files)}
                type="file"
                name="aval"
                placeholder="Fichero a subir"
                required
              />
            </Form.Group>
          </Col>
          {+this.props.allinfoUser.id_type !== 2 ? (
            <Col lg={12} sm={12}></Col>
          ) : (
            ''
          )}
          <Col sm={12} xs={12} className="center">
            <Button
              ref="btnCandidatura"
              variant="primary"
              type="submit"
              className="center"
            >
              Enviar Candidatura
            </Button>
          </Col>
        </Row>
      </Form>
    );
    const formCandiatureColective = (
      <Form
        onSubmit={this.PresentarCandidaturaSocioEnviar}
        className="BoxComitePresentCan"
      >
        <h5>CANDIDATURA COLECTIVA</h5>

        <Row>
          <Col lg={6} sm={12} hidden>
            <Form.Group controlId="typeCandidature">
              <Form.Control
                type="text"
                name="typeCandidature"
                defaultValue={this.state.colectiveType}
              />
            </Form.Group>
          </Col>{' '}
          <Col lg={6} sm={12}>
            <Form.Group controlId="nameUser">
              <Form.Label>Nombre*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nameUser"
                required
                defaultValue={
                  +this.state.userisColective === 1
                    ? this.state.userExtraData.name1
                    : this.props.allinfoUser.name
                }
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="lastNameUser">
              <Form.Label>Apellidos*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellidos"
                name="lastNameUser"
                required
                defaultValue={
                  +this.state.userisColective === 1
                    ? this.state.userExtraData.lastname1
                    : this.props.allinfoUser.lastname
                }
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="dniUser" className="fieldUnderText">
              <Form.Label>DNI*</Form.Label>
              <Form.Control
                required
                type="text"
                name="dniUser"
                placeholder="DNI"
                defaultValue={this.props.allinfoUser.nif}
              />
            </Form.Group>
            <p className="smallTextExplanation">
              El DNI debe estar formado solo por letras y números. No puede
              contener caractéres especiales o espacios en blanco.
            </p>
          </Col>
          <Col>
            <Form.Group controlId="emailUser">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                required
                type="email"
                name="emailUser"
                readOnly="true"
                placeholder="email"
                defaultValue={this.props.allinfoUser.email}
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12}>
            <Form.Group controlId="phoneMobileUser">
              <Form.Label>Teléfono*</Form.Label>
              <Form.Control
                type="tel"
                name="mobileUser"
                required
                placeholder="Telefono"
                defaultValue={this.props.allinfoUser.phone}
              />
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="docCan">
              <Form.Label>Suba su candidatura*</Form.Label>
              <Form.Control
                onChange={(e) => console.log(e.target.files)}
                type="file"
                name="docCan"
                placeholder="Fichero a subir"
                required
              />
            </Form.Group>
          </Col>
          {+this.props.allinfoUser.id_type !== 2 ? (
            <Col lg={12} sm={12}>
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Group controlId="justificante">
                    <Form.Label>
                      Justificante de pertenencia a asociación territorial (si
                      realiza una candidatura colectiva y el resto de miembros
                      pertenece a una asociación territorial, debe de mandarnos
                      un fichero justificando la afiliación del resto de
                      miembros)*
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setState({ upload: e.target.files[0] })
                      }
                      type="file"
                      name="justificante"
                      placeholder="Fichero a subir"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col lg={12} sm={12}>
              <Row>
                <Col lg={12} sm={12}>
                  <Form.Group controlId="justificante">
                    <Form.Label>
                      Justificante de pertenencia a asociación territorial (si
                      realiza una candidatura colectiva y el resto de miembros
                      pertenece a una asociación territorial, debe de mandarnos
                      un fichero justificando la afiliación del resto de
                      miembros)
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setState({ upload: e.target.files[0] })
                      }
                      type="file"
                      name="justificante"
                      placeholder="Fichero a subir"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          )}
          <Col sm={12} xs={12}>
            <hr></hr>
            <h5>Aval*</h5>
            <Form.Group controlId="aval">
              <Form.Label>
                Suba los avales requeridos (agrupe los avales en un único
                documento)
              </Form.Label>
              <Form.Control
                onChange={(e) => console.log(e.target.files)}
                type="file"
                name="aval"
                placeholder="Fichero a subir"
                required
              />
            </Form.Group>
          </Col>
          {+this.props.allinfoUser.id_type !== 2 ? (
            <Col lg={12} sm={12}></Col>
          ) : (
            ''
          )}
          <Col sm={12} xs={12} className="center">
            <Button
              ref="btnCandidatura"
              variant="primary"
              type="submit"
              className="center"
            >
              Enviar Candidatura
            </Button>
          </Col>
        </Row>
      </Form>
    );
    const optionsSendCandidature = (
      <div>
        {+this.state.idChooseVote === 1 ? formCandiatureIndividual : ''}
        {+this.state.idChooseVote === 2 ? formCandiatureColective : ''}
      </div>
    );
    return (
      <div>
        <Modal
          title={'Retirar Candidature'}
          visible={this.state.visibleModalRetirarCandidatura}
          onOk={this.revokeCandidature}
          onCancel={this.cancelCandidature}
          cancelText="Volver atras"
          okText="Retirar"
        >
          <p>Va a retirar su candidatura.</p>
        </Modal>
        <h3 className="center">Abierto proceso para enviar candidaturas</h3>
        <h5 className="center">
          El proceso de candidaturas estará abierto del {this.props.dateBegin}{' '}
          al {this.props.dateEnd}.
        </h5>

        <hr></hr>

        {this.state.candidatureAlreadySent === true ? (
          <div>
            <h4 className="center">Ya ha presentado su candidatura.</h4>
            <p>
              Si lo desea puede retirar su candidatura haciendo click en el
              siguiente botón:
            </p>
            <div className=" center">
              {' '}
              <button
                className="button center"
                onClick={this.revokeCanidatureModal}
              >
                Retirar candidatura
              </button>
            </div>
          </div>
        ) : (
          <div>
            {' '}
            <div style={{ marginBottom: '10px' }}>
              <div>
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.instruciones }}
                />
              </div>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => this.chooseVote(1)}
            >
              PRESENTAR CANDIDATURA INDIVIDUAL
            </button>
            <hr></hr>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => this.chooseVote(2)}
              >
                PRESENTAR CANDIDATURA COLECTIVA
              </button>
            </div>
          </div>
        )}

        {this.state.candidatureAlreadySent === true
          ? ''
          : optionsSendCandidature}
      </div>
    );
  }
}

export default Candidature;
