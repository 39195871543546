import React from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";

import TopImageTitle from "../components/objects/topImageTitle";
import imageText from "../components/img/comites-investigacion-mini.jpg";
import LogoGrupo from "../components/img/GT_.png";
import LogoComite from "../components/img/CI_.png";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

class Comites extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			comites: [],
			listaComites: [],
			gTrabajo: [],
			listaGTrabajo: [],
			listaTrabajoFetching: false,
			array_post: props.array_post,
			array_page: props.array_page,
			text2: "",
			text3: "",
		};
	}
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	getComites = async () => {
		this.setState({
			comiteFetching: true,
		});

		let res = await axios.get("/api/comites_general_get");

		this.setState({
			comites: res.data.records.slice(0, 1),
			comiteFetching: false,
		});
	};

	getListaTrabajo = async () => {
		this.setState({
			listaTrabajoFetching: true,
		});

		let res = await axios.get("/api/workgroups_get");

		this.setState({
			listaGTrabajo: res.data.records,
			listaTrabajoFetching: false,
		});
	};

	getListaComites = async () => {
		this.setState({
			listaComiteFetching: true,
		});

		let res = await axios.get("/api/comites_get");

		this.setState({
			listaComites: res.data.records,
			listaComiteFetching: false,
		});
	};

	componentDidMount() {
		(async () => {
			await this.getComites();
			await this.getListaTrabajo();
			await this.getListaComites();
			await this.collapsible();
		})();
		if (this.props.array_post.length) {
			this.setState({
				text2: this.props.array_post[0].content2,
				text3: this.props.array_post[0].content3,
			});
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { comites } = this.state;
		const { listaComites } = this.state;
		const { listaGTrabajo } = this.state;
		const { listaTrabajoFetching } = this.state;
		const { array_post } = this.state;

		const defaultImage = comites.map((comites) => {
			if (!comites.img) {
				return (
					<div key={comites.id}>
						<img
							className="width-100"
							src={imageText}
							alt="Comites de investigacion FES"
						></img>
					</div>
				);
			} else {
				return (
					<div key={comites.id}>
						<img
							className="width-100"
							src={imageText}
							alt="Comites de investigacion FES"
						></img>
					</div>
				);
			}
		});
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});

		const comite = array_post.map((comite, index) => {
			return (
				<div key={index}>
					<Row>
						<Col lg={6} md={12}>
							<h4>{comite.title}</h4>
							<div dangerouslySetInnerHTML={{ __html: comite.content }} />

							<div
								dangerouslySetInnerHTML={{ __html: comite.content2 }}
								className="text_descriptions"
							/>
						</Col>
						<Col lg={6} md={12}>
							{defaultImage}
						</Col>
					</Row>
				</div>
			);
		});
		/*
		const comite = comites.map((comite) => {
			return (
				<div key={comite.id}>
					<Row>
						<Col lg={6} md={12}>
							<h4>{comite.title}</h4>
							<div dangerouslySetInnerHTML={{ __html: comite.desc_generic }} />
							<button type="button" className="collapsible noCollapsed">
								¿QUÉ SON LOS COMITÉS DE INVESTIGACIÓN?
							</button>
							<div className="content_collapsible">
								<br></br>
								<div
									dangerouslySetInnerHTML={{ __html: comite.desc_us }}
									className="text_descriptions"
								/>
							</div>
							<button type="button" className="collapsible noCollapsed">
								ACTIVIDADES
							</button>
							<div className="content_collapsible">
								<br></br>
								<div
									dangerouslySetInnerHTML={{ __html: comite.desc_rules }}
									className="text_descriptions"
								/>
							</div>
							<button type="button" className="collapsible noCollapsed">
								SERVICIOS DE LA FES A LOS COMITÉS
							</button>
							<div className="content_collapsible">
								<br></br>
								<div
									dangerouslySetInnerHTML={{ __html: comite.desc_services }}
									className="text_descriptions"
								/>
							</div>
							<Divider></Divider>
							<div dangerouslySetInnerHTML={{ __html: this.state.text2 }} />
						</Col>
						<Col lg={6} md={12}>
							{defaultImage}
						</Col>
					</Row>
				</div>
			);
		});
*/
		const listadocomite = listaComites.map((comite) => {
			if (+comite.active === 1 || +comite.active === 2) {
				return (
					<Col key={comite.id} lg={4} md={12} className="comiteLevel01">
						<div className="comiteBox">
							<div className="inner">
								<Row className="tableComiteGrupos">
									<Col
										lg={3}
										md={12}
										className="textColorSecondary center colLogo"
									>
										<img
											src={LogoComite}
											alt="logo Comite"
											className="logoComite"
										></img>
									</Col>
									<Col lg={9} md={12} className="colTitle">
										<Link to={`/comite/${comite.permalink}`}>
											<h4>{comite.title}</h4>
											{+comite.active === 2 ? <p>(En costrucción)</p> : null}
										</Link>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				);
			} else {
				return null;
			}
		});
		const listadoGrupo = !listaTrabajoFetching ? (
			listaGTrabajo.map((gtrabajo) => {
				if (+gtrabajo.active === 1 || +gtrabajo.active === 2) {
					return (
						<Col key={gtrabajo.id} lg={4} md={12} className="comiteLevel01">
							<div className="comiteBox">
								<div className="inner">
									<Row className="tableComiteGrupos">
										<Col
											lg={3}
											md={12}
											className="textColorSecondary center colLogo"
										>
											<img
												src={LogoGrupo}
												alt="logo Grupos de trabajo"
												className="logoComite"
											></img>
										</Col>
										<Col lg={9} md={12} className="colTitle">
											<Link to={`/grupo-de-trabajo/${gtrabajo.permalink}`}>
												<h4>{gtrabajo.title}</h4>
												{+gtrabajo.active === 2 ? (
													<p>(Grupo de trabajo en costrucción)</p>
												) : null}
											</Link>
										</Col>
									</Row>
								</div>
							</div>
						</Col>
					);
				} else {
					return null;
				}
			})
		) : (
			<p>Cargando grupos de trabajo</p>
		);
		const secondSectionComite = comites.map((comite) => {
			return (
				<Row key={comite.id}>
					<Col sm={12}>
						<hr className="whiteSpace"></hr>
						<div>
							<div dangerouslySetInnerHTML={{ __html: this.state.text3 }} />
						</div>
					</Col>
				</Row>
			);
		});

		this.collapsible();
		return (
			<div>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>
						<Col sm={12}>
							{comite}
							<Row>
								<Col lg={6} md={12}>
									<div
										dangerouslySetInnerHTML={{ __html: this.state.content2 }}
									/>{" "}
								</Col>
							</Row>
						</Col>

						<Col>
							<h2 className="center">Listado de comités</h2>
						</Col>
					</Row>
					<Row className="comiteListing">{listadocomite}</Row>
					{secondSectionComite}
					<Row>{listadoGrupo}</Row>
				</Container>
			</div>
		);
	}
}

export default Comites;
