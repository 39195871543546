import React from "react";
import { Row, Col, Container } from "react-grid-system";
import Logos from "./logos.js";
import "../footer/style.css";
import logoFES from "../img/FES_idiomas_white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";

// class component
class Footer extends React.Component {
	render() {
		return (
			<footer>
				<Container>
					<Row className="center ">
						<Col lg={12} className="margin-top-100">
							<Logos />
						</Col>
					</Row>
					<Row className="center">
						<Col lg={12}>
							<p>
								<img
									width="400px"
									src={logoFES}
									alt="logo FES"
									className="logoFooter"
								></img>
							</p>
						</Col>
						<Col lg={12}>
							<a
								target="_blank"
								href="https://www.linkedin.com/company/federacion-espa%C3%B1ola-de-sociologia/"
								rel="noreferrer noopener"
								className="socialFooter"
							>
								<FontAwesomeIcon
									icon={fab.faLinkedin}
									size="2x"
									className="socialIconsTop"
								/>
							</a>
							<a
								target="_blank"
								href="https://www.facebook.com/federacionespanoladesociologia"
								rel="noreferrer noopener"
								className="socialFooter"
							>
								<FontAwesomeIcon
									icon={fab.faFacebookF}
									size="2x"
									className="socialIconsTop"
								/>
							</a>
							<a
								target="_blank"
								rel="noreferrer noopener"
								href="https://twitter.com/FES_Sociologia"
								className="socialFooter"
							>
								<FontAwesomeIcon
									icon={fab.faTwitter}
									size="2x"
									className="socialIconsTop"
								/>
							</a>
							<a
								target="_blank"
								rel="noreferrer noopener"
								href="https://www.youtube.com/channel/UCwpCH36H2KCeyXrzeygUr-A"
								className="socialFooter"
							>
								<FontAwesomeIcon
									icon={fab.faYoutube}
									size="2x"
									className="socialIconsTop"
								/>
							</a>
						</Col>
					</Row>
					<Row>
						<Col sm={2} xs={12}></Col>
						<Col sm={8} xs={12}>
							{" "}
							<Row>
								<Col sm={12}>
									<p className="center"></p>
								</Col>
							</Row>
							<Row className="center">
								<Col lg={4}>
									<p>
										<FontAwesomeIcon icon={Icons.faMapMarked} size="1x" />{" "}
										&nbsp; C/ Montalbán, 8. 28014 Madrid
									</p>
								</Col>
								<Col lg={4}>
									<FontAwesomeIcon icon={Icons.faPhone} size="1x" />
									&nbsp;
									<a href="tel:+34629271961">+34 629 27 19 61</a>
								</Col>
								<Col lg={4}>
									<FontAwesomeIcon icon={Icons.faEnvelope} size="1x" />
									&nbsp;
									<a href="mailto:info@fes-sociologia.com">
										info@fes-sociologia.com
									</a>
								</Col>
							</Row>
							<Row className="center padding-20">
								<Col>
									<a
										href="https://fes-sociologia.com/uploads/privacy/Politica_de_privacidad.pdf"
										target="_blank"
										rel="noopener noreferrer"
										className="marginLittle"
									>
										Politica de privacidad
									</a>
									<span>-</span>
									<a
										href="https://fes-sociologia.com/uploads/privacy/Aviso_legal.pdf"
										target="_blank"
										rel="noopener noreferrer"
										className="marginLittle"
									>
										Aviso Legal
									</a>
								</Col>
							</Row>
							<Row className="center">
								<Col>
									<p>©2020 Todos los derechos reservados por FES.</p>
								</Col>
							</Row>
						</Col>
						<Col sm={2} xs={12}></Col>
					</Row>
				</Container>
			</footer>
		);
	}
}

export default Footer;
