import React from "react";
import SEO from "../components/objects/seo";
class Page404 extends React.Component {
	render() {
		return (
			<section>
				<SEO
					title="Pagina no Encontrada"
					description="La pagina buscada no ha sido encontrada"
				></SEO>
				<p className="center">Lo sentimos no se ha encontrado el contenido </p>
				<h2 className="center">404</h2>
			</section>
		);
	}
}

export default Page404;
