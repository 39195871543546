import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
//import { Link } from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';
class Candidature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidatures: [],
      candidatureDocRef: '',
      candidatureInfo: [],
      candidatureAlreadySent: true,
      candidatureAvailiable: [],
      titleCandidatureSent: '',
      dateCandidatureSent: '',
      colectivePresident: 0,
      comitePresident: 0,
      checkCandidature: 0,
      colectiveType: '',
      idChooseVote: '',
      visibleModalPresentarCandidatura: false,
      visibleModalPresentarCandidaturaSocio: false,
      visibleModalRetirarCandidatura: false,
      userExtraData: '',
      userisColective: 0,
      canSubmit: 0,
    };
  }
  checkTypeOfCandidature = async () => {
    this.setState({
      checkCandidature: 1,
    });
    let formData = new FormData();

    formData.append('email', this.props.emailUser);

    await axios
      .post('/api/users_check_president', formData)
      .then((res) => {
        this.setState({
          colectiveType: res.data.type,
          // modificado aqui
          colectivePresident: 1,
        });
        this.getPresident(res.data.type);
      })
      .catch((error) => {
        this.setState({
          // colectivePresident: 0,
          checkCandidature: 0,
        });
      });

    formData.append('email', this.props.emailUser);

    await axios
      .post('/api/users_check_comite_president', formData)
      .then((res) => {
        this.getComitePresident();
      })
      .catch((error) => {
        this.setState({
          comitePresident: 0,
          checkCandidature: 0,
        });
      });
  };

  getPresident = async (idType) => {
    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('id_type', idType);
    formData.append('email', this.props.allinfoUser.email);
    formData.append('opt', 1);

    await axios
      .post('/api/votes_a_candidates_user_candidate_check', formData)
      .then((res) => {
        this.setState({
          colectivePresident: 1,
          checkCandidature: 0,
        });
      })
      .catch((error) => {
        this.setState({
          // colectivePresident: 0,
          checkCandidature: 0,
        });
      });
  };
  getComitePresident = async () => {
    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('id_type', this.props.allinfoUser.id_type);
    formData.append('email', this.props.allinfoUser.email);
    formData.append('opt', 1);

    await axios
      .post('/api/votes_a_candidates_user_candidate_check', formData)
      .then((res) => {
        this.setState({
          comitePresident: 1,
          checkCandidature: 0,
        });
      })
      .catch((error) => {
        this.setState({
          comitePresident: 0,
          checkCandidature: 0,
        });
      });
  };
  getInformation = async () => {
    await axios
      .get('/api/votes_a_config_get')
      .then((res) => {
        this.setState({
          candidatureInfo: res.data.records,
          candidatureDocRef: res.data.records[res.data.records.length - 1].doc,
        });
      })
      .catch((error) => {});
  };
  checkCandidature = async () => {
    let formData = new FormData();

    formData.append('id_user_sponsor', this.props.idUser);
    formData.append('id_votes', this.props.id_vote);
    await axios
      .post('/api/votes_a_candidates_check', formData)
      .then((res) => {
        this.setState({
          candidatureAlreadySent: false,
        });
      })
      .catch((error) => {
        this.setState({
          candidatureAlreadySent: true,
        });
      });
  };
  getUserExtraInfo = (id) => {
    let formData = new FormData();
    formData.append('id', id);
    axios
      .post('/api/users_extra_data_get', formData)
      .then((res) => {
        this.setState({
          userExtraData: res.data.records[0],
          userisColective: 1,
        });
      })
      .catch((error) => {});
  };
  canSubmit = async () => {
    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('id_type', 2);
    formData.append('email', this.props.allinfoUser.email);

    await axios
      .post('/api/votes_a_candidates_user_candidate_check', formData)
      .then((res) => {
        this.setState({
          canSubmit: 1,
        });
      })
      .catch((error) => {
        this.setState({
          canSubmit: 0,
        });
      });
  };
  componentDidMount() {
    (async () => {
      await this.checkTypeOfCandidature();
      await this.getInformation();
      await this.checkCandidature();
      await this.getUserExtraInfo(this.props.allinfoUser.id);
      await this.canSubmit();
    })();
  }
  PresentarCandidatura2 = (event) => {
    event.preventDefault();

    if (
      event.target.dniUser.value &&
      event.target.nameUser.value &&
      event.target.lastNameUser.value &&
      event.target.emailUser.value &&
      event.target.org_extra.value
    ) {
      this.setState({
        visibleModalPresentarCandidatura2: true,
        forCandiaturedni: event.target.dniUser.value,
        forCandiaturenameUser: event.target.nameUser.value,
        forCandiaturelastNameUser: event.target.lastNameUser.value,
        forCandiatureemailUser: event.target.emailUser.value,
        forCandiaturejob: event.target.org_extra.value,
        forCandiaturemobile: event.target.mobileUser.value,
      });
    } else {
      alert(
        'Por favor, revise que todos los campos obligatorios estén completados y vuelva a intentarlo'
      );
    }
  };
  PresentarCandidatura = (event) => {
    event.preventDefault();

    if (
      event.target.dniUser.value &&
      event.target.nameUser.value &&
      event.target.lastNameUser.value &&
      event.target.emailUser.value &&
      event.target.org_extra.value
    ) {
      this.setState({
        visibleModalPresentarCandidatura: true,
        forCandiaturedni: event.target.dniUser.value,
        forCandiaturenameUser: event.target.nameUser.value,
        forCandiaturelastNameUser: event.target.lastNameUser.value,
        forCandiatureemailUser: event.target.emailUser.value,
        forCandiaturejob: event.target.org_extra.value,
        forCandiaturemobile: event.target.mobileUser.value,
      });
    } else {
      alert(
        'Por favor, revise que todos los campos obligatorios estén completados y vuelva a intentarlo'
      );
    }
  };

  PresentarCandidaturaEnviar = (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user_sponsor', this.props.allinfoUser.id);
    formData.append('dni', this.state.forCandiaturedni);
    formData.append('username', this.state.forCandiaturenameUser);
    formData.append('lastname', this.state.forCandiaturelastNameUser);
    formData.append('email_sponsor', this.props.allinfoUser.email);
    formData.append('email', this.state.forCandiatureemailUser);
    formData.append('phone', this.state.forCandiaturemobile);
    formData.append('job', this.state.forCandiaturejob);

    formData.append('type', this.state.colectiveType);

    axios
      .post('/api/votes_a_candidates_set', formData)
      .then((res) => {
        alert('Ha enviado su candidatura correctamente.');
        this.setState({ visibleModalPresentarCandidatura: false });
        window.location.reload();
      })

      .catch((error) => {
        this.setState({ visibleModalPresentarCandidatura: false });
        alert(
          'Hemos encontrado un error o es posible que ya haya presentado su candidtura.'
        );
      });
  };
  PresentarCandidaturaEnviar9 = (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user_sponsor', this.props.allinfoUser.id);
    formData.append('dni', this.state.forCandiaturedni);
    formData.append('username', this.state.forCandiaturenameUser);
    formData.append('lastname', this.state.forCandiaturelastNameUser);
    formData.append('email_sponsor', this.props.allinfoUser.email);
    formData.append('email', this.state.forCandiatureemailUser);
    formData.append('phone', this.state.forCandiaturemobile);
    formData.append('job', this.state.forCandiaturejob);

    formData.append('type', 9);

    axios
      .post('/api/votes_a_candidates_set', formData)
      .then((res) => {
        alert('Ha enviado su candidatura correctamente.');
        this.setState({ visibleModalPresentarCandidatura2: false });
        window.location.reload();
      })

      .catch((error) => {
        this.setState({ visibleModalPresentarCandidatura2: false });
        alert(
          'Hemos encontrado un error o es posible que ya haya presentado su candidtura.'
        );
      });
  };
  cancelCandidature = () => {
    this.setState({
      visibleModalPresentarCandidaturaSocio: false,
      visibleModalPresentarCandidatura: false,
      visibleModalPresentarCandidatura2: false,
      visibleModalRetirarCandidatura: false,
    });
  };
  PresentarCandidaturaSocio = (event) => {
    event.preventDefault();

    this.setState({
      forCandiaturemobile: event.target.mobileUser.value,
      visibleModalPresentarCandidaturaSocio: true,
    });
  };
  PresentarCandidaturaSocioEnviar = (event) => {
    event.preventDefault();

    if (!this.state.forCandiaturemobile) {
      alert(
        'Por favor, revise que todos los campos obligatorios estén completados y vuelva a intentarlo'
      );
    } else {
      let formData = new FormData();
      formData.append('id_votes', this.props.id_vote);
      formData.append('id_user_sponsor', this.props.allinfoUser.id);
      formData.append('dni', this.props.allinfoUser.nif);
      formData.append('username', this.props.allinfoUser.name);
      formData.append('lastname', this.props.allinfoUser.lastname);
      formData.append('type', 2);
      formData.append('email', this.props.allinfoUser.email);
      formData.append('phone', this.state.forCandiaturemobile);
      formData.append('job', '');
      formData.append('email_sponsor', this.props.allinfoUser.email);

      axios
        .post('/api/votes_a_candidates_set', formData)
        .then((res) => {
          alert('Ha enviado su candidatura correctamente.');
          this.setState({ visibleModalPresentarCandidaturaSocio: false });
          window.location.reload();
        })

        .catch((error) => {
          this.setState({ visibleModalPresentarCandidaturaSocio: false });
          alert(
            'Hemos encontrado un error o es posible que ya haya presentado su candidtura.'
          );
        });
    }
  };
  chooseVote(idVote) {
    this.setState({
      idChooseVote: idVote,
    });
  }
  revokeCandidature = (event) => {
    event.preventDefault();
    let formData = new FormData();

    formData.append('id_votes', this.props.id_vote);
    formData.append('id', this.props.allinfoUser.id);
    formData.append('username', this.props.allinfoUser.name);
    formData.append('lastname', this.props.allinfoUser.lastname);
    formData.append('dni', this.props.allinfoUser.nif);
    formData.append('email', this.props.allinfoUser.email);
    formData.append('phone', this.props.allinfoUser.phone);

    axios
      .post('/api/votes_a_candidates_delete', formData)
      .then((res) => {
        alert('Se ha retirado tu candidatura.');
        window.location.reload();
      })

      .catch((error) => {
        alert('Hemos encontrado un error y no se ha retirado su candidatura.');
      });
  };
  revokeCanidatureModal = (event) => {
    event.preventDefault();
    this.setState({
      visibleModalRetirarCandidatura: true,
    });
  };

  render() {
    let formCandiatureColective;
    let formCandiatureComite;

    //

    console.log('allinfoUser', this.props.allInfoUser);

    console.log('colectiveType', this.state.colectiveType);

    console.log('colectivePresident', this.state.colectivePresident);

    //

    if (+this.state.colectivePresident === 1) {
      formCandiatureColective = (
        <Form
          onSubmit={this.PresentarCandidatura}
          className="BoxComitePresentCan"
        >
          <Row>
            <Col lg={12} sm={12}>
              <h5>
                En el siguiente formulario aparecen los datos del representante
                de la institución disponibles en nuestra base de datos. Debe
                rellenar todos los campos con asterisco. Si lo desea, puede
                designar a otra persona para que represente a su institución en
                la Asamblea. Para ello simplemente escriba los nuevos datos
                sobre los campos del formulario. Para finalizar pulsar “Enviar
                candidatura”.
              </h5>
            </Col>
            <Col lg={6} sm={12} hidden>
              <Form.Group controlId="typeCandidature">
                <Form.Control
                  type="text"
                  name="typeCandidature"
                  defaultValue={this.state.colectiveType}
                />
              </Form.Group>
            </Col>{' '}
            <Col lg={6} sm={12}>
              <Form.Group controlId="nameUser">
                <Form.Label>
                  <Form.Label>Nombre*</Form.Label>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  name="nameUser"
                  required
                  defaultValue={
                    +this.state.userisColective === 1
                      ? this.state.userExtraData.name1
                      : this.props.allinfoUser.name
                  }
                />
              </Form.Group>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="lastNameUser">
                <Form.Label>Apellidos*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Apellidos"
                  name="lastNameUser"
                  required
                  defaultValue={
                    +this.state.userisColective === 1
                      ? this.state.userExtraData.lastname1
                      : this.props.allinfoUser.lastname
                  }
                />
              </Form.Group>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="dniUser" className="fieldUnderText">
                <Form.Label>DNI*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="dniUser"
                  placeholder="DNI"
                  defaultValue={this.props.allinfoUser.nif}
                />
              </Form.Group>
              <p className="smallTextExplanation">
                El DNI debe estar formado solo por letras y números. No puede
                contener caractéres especiales o espacios en blanco.
              </p>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="cargoUser">
                <Form.Label>Cargo en la institución*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="org_extra"
                  placeholder="Cargo"
                  defaultValue=""
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="emailUser">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="emailUser"
                  placeholder="email"
                  defaultValue={this.props.allinfoUser.email}
                />
              </Form.Group>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="phoneMobileUser">
                <Form.Label>Teléfono*</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  name="mobileUser"
                  placeholder="Telefono"
                  defaultValue={this.props.allinfoUser.phone}
                />
              </Form.Group>
            </Col>
            <Col sm={12} xs={12} className="center">
              <Button
                ref="btnCandidatura"
                variant="primary"
                type="submit"
                className="center"
              >
                Enviar Candidatura
              </Button>
            </Col>
          </Row>
        </Form>
      );
    }
    if (+this.state.comitePresident === 1) {
      formCandiatureComite = (
        <Form
          onSubmit={this.PresentarCandidatura2}
          className="BoxComitePresentCan"
        >
          <Row>
            <Col lg={12} sm={12}>
              <h5>
                En el siguiente formulario aparecen los datos del representante
                de la institución disponibles en nuestra base de datos. Debe
                rellenar todos los campos con asterisco. Si lo desea, puede
                designar a otra persona para que represente a su institución en
                la Asamblea. Para ello simplemente escriba los nuevos datos
                sobre los campos del formulario. Para finalizar pulsar “Enviar
                candidatura”.
              </h5>
            </Col>
            <Col lg={6} sm={12} hidden>
              <Form.Group controlId="typeCandidature">
                <Form.Control
                  type="text"
                  name="typeCandidature"
                  defaultValue="1"
                />
              </Form.Group>
            </Col>{' '}
            <Col lg={6} sm={12}>
              <Form.Group controlId="nameUser">
                <Form.Label>Nombre*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  name="nameUser"
                  required
                  defaultValue={
                    +this.state.userisColective === 1
                      ? this.state.userExtraData.name1
                      : this.props.allinfoUser.name
                  }
                />
              </Form.Group>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="lastNameUser">
                <Form.Label>Apellidos*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Apellidos"
                  name="lastNameUser"
                  required
                  defaultValue={
                    +this.state.userisColective === 1
                      ? this.state.userExtraData.lastname1
                      : this.props.allinfoUser.lastname
                  }
                />
              </Form.Group>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="dniUser" className="fieldUnderText">
                <Form.Label>DNI*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="dniUser"
                  placeholder="DNI"
                  defaultValue={this.props.allinfoUser.nif}
                />
              </Form.Group>
              <p className="smallTextExplanation">
                El DNI debe estar formado solo por letras y números. No puede
                contener caractéres especiales o espacios en blanco.
              </p>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="cargoUser">
                <Form.Label>Cargo en la institución*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="org_extra"
                  placeholder="Cargo"
                  defaultValue="Presidente"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="emailUser">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="emailUser"
                  placeholder="email"
                  defaultValue={this.props.allinfoUser.email}
                />
              </Form.Group>
            </Col>
            <Col lg={6} sm={12}>
              <Form.Group controlId="phoneMobileUser">
                <Form.Label>Teléfono*</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  name="mobileUser"
                  placeholder="Telefono"
                  defaultValue={this.props.allinfoUser.phone}
                />
              </Form.Group>
            </Col>
            <Col sm={12} xs={12} className="center">
              <Button
                ref="btnCandidatura"
                variant="primary"
                type="submit"
                className="center"
              >
                Enviar Candidatura
              </Button>
            </Col>
          </Row>
        </Form>
      );
    }

    const formCandiature = (
      <Form
        onSubmit={this.PresentarCandidaturaSocio}
        className="BoxComitePresentCan"
      >
        <Col lg={6} sm={12} hidden>
          <Form.Group controlId="typeCandidature">
            <Form.Control type="text" name="typeCandidature" defaultValue="2" />
          </Form.Group>
        </Col>{' '}
        <Row>
          {+this.props.allinfoUser.id_type === 5 ||
          +this.props.allinfoUser.id_type === 4 ||
          +this.props.allinfoUser.id_type === 6 ||
          +this.props.allinfoUser.id_type === 7 ? (
            <Col sm={12} xs={12} className="center">
              <h5>No puede presentar su candidatura</h5>
            </Col>
          ) : +this.props.allinfoUser.id_type === 2 ? (
            this.props.allinfoUser.email &&
            this.props.allinfoUser.nif &&
            this.props.allinfoUser.lastname ? (
              <Row sm={12} xs={12}>
                <Col lg={12} sm={12}>
                  <h5 className="center">
                    Puede presentarse a las elecciones:
                  </h5>
                  <p>
                    En el siguiente formulario aparecen los datos del socio/a
                    disponibles en nuestra base de datos. Estos datos serán
                    obligatorios para presentar la candidatura.
                  </p>
                </Col>
                <Col lg={6} sm={12} hidden>
                  <Form.Group controlId="typeCandidature">
                    <Form.Control
                      type="text"
                      name="typeCandidature"
                      defaultValue={this.state.colectiveType}
                    />
                  </Form.Group>
                </Col>{' '}
                <Col lg={6} sm={12}>
                  <Form.Group controlId="nameUser">
                    <Form.Label>Nombre*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre"
                      name="nameUser"
                      required
                      readOnly="true"
                      defaultValue={
                        +this.state.userisColective === 1
                          ? this.state.userExtraData.name1
                          : this.props.allinfoUser.name
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group controlId="lastNameUser">
                    <Form.Label>Apellidos*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Apellidos"
                      name="lastNameUser"
                      required
                      readOnly="true"
                      defaultValue={
                        +this.state.userisColective === 1
                          ? this.state.userExtraData.lastname1
                          : this.props.allinfoUser.lastname
                      }
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group controlId="dniUser" className="fieldUnderText">
                    <Form.Label>DNI*</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="dniUser"
                      placeholder="DNI"
                      readOnly="true"
                      defaultValue={this.props.allinfoUser.nif}
                    />
                  </Form.Group>
                  <p className="smallTextExplanation">
                    El DNI debe estar formado solo por letras y números. No
                    puede contener caractéres especiales o espacios en blanco.
                  </p>
                </Col>
                <Col>
                  <Form.Group controlId="emailUser">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      name="emailUser"
                      placeholder="email"
                      readOnly="true"
                      defaultValue={this.props.allinfoUser.email}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group controlId="phoneMobileUser">
                    <Form.Label>Teléfono*</Form.Label>
                    <Form.Control
                      required
                      type="tel"
                      name="mobileUser"
                      placeholder="Telefono"
                      defaultValue={this.props.allinfoUser.phone}
                    />
                  </Form.Group>
                </Col>
                <Col lg={12} sm={12} className="center">
                  {' '}
                  <Button
                    ref="btnCandidatura"
                    variant="primary"
                    type="submit"
                    className="center"
                  >
                    Enviar candidatura
                  </Button>
                </Col>
              </Row>
            ) : (
              <Col sm={12} xs={12} className="center">
                <h5>
                  Para presentar la candidatura es necesario que rellene su
                  perfil. Los datos de nombre, apellido, email, dni, cargo y
                  teléfono son obligatorios para presentarse.
                </h5>
              </Col>
            )
          ) : (
            ''
          )}
        </Row>
      </Form>
    );

    const optionsSendCandidature = (
      <div key={this.state.idChooseVote}>
        {this.state.comitePresident !== 1 &&
        this.state.colectivePresident !== 1 &&
        +this.props.allinfoUser.id_type !== 2 ? (
          <p>
            Su perfil no cumple con los requisitos para presentar candidatura.
          </p>
        ) : (
          <div>
            {' '}
            <p>
              Puede presentar candidatura a una de las siguientes opciones. Una
              vez seleccionada se le pedirán los datos requeridos:
            </p>
          </div>
        )}

        {this.state.colectivePresident === 1 ? (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => this.chooseVote(1)}
            >
              {console.log('colectiveType dentro', this.state.colectiveType)}

              {this.props.allinfoUser.id_type == 4 ||
              this.props.allinfoUser.id_type == 5 ||
              (this.props.allinfoUser.id_type == 3 &&
                this.state.colectiveType != 6 &&
                this.state.colectiveType != 7) ||
              this.state.colectiveType == 4 ||
              this.state.colectiveType == 5 ||
              this.state.colectiveType == 3
                ? 'PRESENTAR CANDIDATURA AL CONSEJO TERRITORIAL'
                : +this.props.allinfoUser.id_type == 6 ||
                  +this.state.colectiveType == 6
                ? 'PRESENTAR CANDIDATURA AL CONSEJO PROFESIONAL'
                : +this.props.allinfoUser.id_type == 7 ||
                  +this.state.colectiveType == 7
                ? 'PRESENTAR CANDIDATURA AL CONSEJO ACADÉMICO'
                : ''}
              {/* 
              {this.props.allinfoUser.id_type == 4 ||
              this.props.allinfoUser == 5 ||
              this.state.colectiveType == 3 ||
              this.state.colectiveType == 4 ||
              this.state.colectiveType == 5
                ? 'PRESENTAR CANDIDATURA AL CONSEJO TERRITORIAL'
                : ''}

              {this.props.allinfoUser.id_type == 3 &&
              this.state.colectiveType != 6 &&
              this.state.colectiveType != 7
                ? 'PRESENTAR CANDIDATURA AL CONSEJO TERRITORIAL'
                : ''}

              {this.props.allinfoUser.id_type == 6 ||
              this.state.colectiveType == 6
                ? 'PRESENTAR CANDIDATURA AL CONSEJO PROFESIONAL'
                : ''}

              {this.props.allinfoUser.id_type == 7 ||
              +this.state.colectiveType == 7
                ? 'PRESENTAR CANDIDATURA AL CONSEJO ACADÉMICO'
                : ''} */}
            </button>
            <hr></hr>
          </div>
        ) : (
          ''
        )}

        {this.state.comitePresident === 1 ? (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => this.chooseVote(2)}
            >
              PRESENTAR CANDIDATURA AL CONSEJO CIENTÍFICO
            </button>
            <hr></hr>
          </div>
        ) : (
          ''
        )}

        {+this.state.canSubmit === 1 ? (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => this.chooseVote(3)}
            >
              PRESENTAR CANDIDATURA COMO REPRESENTANTE DE SOCIOS INDIVIDUALES
            </button>
            <hr></hr>
          </div>
        ) : (
          ''
        )}
        {+this.state.idChooseVote === 1 ? formCandiatureColective : ''}
        {+this.state.idChooseVote === 2 ? formCandiatureComite : ''}
        {+this.state.idChooseVote === 3 ? formCandiature : ''}
      </div>
    );

    return (
      <div>
        <h3 className="center">Abierto proceso para enviar candidaturas</h3>
        <h5 className="center">
          El proceso de candidaturas estará abierto del {this.props.dateBegin}{' '}
          al {this.props.dateEnd}.
        </h5>

        <Row hidden>
          <Col lg={12} sm={12}>
            {' '}
            <p>Intrucciones para presentar su candidatura</p>
          </Col>
          <Col lg={8} sm={12}>
            <ul>
              <li>
                Las elecciones se realizan a candidaturas cerradas. La persona
                que presenta la candidatura deberá ser el que se presente para{' '}
                <u>presidente/a</u>.
              </li>
              <li>Sólo podrá presentar una candidatura.</li>
              <li>
                Deberá descargar y rellenar el documento que encontrará en este
                apartado (se pide un plan de trabajo para los próximos cuatro
                años, y el listado de miembros que componen la candidatura).
              </li>
              <li>
                Cuando haya completado y subido el documento deberá dar a
                “Enviar Candidatura”
              </li>
              <li>
                Una vez emitida la candidatura le llegará un email con la
                confirmación.
              </li>
            </ul>
          </Col>
          <Col lg={4} sm={12} hidden>
            <p className="center">
              {' '}
              <a
                href={this.state.candidatureDocRef}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={Icons.faFileAlt} size="5x" />
              </a>
            </p>
          </Col>
        </Row>
        <Modal
          title={'Presentar Candidatura'}
          visible={this.state.visibleModalPresentarCandidatura}
          onOk={this.PresentarCandidaturaEnviar}
          onCancel={this.cancelCandidature}
          cancelText="Volver atras"
          okText="Presentar"
        >
          <p>Va a presentar su candidatura.</p>
        </Modal>
        <Modal
          title={'Presentar Candidatura'}
          visible={this.state.visibleModalPresentarCandidatura2}
          onOk={this.PresentarCandidaturaEnviar9}
          onCancel={this.cancelCandidature}
          cancelText="Volver atras"
          okText="Presentar"
        >
          <p>Va a presentar su candidatura.</p>
        </Modal>
        <Modal
          title={'Presentar Candidatura'}
          visible={this.state.visibleModalPresentarCandidaturaSocio}
          onOk={this.PresentarCandidaturaSocioEnviar}
          onCancel={this.cancelCandidature}
          cancelText="Volver atras"
          okText="Presentar"
        >
          <p>Va a presentar su candidatura.</p>
        </Modal>
        <Modal
          title={'Retirar Candidature'}
          visible={this.state.visibleModalRetirarCandidatura}
          onOk={this.revokeCandidature}
          onCancel={this.cancelCandidature}
          cancelText="Volver atras"
          okText="Retirar"
        >
          <p>Va a retirar su candidatura.</p>
        </Modal>
        <hr></hr>
        {this.state.candidatureAlreadySent === true ? (
          <div>
            <h4 className="center">Ya ha presentado su candidatura.</h4>
            <p>
              Si lo desea puede retirar su candidatura haciendo click en el
              siguiente botón:
            </p>
            <div className=" center">
              {' '}
              <button
                className="button center"
                onClick={this.revokeCanidatureModal}
              >
                Retirar candidatura
              </button>
            </div>
          </div>
        ) : null}
        {this.state.candidatureAlreadySent === true
          ? ''
          : optionsSendCandidature}
      </div>
    );
  }
}

export default Candidature;
