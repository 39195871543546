import React, { Component } from 'react';
import { Row, Col, Container } from 'react-grid-system';
import "../styles.css"
import "../style/styles_bannerAD.css"
class SliderTopCongreso extends Component {

    render() {
        return (
            <section>
                <Container>
                    <Row class="sliderTop">  
                       <Col sm={12}  class="align_center">
                       <p>Esto es el slider de congresos</p>
                        </Col>
                        
                    </Row>
                </Container>
            </section>
        )
    }
}
export default SliderTopCongreso;