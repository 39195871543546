import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Contacto from "../../WidgetsSidebar/contacto";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import equal from "fast-deep-equal";
import ContactImage from "../../WidgetsSidebar/contactImage";
import ReactGA from "react-ga";
class EstudiosTemplate01 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			filterCat: "",
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
			infoTabs: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	changeFilterCat = (e) => {
		this.setState({
			filterCat: e.target.value.substring(9, 20),
			currentPage: 1,
			activeId: e.target.value.substring(9, 20),
		});
	};

	componentDidMount() {
		//Call to webservice to get the new

		axios
			.get("/api/studies_get")
			.then((res) => {
				//console.log(res);
				this.setState({
					infoTabs: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props.array_page, prevProps.array_page)) {
			this.setState({
				array_page: this.props.array_page,
			});
		}
	}
	render() {
		const { statusPage } = this.state;
		const { array_post } = this.state;
		const { array_page } = this.state;
		const { infoTabs } = this.state;

		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		//	const { years } = this.state;
		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					menu_title="Menú"
					page_array={array_page}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					key={index}
				></MenuPageSidebar>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});
		const tabs = infoTabs.map((tab, index) => {
			return (
				<div key={index}>
					<TabPanel>
						<div
							dangerouslySetInnerHTML={{
								__html: tab.socio_description
									? tab.socio_description
									: "<p>No hay informacion</p>",
							}}
						/>
					</TabPanel>
					<TabPanel>
						<div
							dangerouslySetInnerHTML={{
								__html: tab.where_to_study
									? tab.where_to_study
									: "<p>No hay informacion</p>",
							}}
						/>
					</TabPanel>
					<TabPanel>
						<div
							dangerouslySetInnerHTML={{
								__html: tab.videos ? tab.videos : "<p>No hay informacion</p>",
							}}
						/>
					</TabPanel>
				</div>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col lg={9} md={12}>
							<Tabs>
								<TabList>
									<Tab>¿Que es la sociología?</Tab>
									<Tab>¿Dónde Estudiar?</Tab>
									<Tab>Vídeos</Tab>
								</TabList>
								{tabs}
							</Tabs>
						</Col>
						<Col className="sidebar" lg={3} md={12}>
							<ContactImage />
							<Contacto />
							{sidebarMenuPage}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default EstudiosTemplate01;
