import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import ImageDefault from "../components/img/actividades.jpg";
import TopImageTitle from "../components/objects/topImageTitle";
import NoticiaResume from "../components/objects/noticiaResume";
class Actividades extends React.Component {
	constructor() {
		super();
		this.state = {
			filterCat: "",
			categories: [],
			news: [],
		};
	}
	changeFilterCat = (e) => {
		this.setState({ filterCat: e.target.value.substring(9, 20) });
	};
	componentDidMount() {
		window.scrollTo(0, 0);
		//Call to webservice to get all news
		axios.get("/api/get_all_categories").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
		//Call to webservice to get all news
		axios.get("/api/get_all_news").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				news: res.data.records,
			});
		});
	}
	render() {
		const { categories } = this.state;
		const title = (
			<TopImageTitle
				key_id="topImage"
				title="Actividades"
				cover_image=""
			></TopImageTitle>
		);
		//const { news } = this.state;
		let news = this.state.news.slice();
		if (this.state.filterCat) {
			news = news.filter(
				(item) =>
					+item.categories.map((categories) => categories.id) ===
					+this.state.filterCat
			);
		}
		const categoriesList = categories.length ? (
			categories.map((categories) => {
				if (categories.id === "40" || categories.id === "41") {
					return (
						<Col
							sm={12}
							key={categories.id}
							className={"categories-button category-" + categories.id}
						>
							<button
								className="button"
								onClick={this.changeFilterCat}
								value={"category-" + categories.id}
							>
								{categories.name}
							</button>
						</Col>
					);
				} else {
					return null;
				}
			})
		) : (
			<div className="center">No hay Actividades</div>
		);

		const newsList = news.length ? (
			news.map((news, index) => {
				if (
					+news.categories.map((categories) => categories.id) === 41 ||
					+news.categories.map((categories) => categories.id) === 40
				) {
					return (
						<Col
							sm={4}
							key={news.id}
							className={
								"d-flex category-filter category-" +
								news.categories.map((categories) => categories.id)
							}
						>
							<NoticiaResume
								key={index}
								title={news.title}
								img={!news.cover_image ? ImageDefault : news.cover_image}
								categories={news.categories}
								link={news.permalink}
								meta_description={news.meta_description}
							></NoticiaResume>
						</Col>
					);
				} else {
					return null;
				}
			})
		) : (
			<div className="center">No hay Actividades</div>
		);

		return (
			<section>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>
						<Col lg={9} md={12}>
							<Row>{newsList}</Row>
						</Col>
						<Col sm={3}>
							<Row>
								<h2>Actividades</h2>
								{categoriesList}
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default Actividades;
