import React, { Component } from "react";
//import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

class Contacto extends Component {
	render() {
		return (
			<div>
				<div className="contactoSidebar bg-light padding-10">
					<div>
						<h5 className="titleSidebar">¿Tienes alguna pregunta?</h5>
					</div>
					<div>
						<p>
							<FontAwesomeIcon icon={Icons.faUserTie} size="1x" /> C/ Montalbán,
							8. 28014 Madrid
						</p>
						<p>
							<FontAwesomeIcon icon={Icons.faPhone} size="1x" />{" "}
							<a href="tel:+34 629 27 19 61">+34 629 27 19 61</a>
						</p>
						<p>
							<FontAwesomeIcon icon={Icons.faEnvelope} size="1x" />{" "}
							<a href="mailto:info@fes-sociologia.com">
								info@fes-sociologia.com
							</a>
						</p>
						<p>No dudes en ponerte en contacto con nosotros</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Contacto;
