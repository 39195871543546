import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

//import { Link } from 'react-router-dom';
class BetCanVot extends Component {
	constructor(props) {
		super(props);
		this.state = { availiableComites: [] };
	}
	getAvailiableComites = async () => {
		let formData = new FormData();
		formData.append("id_votes", this.props.idVotation);
		formData.append("id_user", this.props.idUser);
		await axios
			.post("/api/votes_comites_and_candidates_current_year_get", formData)
			.then((res) => {
				console.log(res.data.records);
				this.setState({
					availiableComites: res.data.records,
				});
			})
			.catch((error) => {});
	};
	componentDidMount() {
		(async () => {
			await this.getAvailiableComites();
		})();
	}

	render() {
		return (
			<Row>
				<Col lg={12} sm={12}>
					<h4 className="center">
						El periodo de presentar candidaturas se ha terminado.
					</h4>
					<p className="center">
						A partir del {this.props.dateEnd} podrá votar a los candidatos en
						esta página
					</p>
				</Col>
			</Row>
		);
	}
}

export default BetCanVot;
