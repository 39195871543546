import React from "react";
import SliderTopCongreso from "../components/Congreso/sliderTop";
import Logos from "../components/footer/logos";


const Congreso = () => {
 return <div className="margin-header"> 
            <SliderTopCongreso/>
            <Logos/>
            
        </div>;
}
;

export default Congreso;
