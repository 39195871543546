import React from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import Image01 from "../../objects/image01";
import axios from "axios";
import ImageTopText from "../../img/miembros-individuales-mini.jpg";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { Pagination } from "antd";
import { Form, Button } from "react-bootstrap";

class ListUserTemplate01 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchString: "",
			users: [],
			currentUsers: [],
			currentPage: 1,
			current: 1,
			minValue: 0,
			maxValue: 12,
			userActual: [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
			searchString: "",
		});
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		axios.get("/api/users_get_all_type_2").then((res) => {
			this.setState({
				users: res.data.records,
				userActual: res.data.records,
			});
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	handleChange() {
		this.setState({
			searchString: this.refs.search.value,
		});
	}
	handleChange1 = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 12,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 12 - 12,
				maxValue: value * 12,
				current: value,
			});
		}
	};
	searchName = (event) => {
		event.preventDefault();

		var search = event.target.searchName.value;

		var currentUsers = this.state.users.filter(function (user) {
			return user.fullname.toLowerCase().match(search.toLowerCase());
		});
		this.setState({
			userActual: currentUsers,
		});
	};
	cleanForm = () => {
		var users = this.state.users;
		document.getElementById("searchForm").value = "";
		this.setState({
			userActual: users,
		});
	};
	render() {
		// Logic for displaying current users

		const listUser = this.state.userActual && this.state.userActual.length > 0 && this.state.userActual
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((user, index) => {
				return (
					<Col key={user.id} className={user.name} sm={4}>
						<div className="listingUsers shadow">
							<p>
								{+user.type_status === +0 ? (
									<Link to={"/usuario/" + user.id}>
										{user.name + " " + user.lastname}
									</Link>
								) : (
									user.name + " " + user.lastname
								)}
							</p>
							<p>{user.email}</p>
							<p>{user.type}</p>
						</div>
					</Col>
				);
			});
		// Logic for displaying page numbers

		const title = this.props.array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		const image = this.props.array_post.map((post, index) => {
			return (
				<Image01
					key={index}
					key_id={post.id}
					alt_title={post.title}
					class="imagePlaceholder"
					cover_image={ImageTopText}
				></Image01>
			);
		});
		const notice = this.props.array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});

		const MetaTagsSeo = this.props.array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>

				<Container>
					<Row>
						<Col sm={6} xs={12}>
							{notice}
							<Link to="afiliate">
								{" "}
								<button className="button margin-top-20 margin-bottom-20">
									¿Cómo afiliarse?
								</button>
							</Link>
						</Col>
						<Col sm={6} xs={12}>
							{image}
						</Col>
					</Row>
					<Row className="lastMinuteNews">
						<Col sm={12}>
							<Row>
								<Col sm={12}>
									<h2 className="center width-100">Listado de socios</h2>
									<Form onSubmit={this.searchName}>
										<Form.Control
											type="text"
											placeholder="Buscar"
											name="searchName"
											require="true"
											className="width-100 searchBarHome searchMembers"
											id="searchForm"
										/>
										<Container>
											<Row>
												<Col lg={3} md={3} sm={12}></Col>
												<Col
													lg={3}
													md={3}
													sm={12}
													style={{ paddingTop: "10px" }}
												>
													{" "}
													<Button
														ref="btnSettings"
														variant="primary"
														type="submit"
														className="width-100"
													>
														Buscar
													</Button>
												</Col>
												<Col
													lg={3}
													md={3}
													sm={12}
													style={{ paddingTop: "10px" }}
												>
													{" "}
													<Button
														ref="btnSettings"
														variant="primary"
														className="width-100"
														onClick={this.cleanForm}
													>
														Reiniciar búsqueda
													</Button>
												</Col>
												<Col lg={3} md={3} sm={12}></Col>
											</Row>
										</Container>
									</Form>
								</Col>
							</Row>
							<hr></hr>
							<Row>{listUser}</Row>
							<Row>
								<Col sm={12} xs={12}>
									<div className="center"></div>
									<div>
										<Pagination
											key={this.state.userActual}
											defaultCurrent={1}
											current={this.state.current}
											pageSize={12}
											onChange={this.handleChange1}
											total={this.state.userActual && this.state.userActual.length > 0 && +this.state.userActual.length}
										/>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default ListUserTemplate01;
