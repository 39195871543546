import React from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";

const ConstructionTemplate01 = props => {
	const title = props.array_post.map((post, index) => {
		return (
			<TopImageTitle
				key={index}
				key_id={post.id}
				title={post.title}
				cover_image={post.img}
				subtitle="Pagina en Construcción"
			></TopImageTitle>
		);
	});

	const MetaTagsSeo = props.array_post.map(post => {
		return (
			<SEO
				title={post.title}
				cover_image={post.img}
				key={"SEO" + post.id}
			></SEO>
		);
	});

	return (
		<div>
			{MetaTagsSeo}
			{title}
			<hr className="whiteSpace"></hr>
			<Container>
				<Row className="lastMinuteNews">
					<Col lg={9} md={12}>
						La página que esta buscando esta en construcción
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default ConstructionTemplate01;
