import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Container } from "react-grid-system";
import ImageTopDefault from "../components/img/FES_background.jpg";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ButtonFES from "../components/objects/button";
import SEO from "../components/objects/seo";
import ContactMember from "../components/objects/contactMembers";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import PostResume from "../components/objects/postResume";
import { Pagination } from "antd";

class GTrabajo extends React.Component {
	state = {
		comites: [],
		members: [],
		documents: [],
		contactInfo: [],
		newsComite: [],
		status: 0,
		current: 1,
		minValue: 0,
		maxValue: 12,
		newsActual: [],
	};
	handleChange1 = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 12,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 12 - 12,
				maxValue: value * 12,
				current: value,
			});
		}
	};
	componentDidMount() {
		window.scrollTo(0, 0);
		const permalink = this.props.match.params.permalink;
		let formData = new FormData();
		formData.append("permalink", permalink);

		//Call to webservice to get the new
		axios.post("/api/comites_get_by_permalink", formData).then((res) => {
			this.setState({
				comites: res.data.records,
			});
			var comiteID = res.data.records[0].id;
			var statusComite = res.data.records[0].active;

			if (+statusComite === 1) {
				this.setState({
					status: 1,
				});
			} else {
				this.setState({
					status: 0,
				});
			}
			var formDataMembers = new FormData();
			formDataMembers.append("id_comite", comiteID);
			axios
				.post("/api/comites_members_get", formDataMembers)

				.then((res) => {
					this.setState({
						members: res.data.records,
					});
				});
			var formDataDocuments = new FormData();
			formDataDocuments.append("id_comite", comiteID);
			axios
				.post("/api/comites_documents_get", formDataDocuments)
				.then((res) => {
					this.setState({
						documents: res.data.records,
					});
				});

			var formDataContact = new FormData();
			formDataContact.append("id_comite", comiteID);
			axios.post("/api/comites_contact_get", formDataContact).then((res) => {
				this.setState({
					contactInfo: res.data.records,
				});
			});

			var formComiteNews = new FormData();
			formComiteNews.append("id_comite", comiteID);
			axios
				.post("/api/comites_featured_news_get", formComiteNews)
				.then((res) => {
					if (res.data.records) {
						this.setState({
							newsComite: res.data.records,
						});
					}
				});
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		const permalink = this.props.match.params.permalink;

		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();
			formData.append("permalink", permalink);

			//Call to webservice to get the new
			axios.post("/api/comites_get_by_permalink", formData).then((res) => {
				this.setState({
					comites: res.data.records,
				});
				var comiteID = res.data.records[0].id;

				var statusComite = res.data.records[0].active;
				if (+statusComite === 1) {
					this.setState({
						status: 1,
					});
				} else {
					this.setState({
						status: 0,
					});
				}
				var formDataMembers = new FormData();
				formDataMembers.append("id_comite", comiteID);
				axios.post("/api/comites_members_get", formDataMembers).then((res) => {
					this.setState({
						members: res.data.records,
					});
				});
				var formDataDocuments = new FormData();
				formDataDocuments.append("id_comite", comiteID);
				axios
					.post("/api/comites_documents_get", formDataDocuments)
					.then((res) => {
						this.setState({
							documents: res.data.records,
						});
					});
				var formDataContact = new FormData();
				formDataContact.append("id_comite", comiteID);
				axios.post("/api/comites_contact_get", formDataContact).then((res) => {
					this.setState({
						contactInfo: res.data.records,
					});
				});
				var formComiteNews = new FormData();
				formComiteNews.append("id_comite", comiteID);
				axios
					.post("/api/comites_featured_news_get", formComiteNews)
					.then((res) => {
						if (res.data.records) {
							this.setState({
								newsComite: res.data.records,
							});
						}
					});
			});
		}
	}

	render() {
		const { comites } = this.state;
		const { members } = this.state;
		const { documents } = this.state;
		const { contactInfo } = this.state;

		const sortMembers = members.sort((a, b) => b.id_rol - a.id_rol);
		const membersDirectives = sortMembers.map((member) => {
			if (+member.id_rol !== 1) {
				return (
					<Col sm={4} xs={12} key={"dir" + member.id}>
						<ContactMember
							rol_name={member.rol_name}
							name={member.name + " " + member.lastname}
						></ContactMember>
					</Col>
				);
			} else {
				return null;
			}
		});
		const membersBasic = members.map((member, index) => {
			if (+member.id_rol === 1) {
				return (
					<Col sm={4} xs={12} key={"base" + member.id + index}>
						<div className="memberComite">
							<p>
								<FontAwesomeIcon icon={Icons.faUserTie} size="1x" />{" "}
								{member.name + " " + member.lastname}
							</p>
						</div>
					</Col>
				);
			} else {
				return null;
			}
		});
		const documentButtons = documents.map((document) => {
			if (this.state.status === 1) {
				if (document.id) {
					return (
						<div key={document.id}>
							<h2>Documentos</h2>
							<ButtonFES
								button_text={document.doc_1}
								button_class="button width-100"
								button_link={document.btn_text_1}
							></ButtonFES>
							<ButtonFES
								button_text={document.doc_2}
								button_class="button width-100"
								button_link={document.btn_text_2}
							></ButtonFES>
							<ButtonFES
								button_text={document.doc_3}
								button_class="button width-100"
								button_link={document.btn_text_3}
							></ButtonFES>
							<ButtonFES
								button_text={document.doc_4}
								button_class="button width-100"
								button_link={document.btn_text_4}
							></ButtonFES>
							<ButtonFES
								button_text={document.doc_5}
								button_class="button width-100"
								button_link={document.btn_text_5}
							></ButtonFES>
						</div>
					);
				} else {
					return null;
				}
			} else {
				return null;
			}
		});
		const contactInformation = contactInfo.map((contactIn) => {
			if (this.state.status === 1) {
				return (
					<div key={contactIn.id}>
						<h2>Contacto</h2>
						<div>
							<p>
								<FontAwesomeIcon icon={Icons.faUser} size="1x" />{" "}
								{!contactIn.name ? "No disponible" : contactIn.name}
							</p>
							<p>
								<FontAwesomeIcon icon={Icons.faPhone} size="1x" />{" "}
								{!contactIn.phone ? "No disponible" : contactIn.phone}
							</p>
							<p>
								<FontAwesomeIcon icon={Icons.faEnvelope} size="1x" />{" "}
								{!contactIn.email ? "No disponible" : contactIn.email}
							</p>
							<p>
								{!contactIn.ig ? null : (
									<a href={contactIn.ig}>
										<FontAwesomeIcon
											icon={fab.faInstagram}
											size="2x"
											className="socialIconsComite"
										/>
									</a>
								)}
								{!contactIn.fb ? null : (
									<a href={contactIn.fb}>
										<FontAwesomeIcon
											icon={fab.faFacebookF}
											size="2x"
											className="socialIconsComite"
										/>
									</a>
								)}
								{!contactIn.tw ? null : (
									<a href={contactIn.tw}>
										<FontAwesomeIcon
											icon={fab.faTwitter}
											size="2x"
											className="socialIconsComite"
										/>
									</a>
								)}
							</p>
						</div>
					</div>
				);
			} else {
				return null;
			}
		});
		const titleAlone = comites.map((comite) => {
			return (
				<Container key={comite.id}>
					<Row>
						<Col sm={12}>
							<h2>{comite.title}</h2>
						</Col>
					</Row>
				</Container>
			);
		});
		const newsInComite = this.state.newsComite
			.sort(function (a, b) {
				return new Date(b.published_date) - new Date(a.published_date);
			})
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((news, index) => {
				return (
					<Col lg={4} sm={6} xs={12} key={news.id}>
						<PostResume
							key={index}
							title={news.title}
							categories={news.categories}
							img={news.img}
							link={news.permalink}
							short_description={news.short_description}
							meta_description={news.meta_description}
							date={news.published_date}
						></PostResume>
					</Col>
				);
			});
		const MetaTagsSeo = comites.map((comite) => {
			return (
				<SEO
					title={comite.meta_title}
					cover_image={comite.img}
					description={comite.meta_description}
					key={"SEO" + comite.id}
				></SEO>
			);
		});
		const title = comites.map((comite) => {
			if (!comite.cover_image) {
				return (
					<Container
						key={comite.id}
						className="width-100 max-width-100 backgroundImage backgroundDark"
						style={{ background: `url('${ImageTopDefault}')` }}
					>
						<Row className="max-width-site">
							<Col sm={12}>
								<div className="leftTitle">
									<h1 className="title-comites">{comite.title}</h1>
									<h6 className="subtitleComite textWhite">
										{comite.subtitle}
									</h6>
									<ButtonFES
										button_text="¡AFÍLIATE A ESTE COMITÉ!"
										button_class="button zoom-on-hover"
										button_link="#"
									></ButtonFES>
								</div>
							</Col>
						</Row>
					</Container>
				);
			} else {
				return (
					<Container
						className="width-100 max-width-100 backgroundImage noticeTitle backgroundDark"
						style={{ background: `url('${comite.cover_image}')` }}
					>
						<Row className="max-width-site">
							<Col sm={12}>
								<div className="leftTitle">
									<h1>{comite.title}</h1>
									<h6 className="subtitleComite textWhite">
										{comite.subtitle}
									</h6>
									<ButtonFES
										button_text="¡AFÍLIATE A ESTE GRUPO DE TRABAJO!"
										button_class="button zoom-on-hover"
										button_link="#"
									></ButtonFES>
								</div>
							</Col>
						</Row>
					</Container>
				);
			}
		});
		const comite = comites.map((comite) => {
			if (+comite.active === 1 || +comite.active === 2) {
				return (
					<div key={comite.id}>
						<Row>
							<Col sm={12}>
								{+comite.active === 2 ? (
									<div className="alert alert-warning" role="alert">
										<p>En construcción</p>
									</div>
								) : null}
								<div className="inner">
									<Tabs>
										<TabList>
											<Tab>Inicio</Tab>
											<Tab>Equipo y miembros</Tab>
											<Tab>Noticias</Tab>
											<Tab>Más Información</Tab>
										</TabList>
										<TabPanel>
											<div
												dangerouslySetInnerHTML={{ __html: comite.content }}
											/>
										</TabPanel>
										<TabPanel>
											<h5>Equipo Directivo</h5>
											<div className="separator separatorGrad"></div>
											<Container>
												<Row>
													{membersDirectives.length === 0
														? "No hay directivos"
														: membersDirectives}
												</Row>
											</Container>
											<h5>Miembros</h5>
											<div className="separator separatorGrad"></div>
											<Container>
												<Row>
													{membersBasic.length === 0
														? "No hay miembros"
														: membersBasic}
												</Row>
											</Container>
										</TabPanel>
										<TabPanel>
											<Row>
												{newsInComite}
												<Col sm={12} xs={12}>
													<div className="center"></div>
													<div>
														<Pagination
															key={this.state.newsComite}
															defaultCurrent={1}
															current={this.state.current}
															pageSize={12}
															onChange={this.handleChange1}
															total={+this.state.newsComite.length}
														/>
													</div>
												</Col>
											</Row>
										</TabPanel>

										<TabPanel>
											<div
												dangerouslySetInnerHTML={{
													__html: !comite.more_info_html
														? "No hay más información"
														: comite.more_info_html,
												}}
											/>
										</TabPanel>
									</Tabs>
								</div>
							</Col>
						</Row>
					</div>
				);
			} else {
				return <p>El Grupo de trabajo elegido se encuentra deshabilitado</p>;
			}
		});

		return (
			<section>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>
						<Col sm={12}>
							<p>
								<Link
									to="/actividades/comites-de-investigacion"
									className="goBacklink"
								>
									<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver
									a Grupos de trabajo
								</Link>
							</p>
						</Col>
						<Col sm={9} xs={12}>
							<Row>
								<Col>{titleAlone}</Col>
								<Col sm={12}>{comite}</Col>
							</Row>
						</Col>
						<Col sm={3} xs={12}>
							{contactInformation}
							{documentButtons}
							<ButtonFES
								button_text="¡AFÍLIATE A ESTE GRUPO DE TRABAJO!"
								button_class="button zoom-on-hover"
								button_link="/afiliate"
							></ButtonFES>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default GTrabajo;
