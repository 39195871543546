import React, { Component } from "react";
import ImageTopDefault from "../img/FES_background.jpg";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";
import axios from "axios";

class TopImageTitle extends Component {
	constructor(props) {
		super();
		this.state = {
			cover_image: props.cover_image,
			subtitle: props.subtitle,
			titleGeneral: props.title,
			id_parent: props.id_parent,
			id_menu: props.id_parentMenu,
			customBread: props.customBread,
			title: [],
			statusPage: 0,
			menusTitle: [],
			menusTitlefetching: false,
		};
	}
	getParentInformation = async () => {
		const { id_parent } = this.state;
		//Call to webservice to get the new
		if (id_parent !== undefined && id_parent !== null) {
			let formData = new FormData();

			formData.append("id_page", id_parent);

			axios
				.post("/api/subpages_get_parent_title", formData)
				.then((res) => {
					this.setState({
						title: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	};

	getMenuInformation = async () => {
		const { id_menu } = this.state;
		this.setState({
			menusTitlefetching: true,
		});
		if (id_menu !== undefined && id_menu !== null) {
			axios
				.get("/api/menu_get")
				.then((res) => {
					this.setState({
						menusTitle: res.data.records,
						menusTitlefetching: false,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	};
	componentDidMount() {
		(async () => {
			await this.getParentInformation();
			await this.getMenuInformation();
		})();
	}

	render() {
		const { cover_image } = this.state;
		const { subtitle } = this.state;
		const { title } = this.state;
		const { titleGeneral } = this.state;
		const { id_parent } = this.state;
		const { customBread } = this.state;
		const { menusTitle } = this.state;
		const { id_menu } = this.state;
		const { menusTitlefetching } = this.state;

		const menuBread01 = !menusTitlefetching
			? menusTitle.map((bread, index) => {
					if (+bread.id === +id_menu) {
						return <span key={index}> > {bread.name}</span>;
					} else {
						return null;
					}
			  })
			: null;

		const titleBreadcrumbs = title.map((bread, index) => {
			if (title.length > 0) {
				return <span key={index}> > {bread.title}</span>;
			} else {
				return null;
			}
		});

		const customTitle = (
			<span>{!customBread ? null : " > " + customBread}</span>
		);
		const titleTop = (
			<Container
				className="width-100 max-width-100 backgroundImage noticeTitle backgroundDark"
				style={{
					background: `url('${!cover_image ? ImageTopDefault : cover_image}')`,
				}}
			>
				<Row className="max-width-site">
					<Col sm={12}>
						<div className="centerTitleBlog">
							<h1 className="center">{titleGeneral}</h1>
							<h6 className="subtitle center textWhite">
								{!subtitle ? "" : subtitle}
							</h6>

							<p className="center breadcrumbNotice">
								<Link to="/"> Inicio</Link>
								{!menuBread01 ? null : menuBread01}
								{!id_parent
									? !customTitle
										? null
										: customTitle
									: !titleBreadcrumbs
									? null
									: titleBreadcrumbs}{" "}
								{"> " + titleGeneral}
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		);

		return <div>{titleTop}</div>;
	}
}
export default TopImageTitle;
