import React from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import ButtonFES from "../../objects/button";
import Contacto from "../../WidgetsSidebar/contacto";

const FormationTemplate01 = (props) => {
	const title = props.array_post.map((post, index) => {
		return (
			<TopImageTitle
				key={index}
				key_id={post.id}
				title={post.title}
				cover_image={post.img}
				subtitle={post.subtitle}
				id_parent={!post.id_page_parent ? null : post.id_page_parent}
				id_parentMenu={!post.id_menu ? null : post.id_menu}
			></TopImageTitle>
		);
	});

	const sidebarMenuPage = (
		<div>
			<ButtonFES
				button_text="Actividades programadas"
				button_class="button width-100"
				button_link="/actividades/"
			></ButtonFES>
			<ButtonFES
				button_text="Actividades programadas"
				button_class="button width-100"
				button_link="/actividades/"
			></ButtonFES>
		</div>
	);

	const notice = props.array_post.map((post) => {
		return (
			<Col key={post.id}>
				<div className="inner">
					<div className="noticeInfo">
						<div dangerouslySetInnerHTML={{ __html: post.content }} />
					</div>
				</div>
				<hr></hr>
			</Col>
		);
	});

	const MetaTagsSeo = props.array_post.map((post) => {
		return (
			<SEO
				title={post.title}
				cover_image={post.img}
				key={"SEO" + post.id}
			></SEO>
		);
	});

	return (
		<div>
			{MetaTagsSeo}
			{title}
			<hr className="whiteSpace"></hr>
			<Container>
				<Row className="lastMinuteNews">
					<Col lg={9} md={12}>
						{notice}
					</Col>
					<Col className="sidebar" lg={3} md={12}>
						<h2>Actividades</h2>
						<ul className="sidebarListingNumber">{sidebarMenuPage}</ul>
						<Contacto />
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default FormationTemplate01;
