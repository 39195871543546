import React from "react";
import TopImageTitle from "../../components/objects/topImageTitle";
import { Row, Col, Container } from "react-grid-system";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
class PaymentKO extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;
		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	render() {
		const title = (
			<TopImageTitle
				title="Confirmacion de Pago"
				bread01="Pago"
			></TopImageTitle>
		);

		return (
			<section>
				{title}
				<Container>
					<Row className="afiliationPage">
						<Col lg={12} sm={12}>
							<div>
								<h1 className="center">
									Se ha encontrado algún problema y su pago no se ha realizado
								</h1>
								<h3 className="center">
									Puede reintentar el pago desde su perfil en la{" "}
									<Link
										to={{
											pathname: `/perfil`,
											state: {
												key: "afiliationData",
											},
										}}
									>
										pestaña pedidos.
									</Link>
								</h3>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default PaymentKO;
