import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import ImageDefault from "../img/noticias_default.jpg";
import ModalVideo from "react-modal-video";
import "../../../../node_modules/react-modal-video/scss/modal-video.scss";
import { Link } from "react-router-dom";

class TopicOfTheMonth extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			monthly: [],
			infoNotice: [],
		};
		this.openModalmonth = this.openModalmonth.bind(this);
	}

	openModalmonth() {
		this.setState({ isOpen: true });
	}

	getMonthly = async () => {
		this.setState({
			estudiosFetching: true,
		});
		let res = await axios.get("/api/design_home_monthly_topic_get");

		this.setState({ monthly: res.data.records });
		var idNotice = res.data.records[0].id;
		let formData = new FormData();
		formData.append("id", idNotice);
		let res2 = await axios.post("/api/design_home_monthly_topic_get");
		this.setState({ infoNotice: res2.data.records });
	};

	componentDidMount() {
		//Call to webservice to get all news
		(async () => {
			await this.getMonthly();
		})();
		axios
			.get("/api/design_home_mix_get")
			.then((res) => {
				this.setState({
					//WE make sure to get only the last 3
					title1: res.data.records[0].monthly_topic_title1,
					title2: res.data.records[0].monthly_topic_title2,
				});
			})
			.catch((error) => {});
	}

	render() {
		const { monthly } = this.state;
		const { infoNotice } = this.state;
		console.log(monthly);
		const defaultImage = infoNotice.map((month) => {
			if (!month.cover_image) {
				return (
					<div key={month.id}>
						<img className="width-100" src={`${ImageDefault}`} alt="alt"></img>
					</div>
				);
			} else {
				return (
					<div key={month.id}>
						<img className="width-100" src={month.cover_image} alt="alt"></img>
					</div>
				);
			}
		});
		const title = monthly.map((month) => {
			return (
				<div key={month.id}>
					<h2>{month.sectionTitle}</h2>
					<h4 className="monthlyTitle">{month.title}</h4>
				</div>
			);
		});
		const video = monthly.map((month) => {
			if (month.video) {
				return (
					<span className="seeVideo" key={month.id}>
						<ModalVideo
							channel="youtube"
							isOpen={this.state.isOpen}
							videoId={month.video}
							onClose={() => this.setState({ isOpen: false })}
						/>
						<button className="button" onClick={this.openModalmonth}>
							Ver video
						</button>
					</span>
				);
			} else {
				return null;
			}
		});
		const postList = infoNotice.length ? (
			infoNotice.map((month) => {
				if (month.published_date) {
					var date = month.published_date;
					date = date.split(" ")[0];
					var yyyy = date.split("-")[0];
					var mmmm = date.split("-")[1];
					var dddd = date.split("-")[2];
					date = dddd + "-" + mmmm + "-" + yyyy;
				}
				return (
					<div key={month.id}>
						<Row>
							<Col sm={12} lg={6}>
								<div className="inner">
									{title}
									<p>{month.short_description}</p>
									<h6>{date}</h6>
									<button className="button">
										{+month.type !== 1 ? (
											<Link to={"/noticia" + month.permalink}>
												+Explorar ahora
											</Link>
										) : (
											<Link to={"/actualidad" + month.permalink}>
												+Explorar ahora
											</Link>
										)}
									</button>
								</div>
							</Col>
							<Col sm={12} lg={6}>
								{defaultImage}
								{video}
							</Col>
						</Row>
					</div>
				);
			})
		) : (
			<div className="center">No hay tema del mes</div>
		);
		return (
			<section>
				<Container>
					<Row>
						<Col sm={12} lg={12}>
							<div className="center bottom-20">
								<h4 className="titleItalic">{this.state.title1}</h4>
								<h2>{this.state.title2}</h2>
							</div>
						</Col>
					</Row>
					{postList}
				</Container>
			</section>
		);
	}
}

export default TopicOfTheMonth;
