import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Table, Input, Space, Divider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import axios from "axios";
import { withRouter } from "react-router-dom";
//import { Link } from 'react-router-dom';
class TabDocFes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			docsFromAdmin: [],
			docsSendUser: [],
		};
	}
	//BEGIN To search in a table
	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						this.searchInput = node;
					}}
					placeholder={`Buscar ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						this.handleSearch(selectedKeys, confirm, dataIndex)
					}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Buscar
					</Button>
					<Button
						onClick={() => this.handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reiniciar
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: (text) =>
			this.props.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[this.props.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});
	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};
	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: "" });
	};

	//END to search in a table
	changeSelectDoc = (event) => {
		this.setState((prevState) => ({
			docRequired: this.refs.docRequiredFes.value,
		}));
	};
	getDocs = async () => {
		let formData = new FormData();
		formData.append("id_comite", this.props.idComite);
		await axios
			.post("/api/comites_admin_docs_get_by_id_comite", formData)
			.then((res) => {
				this.setState({
					docsFromAdmin: res.data.records,
				});
				var temp_array = [];

				res.data.records.forEach((item) => {
					console.log(item.doc_user);
					if (item.doc_user) {
						temp_array.push(item);
					}
				});
				console.log(temp_array);
				this.setState({
					//Just peak the last 3
					docsSendUser: temp_array,
				});
			})
			.catch((error) => {});
	};

	componentDidMount() {
		(async () => {
			await this.getDocs();
		})();
	}
	handleSubmitUpload = (event) => {
		event.preventDefault();
		if (event.target.id_comite_docs.value === "01") {
			alert("Seleccione el documento que desea subir");
		} else {
			let formData = new FormData();
			formData.append("id_comites_docs", event.target.id_comite_docs.value);
			formData.append("file", event.target.fileDoc.files[0]);
			formData.append("comite_name", this.props.nameComite);

			axios
				.post("/api/comites_admin_docs_set_user_doc", formData)
				.then((res) => {
					alert("Se ha enviado el documento correctamente");
					this.props.history.push({
						pathname: "/perfil/admin/comite/" + this.props.idComite,
						state: {
							key: "docFES",
						},
					});
				})
				.catch((error) => {
					alert("Se ha encontrado un error y no se ha subido el documento");
				});
		}
	};

	handleSubmitUploadnoid = (event) => {
		event.preventDefault();
		if (!event.target.fileDoc.files[0]) {
			alert("Asegúrese de haber cargado un documento");
		} else {
			let formData = new FormData();
			formData.append("id_comite", this.props.idComite);
			formData.append("file", event.target.fileDoc.files[0]);
			formData.append("comite_name", this.props.nameComite);
			axios
				.post("/api/comites_admin_docs_create_user_doc", formData)
				.then((res) => {
					alert("Se ha enviado el documento correctamente");
					this.props.history.push({
						pathname: "/perfil/admin/comite/" + this.props.idComite,
						state: {
							key: "docFES",
						},
					});
				})
				.catch((error) => {
					alert("Se ha encontrado un error y no se ha subido el documento");
				});
		}
	};

	render() {
		const columnsDocs = [
			{
				title: "Nombre del documento",
				dataIndex: "doc_name",
				key: "doc_name",
				width: "40%",
				...this.getColumnSearchProps("doc_name"),
			},
			{
				title: "Descargar Documento",
				dataIndex: "doc_admin",
				key: "doc_admin",
				width: "60%",
				render: (text, record) =>
					!record.doc_name ? null : (
						<span>
							<a
								href={record.doc_admin}
								target="_blank"
								rel="noopener noreferrer"
								download
							>
								Link a documento
							</a>{" "}
						</span>
					),
			},
		];

		const columnsDocsCI = [
			{
				title: "Nombre del documento",
				dataIndex: "doc_name",
				key: "doc_name",
				width: "40%",
				render: (text, record) =>
					!record.doc_name ? (
						<span>Documento enviado</span>
					) : (
						<span>{record.doc_name}</span>
					),
			},
			{
				title: "Documentación presentada por CI",
				dataIndex: "doc_user",
				key: "doc_user",
				width: "60%",
				render: (text, record) =>
					!record.doc_name ? (
						<span>
							<a
								href={record.doc_user}
								target="_blank"
								rel="noopener noreferrer"
								download
							>
								Link a documento:{" "}
								{record.doc_user.substr(record.doc_user.lastIndexOf("/") + 1)}
							</a>
						</span>
					) : (
						<span>
							{record.doc_user ? (
								<a
									href={record.doc_user}
									target="_blank"
									rel="noopener noreferrer"
									download
								>
									Link a documento
								</a>
							) : (
								<p>
									Documentación no presentada o de carácter informativo que no
									necesita su devolución a FES
								</p>
							)}
						</span>
					),
			},
		];
		const docToUpload = this.state.docsFromAdmin.map((da, index) => {
			if (da.doc_name) {
				return (
					<option key={index} value={da.id}>
						{da.doc_name}
					</option>
				);
			} else return null;
		});

		return (
			<div>
				<Table
					columns={columnsDocs}
					onChange={this.onChange}
					dataSource={this.state.docsFromAdmin.filter((doc) => doc.doc_name)}
					className="isoSearchableTable"
					rowKey="id"
				/>
				<Divider dashed></Divider>
				<p>
					En esta sección encontrarás un listado de la documentación que has
					presentado a FES.
				</p>
				<Table
					columns={columnsDocsCI}
					onChange={this.onChange}
					dataSource={this.state.docsSendUser}
					className="isoSearchableTable"
					rowKey="id"
				/>
				<Form onSubmit={this.handleSubmitUpload}>
					<Row>
						<Col lg={12} sm={12}>
							<Form.Group controlId="docRequired">
								<p>
									<b>Devolver un documento cumplimentado a FES:</b>
								</p>
								<p>
									Despliegue el listado y elija el nombre del documento que se
									le ha pedido cumplimentar. En "seleccionar archivo" adjunte su
									documento ya cumplimentado.
								</p>
								<Form.Control
									as="select"
									name="id_comite_docs"
									ref="docRequiredFes"
									required
								>
									<option value="01">-Seleccione una opción-</option>
									{docToUpload}
								</Form.Control>
							</Form.Group>
						</Col>
						<Col lg={12} sm={12}>
							<Form.Group controlId="docRequiredFiles">
								<Form.Label>Documento a subir*:</Form.Label>
								<Form.Control
									type="file"
									name="fileDoc"
									placeholder="Fichero a subir"
									required
								/>
							</Form.Group>
						</Col>
						<Col sm={12} xs={12}>
							<Button ref="btnMoreInfo" variant="primary" type="submit">
								Enviar Documentación
							</Button>
						</Col>
					</Row>
				</Form>
				<Divider dashed></Divider>
				<Form onSubmit={this.handleSubmitUploadnoid}>
					<Row>
						<Col lg={12} sm={12}>
							<Form.Group controlId="docRequired">
								<p>
									<b>Subir otro documento:</b>
								</p>
								<p>
									Podrá subir otros documentos que FES le solicite (sin
									plantilla previa)
								</p>
							</Form.Group>
						</Col>
						<Col lg={12} sm={12}>
							<Form.Group controlId="docRequiredFiles">
								<Form.Label>Documento a subir*:</Form.Label>
								<Form.Control
									type="file"
									name="fileDoc"
									placeholder="Fichero a subir"
									required
								/>
							</Form.Group>
						</Col>
						<Col sm={12} xs={12}>
							<Button ref="btnMoreInfo" variant="primary" type="submit">
								Enviar Documentación
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		);
	}
}
export default withRouter(TabDocFes);
