import React from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import Contacto from "../components/WidgetsSidebar/contacto";
//import ImageTopDefault from "../components/img/FES_background.jpg";
import { Link } from "react-router-dom";
import TopImageTitle from "../components/objects/topImageTitle";

class Formacion extends React.Component {
	state = {
		posts: [],
		page: [],
	};
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		const { permalink } = this.props.match.params;
		let formData = new FormData();
		formData.append("permalink", "/formacion/" + permalink);

		//Call to webservice to get the new
		axios
			.post("/api/pages_get_by_permalink", formData)
			.then((res) => {
				//console.log(res);
				this.setState({
					posts: res.data.records.slice(0, 1),
				});
				var id_menu = res.data.records[0].id_menu;
				var id_menu_call = new FormData();
				id_menu_call.append("id_menu", id_menu);
				axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
					this.setState({
						page: res.data.records,
					});
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const { permalink } = this.props.match.params;
		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();
			formData.append("permalink", "/formacion/" + permalink);
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			//Call to webservice to get the new
			axios
				.post("/api/pages_get_by_permalink", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						posts: res.data.records.slice(0, 1),
					});
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							page: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	}

	render() {
		const { posts } = this.state;

		const sidebarMenuPage = (
			<span>
				<li>
					<div className="numberListing">01</div>
					<Link to="/actividades/">Actividades programadas</Link>
				</li>
				<li>
					<div className="numberListing">02</div>
					<Link to="/actividades/">Actividades pasadas</Link>
				</li>
			</span>
		);
		const title = posts.map((post) => {
			if (post.subtitle === "null") {
				post.subtitle = "";
			}
			return (
				<TopImageTitle
					key_id={post.id}
					title={post.title}
					cover_image={post.cover_image}
				></TopImageTitle>
			);
		});

		const notice = posts.map((post) => {
			return (
				<Col>
					<div key={post.id} className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});
		this.collapsible();
		return (
			<section>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col lg={9} md={12}>
							{notice}
						</Col>
						<Col className="sidebar" lg={3} md={12}>
							<h2>Actividades</h2>
							<ul className="sidebarListingNumber">{sidebarMenuPage}</ul>
							<Contacto />
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default Formacion;
