import React from "react";
//import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
var patternUrl = /^((http|https|ftp):\/\/)/;
const ButtonFES = props => {
	if (props.button_link && props.button_text) {
		return (
			<a rel="noopener noreferrer" target="_blank" href={props.button_link}>
				<button className={props.button_class + " buttonSidebar"}>
					{!patternUrl.test(props.button_text)
						? props.button_text
						: props.button_text
								.substring(props.button_text.lastIndexOf("/") + 1)
								.split(".")[0]}{" "}
					{!props.icon ? null : (
						<FontAwesomeIcon icon={Icons.faDownload} size="1x" />
					)}
				</button>
			</a>
		);
	} else {
		return null;
	}
};
export default ButtonFES;
