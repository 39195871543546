import React from "react";

import axios from "axios";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import BasicUser from "./UserProfiles/basicUser";
import ColectivoUser from "./UserProfiles/colectivoUser";

class Perfil extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			userInfo: [],
			islogged: 0,
			statusPage: 0,
			userOrders: [],
			pageUpdate: "",
			key: "personalData",
			personalDataNotComplete: false,
			profesionalDataNotComplete: false,
			msgCompleteProfilePersonal: null,
			msgCompleteProfileProfesional: null,
			msgCompleteProfileSettings: null,
			msgCompleteProfile: null,
			beginIban: "",
			endIban: "",
			showLinkToPDF: false,
			numComitesPagoPDF: 0,
			userToColectivo: 0,
		};
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(key) {
		this.setState({ key });
	}
	checkTokken = async () => {
		const jwt = localStorage.getItem("access_token");

		if (!jwt) {
			this.setState.islogged = 0;
		} else {
			this.setState.islogged = 1;
			let formData = new FormData();
			formData.append("jwt", jwt);
			await axios
				.post("/api/validate_token", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						userInfo: res.data.data,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	};
	getOrdersUser = async () => {
		const { userInfo } = this.state;
		var userId = userInfo.id;
		let formData = new FormData();
		formData.append("id_user", userId);
		await axios
			.post("/api/payment_get_by_id_user", formData)
			.then((res) => {
				//console.log(res);
				this.setState({
					userOrders: res.data.records,
				});
			})
			.catch((error) => {});
	};
	setMessageWarning() {
		const { userInfo } = this.state;
		//Message user Personal data
		if (userInfo) {
			if (
				!userInfo.name ||
				!userInfo.nif ||
				!userInfo.country ||
				!userInfo.city ||
				!userInfo.region ||
				!userInfo.country_residence
			) {
				this.setState({
					msgCompleteProfilePersonal:
						"Los campos marcados con un asterisco (*) serán obligatorios",
				});
			} else {
				this.setState({
					msgCompleteProfilePersonal: null,
				});
			}
		} else {
			this.setState({
				msgCompleteProfilePersonal: null,
			});
		}
		//Message user profesional data
		if (userInfo) {
			if (!userInfo.org || !userInfo.job || !userInfo.education) {
				this.setState({
					msgCompleteProfileProfesional:
						"Los campos marcados con un asterisco (*) serán obligatorios",
				});
			} else {
				this.setState({
					msgCompleteProfileProfesional: null,
				});
			}
		} else {
			this.setState({
				msgCompleteProfileProfesional: null,
			});
		}
		//Message user settings data
		if (userInfo) {
			if (+userInfo.newsletter === 2 || +userInfo.type_status === 2) {
				this.setState({
					msgCompleteProfileSettings:
						"Los campos marcados con un asterisco (*) serán obligatorios",
				});
			} else {
				this.setState({
					msgCompleteProfileSettings: null,
				});
			}
		} else {
			this.setState({
				msgCompleteProfileSettings: null,
			});
		}
	}

	componentDidMount() {
		(async () => {
			await this.checkTokken();
		})();
		//Check if key is sent to set it for the tabs
		if (this.props.history.location.state) {
			this.setState({
				key: this.props.history.location.state.key,
			});
			if (this.props.history.location.state.userToColectivo) {
				this.setState({
					key: this.props.history.location.state.key,
					userToColectivo: this.props.history.location.state.userToColectivo,
				});
			}
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { userInfo } = this.state;

		let TemplateSelection;

		if (userInfo) {
			//Template colectivos
			if (
				+userInfo.id_type === +4 ||
				+userInfo.id_type === +5 ||
				+userInfo.id_type === +7 ||
				+userInfo.id_type === +6 ||
				+userInfo.id_type === +10 ||
				+userInfo.id_type === +14 ||
				+userInfo.id_type === +12 ||
				+this.state.userToColectivo !== 0
			) {
				TemplateSelection = (
					<ColectivoUser
						toColectivo={this.state.userToColectivo}
					></ColectivoUser>
				);
			} //Template default
			else {
				TemplateSelection = <BasicUser></BasicUser>;
			}
		}

		return <div>{TemplateSelection}</div>;
	}
}
export default withRouter(Perfil);
