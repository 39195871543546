import React from 'react';
//import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const ContactMember = props => {
  return (
    <div className="memberComite">
      <h5>{props.rol_name}</h5>
      {!props.name ? null : (
        <p>
          <FontAwesomeIcon icon={Icons.faUserTie} size="1x" /> {props.name}
        </p>
      )}

      {!props.phone ? null : (
        <p>
          <FontAwesomeIcon icon={Icons.faPhone} size="1x" /> {props.phone}
        </p>
      )}
      {!props.email ? null : (
        <p>
          <FontAwesomeIcon icon={Icons.faEnvelope} size="1x" /> {props.email}
        </p>
      )}
    </div>
  );
};

export default ContactMember;
