import React from "react";
import { Container, Row, Col } from "react-bootstrap";

class Unsuscribe extends React.Component {
	state = {};

	render() {
		return (
			<div>
				<hr className="whiteSpace"></hr>
				<Container className="width-100 nopadding maxWidth-100 ">
					<Row>
						<Col lg={12} sm={12}>
							<hr className="whiteSpace"></hr>
							<h2 className="center">
								Te has dado de baja del boletin de noticias de la FES
							</h2>
							<hr className="whiteSpace"></hr>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Unsuscribe;
