import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";
import ActividadesSidebar from "../components/WidgetsSidebar/actividadesSidebar";
import ImageDefault from "../components/img/Fes.png";
import { Form, Button } from "react-bootstrap";
import ButtonFES from "../components/objects/button";
import TopImageTitle from "../components/objects/topImageTitle";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
//import Test from "../components/WidgetsSidebar/test";
import Cookies from "js-cookie";

class Actividad extends Component {
	state = {
		posts: [],
		prevPost: [],
		nxtPost: [],
		postID: "",
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;

		//fetch(`noticia/${permalink}`);
		let formData = new FormData();
		formData.append("permalink", permalink);
		//formData.append("permalink", pathurl);

		//Call to webservice to get the new
		axios.post("/api/get_single_news_by_permalink", formData).then((res) => {
			//console.log(res);
			this.setState({
				posts: res.data.records,
				postID: res.data.records[0].id,
			});

			var prevId = res.data.records[0].id - 1;
			var nextId = +res.data.records[0].id + 1;

			var formDataprev = new FormData();
			formDataprev.append("id", prevId);
			axios.post("/api/get_single_news", formDataprev).then((res) => {
				this.setState({
					prevPost: res.data.records,
				});
			});

			var formDatanext = new FormData();
			formDatanext.append("id", nextId);
			axios.post("/api/get_single_news", formDatanext).then((res) => {
				this.setState({
					nxtPost: res.data.records,
				});
			});
		});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;

		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();
			formData.append("permalink", permalink);
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			axios.post("/api/get_single_news_by_permalink", formData).then((res) => {
				//console.log(res);
				this.setState({
					posts: res.data.records,
				});
				var prevId = res.data.records[0].id - 1;
				var nextId = +res.data.records[0].id + 1;
				let formData = new FormData();
				formData.append("id", prevId);
				axios.post("/api/get_single_news", formData).then((res) => {
					this.setState({
						prevPost: res.data.records,
					});
				});

				var formDatanext = new FormData();
				formDatanext.append("id", nextId);
				axios.post("/api/get_single_news", formDatanext).then((res) => {
					this.setState({
						nxtPost: res.data.records,
					});
				});
			});
		}
	}

	render() {
		const { posts } = this.state;
		const { prevPost } = this.state;
		const { nxtPost } = this.state;
		const title = posts.map((post) => {
			return (
				<TopImageTitle
					key_id={post.id}
					title={post.title}
					cover_image={post.cover_image}
				></TopImageTitle>
			);
		});
		const previousPost = prevPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link to={`/actividad/${post.permalink}`} className="post-prev">
						<p class="m-2 m-lg-0">Anterior Noticia</p>
						<h6 class="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const nextPost = nxtPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link to={`/actividad/${post.permalink}`} className="post-next">
						<p class="m-2 m-lg-0">Siguiente Noticia</p>
						<h6 class="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const defaultImage = posts.map((post) => {
			if (!post.cover_image) {
				return <img src={ImageDefault} alt="a" className="blogImage"></img>;
			} else {
				return <img src={post.cover_image} alt="a" className="blogImage"></img>;
			}
		});
		const socialShare = posts.map((post, index) => {
			var image = post.cover_image
				? post.cover_image
				: "https://fes-sociologia.com/images/fes_logo.jpg";

			return (
				<div key={index}>
					<FacebookShareButton
						url={
							global.socialShare +
							"?link=" +
							post.permalink +
							"&image=" +
							image +
							"&title=" +
							encodeURIComponent(post.title.trim()) +
							"&description=" +
							encodeURIComponent(post.meta_description.trim()) +
							"&type=1"
						}
						quote="Federación Española de Sociología "
					>
						<FacebookIcon size={32} round={true} />
					</FacebookShareButton>
					<TwitterShareButton
						title={post.title}
						url={window.location.href}
						hashtags={["FESociologia"]}
					>
						<TwitterIcon size={32} round={true} />
					</TwitterShareButton>
				</div>
			);
		});
		const notice = posts.map((post) => {
			return (
				<Col>
					<div key={post.id} className="inner">
						{defaultImage}
						<div className="noticeInfo">
							<div>
								<p>{post.published_date}, Autor</p>
							</div>
							<div className="socialShare">{socialShare}</div>
						</div>
						<h2>{post.title}</h2>

						<div dangerouslySetInnerHTML={{ __html: post.content }} />
					</div>
					<hr></hr>
					<Container>
						<Row>
							<Col lg={9} md={12}>
								<div className="tags">
									<Link to={"/actividad/PUBLICACIONES-DE-SOCIOLOGA"}>
										PUBLICACIONES DE SOCIOLOGÍA
									</Link>
									<Link to={"/actividad/PUBLICACIONES-DE-SOCIOLOGA"}>
										PUBLICACIONES DE SOCIOLOGÍA
									</Link>
								</div>
							</Col>
							<Col sm={3}>
								<div className="socialShare">
									<ul className="social-icons si-colored-bg light">
										<li className="social-icons-item mb-2 social-facebook">
											<Link
												to={"/actividad/PUBLICACIONES-DE-SOCIOLOGA"}
												className="social-icons-link"
											>
												<i className="fa fa-facebook"></i>
											</Link>
										</li>
										<li className="social-icons-item mb-2 social-twitter">
											<Link
												to={"/actividad/PUBLICACIONES-DE-SOCIOLOGA"}
												className="social-icons-link"
											>
												<i className="fa fa-twitter"></i>
											</Link>
										</li>
										<li className="social-icons-item mb-2 social-linkedin">
											<Link
												to={"/actividad/PUBLICACIONES-DE-SOCIOLOGA"}
												className="social-icons-link"
											>
												<i className="fa fa-linkedin"></i>
											</Link>
										</li>
									</ul>
								</div>
							</Col>
						</Row>
						<Row className="postNavigation">
							{previousPost}
							<Col sm={2}>
								<Link>
									<i className="fa fa-th allPost"></i>{" "}
								</Link>
							</Col>
							{nextPost}
						</Row>
						<hr></hr>
						<Row>
							<Col sm={12}>
								<h2 className="center">Formulario de Registro</h2>
								<Form>
									<Form.Group controlId="formBasicEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control type="email" placeholder="Email@email.com" />
									</Form.Group>
									<Form.Group controlId="formGridAddress1">
										<Form.Label>Nombre</Form.Label>
										<Form.Control placeholder="Nombre..." />
									</Form.Group>
									<Form.Group controlId="exampleForm.ControlTextarea1">
										<Form.Label>Rellene con mensaje</Form.Label>
										<Form.Control
											as="textarea"
											rows="3"
											placeholder="Mensaje..."
										/>
									</Form.Group>
									<Form.Group controlId="formBasicCheckbox">
										<Form.Check
											type="checkbox"
											label="Acepto los terminos y condiciones"
										/>
									</Form.Group>
									<Button variant="primary" type="submit" className="button">
										Enviar
									</Button>
								</Form>
							</Col>
						</Row>
					</Container>
				</Col>
			);
		});

		let editThisPage;
		if (Cookies.get("isAdmin") === "1") {
			editThisPage = (
				<div>
					<a
						className="button textWhite editEntryButton"
						target="_blank"
						rel="noopener noreferrer"
						href={global.urlAdmin + "dashboard/news/edit/" + this.state.postID}
					>
						Editar entrada
					</a>
				</div>
			);
		} else {
			editThisPage = "";
		}
		return (
			<section>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col className="sidebar" lg={3} md={12}>
							{editThisPage}
							<ActividadesSidebar />
							<h2 className="titleSidebar">Descargas</h2>
							<ButtonFES
								button_text="Descargar PDF"
								button_link="#"
								button_class="button center"
							></ButtonFES>
						</Col>
						<Col lg={9} md={12}>
							{notice}
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default Actividad;
