import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";
import Categories from "../components/WidgetsSidebar/categories";
import Newsletter from "../components/WidgetsSidebar/newsletter";
import LastNews from "../components/WidgetsSidebar/lastNews";

import ImageDefault from "../components/img/noticias_default.jpg";
import TopImageTitle from "../components/objects/topImageTitle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import SEO from "../components/objects/seo";
import Cookies from "js-cookie";

class Noticia extends Component {
	state = {
		posts: [],
		prevPost: [],
		nxtPost: [],
		postID: "",
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;
		const cat = this.props.match.params.cat;
		const id = this.props.match.params.id;
		//fetch(`noticia/${permalink}`);

		let formData = new FormData();

		if (!id) {
			formData.append("permalink", "/" + permalink);
		} else {
			formData.append(
				"permalink",
				"/" + permalink + "/" + cat + "/" + id + "/"
			);
		}

		//formData.append("permalink", pathurl);

		//Call to webservice to get the new
		axios.post("/api/get_single_news_by_permalink", formData).then((res) => {
			//console.log(res);
			this.setState({
				posts: res.data.records,
				postID: res.data.records[0].id,
			});

			var prevId = res.data.records[0].id - 1;
			var nextId = +res.data.records[0].id + 1;

			var formDataprev = new FormData();
			formDataprev.append("id", prevId);
			axios.post("/api/get_single_news", formDataprev).then((res) => {
				this.setState({
					prevPost: res.data.records,
				});
			});

			var formDatanext = new FormData();
			formDatanext.append("id", nextId);
			axios.post("/api/get_single_news", formDatanext).then((res) => {
				this.setState({
					nxtPost: res.data.records,
				});
			});
		});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;
		const cat = this.props.match.params.cat;
		const id = this.props.match.params.id;

		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();

			if (!id) {
				formData.append("permalink", "/" + permalink);
			} else {
				formData.append(
					"permalink",
					"/" + permalink + "/" + cat + "/" + id + "/"
				);
			}
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			axios.post("/api/get_single_news_by_permalink", formData).then((res) => {
				//console.log(res);
				this.setState({
					posts: res.data.records,
				});
				var prevId = res.data.records[0].id - 1;
				var nextId = +res.data.records[0].id + 1;
				let formData = new FormData();
				formData.append("id", prevId);
				axios.post("/api/get_single_news", formData).then((res) => {
					this.setState({
						prevPost: res.data.records,
					});
				});

				var formDatanext = new FormData();
				formDatanext.append("id", nextId);
				axios.post("/api/get_single_news", formDatanext).then((res) => {
					this.setState({
						nxtPost: res.data.records,
					});
				});
			});
		}
	}

	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;
		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}
	render() {
		this.collapsible();
		const { posts } = this.state;
		const { prevPost } = this.state;
		const { nxtPost } = this.state;

		let editThisPage;
		if (Cookies.get("isAdmin") === "1") {
			editThisPage = (
				<div>
					<a
						className="button textWhite editEntryButton"
						target="_blank"
						rel="noopener noreferrer"
						href={global.urlAdmin + "dashboard/news/edit/" + this.state.postID}
					>
						Editar entrada
					</a>
				</div>
			);
		} else {
			editThisPage = "";
		}
		const MetaTagsSeo = posts.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.cover_image}
					key={"SEO" + post.id}
				></SEO>
			);
		});
		const title = posts.map((post, index) => {
			return (
				<TopImageTitle
					key={post.id}
					key_id={post.id + index}
					title={post.title}
					cover_image={post.cover_image}
					bread01={post.categories.map((categories) => categories.name)}
				></TopImageTitle>
			);
		});

		const previousPost = prevPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link to={`/noticia${post.permalink}`} className="post-prev">
						<p className="m-2 m-lg-0">Anterior Noticia</p>
						<h6 className="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const nextPost = nxtPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link to={`/noticia${post.permalink}`} className="post-next">
						<p className="m-2 m-lg-0">Siguiente Noticia</p>
						<h6 className="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const defaultImage = posts.map((post) => {
			if (!post.cover_image) {
				return (
					<img
						key={post.id}
						src={ImageDefault}
						alt="a"
						className="blogImage"
					></img>
				);
			} else {
				return (
					<img
						key={post.id}
						src={post.cover_image}
						alt="a"
						className="blogImage"
					></img>
				);
			}
		});
		const socialShare = posts.map((post, index) => {
			var image = post.cover_image
				? post.cover_image
				: "https://fes-sociologia.com/images/fes_logo.jpg";
			console.log(
				global.socialShare +
					"?link=/noticia" +
					post.permalink +
					"&image=" +
					image +
					"&title=" +
					encodeURIComponent(post.title.trim()) +
					"&description=" +
					encodeURIComponent(post.meta_description.trim()) +
					"&type=1"
			);
			return (
				<div key={index}>
					<FacebookShareButton
						url={
							global.socialShare +
							"?link=/noticia" +
							post.permalink +
							"&image=" +
							image +
							"&title=" +
							encodeURIComponent(post.title.trim()) +
							"&description=" +
							encodeURIComponent(post.meta_description.trim()) +
							"&type=1"
						}
						quote="Federación Española de Sociología "
					>
						<FacebookIcon size={32} round={true} />
					</FacebookShareButton>
					<TwitterShareButton
						title={post.title}
						url={window.location.href}
						hashtags={["FESociologia"]}
					>
						<TwitterIcon size={32} round={true} />
					</TwitterShareButton>
				</div>
			);
		});
		const catNotWant = global.notWantedCategoriesNews;
		let categoriesTopLeft;
		if (posts[0]) {
			categoriesTopLeft = posts[0].categories.map((categories, index) => {
				if (!posts[0].categories.length) {
					return null;
				} else {
					if (catNotWant.some((r) => categories.id.includes(r))) {
						//catCount++;
						return null;
					} else {
						return (
							<Link
								className="button"
								key={categories.id + index}
								to={"/categoria/" + categories.id}
							>
								<span>{categories.name}</span>
							</Link>
						);
					}
				}
			});
		}
		const notice = posts.map((post) => {
			var date = post.published_date;
			date = date.split(" ")[0];
			var yyyy = date.split("-")[0];
			var mmmm = date.split("-")[1];
			var dddd = date.split("-")[2];
			date = dddd + "-" + mmmm + "-" + yyyy;
			return (
				<Col key={post.id}>
					<div className="inner">
						{defaultImage}
						<span className="categoryNewsTop">{categoriesTopLeft}</span>
						<div className="noticeInfo">
							<div>
								<p>{date}</p>
							</div>
							<div className="socialShare">{socialShare}</div>
						</div>
						<h2>{post.title}</h2>

						<div dangerouslySetInnerHTML={{ __html: post.content }} />
					</div>
					<hr className="separatorNew"></hr>
					<Container>
						<Row>
							<Col sm={3}></Col>
						</Row>
						<Row className="postNavigation">
							{previousPost}
							<Col sm={2}>
								<Link to="/informate/noticias">
									<i className="fa fa-th allPost"></i>{" "}
								</Link>
							</Col>
							{nextPost}
						</Row>
					</Container>
				</Col>
			);
		});
		let goBack;
		this.state.posts.map((ne, index) => {
			var idcat = ne.categories.map((categories) => categories.id);
			if (global.notWantedCategoriesNews.some((r) => idcat.includes(r))) {
				let intersection = global.notWantedCategoriesNews.filter((x) =>
					idcat.includes(x)
				);
				if (intersection.includes("51")) {
					goBack = (
						<p>
							<Link to="/Publicaciones/Serie-Sur-Europa" className="goBacklink">
								<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
								Noticias
							</Link>
						</p>
					);
					return null;
				} else {
					goBack = (
						<p>
							<Link to="/Divulgación/noticias" className="goBacklink">
								<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
								Noticias
							</Link>
						</p>
					);
					return null;
				}
			} else {
				goBack = (
					<p>
						<Link to="/Divulgación/noticias" className="goBacklink">
							<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
							Noticias
						</Link>
					</p>
				);
				return null;
			}
		});
		return (
			<section>
				{title}
				{MetaTagsSeo}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col sm={12}>{goBack}</Col>
						<Col sm={12}></Col>
						<Col className="sidebar" lg={3} md={3} sm={12}>
							{editThisPage}
							<Categories />
							<Newsletter />
							<LastNews />
						</Col>
						<Col lg={9} md={9} sm={12}>
							{notice}
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default Noticia;
