import React from "react";
import { Row, Col, Container } from "react-grid-system";
import Carousel from "react-multi-carousel";
import axios from "axios";
import "react-multi-carousel/lib/styles.css";
import "../footer/style.css";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

// class component
class Logos extends React.Component {
	state = {
		sliderLogos: [],
	};
	getLogos = async () => {
		let res = await axios.get("/api/design_footer_sponsors_get");

		this.setState({ sliderLogos: res.data.records });
	};
	componentDidMount() {
		//Call to webservice to get all news
		(async () => {
			await this.getLogos();
		})();
	}
	render() {
		const { sliderLogos } = this.state;
		const sliderList = sliderLogos.length ? (
			sliderLogos.map((slider, index) => {
				return (
					<div key={index}>
						<img
							src={`${slider.img}`}
							alt={slider.alt}
							className="footer-logo-slider"
						/>
					</div>
				);
			})
		) : (
			<div className="center">No hay Logos</div>
		);
		return (
			<Container>
				<Row className="center">
					<Col>
						<Carousel responsive={responsive} infinite={true} arrows={false}>
							{sliderList}
						</Carousel>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Logos;
