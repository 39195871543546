import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import logoWhite from "../components/img/logo-white.svg";
import { loginFES } from "../components/utils/JWTAuth";
import { logoutFES } from "../components/utils/JWTAuth";
import { checkLoggedFES } from "../components/utils/JWTAuth";
import ReactGA from "react-ga";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

class Login extends React.Component {
	state = {
		email: "",
		password: "",
		emailUrl: "",
		url: "",
	};
	handleEmailChange = (evt) => {
		this.setState({ email: evt.target.value });
	};

	handlePasswordChange = (evt) => {
		this.setState({ password: evt.target.value });
	};
	componentDidMount() {
		const values = queryString.parse(window.location.search);
		window.scrollTo(0, 0);

		if (values.fromAdmin) {
			Cookies.set("fromAdmin", "1", { expires: 1 });
		}
		if (values.email) {
			this.setState({
				email: values.email,
				emailUrl: values.email,
			});
		}
		if (this.props.history.location.state === undefined) {
			return null;
		} else {
			this.setState({
				url: this.props.history.location.state.url_permalink,
			});
		}

		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	async logoutFES() {
		await logoutFES();
	}
	async checkLoggedFES() {
		await checkLoggedFES();
	}
	handleSubmit = (event) => {
		event.preventDefault();
	};
	handleSignIn(e) {
		e.preventDefault();
		let formData = new FormData();

		const checkEmail = new RegExp(
			"^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$"
		);

		if (checkEmail.test(this.refs.email.value) === true) {
			formData.append("email", this.refs.email.value);
			formData.append("passw", this.refs.passw.value);

			loginFES(formData, this.state.url, this.refs.email.value);
		} else {
			alert(
				"El email introducido no es válido. No debe usar caracteres especiales o espacios en blanco, y el email debe contener una @ y por lo menos un punto."
			);
		}
	}

	render() {
		const { email, password } = this.state;
		const isEnabled = email.length > 0 && password.length > 0;

		return (
			<div>
				<hr className="whiteSpace"></hr>
				<Container className="width-100 nopadding maxWidth-100 ">
					<Row>
						<Col md={5} sm={12}>
							<div className="align-items-center h-sm-100-vh d-flex bg-grad-blue ">
								<div className="width-100 textWhite login-padding">
									<img src={logoWhite} alt="Fes login logo" width="230px"></img>
									<h2 className="textWhite">Te damos la bienvenida a FES</h2>
									<div hidden>
										{" "}
										<h2 className="textWhite">
											Aviso para usuarios registrados en la antigua web
										</h2>
										<div className="listCheck textWhite">
											<p className="textWhite">Estimado/a amigo/a,</p>
											<p className="textWhite">
												En nuestro proceso de mejora, hemos actualizado nuestra
												web y necesitamos que actualices tu contraseña.
											</p>
											<p className="textWhite">
												Si ya entrabas en nuestra antigua web, utiliza la cuenta
												de email que utilizabas y pulsa sobre la opción
												“Recordar contraseña”, te llegará un email con un enlace
												donde podrás poner tu password nueva.
											</p>
											<p className="textWhite">
												Una vez cambiada la contraseña, utiliza tu email y tu
												nueva contraseña para loguearte y acceder a tus datos.
											</p>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col md={7} sm={12}>
							<div className="align-items-center h-sm-100-vh d-flex mx-auto form-login">
								<div className="width-100  login-padding ">
									<h2>Inicia sesión</h2>
									<div className="listCheck">
										<Form onSubmit={this.handleSignIn.bind(this)}>
											<Form.Group>
												<Form.Label>Email</Form.Label>
												<Form.Control
													type="text"
													placeholder="E-mail"
													id="email"
													name="email"
													ref="email"
													defaultValue={
														this.state.email
															? this.state.email
															: this.state.emailUrl
													}
													onChange={this.handleEmailChange}
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label>Contraseña</Form.Label>
												<Form.Control
													type="password"
													placeholder="Contraseña"
													id="password"
													name="passw"
													ref="passw"
													value={this.state.password}
													onChange={this.handlePasswordChange}
												/>
											</Form.Group>
											<span></span>
											<p>
												¿No tienes una cuenta?{" "}
												<a href="/registro">Registrarse ahora</a>
												<br></br>
												¿Has olvidado la contraseña?
												<a href="/recordar-password">Recordar Contraseña</a>
											</p>

											<Button
												disabled={!isEnabled}
												//onClick={this.loginFES}
												variant="primary"
												className="Acceder"
												type="submit"
											>
												Entrar
											</Button>
										</Form>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default withRouter(Login);
