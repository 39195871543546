import React from 'react';
import { Tabs, Tab, Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import SidebarProfile from '../components/sidebar';
import TopImageTitle from '../../../components/objects/topImageTitle';
import './styles.css';
import { Table, Collapse, Input, Space, Upload, Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Loader from 'react-loader-spinner';
import TabResume from './Components/tabResume';
import TabDoc from './Components/tabDoc';
import TabInfo from './Components/tabInformation';
import TabDocFes from './Components/tabDocFes';
import TabUsers from './Components/tabUsers';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashLink as Link } from 'react-router-hash-link';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import SEO from '../../../components/objects/seo';
//import TabUser from "./Components/tabUsers";

class AdminComites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: [],
      idComite: this.props.match.params.id,
      key: 'resumenComite',
      fetchingAdminOrder: [],
      userOrders: [],
      pdfList: [],
      userComitesAdmin: [],
      comiteRoles: [],
      comitesMembers: [],
      news: [],
      comiteContactData: [],
      content: '',
      editNews: false,
      isAdmin: false,
      visibleModalRol: false,
      fetchRoles: true,
      fetchMembers: true,
      fetchContactData: true,
      fetchNews: true,
      fetchComiteData: true,
      fetchDocData: true,
      docComite: [],
      titleComite: '',
      allCategories: [],
      fetchCatNews: true,
      msgCompleteContact: '',
      phase: '',
      idVotation: '',
      imgToEditNew: '',
      fetchInformation: true,
      categoriesComitesId: [{ label: 'Comite de investigación', value: '52' }],
      pdfUrl: '',
      newToDeleteId: '',
      comitesUserGet: [],
      newSidebar: [],
      orderForSidebar: [],
      publishedDate: [],
      validatedCreateForm: false,
      validatedEditForm: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
  }
  forceUpdateHandler() {
    this.forceUpdate();
  }
  handleSelect(key) {
    this.setState({ key });
  }
  checkTokken = async () => {
    const jwt = localStorage.getItem('access_token');
    if (!jwt) {
      this.setState.islogged = 0;
    } else {
      this.setState.islogged = 1;
      let formData = new FormData();
      formData.append('jwt', jwt);
      await axios
        .post('/api/validate_token', formData)
        .then((res) => {
          this.setState({
            userInfo: res.data.data,
          });
        })
        .catch((error) => {
          this.setState({
            statusPage: 1,
          });
        });
    }
  };

  handleChangePdf = (doc) => {
    if (doc.file.status !== 'uploading') {
      this.setState({ pdfList: doc.fileList });
    }
  };
  handleUploadPdf = ({ fileList }) => {
    this.setState({ pdfList: fileList });

    let formData = new FormData();
    const file = fileList[fileList.length - 1].originFileObj;
    try {
      formData.append('pdf', file);
    } catch (error) {
      formData.append('pdf', '');
    }

    axios
      .post('/api/attached_upload', formData)
      .then((res) => {
        this.setState({ pdfUrl: res.data.url });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  checkDateVotation = async () => {
    this.setState({
      fetchInformation: true,
    });
    await axios
      .post('/api/votes_check_dates')

      .then((res) => {
        this.setState({
          phase: res.data.records[1].phase,
          idVotation: res.data.records[0].id,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchInformation: false,
        });
      });
  };
  checkIfAdminComite = async () => {
    let formData = new FormData();
    const { userInfo } = this.state;
    var userId = userInfo.id;
    var id_comite = this.props.match.params.id;
    formData.append('id_comite', id_comite);
    formData.append('id_user', userId);
    await axios
      .post('/api/comites_check_admin', formData)
      .then((res) => {
        this.setState({
          isAdmin: true,
        });
      })
      .catch((error) => {
        this.setState({
          isAdmin: false,
        });
        //Redirect to profile if not admin
        this.props.history.push('/perfil');
      });
  };
  getOrdersUser = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id_user', userId);
    await axios
      .post('/api/payment_get_by_id_user', formData)
      .then((res) => {
        this.setState({
          userOrders: res.data.records,
        });
      })
      .catch((error) => { });
  };
  getCategoriesNews = async () => {
    await axios
      .get('/api/get_associated_categories')
      .then((res) => {
        this.setState({
          allCategories: res.data.records,
          fetchCatNews: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchCatNews: false,
        });
      });
  };
  getComitesAdmin = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    this.setState({
      fetchingAdminOrder: true,
    });
    formData.append('id', userId);
    await axios
      .post('/api/users_comites_admin_get_by_id', formData)
      .then((res) => {
        this.setState({
          userComitesAdmin: res.data.records,
          fetchingAdminOrder: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingAdminOrder: false,
        });
      });
  };
  async getRolesMembers() {
    await axios
      .post('/api/comites_get_roles')
      .then((res) => {
        this.setState({
          comiteRoles: res.data.records,
          fetchRoles: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchRoles: false,
        });
      });
  }
  async getComiteMembers() {
    let formData = new FormData();
    var id_comite = this.props.match.params.id;
    formData.append('id_comite', id_comite);
    await axios
      .post('/api/comites_members_get_by_id', formData)
      .then((res) => {
        this.setState({
          comitesMembers: res.data.records,
          fetchMembers: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchMembers: false,
        });
      });
  }
  getComiteMembersChild = () => {
    this.setState({
      fetchMembers: true,
    });
    let formData = new FormData();
    var id_comite = this.props.match.params.id;
    formData.append('id_comite', id_comite);
    axios
      .post('/api/comites_members_get_by_id', formData)
      .then((res) => {
        this.setState({
          comitesMembers: res.data.records,
          fetchMembers: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchMembers: false,
        });
      });
  };
  async getComiteNews() {
    this.setState({
      fetchNews: true,
    });
    let formData = new FormData();
    var id_comite = this.props.match.params.id;
    formData.append('id_comite', id_comite);
    await axios
      .post('/api/comites_featured_news_get', formData)
      .then((res) => {
        if (res.data.records) {
          this.setState({
            news: res.data.records,
          });
        }
        this.setState({
          fetchNews: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchNews: false,
        });
      });
  }
  async getComiteContactData() {
    let formData = new FormData();
    var id_comite = this.props.match.params.id;
    formData.append('id_comite', id_comite);
    await axios
      .post('/api/comites_contact_get', formData)
      .then((res) => {
        this.setState({
          comiteContactData: res.data.records,
          fetchContactData: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchContactData: false,
        });
      });
  }
  async getComiteInfo() {
    let formData = new FormData();
    var id_comite = this.props.match.params.id;
    formData.append('id', id_comite);
    await axios
      .post('/api/comites_get_by_id', formData)
      .then((res) => {
        this.setState({
          titleComite: res.data.records[0].title,
          moreInfoComite: res.data.records[0].more_info_html,
          contetComite: res.data.records[0].content,
          fetchComiteData: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchComiteData: false,
        });
      });
  }
  async getComiteDocuments() {
    let formData = new FormData();
    var id_comite = this.props.match.params.id;
    formData.append('id_comite', id_comite);
    await axios
      .post('/api/comites_documents_get', formData)
      .then((res) => {
        this.setState({
          docComite: res.data.records,
          fetchDocData: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchDocData: false,
        });
      });
  }
  getComiteDocumentsChild = () => {
    this.setState({
      fetchDocData: true,
    });
    let formData = new FormData();
    var id_comite = this.props.match.params.id;
    formData.append('id_comite', id_comite);
    axios
      .post('/api/comites_documents_get', formData)
      .then((res) => {
        this.setState({
          docComite: res.data.records,
          fetchDocData: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchDocData: false,
        });
      });
  };
  setMessageWarning() {
    const { comiteContactData } = this.state;
    //Message user Personal data
    if (comiteContactData) {
      comiteContactData.map((cd, index) => {
        if (!cd.name || !cd.email) {
          this.setState({
            msgCompleteContact:
              'Para poder ver el resto de opciones es necesario que indique los campos marcados con asterisco (*) en la informacion de contacto del comité',
          });
        } else {
          this.setState({
            msgCompleteContact: null,
          });
        }
        return null;
      });
    } else {
      this.setState({
        msgCompleteContact: null,
      });
    }
  }
  getComitesUserActual = async () => {
    const { userInfo } = this.state;
    var userId = userInfo.id;
    let formData = new FormData();
    formData.append('id', userId);

    await axios
      .post('/api/users_comites_current_year_get_by_id', formData)
      .then((res) => {
        this.setState({
          comitesUserGet: res.data.records,
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    (async () => {
      await this.checkTokken();
      await this.checkIfAdminComite();
      await this.getComitesAdmin();
      await this.getOrdersUser();
      await this.getRolesMembers();
      await this.getComiteMembers();
      await this.getCategoriesNews();
      await this.getComiteNews();
      await this.getComiteDocuments();
      await this.getComiteContactData();
      await this.getComiteInfo();
      await this.setMessageWarning();
      await this.checkDateVotation();
      await this.getComitesUserActual();
    })();
    if (this.props.history.location.state) {
      this.setState({
        key: this.props.history.location.state.key,
      });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params !== prevProps.match.params) {
      (async () => {
        await this.checkTokken();
        await this.checkIfAdminComite();
        await this.getComitesAdmin();
        await this.getOrdersUser();
        await this.getRolesMembers();
        await this.getComiteMembers();
        await this.getCategoriesNews();
        await this.getComiteNews();
        await this.getComiteDocuments();
        await this.getComiteContactData();
        await this.getComiteInfo();
        await this.setMessageWarning();
        await this.checkDateVotation();
      })();
      if (this.props.history.location.state) {
        this.setState({
          key: this.props.history.location.state.key,
        });
      }
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentWillReceiveProps(newProps) {
    this.setState({
      location: newProps.location,
    });
  }
  handleEditorChangeNewContent = (content, editor) => {
    this.setState({
      contentNew: content,
    });
  };
  handleEditorChangeNewUpdateContent = (content, editor) => {
    this.setState({
      contentToEditNewSend: content,
    });
  };
  deleteNewId = () => {
    let formData = new FormData();

    formData.append('id', this.state.newToDeleteId);
    if (this.state.news_type_delete === 'topicality') {
      axios
        .post('/api/topicality_delete_by_id', formData)
        .then((res) => {
          this.setState({
            visibleModalDelete: false,
            newToDeleteTitle: '',
            newToDeleteId: '',
          });
          alert('La noticia se ha borrado correctamente');
          this.props.history.push({
            pathname: '/perfil/admin/comite/' + this.state.idComite,
            state: {
              key: 'newsComite',
            },
          });
        })
        .catch((error) => {
          console.log(error.response.data);
          this.setState({
            visibleModalDelete: false,
            newToDeleteTitle: '',
            newToDeleteId: '',
          });
          alert('No se ha podido borrar la noticia');
        });
    } else if (this.state.news_type_delete === 'news') {
      axios
        .post('/api/news_delete_by_id', formData)
        .then((res) => {
          this.setState({
            visibleModalDelete: false,
            newToDeleteTitle: '',
            newToDeleteId: '',
          });
          alert('La noticia se ha borrado correctamente');
          this.props.history.push({
            pathname: '/perfil/admin/comite/' + this.state.idComite,
            state: {
              key: 'newsComite',
            },
          });
        })
        .catch((error) => {
          console.log(error.response.data);
          this.setState({
            visibleModalDelete: false,
            newToDeleteTitle: '',
            newToDeleteId: '',
          });
          alert('No se ha podido borrar la noticia');
        });
    } else {
      alert('No se ha podido borrar la noticia');
    }
  };

  handleSubmitNews = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.newToFes.value && event.target.titleNew.value && event.target.short_descriptionNew.value) {

      this.setState({ validatedCreateForm: true });

      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();
      var date = new Date().getDate();
      var FullDate = year + '-' + month + '-' + date;

      let formData = new FormData();
      var id_comite = this.props.match.params.id;
      //formData.append(	"categories",JSON.stringify(event.target.categoryNew.value)	);
      formData.append(
        'categories',
        JSON.stringify(this.state.categoriesComitesId)
      );
      formData.append('title', event.target.titleNew.value);
      formData.append('content', this.state.contentNew);
      formData.append('meta_title', event.target.titleNew.value);
      formData.append(
        'meta_description',
        !event.target.short_descriptionNew.value
          ? event.target.titleNew.value
          : event.target.short_descriptionNew.value
      );
      formData.append('source', 1);
      formData.append(
        'short_description',
        event.target.short_descriptionNew.value
      );
      formData.append('img', event.target.newsImage.files[0]);
      formData.append('sendMail', event.target.newToFes.value === 'true' ? 1 : 0);
      formData.append('newToFes', event.target.newToFes.value);
      formData.append('comite_name', this.state.titleComite);
      formData.append('display', true);
      formData.append('date', FullDate);
      formData.append('external_url', '');
      formData.append('keywords', '');
      formData.append('tags', '');
      formData.append('display_comites', true);
      formData.append('id_comite', id_comite);

      axios
        .post('/api/create_news', formData)
        .then((res) => {
          alert('Se ha publicado la noticia');
          this.getComiteNews();
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          alert('Hemos encontrado un problema y no se ha publicado la noticia');
        });
    } else {
      this.setState({ validatedCreateForm: true });
    }


  };

  handleUpdateNews = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.newToFes.value && event.target.titleNew.value && event.target.short_descriptionNew.value) {

      this.setState({ validatedEditForm: true });

      let formData = new FormData();
      var id_comite = this.props.match.params.id;
      //formData.append(	"categories",JSON.stringify(event.target.categoryNew.value)	);
      formData.append(
        'categories',
        JSON.stringify(this.state.categoriesComitesId)
      );

      formData.append('id', this.state.idToEditNew);
      formData.append('title', event.target.titleNew.value);
      formData.append('content', this.state.contentToEditNewSend);
      formData.append('meta_title', event.target.titleNew.value);
      formData.append(
        'meta_description',
        !event.target.short_descriptionNew.value
          ? event.target.titleNew.value
          : event.target.short_descriptionNew.value
      );
      formData.append(
        'short_description',
        event.target.short_descriptionNew.value
      );
      formData.append('img', event.target.newsImage.files[0]);
      formData.append('current_img', this.state.imgToEditNew);
      formData.append('display', true);
      formData.append('date', this.state.publishedDate);
      formData.append('external_url', '');
      formData.append('keywords', '');
      formData.append('tags', '');
      formData.append('display_comites', true);
      formData.append('id_comite', id_comite);
      formData.append('newToFes', event.target.newToFes.value);

      if (this.state.news_type === 'topicality') {
        axios
          .post('/api/topicality_update', formData)
          .then((res) => {
            alert('Se ha Actualizado la noticia');
            this.setState({
              editNews: false,
              idToEditNew: '',
              titleToEdit: '',
              contentToEditNew: '',
              contentToEditNewSend: '',
              categoriesToEditNew: '',
              short_descriptionToEditNew: '',
            });
            this.getComiteNews();
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.log(error.response.data);
            alert(
              'Hemos encontrado un problema y no se ha actualizado la noticia'
            );
          });
      } else if (this.state.news_type === 'news') {
        axios
          .post('/api/update_news_comites', formData)
          .then((res) => {
            alert('Se ha Actualizado la noticia');
            this.setState({
              editNews: false,
              idToEditNew: '',
              titleToEdit: '',
              contentToEditNew: '',
              contentToEditNewSend: '',
              categoriesToEditNew: '',
              short_descriptionToEditNew: '',
            });
            this.getComiteNews();
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.log(error.response.data);
            alert(
              'Hemos encontrado un problema y no se ha actualizado la noticia'
            );
          });
      } else {
        alert('Hemos encontrado un problema y no se ha actualizado la noticia');
      }

    } else {
      this.setState({ validatedEditForm: true });
    }

  };

  editThisNew = (
    idNew,
    titleNew,
    contentNew,
    categoriesNew,
    imgNew,
    short_descriptionNew,
    news_type,
    publishedDate,
    newToFes,
  ) => {
    this.forceUpdateHandler();
    this.setState({
      editNews: true,
      idToEditNew: idNew,
      titleToEdit: titleNew,
      contentToEditNew: contentNew,
      contentToEditNewSend: contentNew,
      categoriesToEditNew: categoriesNew,
      imgToEditNew: imgNew,
      short_descriptionToEditNew: short_descriptionNew,
      news_type: news_type,
      publishedDate: publishedDate,
      newToFes: newToFes,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reiniciar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  deleteNew = (idNew, titleNew, news_type) => {
    this.setState({
      newToDeleteTitle: titleNew,
      newToDeleteId: idNew,
      visibleModalDelete: true,
      news_type_delete: news_type,
    });
  };
  handleOkDelete = (e) => {
    e.preventDefault();
    this.deleteNewId();
  };
  handleCancelDelete = (e) => {
    this.setState({
      visibleModalDelete: false,
      newToDeleteTitle: '',
      newToDeleteId: '',
      news_type_delete: '',
    });
  };
  getNewSidebar = async () => {
    let formData = new FormData();

    formData.append('id_categ', 21);
    await axios
      .post('/api/news_get_by_id_categ', formData)
      .then((res) => {
        this.setState({
          newSidebar: res.data.records,
        });
      })
      .catch((error) => { });
  };
  checkForRenew = (arrayofItems) => {
    this.setState({
      fetchingAdminOrder: true,
    });
    var temp_array = [];
    var temp_array_year = [];
    arrayofItems.forEach((item) => {
      if (+item.id_template !== 11 && +item.id_template !== 12) {
        var id = item.id;
        let formData = new FormData();
        formData.append('id', item.id);
        axios
          .post('/api/payment_renov_get', formData)
          .then((res) => {
            temp_array.push(id);
            temp_array_year.push(id);
            this.setRenovationMesagge();
          })
          .catch((error) => {
            if (error.response.data.message === 'Not today') {
              temp_array_year.push(id);
              return null;
            } else if (
              error.response.data.records[0].message ===
              'Date ok, but this order is already renovated'
            ) {
              temp_array_year.push(id);
            }
          });
      }
    });
    this.setState({
      renovableOrder: temp_array,
      orderForSidebar: temp_array_year,
      fetchingAdminOrder: false,
    });
  };
  checkIfRenovable = (id) => {
    if (this.state.renovableOrder.includes(id)) {
      return true;
    } else {
      return false;
    }
  };
  publishDate = (dateNew) => {
    var date = dateNew;
    date = date.split(' ')[0];
    var yyyy = date.split('-')[0];
    var mmmm = date.split('-')[1];
    var dddd = date.split('-')[2];
    date = dddd + '-' + mmmm + '-' + yyyy;
    return date;
  };

  render() {
    const { fetchingAdminOrder } = this.state;
    const { userInfo } = this.state;
    const { userOrders } = this.state;
    const { userComitesAdmin } = this.state;
    const { Panel } = Collapse;
    const { comiteContactData } = this.state;
    const { isAdmin } = this.state;
    const { fetchRoles } = this.state;
    const { fetchMembers } = this.state;
    const { fetchContactData } = this.state;
    const { fetchNews } = this.state;
    const { titleComite } = this.state;
    const { idComite } = this.state;
    const { fetchDocData } = this.state;
    const { fetchComiteData } = this.state;
    const { allCategories } = this.state;
    const { fetchCatNews } = this.state;
    const { msgCompleteContact } = this.state;
    const { pdfList } = this.state;
    const allLoad =
      fetchRoles === false &&
      fetchMembers === false &&
      fetchContactData === false &&
      fetchNews === false &&
      fetchDocData === false &&
      fetchComiteData === false &&
      fetchCatNews === false &&
      isAdmin === true;
    const sidebar = !fetchingAdminOrder ? (
      <SidebarProfile
        userInfo={userInfo}
        userOrders={userOrders}
        userComitesAdmin={userComitesAdmin}
        votationActive={this.state.phase}
        comitesUserGet={this.state.comitesUserGet}
        newSidebar={this.state.newSidebar}
        orderForSidebar={this.state.orderForSidebar}
        renovableOrder={this.state.renovableOrder}
        {...this.state}
      ></SidebarProfile>
    ) : (
      <p>Cargando</p>
    );

    let title;
    if (!titleComite) {
      title = null;
    } else if (titleComite) {
      title = (
        <TopImageTitle
          title={titleComite}
          subtitle="Resumen y administracion"
          bread01="Comite x"
        ></TopImageTitle>
      );
    }

    let categoriesNews;
    if (allCategories) {
      categoriesNews = allCategories.map((cat, index) => {
        return (
          <option key={index} value={cat.id}>
            {cat.name}
          </option>
        );
      });
    }
    const columnsNews = [
      {
        title: 'Noticias publicadas',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => (
          <span>
            <p
              onClick={() =>
                this.editThisNew(
                  record.id,
                  record.title,
                  record.content,
                  record.categories,
                  record.img,
                  record.short_description.content,
                  record.news_type,
                  record.published_date,
                  record.newToFes,
                )
              }
            >
              {record.title}
            </p>
          </span>
        ),
        ...this.getColumnSearchProps('title'),
      },
      {
        title: 'Fecha de publicación',
        dataIndex: 'published_date',
        key: 'published_date',
        render: (text, record) => (
          <span>{this.publishDate(record.published_date)}</span>
        ),
      },
      {
        title: 'Editar Noticia',
        dataIndex: '',
        key: 'x',
        render: (text, record) => (
          <span>
            <p
              className="button"
              onClick={(event) =>
                this.editThisNew(
                  record.id,
                  record.title,
                  record.content,
                  record.categories,
                  record.img,
                  record.short_description,
                  record.news_type,
                  record.published_date,
                  record.newToFes,
                )
              }
            >
              Editar
            </p>
          </span>
        ),
      },
      {
        title: 'Borrar noticia',
        dataIndex: '',
        key: 'y',
        render: (text, record) => (
          <span>
            <p
              className="button"
              onClick={(event) =>
                this.deleteNew(record.id, record.title, record.news_type)
              }
            >
              Borrar
            </p>
          </span>
        ),
      },
    ];

    const tableNews = (
      <Table
        columns={columnsNews}
        onChange={this.onChange}
        dataSource={this.state.news}
        className="isoSearchableTable"
        rowKey="id"
      />
    );
    const uploadPdfButton = <p>Subir documentos al Servidor</p>;
    const editClickedNew = (
      <Form onSubmit={this.handleUpdateNews} noValidate validated={this.state.validatedEditForm} key={this.state.contentToEditNew}>
        <Row>
          <Col lg={12} sm={12}>
            <Form.Group controlId="titleNew">
              <Form.Label>Título de la noticia*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título"
                name="titleNew"
                ref="titleNew"
                defaultValue={this.state.titleToEdit}
                key={this.state.titleToEdit}
                required
              />
              <Form.Control.Feedback type="invalid">
                Introduzca un título
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="short_descriptionNew">
              <Form.Label>
                Entradilla (Esto es un pequeño texto que aparece antes de ver la
                noticia al completo, longitud máxima 170 caracteres)*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Entradilla"
                name="short_descriptionNew"
                ref="short_descriptionNew"
                key={this.state.short_descriptionToEditNew}
                defaultValue={this.state.short_descriptionToEditNew}
                maxLength="170"
                required
              />
              <Form.Control.Feedback type="invalid">
                Introduzca una entradilla
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}></Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="contentNews">
              <Form.Label>Contenido de la noticia*</Form.Label>
              <Editor
                apiKey={global.editorApiKey}
                key={this.state.short_descriptionToEditNew}
                initialValue={this.state.contentToEditNew}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  contextmenu: false,
                  toolbar:
                    ' undo redo | formatselect | link bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                }}
                onEditorChange={this.handleEditorChangeNewUpdateContent}
              />
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Label>Subir documento para enlazar en la noticia</Form.Label>
            <p className="descriptionNew">
              1) Suba el documento que desea enlazar a través del cuadro que
              mostramos más abajo.
            </p>
            <p className="descriptionNew">
              2) Una vez subido, le aparecerá la url que deberá copiar para
              enlazar en el texto de la noticia.
            </p>
          </Col>
          <Col lg={12} sm={12}>
            {' '}
            <Upload
              listType="picture-card"
              fileList={pdfList}
              onPreview={this.handlePreview}
              onChange={this.handleUploadPdf}
              beforeUpload={() => false}
              className="custom-upload-square"
            >
              {pdfList.length >= 20 ? null : uploadPdfButton}
            </Upload>
            <p key={this.state.pdfUrl ? 'notLoadedYet' : 'loaded'}>
              {this.state.pdfUrl}
            </p>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="imageNews">
              <Form.Label>
                Imagen de la noticia (Tamaño de imagen recomendado de
                800X300px):
              </Form.Label>
              <Form.Label className='image-warning'>
                Por favor, suba una imagen que no contenga letras, ni carteles con texto. Resulta ilegible. Los carteles se podrán subir en la noticia como adjunto. La FES desechará la imagen si considera que no cumple los criterios
              </Form.Label>
              <Form.Control
                onChange={(e) => console.log(e.target.files)}
                type="file"
                name="newsImage"
                placeholder="Fichero a subir"
                accept="image/*"
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12} key={this.state.img}>
            {this.state.imgToEditNew ? (
              <div>
                <p>Imagen actual:</p>
                <img
                  src={this.state.imgToEditNew}
                  className="width-100"
                  alt="Visior de imagen"
                ></img>
              </div>
            ) : (
              <p>No hay imagen de portada</p>
            )}
          </Col>
          <Col lg={6} sm={12} hidden>
            <Form.Group controlId="Categoryselect">
              <Form.Label>Seleccione la categoria*</Form.Label>
              <Form.Control as="select" name="categoryNew" ref="categoryNew">
                <option value="01">-Seleccione una opción-</option>
                {categoriesNews}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="newtoFES">
              <Form.Label>
                Tipo de noticia
              </Form.Label>
              <Form.Control
                as="select"
                name="newToFes"
                ref="newToFes"
                value={this.state.newToFes == 1 ? "true" : "false"}
                onChange={(e) => this.setState({ newToFes: e.target.value === "true" ? true : false })}
                defaultValue=""
                required
              >
                <option value="" disabled>Selecciona una opción</option>
                <option value="true">Actividad propia</option>
                <option value="false">Otros</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Seleccione una noticia
              </Form.Control.Feedback>
            </Form.Group>

          </Col>

          <Col sm={12} xs={12}>
            <div className='warning-container'>
              {this.state.newToFes ?
                <div>
                  Elija la opción “Actividad propia” para noticias correspondientes a actividades propias de los comités. Estas noticias se asignarán de forma automática a la categoría "Comités de investigación" y se mostrarán en la sección pública de noticias de FES y en el boletín.
                </div>
                :
                <div>
                  Elija la opción "Otros" para noticias de interés para los inscritos al comités, pero que no correspondan a actividades realizadas por el propio comité. Estas noticias se mostrarán únicamente en la sección de noticias del apartado web del comité.
                </div>}
            </div>
          </Col>
          <Col sm={12} xs={12}>
            <Button ref="btnNews" variant="primary" type="submit">
              Actualizar noticia
            </Button>
          </Col>
        </Row>
      </Form>
    );
    const formCreateNews = (
      <Form noValidate validated={this.state.validatedCreateForm} onSubmit={this.handleSubmitNews}>
        <Row>
          <Col lg={12} sm={12}>
            <Form.Group controlId="titleNew">
              <Form.Label>Título de la noticia*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título"
                name="titleNew"
                ref="titleNew"
                required
              />
              <Form.Control.Feedback type="invalid">
                Introduzca un título
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="short_descriptionNew">
              <Form.Label>
                Entradilla (Esto es un pequeño texto que aparece antes de ver la
                noticia al completo, longitud máxima 170 caracteres)*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Entradilla"
                name="short_descriptionNew"
                ref="short_descriptionNew"
                maxLength="170"
                required
              />
              <Form.Control.Feedback type="invalid">
                Introduzca una entradilla
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={12} sm={12}>
            <Form.Group controlId="contentNews">
              <Form.Label>Contenido de la noticia*</Form.Label>
              <Editor
                apiKey={global.editorApiKey}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | link bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                }}
                onEditorChange={this.handleEditorChangeNewContent}
              />
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Label>Subir documento para enlazar en la noticia</Form.Label>
            <p className="descriptionNew">
              1) Suba el documento que desea enlazar a través del cuadro que
              mostramos más abajo.
            </p>
            <p className="descriptionNew">
              2) Una vez subido, le aparecerá la url que deberá copiar para
              enlazar en el texto de la noticia.
            </p>
          </Col>

          <Col lg={12} sm={12}>
            <Upload
              listType="picture-card"
              fileList={pdfList}
              onPreview={this.handlePreview}
              onChange={this.handleUploadPdf}
              beforeUpload={() => false}
              className="custom-upload-square"
            >
              {pdfList.length >= 20 ? null : uploadPdfButton}
            </Upload>
            <p key={this.state.pdfUrl ? 'notLoadedYet' : 'loaded'}>
              {this.state.pdfUrl}
            </p>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="imageNews">
              <Form.Label>
                Imagen de la noticia (Tamaño de imagen recomendado de
                800X300px):
              </Form.Label>
              <Form.Label className='image-warning'>
                Por favor, suba una imagen que no contenga letras, ni carteles con texto. Resulta ilegible. Los carteles se podrán subir en la noticia como adjunto. La FES desechará la imagen si considera que no cumple los criterios
              </Form.Label>
              <Form.Control
                onChange={(e) => console.log(e.target.files)}
                type="file"
                name="newsImage"
                placeholder="Fichero a subir"
                accept="image/*"
              />
            </Form.Group>
          </Col>
          <Col lg={6} sm={12} hidden>
            <Form.Group controlId="Categoryselect">
              <Form.Label>Seleccione la categoria*</Form.Label>
              <Form.Control as="select" name="categoryNew" ref="categoryNew">
                <option value="01">-Seleccione una opción-</option>
                {categoriesNews}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg={12} sm={12}>
            <Form.Group controlId="newtoFES">
              <Form.Label>
                Tipo de noticia
              </Form.Label>
              <Form.Control
                as="select"
                name="newToFes"
                ref="newToFes"
                onChange={(data) => { this.setState({ newToFes: data.value }); console.log(data.value) }}
                defaultValue=""
                aria-describedby="inputGroupPrepend"
                required
              >
                <option value="" disabled>Selecciona una opción</option>
                <option value="true">Actividad propia</option>
                <option value="false">Otros</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Seleccione una noticia
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} xs={12}>
            <div className='warning-container'>
              <ul>
                <li>
                  Elija la opción "Otros" para noticias de interés para los inscritos al comités, pero que no correspondan a actividades realizadas por el propio comité. Estas noticias se mostrarán únicamente en la sección de noticias del apartado web del comité.
                </li>
                <li>
                  Elija la opción “Actividad propia” para noticias correspondientes a actividades propias de los comités. Estas noticias se asignarán de forma automática a la categoría "Comités de investigación" y se mostrarán en la sección pública de noticias de FES y en el boletín.
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={12} xs={12}>
            <Button ref="btnNews" variant="primary" type="submit">
              Publicar noticia
            </Button>
          </Col>
        </Row>
      </Form>
    );
    const headers = [
      { label: 'Nombre', key: 'name' },
      { label: 'Apellidos', key: 'lastname' },
      { label: 'Email', key: 'email' },
      { label: 'Telefono', key: 'phone' },
      { label: 'Tipo de Socio/a ', key: 'type_name' },
      { label: 'Institucion actual', key: 'org' },
      { label: 'Detalle de institución actual', key: 'org_extra' },
      { label: 'Rol', key: 'rol_name' },
    ];

    const listUserDownload = (
      <CSVLink
        filename={'Listado-de-miembros.csv'}
        data={this.state.comitesMembers}
        headers={headers}
        separator={';'}
        enclosingCharacter={' '}
      >
        <button className="button">Descargar Listado actual de Socios</button>
      </CSVLink>
    );

    return (
      <div>
        <SEO title="Administrar Comités" key={'SEO-ADMINISTRAR'}></SEO>
        <hr className="whiteSpace"></hr>
        {title}

        <section>
          <Container>
            <Row>
              <Col sm={12}>
                <Modal
                  title="Borrar noticia"
                  visible={this.state.visibleModalDelete}
                  onOk={this.handleOkDelete}
                  onCancel={this.handleCancelDelete}
                  cancelText="Cancelar"
                  okText="Aceptar"
                >
                  {' '}
                  <p>
                    {'Se dispone a borrar la noticia con titulo: ' +
                      this.state.newToDeleteTitle}
                  </p>
                </Modal>
                <p>
                  <Link to="/perfil" className="goBacklink">
                    <FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" />{' '}
                    Volver al perfil
                  </Link>
                </p>
              </Col>
              <Col lg={3} sm={12}>
                {sidebar}
              </Col>
              {allLoad === false ? (
                <div className="centerloader">
                  <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  />
                  <h3>Cargando información del Comité</h3>
                </div>
              ) : (
                <Col lg={9} sm={12}>
                  {msgCompleteContact ? (
                    <div className="alert alert-warning" role="alert">
                      {msgCompleteContact}
                    </div>
                  ) : null}
                  <Tabs
                    className="tabsComites"
                    defaultActiveKey="resumen"
                    activeKey={this.state.key}
                    onSelect={this.handleSelect}
                  >
                    <Tab eventKey="resumenComite" title="Resumen">
                      <div className="margin-top-20"></div>
                      <TabResume
                        news={this.state.news}
                        members={this.state.comitesMembers}
                        documents={this.state.docComite}
                        comiteContactData={comiteContactData}
                        idComite={idComite}
                      ></TabResume>
                    </Tab>
                    <Tab
                      eventKey="membersComite"
                      title="Miembros"
                      disabled={msgCompleteContact === null ? false : true}
                    >
                      <div className="margin-top-20"></div>
                      {listUserDownload}
                      <div className="margin-top-20"></div>
                      <TabUsers
                        comitesMembers={this.state.comitesMembers}
                        comiteRoles={this.state.comiteRoles}
                        idComite={idComite}
                        functGetMember={this.getComiteMembersChild}
                      ></TabUsers>
                    </Tab>
                    <Tab
                      eventKey="newsComite"
                      title="Noticias"
                      disabled={msgCompleteContact === null ? false : true}
                    >
                      <div className="margin-top-20"></div>
                      <Collapse bordered={false}>
                        <Panel header="Crear noticia nueva" key="1">
                          {formCreateNews}
                        </Panel>
                      </Collapse>

                      <div className="margin-top-20"></div>
                      {tableNews}
                      <div id="editNew" className="anchor"></div>
                      <div
                        className={`${this.state.editNews === false ? 'hidden' : ''
                          }`}
                      >
                        <Collapse
                          bordered={false}
                          ref="panelEditNews"
                          defaultActiveKey={['1']}
                        >
                          <Panel header="Editar noticia Seleccionada" key="1">
                            {editClickedNew}
                          </Panel>
                        </Collapse>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="docComite"
                      title="Documentos"
                      disabled={msgCompleteContact === null ? false : true}
                    >
                      <div className="margin-top-20"></div>
                      <div>
                        <p>
                          Aquí puede subir hasta 5 ficheros que se verán en el
                          lateral del sito web del comité. Si sube un documento
                          donde había previamente uno, se borrará el antiguo.
                        </p>
                        <p>
                          El título del documento publicado será el nombre del
                          fichero subido, por lo que recomendamos usar nombre
                          corto y conciso para ajustarnos al espacio en la
                          columna.
                        </p>
                      </div>
                      <TabDoc
                        userInfo={this.state.userInfo}
                        docComite={this.state.docComite}
                        idComite={idComite}
                        checkDocChild={this.getComiteDocumentsChild}
                      ></TabDoc>
                    </Tab>
                    <Tab
                      eventKey="moreInfoComite"
                      title="Información"
                      disabled={msgCompleteContact === null ? false : true}
                    >
                      <div className="margin-top-20"></div>
                      <div>
                        <p>
                          Aquí puedes rellenar la información que aparecerá
                          publicada en los apartados de “Inicio” y “Más
                          Información” de tu comité
                        </p>
                        <p>
                          Para que la información editada en ambos apartados se
                          guarde, es necesario dar al botón azul “Actualizar
                          información” que aparece al final de la página.
                        </p>
                      </div>
                      <TabInfo
                        idComite={idComite}
                        moreInfoComite={this.state.moreInfoComite}
                        contetComite={this.state.contetComite}
                      ></TabInfo>
                    </Tab>
                    <Tab
                      eventKey="docFES"
                      title="Repositorio"
                      disabled={msgCompleteContact === null ? false : true}
                    >
                      <div className="margin-top-20"></div>
                      <div>
                        <p>
                          En esta sección encontrarás documentos de interés
                          enviados por FES
                        </p>
                      </div>

                      <TabDocFes
                        docList={this.state.news}
                        idComite={idComite}
                        nameComite={this.state.titleComite}
                      ></TabDocFes>
                    </Tab>
                  </Tabs>
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default withRouter(AdminComites);
