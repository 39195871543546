import React from 'react';
import { Button } from 'react-bootstrap';
import { Table, Input, Space, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';

//import { Link } from 'react-router-dom';
class TabUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: [],
      editNews: false,
      isAdmin: false,
      visibleModalRol: false,
    };
  }
  confirmationModifyAdmin = (e, id_user, name) => {
    console.log(e);
    this.setState({
      visibleModalAdmin: true,
      changeAdminTo: e,
      idUserChangeAdmin: id_user,
      nameUserChangeAdmin: name,
    });
  };

  updateAdminLevel = () => {
    let formData = new FormData();

    formData.append('id_comite', this.props.idComite);
    formData.append('id_member', this.state.idUserChangeAdmin);
    formData.append('admin', this.state.changeAdminTo);

    axios
      .post('/api/comites_update_admin', formData)
      .then((res) => {
        this.setState({
          visibleModalAdmin: false,
          changeAdminTo: '',
          idUserChangeAdmin: '',
          nameUserChangeAdmin: '',
        });
        alert('Usuario actualizado correctamente');
        this.props.functGetMember();
      })
      .catch((error) => {
        this.setState({
          visibleModalAdmin: false,
          changeAdminTo: '',
          idUserChangeAdmin: '',
          nameUserChangeAdmin: '',
        });
        alert(
          'No se ha podido actualizar el usuario, le recordamos que el númwero máximo de administradores es 2.'
        );
      });
  };
  handleOkModifyAdmin = (e, id_user) => {
    e.preventDefault();
    var i = 0;
    if (+this.state.changeAdminTo === 1) {
      this.props.comitesMembers.map((cm, index) => {
        if (+cm.admin === 1) {
          return i++;
        } else {
          return null;
        }
      });
      if (i >= 2) {
        alert(
          'El número máximo de administradores del Comité son dos. Ya ha llegado a su máximo. Para crear nuevos administradores tiene que dar de baja uno de los ya existentes.'
        );
        this.setState({
          visibleModalAdmin: false,
          changeAdminTo: '',
          idUserChangeAdmin: '',
          nameUserChangeAdmin: '',
        });
        this.props.functGetMember();
      } else {
        this.updateAdminLevel();
      }
    } else if (+this.state.changeAdminTo === 0) {
      this.updateAdminLevel();
    }
  };

  handleCancelModifyAdmin = (e) => {
    this.setState({
      visibleModalAdmin: false,
      changeAdminTo: '',
      idUserChangeAdmin: '',
      nameUserChangeAdmin: '',
    });
  };

  confirmationModifyRol = (e, id_user, name) => {
    this.setState({
      visibleModalRol: true,
      newRolMod: e,
      idUserRolMod: id_user,
      nameUserRolMod: name,
    });
  };

  handleOkModifyRol = (e) => {
    let formData = new FormData();

    formData.append('id', this.state.idUserRolMod);
    formData.append('id_rol', this.state.newRolMod);

    axios
      .post('/api/comites_user_update_rol', formData)
      .then((res) => {
        this.setState({
          visibleModalRol: false,
          newRolMod: '',
          idUserRolMod: '',
          nameUserRolMod: '',
          rolNameMod: '',
        });
        alert('Usuario actualizado correctamente');
      })
      .catch((error) => {
        this.setState({
          visibleModalRol: false,
          newRolMod: '',
          idUserRolMod: '',
          nameUserRolMod: '',
          rolNameMod: '',
        });
        alert('No se ha podido actualizar el usuuario');
      });
  };

  handleCancelModifyRol = (e) => {
    this.setState({
      visibleModalRol: false,
      newRolMod: '',
      idUserRolMod: '',
      nameUserRolMod: '',
      rolNameMod: '',
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            className="resetFilter"
            style={{ width: 90 }}
          >
            Quitar filtro
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.props.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.props.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  changeSelectDoc = (event) => {
    this.setState((prevState) => ({
      docRequired: this.refs.docRequiredFes.value,
    }));
  };
  render() {
    const { Option } = Select;
    let userRoles;
    if (this.props.comiteRoles.length > 0) {
      userRoles = this.props.comiteRoles.map((cr, index) => {
        return (
          <Option value={cr.id} key={cr.id}>
            {cr.name}
          </Option>
        );
      });
    }

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <span>
            <Link to="/">{record.name}</Link>
          </span>
        ),
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Apellido',
        dataIndex: 'lastname',
        key: 'lastname',
        ...this.getColumnSearchProps('lastname'),
        sorter: (a, b) => a.lastname.length - b.lastname.length,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Telefono',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Tipo de Socio/a ',
        dataIndex: 'type_name',
        key: 'type_name',
      },
      {
        title: 'Rol',
        dataIndex: 'rol_name',
        key: 'rol_name',
        filters: [
          {
            text: 'Básico',
            value: 'Básico',
          },
          {
            text: 'Vocalía',
            value: 'Vocalía',
          },
          {
            text: 'Secretaría',
            value: 'Secretaría',
          },
          {
            text: 'Presidencia',
            value: 'Presidencia',
          },
        ],
        onFilter: (value, record) => record.rol_name.indexOf(value) === 0,
        render: (text, record) => (
          <span>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Rol"
              optionFilterProp="children"
              onChange={(e) =>
                this.confirmationModifyRol(e, record.id_member, record.name)
              }
              defaultValue={record.id_rol}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="delete">{'Eliminar de comite'}</Option>
              {userRoles}
            </Select>
          </span>
        ),
      },
      {
        title: 'Admin',
        dataIndex: 'admin',
        key: 'rol',
        render: (text, record) => (
          <span>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Admin"
              optionFilterProp="children"
              onChange={(e) =>
                this.confirmationModifyAdmin(e, record.id_member, record.name)
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              defaultValue={record.admin}
            >
              <Option value="0">No</Option>
              <Option value="1">Si</Option>
            </Select>
          </span>
        ),
      },
    ];
    return (
      <div>
        <Modal
          title={
            this.state.newRolMod === 'delete'
              ? 'Eliminar Usuario del comite'
              : 'Modificar Rol'
          }
          visible={this.state.visibleModalRol}
          onOk={this.handleOkModifyRol}
          onCancel={this.handleCancelModifyRol}
          cancelText="Cancelar"
          okText="Aceptar"
        >
          <p>
            {this.state.newRolMod === 'delete'
              ? 'Se dispone a eliminar el usuario ' + this.state.nameUserRolMod
              : 'Se dispone a modificar el usuario ' +
                this.state.nameUserRolMod}
          </p>
        </Modal>
        <Modal
          title="Permisos de Administración"
          visible={this.state.visibleModalAdmin}
          onOk={this.handleOkModifyAdmin}
          onCancel={this.handleCancelModifyAdmin}
          cancelText="Cancelar"
          okText="Aceptar"
        >
          {' '}
          <p>
            {'Se dispone a modificar los permisos de administrador al usuario: ' +
              this.state.nameUserChangeAdmin}
          </p>
        </Modal>
        <p>
          Si has realizado una búsqueda y deseas volver al listado de la tabla
          anterior, haz clic en la lupa y encontrarás el botón “quitar filtro”.
        </p>
        <Table
          columns={columns}
          onChange={this.onChange}
          dataSource={this.props.comitesMembers}
          className="isoSearchableTable"
          rowKey="id_member"
        />
      </div>
    );
  }
}
export default TabUsers;
