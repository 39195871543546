import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../components/objects/topImageTitle";
import SEO from "../components/objects/seo";
import EscuelaDoctoradoResume from "../components/objects/escuelaDoctoradoResume";
import ReactGA from "react-ga";
class ConferenciasEventos extends React.Component {
	constructor() {
		super();
		this.state = {
			news: [],
			newsList: [],
		};
	}

	componentDidMount() {
		//Call to webservice to get all news
		const { id } = this.props.match.params;
		let formData = new FormData();
		formData.append("id_categ", id);
		axios
			.post("/api/activities_get_by_id_categ", formData)
			.then((res) => {
				this.setState({
					news: res.data.records,
				});
			})
			.catch(function (error) {});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const title = (
			<TopImageTitle
				key="0001"
				key_id="00003"
				title="ESCUELA DE DOCTORADO"
				cover_image=""
				subtitle="Toda la informacion sobre escuela de doctorado"
				bread01="Conferencias y eventos"
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="conferencias y eventos"
				description="Ultimas novedades sobre las conferencias y eventos de FES."
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);

		const { news } = this.state;
		const newsList = news.length ? (
			news.map((news, index) => {
				if (+news.display === 0) {
					return (
						<Col
							lg={4}
							sm={6}
							key={news.id}
							className={
								"new-cat d-flex category-filter category-" +
								news.categories.map((categories) => categories.id)
							}
						>
							<EscuelaDoctoradoResume
								key={index}
								title={news.title}
								img={news.cover_image}
								categories={news.categories}
								link={news.permalink}
								short_description={
									!news.short_description
										? news.content
										: news.short_description
								}
								meta_description={news.meta_description}
								date={news.published_date}
							></EscuelaDoctoradoResume>
						</Col>
					);
				} else return null;
			})
		) : (
			<div className="center">No hay Actividades</div>
		);
		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>{newsList}</Row>
				</Container>
			</section>
		);
	}
}

export default ConferenciasEventos;
