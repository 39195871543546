import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";

class featuredNews extends Component {
	state = {
		posts: [],
		featuredNewsLoad: 0,
	};
	componentDidMount() {
		//Call webservice get featured news
		axios
			.get("/api/design_home_featured_news_get")
			.then((res) => {
				this.setState({
					//WE make sure to get only the last 3
					posts: res.data.records.slice(0, 3),
				});
			})
			.catch((error) => {});
		axios
			.get("/api/design_home_mix_get")
			.then((res) => {
				this.setState({
					//WE make sure to get only the last 3
					title: res.data.records[0].featured_news_title,
				});
			})
			.catch((error) => {});
	}

	render() {
		const { posts } = this.state;
		const postList = posts.length ? (
			posts.map((post) => {
				if (post.published_date) {
					var date = post.published_date;
					date = date.split(" ")[0];
					var yyyy = date.split("-")[0];
					var mmmm = date.split("-")[1];
					var dddd = date.split("-")[2];
					date = dddd + "-" + mmmm + "-" + yyyy;
				}
				return (
					<Col className="d-flex" lg={4} sm={12} key={post.id}>
						<div className="bg-white shadow border-radius-3 separation-15 inner padding-20 boxResumeNews">
							<div>
								<h5>{post.title}</h5>
								<p>{post.short_description}</p>
								<h6>{date}</h6>
								<button className="button width-100">
									<Link
										to={`/${
											post.news_type === "news" ? "noticia" : "actualidad"
										}${post.permalink}`}
									>
										+Explorar ahora
									</Link>
								</button>
							</div>
						</div>
					</Col>
				);
			})
		) : (
			<div className="center"></div>
		);
		let featuredNews;
		if (posts.length > 0) {
			featuredNews = (
				<Container>
					<Row>
						<Col>
							<h2 className="center">{this.state.title}</h2>
						</Col>
					</Row>
					<Row>{postList}</Row>
				</Container>
			);
		} else {
			featuredNews = <span></span>;
		}

		return <div className="featuredNews">{featuredNews}</div>;
	}
}

export default featuredNews;
