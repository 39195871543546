import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import { Checkbox, Divider } from 'antd';
import Modal from 'antd/lib/modal/Modal';

//import { Link } from 'react-router-dom';
class VotationActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidatures: [],
      candidatureDocRef: '',
      candidatureInfo: [],
      candidatureAlreadySent: true,
      candidatureAvailiable: [],
      titleCandidatureSent: '',
      dateCandidatureSent: '',
      colectivePresident: 0,
      comitePresident: 0,
      checkVotation: 0,
      colectiveType: '',
      individualMembers: [],
      colectiveMembers: [],
      presidentMembers: [],
      individualMemberStatus: 0,
      colectiveMemberStatus: 0,
      presidentMemberStatus: 0,
      checkedPresident: 0,
      checkedVotePresident: new Map(),
      checkedVoteColectivo: new Map(),
      checkedVoteIndividual: new Map(),
      votePresident: [],
      voteComite: [],
      voteIndividual: [],
      voteColectivo: [],
      maxVoteIndividual: 0,
      maxVoteColectivo: 0,
      maxVoteOtro: 0,
      maxVotePresident: 0,
      voteToType: 0,
      visibleModalVotation: false,
      canVote: 3,
      titleOfVotation: '',
    };
    this.onChangeVotedPresident = this.onChangeVotedPresident.bind(this);
    this.onChangeVotedColectivo = this.onChangeVotedColectivo.bind(this);
    this.onChangeVotedIndividual = this.onChangeVotedIndividual.bind(this);
  }
  getCandidatesByType = async (type) => {
    let formData = new FormData();

    formData.append('id_votes', this.props.id_vote);
    formData.append('type', type);

    await axios
      .post('/api/votes_a_candidates_get_by_type', formData)
      // aqui
      .then((res) => {
        if (+type === 2) {
          this.setState({
            individualMemberStatus: 1,
            individualMembers: res.data.records,
          });
        } else if (+type === 6 || +type === 7) {
          this.setState({
            colectiveMemberStatus: 1,
            colectiveMembers: res.data.records,
          });
        } else if (+type === 9) {
          this.setState({
            presidentMemberStatus: 1,
            presidentMembers: res.data.records,
          });
        } else return null;
      })
      .catch((error) => {
        this.setState({
          checkVotation: 0,
        });
      });
  };

  checkTypeOfVote = async () => {
    this.setState({
      checkVotation: 1,
    });
    let formData = new FormData();

    formData.append('email', this.props.emailUser);
    await axios
      .post('/api/users_check_president', formData)
      .then((res) => {
        if (res.data.type !== 5) {
          this.setState({
            colectiveType: res.data.type,
          });
          this.getVoteColectiveData(res.data.type);
          this.getPresident(res.data.type);
        } else {
          this.setState({
            colectivePresident: 0,
            colectiveType: res.data.type,
            checkVotation: 0,
          });
          this.getVoteColectiveData(res.data.type);
        }
      })
      .catch((error) => {
        this.setState({
          colectivePresident: 0,
          checkVotation: 0,
        });
      });

    formData.append('email', this.props.emailUser);
    await axios
      .post('/api/users_check_comite_president', formData)
      .then((res) => {
        this.getComitePresident();
      })
      .catch((error) => {
        this.setState({
          comitePresident: 0,
          checkVotation: 0,
        });
      });
  };
  getPresident = async (idType) => {
    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('id_type', idType);
    formData.append('email', this.props.allinfoUser.email);
    formData.append('opt', 1);

    await axios
      .post('/api/votes_a_candidates_user_candidate_check', formData)
      .then((res) => {
        this.setState({
          colectivePresident: 1,
          checkVotation: 0,
        });
        this.getCandidatesByType(idType);
      })
      .catch((error) => {
        this.setState({
          colectivePresident: 0,
          checkVotation: 0,
        });
      });
  };
  getComitePresident = async () => {
    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('id_type', this.props.allinfoUser.id_type);
    formData.append('email', this.props.allinfoUser.email);
    formData.append('opt', 1);

    await axios
      .post('/api/votes_a_candidates_user_candidate_check', formData)
      .then((res) => {
        this.setState({
          comitePresident: 1,
          checkVotation: 0,
        });
        this.getCandidatesByType(9);
      })
      .catch((error) => {
        this.setState({
          comitePresident: 0,
          checkCandidature: 0,
        });
      });
  };
  getInformation = async () => {
    await axios
      .get('/api/votes_a_config_get')
      .then((res) => {
        this.setState({
          candidatureInfo: res.data.records,
          candidatureDocRef: res.data.records[res.data.records.length - 1].doc,
        });
      })
      .catch((error) => {});
  };
  checkVotation = async () => {
    let formData = new FormData();

    formData.append('id_user_sponsor', this.props.idUser);
    formData.append('id_votes', this.props.id_vote);

    await axios
      .post('/api/votes_a_candidates_check', formData)
      .then((res) => {
        this.setState({
          candidatureAlreadySent: false,
        });
      })
      .catch((error) => {
        this.setState({
          candidatureAlreadySent: true,
        });
      });
  };
  getVoteColectiveData = (type) => {
    let formData = new FormData();
    formData.append('id', this.props.id_vote);

    if (+type === 7) {
      axios
        .post('/api/votes_a_config_get_by_id', formData)
        .then((res) => {
          this.setState({
            maxVoteColectivo: res.data.records[0].num_type_7,
          });
        })
        .catch((error) => {
          this.setState({});
        });
    } else {
      axios
        .post('/api/votes_a_config_get_by_id', formData)
        .then((res) => {
          this.setState({
            maxVoteColectivo: res.data.records[0].num_type_6,
          });
        })
        .catch((error) => {
          this.setState({});
        });
    }
  };
  getInfoVote = async () => {
    let formData = new FormData();
    formData.append('id', this.props.id_vote);

    if (+this.props.allinfoUser.id_type === 7) {
      await axios
        .post('/api/votes_a_config_get_by_id', formData)
        .then((res) => {
          this.setState({
            maxVoteIndividual: res.data.records[0].num_type_2,
            maxVoteOtro: res.data.records[0].num_type_6,
            maxVotePresident: res.data.records[0].num_type_9,
            titleOfVotation: res.data.records[0].title,
          });
        })
        .catch((error) => {
          this.setState({
            candidatureAlreadySent: true,
          });
        });
    } else {
      await axios
        .post('/api/votes_a_config_get_by_id', formData)
        .then((res) => {
          this.setState({
            maxVoteIndividual: res.data.records[0].num_type_2,
            maxVoteOtro: res.data.records[0].num_type_6,
            maxVotePresident: res.data.records[0].num_type_9,
            titleOfVotation: res.data.records[0].title,
          });
        })
        .catch((error) => {
          this.setState({
            candidatureAlreadySent: true,
          });
        });
    }
  };
  voteIndividual = async () => {
    let formData = new FormData();
    formData.append('id_votes', this.props.id_vote);
    formData.append('id_user', this.props.allinfoUser.id);
    formData.append('id_type', 2);
    formData.append('email', this.props.allinfoUser.email);

    await axios
      .post('/api/votes_a_candidates_user_candidate_check', formData)
      .then((res) => {
        this.setState({
          individualMemberStatus: 1,
        });
        this.getCandidatesByType(2);
      })
      .catch((error) => {
        this.setState({
          individualMemberStatus: 0,
        });
      });
  };
  canVote = async () => {
    let formData = new FormData();

    formData.append('id_votes', this.props.id_vote);
    formData.append('email', this.props.allinfoUser.email);

    await axios
      .post('/api/votes_a_check_user_has_voted', formData)
      .then((res) => {
        this.setState({
          canVote: 1,
        });
      })
      .catch((error) => {
        this.setState({
          canVote: 0,
        });
      });
  };

  componentDidMount() {
    (async () => {
      await this.checkTypeOfVote();
      await this.getInformation();
      await this.checkVotation();
      await this.getInfoVote();
      await this.canVote();
      await this.voteIndividual();
    })();
  }
  voteAll = async (event) => {
    event.preventDefault();

    if (
      this.state.votePresident.length < 1 &&
      this.state.voteColectivo.length < 1 &&
      this.state.voteIndividual.length < 1
    ) {
      alert('Por favor seleccione por lo menos a un representante');
    } else {
      var user_data = {
        id_user: this.props.allinfoUser.id,
        username: this.props.allinfoUser.name,
        lastname: this.props.allinfoUser.lastname,
        title: this.state.titleOfVotation,
        email: this.props.allinfoUser.email,
      };
      var candidates = [];
      if (this.state.checkedVotePresident) {
        for (const [
          index,
          value,
        ] of this.state.checkedVotePresident.entries()) {
          console.log(index);
          candidates.push(value);
        }
      }

      if (this.state.checkedVoteColectivo) {
        for (const [
          index,
          value,
        ] of this.state.checkedVoteColectivo.entries()) {
          console.log(index);
          candidates.push(value);
        }
      }

      if (this.state.checkedVoteIndividual) {
        for (const [
          index,
          value,
        ] of this.state.checkedVoteIndividual.entries()) {
          console.log(index);
          candidates.push(value);
        }
      }

      var fullArray = [{ candidates: candidates }, { user_data: user_data }];

      let formData = new FormData();

      formData.append('vote_data', JSON.stringify(fullArray));

      await axios
        .post('/api/votes_a_vote_set', formData)
        .then((res) => {
          alert('Su voto se ha guardado');
          this.setState({
            visibleModalVotation: false,
          });
          window.location.reload();
        })
        .catch((error) => {
          alert('Se ha encontrado un problema y su voto no ha sido guardado');
          this.setState({
            visibleModalVotation: false,
          });
        });
    }
  };
  maxNumberVotes = (id, maxVote, voteId) => {
    if (voteId === 1) {
      return (
        this.state.votePresident.length > +maxVote - 1 &&
        this.state.votePresident.indexOf(id) === -1
      );
    } else if (voteId === 2) {
      return (
        this.state.voteColectivo.length > +maxVote - 1 &&
        this.state.voteColectivo.indexOf(id) === -1
      );
    } else if (voteId === 3) {
      return (
        this.state.voteIndividual.length > +maxVote - 1 &&
        this.state.voteIndividual.indexOf(id) === -1
      );
    } else return null;
  };
  onChangeVotedPresident(event) {
    var arrayInfo = {
      name: event.target.datatitle,
      id: event.target.datatid,
      type: event.target.datatype,
    };
    var listTocheck = this.state.checkedVotePresident;
    var deleted = listTocheck.delete(event.target.datatid);
    if (deleted === true) {
      this.setState((prevState) => ({
        checkedVotePresident: listTocheck,
      }));
    } else {
      this.setState((prevState) => ({
        checkedVotePresident: prevState.checkedVotePresident.set(
          event.target.datatid,
          arrayInfo
        ),
      }));
    }
  }
  onChangeVotedColectivo(event) {
    var arrayInfo = {
      name: event.target.datatitle,
      id: event.target.datatid,
      type: event.target.datatype,
    };

    var listTocheck = this.state.checkedVoteColectivo;
    var deleted = listTocheck.delete(event.target.datatid);
    if (deleted === true) {
      this.setState((prevState) => ({
        checkedVoteColectivo: listTocheck,
      }));
    } else {
      this.setState((prevState) => ({
        checkedVoteColectivo: prevState.checkedVoteColectivo.set(
          event.target.datatid,
          arrayInfo
        ),
      }));
    }
  }
  onChangeVotedIndividual(event) {
    var arrayInfo = {
      name: event.target.datatitle,
      id: event.target.datatid,
      type: event.target.datatype,
    };

    var listTocheck = this.state.checkedVoteIndividual;
    var deleted = listTocheck.delete(event.target.datatid);
    if (deleted === true) {
      this.setState((prevState) => ({
        checkedVoteIndividual: listTocheck,
      }));
    } else {
      this.setState((prevState) => ({
        checkedVoteIndividual: prevState.checkedVoteIndividual.set(
          event.target.datatid,
          arrayInfo
        ),
      }));
    }
  }
  onChangeVotePresident = (checkedValues) => {
    this.setState(() => {
      return { votePresident: checkedValues };
    });
  };
  onChangeVoteColectivo = (checkedValues) => {
    this.setState(() => {
      return { voteColectivo: checkedValues };
    });
  };
  onChangeVoteIndividual = (checkedValues) => {
    this.setState(() => {
      return { voteIndividual: checkedValues };
    });
  };
  confirmationVotation = (e) => {
    this.setState({
      visibleModalVotation: true,
    });
  };
  handleCancelVote = (e) => {
    this.setState({
      visibleModalVotation: false,
    });
  };

  render() {
    let formVotePresident;
    let formVoteColectivo;
    let formVoteIndividual;

    const memberListPresident = this.state.presidentMembers.map(
      (member, index) => {
        console.log('member list president', member);

        return (
          <Col sm={12} className="comiteCheckbox" key={member.id}>
            <Checkbox
              value={member.id}
              datatitle={member.username + ' ' + member.lastname}
              datatid={member.id}
              datatype={member.type}
              disabled={this.maxNumberVotes(
                member.id,
                this.state.maxVotePresident,
                1
              )}
              ref={member.username}
              onChange={this.onChangeVotedPresident}
            >
              {member.username + ' ' + member.lastname}
            </Checkbox>
          </Col>
        );
      }
    );
    const memberListColectivo = this.state.colectiveMembers.map(
      (member, index) => {
        return (
          <Col sm={12} className="comiteCheckbox" key={member.id}>
            <Checkbox
              value={member.id}
              datatitle={member.username + ' ' + member.lastname}
              datatid={member.id}
              datatype={member.type}
              disabled={this.maxNumberVotes(
                member.id,
                this.state.maxVoteColectivo,
                2
              )}
              ref={member.username}
              onChange={this.onChangeVotedColectivo}
            >
              {member.username + ' ' + member.lastname}
            </Checkbox>
          </Col>
        );
      }
    );
    const memberListIndividual = this.state.individualMembers.map(
      (member, index) => {
        return (
          <Col sm={12} className="comiteCheckbox" key={member.id}>
            <Checkbox
              value={member.id}
              datatitle={member.username + ' ' + member.lastname}
              datatid={member.id}
              datatype={member.type}
              disabled={this.maxNumberVotes(
                member.id,
                this.state.maxVoteIndividual,
                3
              )}
              ref={member.username}
              onChange={this.onChangeVotedIndividual}
            >
              {member.username} {member.lastname}
            </Checkbox>
          </Col>
        );
      }
    );
    if (+this.state.presidentMemberStatus === 1) {
      formVotePresident = (
        <Form className="BoxComitePresentCan" key={this.state.presidentMembers}>
          {+this.state.presidentMembers.length <=
          +this.state.maxVotePresident ? (
            <Row>
              <Col lg={12} sm={12}>
                <h4>
                  Votar a las personas representates del Consejo científico
                </h4>
                <h5>
                  El número de candidaturas presentadas es inferior o igual a
                  las permitidas, por lo que quedan seleccionadas
                  automáticamente
                </h5>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12} sm={12}>
                <h4>
                  Votar a las personas representates del Consejo científico
                </h4>
                <h5>
                  Seleccione de la siguiente lista las personas candidatas que
                  quiere votar (máximo {this.state.maxVotePresident}). Recuerde
                  que una vez emitido el voto no podrá modificarlo ni volver a
                  votar.
                </h5>
              </Col>
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={this.onChangeVotePresident}
                value={this.state.votePresident}
              >
                {memberListPresident}
              </Checkbox.Group>
            </Row>
          )}
        </Form>
      );
    }

    if (+this.state.colectiveMemberStatus === 1) {
      formVoteColectivo = (
        <Form className="BoxComitePresentCan">
          {+this.state.colectiveMembers.length <=
          +this.state.maxVoteColectivo ? (
            <Row>
              <Col lg={12} sm={12}>
                <h4>
                  {+this.props.allinfoUser.id_type === 6 ||
                  +this.state.colectiveType === 6
                    ? 'Votar a las personas representates de Consejo Profesional'
                    : +this.props.allinfoUser.id_type === 7 ||
                      +this.state.colectiveType === 7
                    ? 'Votar a las personas representates de Consejo Académico'
                    : ''}
                </h4>
                <h5>
                  El número de candidaturas presentadas es inferior o igual a
                  las permitidas, por lo que quedan seleccionadas
                  automáticamente
                </h5>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12} sm={12}>
                <h4>
                  {+this.props.allinfoUser.id_type === 6 ||
                  +this.state.colectiveType === 6
                    ? 'Votar a las personas representates de Consejo Profesional'
                    : +this.props.allinfoUser.id_type === 7 ||
                      +this.state.colectiveType === 7
                    ? 'Votar a las personas representates de Consejo Académico'
                    : ''}
                </h4>
                <h5>
                  Seleccione de la siguiente lista las personas candidatas que
                  quiere votar (máximo {this.state.maxVoteColectivo}). Recuerde
                  que una vez emitido el voto no podrá modificarlo ni volver a
                  votar.
                </h5>
              </Col>
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={this.onChangeVoteColectivo}
                value={this.state.voteColectivo}
              >
                {memberListColectivo}
              </Checkbox.Group>
            </Row>
          )}
        </Form>
      );
    }

    if (+this.state.individualMemberStatus === 1) {
      formVoteIndividual = (
        <Form className="BoxComitePresentCan">
          {+this.state.individualMembers.length <=
          +this.state.maxVoteIndividual ? (
            <Row>
              <Col lg={12} sm={12}>
                <h4>
                  Votar a las personas representantes de los Socios individuales{' '}
                </h4>
                <h5>
                  El número de candidaturas presentadas es inferior o igual a
                  las permitidas, por lo que quedan seleccionadas
                  automáticamente
                </h5>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12} sm={12}>
                <h4>
                  Votar a las personas representantes de los Socios individuales
                </h4>
                <h5>
                  Seleccione de la siguiente lista las personas candidatas que
                  quiere votar (máximo {this.state.maxVoteIndividual}). Recuerde
                  que una vez emitido el voto no podrá modificarlo ni volver a
                  votar.
                </h5>
              </Col>
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={this.onChangeVoteIndividual}
                value={this.state.voteIndividual}
              >
                {memberListIndividual}
              </Checkbox.Group>
            </Row>
          )}
        </Form>
      );
    }
    const optionsVoteCandidates = (
      <div>
        {+this.props.allinfoUser.id_type === 4 ||
        +this.props.allinfoUser.id_type === 5 ||
        +this.state.colectiveType === 5 ? (
          <div className="BoxComitePresentCan">
            <h4>Votaciones al Consejo Territorial</h4>{' '}
            <p>La votación no procede. </p>
            <p>
              Los miembros del Consejo Territorial no presentan candidatos sino
              nombramientos directos.{' '}
            </p>
          </div>
        ) : this.state.comitePresident !== 1 &&
          this.state.colectivePresident !== 1 &&
          +this.props.allinfoUser.id_type !== 2 &&
          +this.state.individualMemberStatus !== 1 ? (
          <p>Su perfil no cumple con los requisitos para votar.</p>
        ) : (
          <div>
            {' '}
            <p hidden>
              Puede votar en cada una de estas secciones, existe un numero
              maximo de personas a las que puede votar, una vez realizo el voto
              no podra modificarlo, añadir o cambiar sus opciones.
            </p>
          </div>
        )}

        {+this.state.presidentMemberStatus === 1 ? (
          !this.state.presidentMembers.length ? (
            <p></p>
          ) : (
            formVotePresident
          )
        ) : (
          ''
        )}

        {+this.state.colectiveMemberStatus === 1 ? (
          !this.state.colectiveMembers.length ? (
            <p></p>
          ) : (
            formVoteColectivo
          )
        ) : (
          ''
        )}
        {+this.props.allinfoUser.id_type === 4 ||
        +this.props.allinfoUser.id_type === 5 ||
        +this.props.allinfoUser.id_type === 3 ? (
          ''
        ) : !this.state.colectiveMembers.length &&
          !this.state.presidentMembers.length &&
          !this.state.individualMembers.length ? (
          <p></p>
        ) : null}

        {+this.state.individualMemberStatus === 1 ? formVoteIndividual : ''}
        {this.state.individualMemberStatus === 1 ||
        this.state.colectiveMemberStatus === 1 ||
        this.state.presidentMemberStatus === 1 ? (
          +this.state.presidentMembers.length <= +this.state.maxVotePresident &&
          +this.state.colectiveMembers.length <= +this.state.maxVoteColectivo &&
          +this.state.individualMembers.length <=
            +this.state.maxVoteIndividual ? (
            ''
          ) : (
            <div className="center ">
              <Divider></Divider>
              <p className="button center" onClick={this.confirmationVotation}>
                Votar
              </p>
            </div>
          )
        ) : (
          ''
        )}
      </div>
    );
    return (
      <div>
        <Modal
          title={'Votación'}
          visible={this.state.visibleModalVotation}
          onOk={this.voteAll}
          onCancel={this.handleCancelVote}
          cancelText="Volver atras"
          okText="Votar"
        >
          <p>
            Va a realizar la votación. Una vez presentada no podrá modificarla.
          </p>
        </Modal>
        <h3 className="center">Abierto proceso de votación</h3>
        <h5 className="center">
          El proceso de votación estará abierto del {this.props.dateBegin} al{' '}
          {this.props.dateEnd}.
        </h5>
        <p>
          {' '}
          <b>Atención</b>: Va a realizar la votación. Si en su caso tiene opción
          de votar a más de un órgano de representación, debe realizar todas las
          votaciones a la vez. Una vez dado a votar no podrá volver a entrar si
          tuviera alguna votación sin efectuar.
        </p>
        <hr></hr>

        {this.state.canVote === 1 ? (
          optionsVoteCandidates
        ) : (
          <p>Su voto ya ha sido emitido. No podrá volver a votar.</p>
        )}
      </div>
    );
  }
}

export default VotationActive;
