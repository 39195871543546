import React, { Component } from "react";
//import ImageDefault from "../img/noticias_default.jpg";
//import { Link } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import MultimediaResume from "../objects/multimediaResume";
import NoticiaResume from "../objects/noticiaResume";
import ActualidadResume from "./actualidadResume";
class PaginationwithoutCAT extends Component {
	constructor(props) {
		super(props);
		this.state = {
			array_post: props.array_post,
			type: props.type,
			hideCat: props.hideCat,
			statusPage: 0,
			currentPage: 1,
			todosPerPage: props.itemsPerPage,
			onlyThisCat: props.onlyThisCat,
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		if (this.props !== prevProps) {
		}
	}
	render() {
		let _post = this.state.array_post;
		const { currentPage, todosPerPage } = this.state;
		const { onlyThisCat } = this.state;
		const { type } = this.state;
		const { hideCat } = this.state;

		const indexOfLastTodo = currentPage * todosPerPage;

		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;

		const currentTodos = _post.slice(indexOfFirstTodo, indexOfLastTodo);

		const newsList = currentTodos.map((news, index) => {
			var idcat = news.categories.map((categories) => categories.id);
			//Array de categorias que no queremos mostrar
			let typeOfPost;

			if (type === "multimedia") {
				typeOfPost = (
					<MultimediaResume
						key={index}
						title={news.title}
						img={news.cover_image}
						link={news.permalink}
						categories={hideCat === "1" ? undefined : news.categories}
						short_description={news.short_description}
						date={news.published_date}
						meta_description={news.meta_description}
					></MultimediaResume>
				);
			} else if (type === "noticia") {
				typeOfPost = (
					<NoticiaResume
						key={index}
						title={news.title}
						img={news.cover_image}
						link={news.permalink}
						short_description={news.short_description}
						date={news.published_date}
						meta_description={news.meta_description}
					></NoticiaResume>
				);
			} else if (type === "actualidad") {
				typeOfPost = (
					<ActualidadResume
						key={index}
						title={news.title}
						img={news.cover_image}
						link={news.permalink}
						short_description={news.short_description}
						date={news.published_date}
						meta_description={news.meta_description}
					></ActualidadResume>
				);
			}

			if (onlyThisCat.length > 0) {
				if (onlyThisCat.some((r) => idcat.includes(r))) {
					return (
						<Col
							lg={4}
							sm={6}
							key={news.id}
							className={
								"new-cat d-flex category-filter category-" +
								news.categories.map((categories) => categories.id)
							}
						>
							{typeOfPost}
						</Col>
					);
				} else {
					return null;
				}
			} else {
				return (
					<Col
						lg={4}
						sm={6}
						key={news.id}
						className={
							"new-cat d-flex category-filter category-" +
							news.categories.map((categories) => categories.id)
						}
					>
						{typeOfPost}
					</Col>
				);
			}
		});
		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(_post.length / todosPerPage); i++) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number) => {
			return (
				<span key={number} id={number} onClick={this.handleClick}>
					{number}
				</span>
			);
		});

		return (
			<div>
				<Row className="listCategory">{newsList}</Row>
				<div className="center">
					<div id="page-numbers">{renderPageNumbers}</div>
				</div>
			</div>
		);
	}
}
export default PaginationwithoutCAT;
