import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";
import CategoriesActualidad from "../components/WidgetsSidebar/categoriesActualidad";
import Newsletter from "../components/WidgetsSidebar/newsletter";
import ImageDefault from "../components/img/noticias_default.jpg";
import TopImageTitle from "../components/objects/topImageTitle";
import ButtonFES from "../components/objects/button";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

class ActualidadNotice extends Component {
	state = {
		posts: [],
		prevPost: [],
		nxtPost: [],
		postID: "",
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;
		const cat = this.props.match.params.cat;
		const id = this.props.match.params.id;
		//fetch(`noticia/${permalink}`);

		let formData = new FormData();

		if (!id) {
			formData.append("permalink", "/" + permalink);
		} else {
			formData.append(
				"permalink",
				"/" + permalink + "/" + cat + "/" + id + "/"
			);
		}

		//formData.append("permalink", pathurl);

		//Call to webservice to get the new
		axios.post("/api/topicality_get_by_permalink", formData).then((res) => {
			//console.log(res);
			this.setState({
				posts: res.data.records,
				postID: res.data.records[0].id,
			});

			var prevId = res.data.records[0].id - 1;
			var nextId = +res.data.records[0].id + 1;

			var formDataprev = new FormData();
			formDataprev.append("id", prevId);
			axios
				.post("/api/topicality_get_by_id", formDataprev)
				.then((res) => {
					this.setState({
						prevPost: res.data.records,
					});
				})
				.catch(function (error) { });

			var formDatanext = new FormData();
			formDatanext.append("id", nextId);
			axios
				.post("/api/topicality_get_by_id", formDatanext)
				.then((res) => {
					this.setState({
						nxtPost: res.data.records,
					});
				})
				.catch(function (error) { });
		});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;
		const cat = this.props.match.params.cat;
		const id = this.props.match.params.id;

		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();
			if (!id) {
				formData.append("permalink", "/" + permalink);
			} else {
				formData.append(
					"permalink",
					"/" + permalink + "/" + cat + "/" + id + "/"
				);
			}
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			axios
				.post("/api/topicality_get_by_permalink", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						posts: res.data.records,
						postID: res.data.records[0].id,
					});
					var prevId = res.data.records[0].id - 1;
					var nextId = +res.data.records[0].id + 1;
					let formData = new FormData();
					formData.append("id", prevId);
					axios
						.post("/api/topicality_get_by_id", formData)
						.then((res) => {
							this.setState({
								prevPost: res.data.records,
							});
						})
						.catch(function (error) { });

					var formDatanext = new FormData();
					formDatanext.append("id", nextId);
					axios.post("/api/topicality_get_by_id", formDatanext).then((res) => {
						this.setState({
							nxtPost: res.data.records,
						});
					});
				})
				.catch(function (error) { });
		}
	}
	componentWillReceiveProps(nextProps) {
		const permalink = this.props.match.params.permalink;
		const cat = this.props.match.params.cat;
		const id = this.props.match.params.id;
		if (nextProps.match.params !== this.props.match.params) {
			window.scrollTo(0, 0);
			let formData = new FormData();
			if (!id) {
				formData.append("permalink", "/" + permalink);
			} else {
				formData.append(
					"permalink",
					"/" + permalink + "/" + cat + "/" + id + "/"
				);
			}
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			axios
				.post("/api/topicality_get_by_permalink", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						posts: res.data.records,
					});
					var prevId = res.data.records[0].id - 1;
					var nextId = +res.data.records[0].id + 1;
					let formData = new FormData();
					formData.append("id", prevId);
					axios
						.post("/api/topicality_get_by_id", formData)
						.then((res) => {
							this.setState({
								prevPost: res.data.records,
							});
						})
						.catch(function (error) { });

					var formDatanext = new FormData();
					formDatanext.append("id", nextId);
					axios.post("/api/topicality_get_by_id", formDatanext).then((res) => {
						this.setState({
							nxtPost: res.data.records,
						});
					});
				})
				.catch(function (error) { });
		}
	}
	render() {
		const { posts } = this.state;
		const { prevPost } = this.state;
		const { nxtPost } = this.state;
		let editThisPage;
		if (Cookies.get("isAdmin") === "1") {
			editThisPage = (
				<div>
					<a
						className="button textWhite editEntryButton"
						target="_blank"
						rel="noopener noreferrer"
						href={
							global.urlAdmin + "dashboard/topicality/edit/" + this.state.postID
						}
					>
						Editar entrada
					</a>
				</div>
			);
		} else {
			editThisPage = "";
		}
		this.HideMovetoNew = false;

		const title = posts.map((post, index) => {
			return (
				<TopImageTitle
					key={post.id}
					key_id={post.id + index}
					title={post.title}
					cover_image={post.cover_image}
					bread01={post.categories.map((categories) => categories.name)}
				></TopImageTitle>
			);
		});
		const previousPost = prevPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link to={`/actualidad${post.permalink}`} className="post-prev">
						<p className="m-2 m-lg-0">Anterior Noticia</p>
						<h6 className="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const nextPost = nxtPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link to={`/actualidad${post.permalink}`} className="post-next">
						<p className="m-2 m-lg-0">Siguiente Noticia</p>
						<h6 className="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const notMorePrevPost = (
			<Col sm={5}>
				<Link to="#" className="post-prev">
					<p className="m-2 m-lg-0">No hay más noticias</p>
				</Link>
			</Col>
		);
		const notMorenextPost = (
			<Col sm={5}>
				<Link to="#" className="post-next">
					<p className="m-2 m-lg-0">No hay más noticias</p>
				</Link>
			</Col>
		);

		const defaultImage = posts.map((post) => {
			if (!post.cover_image) {
				return (
					<img
						key={post.id}
						src={ImageDefault}
						alt="a"
						className="blogImage"
					></img>
				);
			} else {
				return (
					<img
						key={post.id}
						src={post.cover_image}
						alt="a"
						className="blogImage"
					></img>
				);
			}
		});
		const socialShare = posts.map((post, index) => {
			var image = post.cover_image
				? post.cover_image
				: "https://fes-sociologia.com/images/fes_logo.jpg";

			return (
				<div key={index}>
					<FacebookShareButton
						url={
							global.socialShare +
							"?link=/actualidad" +
							post.permalink +
							"&image=" +
							image +
							"&title=" +
							encodeURIComponent(post.title.trim()) +
							"&description=" +
							encodeURIComponent(post.meta_description.trim()) +
							"&type=2"
						}
						quote="Federación Española de Sociología "
					>
						<FacebookIcon size={32} round={true} />
					</FacebookShareButton>
					<TwitterShareButton
						title={post.title}
						url={window.location.href}
						hashtags={["FESociologia"]}
					>
						<TwitterIcon size={32} round={true} />
					</TwitterShareButton>
				</div>
			);
		});
		const catNotWant = global.notWantedCategoriesActu;
		//var catCount;
		let categoriesTopLeft;
		if (posts[0]) {
			categoriesTopLeft = posts[0].categories.map((categories, index) => {
				if (!posts[0].categories.length) {
					return null;
				} else {
					if (catNotWant.some((r) => categories.id.includes(r))) {
						//catCount++;
						return null;
					} else {
						return (
							<Link
								className="button"
								key={categories.id + index}
								to={"/categoria-actualidad/" + categories.id}
							>
								<span>{categories.name}</span>
							</Link>
						);
					}
				}
			});
		}

		const documentsButtons = posts.map((post) => {
			if (post.docs.length === 0 || !post.docs) {
				return null;
			} else {
				return post.docs.map((doc) => {
					return (
						<ButtonFES
							button_text={doc.alt}
							button_class="button width-100"
							button_link={doc.doc}
							icon="a"
							key={doc.id}
						></ButtonFES>
					);
				});
			}
		});

		const categoriesInSidebar = posts.map((news, index) => {
			var idcat = news.categories.map((categories) => categories.id);
			//Array de categorias que no queremos mostrar
			const catNotWant = global.notWantedCategoriesActu;
			if (catNotWant.some((r) => idcat.includes(r))) {
				return null;
			} else {
				return <CategoriesActualidad key="catAc" />;
			}
		});

		let goBack;
		this.state.posts.map((ne, index) => {
			var idcat = ne.categories.map((categories) => categories.id);
			if (global.notWantedCategoriesActu.some((r) => idcat.includes(r))) {
				let intersection = global.notWantedCategoriesActu.filter((x) =>
					idcat.includes(x)
				);
				if (intersection.length !== idcat.length) {
					goBack = (
						<p>
							<Link to="/Divulgación/Actualidad" className="goBacklink">
								<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
								Noticias
							</Link>
						</p>
					);
					this.HideMovetoNew = true;
					return null;
				} else if (intersection.includes("13") || intersection.includes("14")) {
					goBack = (
						<p>
							<Link to="/recursos/practica-sociologica" className="goBacklink">
								<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
								Noticias
							</Link>
						</p>
					);
					this.HideMovetoNew = true;
					return null;
				} else if (intersection.includes("12")) {
					goBack = (
						<p>
							<Link
								to="/actividades/internacionalizacion"
								className="goBacklink"
							>
								<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
								Internacionalización
							</Link>
						</p>
					);
					this.HideMovetoNew = true;
					return null;
				} else {
					goBack = (
						<p>
							<Link to="/Divulgación/Actualidad" className="goBacklink">
								<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
								Noticias
							</Link>
						</p>
					);
					this.HideMovetoNew = false;
					return null;
				}
			} else {
				goBack = (
					<p>
						<Link to="/Divulgación/Actualidad" className="goBacklink">
							<FontAwesomeIcon icon={Icons.faArrowLeft} size="1x" /> Volver a
							Noticias
						</Link>
					</p>
				);
				this.HideMovetoNew = false;
				return null;
			}
		});
		const notice = posts.map((post) => {
			var date = post.published_date;
			date = date.split(" ")[0];
			var yyyy = date.split("-")[0];
			var mmmm = date.split("-")[1];
			var dddd = date.split("-")[2];
			date = dddd + "-" + mmmm + "-" + yyyy;

			return (
				<Col key={post.id}>
					<div className="inner">
						{defaultImage}
						<span className="categoryNewsTop">{categoriesTopLeft}</span>
						<div className="noticeInfo">
							<div>
								<p>{date}</p>
							</div>
							<div className="socialShare">{socialShare}</div>
						</div>
						<h2>{post.title}</h2>
						<div dangerouslySetInnerHTML={{ __html: post.content }} />
					</div>
					<hr className="separatorNew"></hr>
					<p>{this.HideMovetoNew}</p>

					<Container>
						<Row className="postNavigation">
							{!prevPost.length ? notMorePrevPost : previousPost}
							<Col sm={2}>
								<Link to="/informate/Actualidad">
									<i className="fa fa-th allPost"></i>{" "}
								</Link>
							</Col>
							{!nxtPost.length > 0 ? notMorenextPost : nextPost}
						</Row>
					</Container>
				</Col>
			);
		});
		return (
			<section>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>
						<Col>{goBack}</Col>
					</Row>
					<Row className="lastMinuteNews">
						<Col className="sidebar" lg={3} md={3} sm={12}>
							{editThisPage}
							{categoriesInSidebar}

							{documentsButtons[0] == null ? (
								""
							) : (
								<div>
									<h5 className="titleSidebar">Documentos Relacionados</h5>
									{documentsButtons}
								</div>
							)}

							<Newsletter />
						</Col>
						<Col lg={9} md={9} sm={12}>
							{notice}
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default ActualidadNotice;
