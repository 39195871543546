import axios from "axios";
import Cookies from "js-cookie";

const SERVER_URL = "https://fes-sociologia.com/api";

//const SERVER_URL = "https://webfes.espartup.com/api";

const loginFES = async (data, url, email) => {
	const LOGIN_ENDPOINT = `${SERVER_URL}/login.php`;

	try {
		let response = await axios.post(LOGIN_ENDPOINT, data);

		if (response.status === 200) {
			let jwt = response.data.jwt;
			localStorage.setItem("access_token", jwt);
			if (
				email === "direccion@fes-sociologia.com" ||
				email === "test@test.com"
			) {
				Cookies.set("isAdmin", "1", { expires: 1 });
			}
			if (url) {
				window.location.href = url;
			} else {
				window.location.href = "/perfil";
			}
		}
	} catch (e) {
		if (e.response.data.message === "User not verified.") {
			alert(
				"Antes de entrar, es necesario que valide su cuenta a través del email que le hemos enviado."
			);
			Cookies.remove("isAdmin");
			window.location.href = "/login";
		} else {
			alert(
				"El usuario o contraseña introducido no es correcto. Si no se acuerda de su contraseña puede utilizar la opción recordar contraseña."
			);
			Cookies.set("isAdmin");
		}
		window.location.href = "/login";
	}
};

const checkLoggedFES = async (data) => {
	const jwt = localStorage.getItem("access_token");
	const CHECK_JWT = `${SERVER_URL}/validate_token`;
	try {
		// eslint-disable-next-line
		let formData = new FormData();
		formData.append("jwt", jwt);
		let response = await axios.post(CHECK_JWT, formData);
		if (response.status === 200) {
		}
	} catch (e) {}
};

const logoutFES = () => {
	Cookies.remove("isAdmin");
	Cookies.remove("fromAdmin");
	localStorage.removeItem("usernameShow");
	localStorage.removeItem("access_token");
	window.location.href = "/";
};

export { loginFES, checkLoggedFES, logoutFES };
