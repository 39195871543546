import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import CountUp from "react-countup";
import axios from "axios";
//import { Link } from 'react-router-dom';
class TabResume extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countDocs: 0,
		};
	}
	countDocs = () => {
		if (this.props.documents) {
			var i = 0;
			this.props.documents.map((doc, index) => {
				if (doc.doc_1 !== "") {
					i++;
				}
				if (doc.doc_2 !== "") {
					i++;
				}
				if (doc.doc_3 !== "") {
					i++;
				}
				if (doc.doc_4 !== "") {
					i++;
				}
				if (doc.doc_5 !== "") {
					i++;
				}
				return null;
			});

			this.setState({
				countDocs: i,
			});
		}
	};
	componentDidMount() {
		this.countDocs();
	}
	handleSubmitContactData = (event) => {
		event.preventDefault();
		let formData = new FormData();

		formData.append("id", this.props.idComite);
		formData.append("address", event.target.comiteAddressContact.value);
		formData.append("phone", event.target.comitePhoneContact.value);
		formData.append("email", event.target.comiteEmailContact.value);
		formData.append("ig", event.target.igComiteData.value);
		formData.append("fb", event.target.faComiteData.value);
		formData.append("tw", event.target.twComiteData.value);
		formData.append("name", event.target.comiteNameContact.value);

		axios
			.post("/api/comites_contact_update", formData)
			.then((res) => {
				alert("Informacion actualizada correctamente");
				window.location.reload();
			})
			.catch((error) => {
				alert("No se ha podido actualizar la información");
			});
	};

	render() {
		return (
			<Row className="ResumeComite">
				<Col lg={4} className="boxResumeComite">
					<p className="center">Número de afiliados</p>

					<p className="center numberResumen">
						<CountUp
							start={0}
							end={this.props.members.length}
							duration={2}
							delay={1}
						/>
					</p>
				</Col>
				<Col lg={4} className="boxResumeComite">
					<p className="center">Número de noticias publicadas</p>
					<p className="center numberResumen">
						<CountUp
							start={0}
							end={this.props.news.length}
							duration={2}
							delay={1}
						/>
					</p>
				</Col>

				<Col lg={4} className="boxResumeComite">
					<p className="center ">Número de documentos</p>
					<p className="center numberResumen">
						<CountUp
							start={0}
							end={this.state.countDocs}
							duration={2}
							delay={1}
						/>
					</p>
				</Col>

				<Col lg={12}>
					<hr></hr>
					<h4>Información de contacto del comité </h4>
					<Form onSubmit={this.handleSubmitContactData}>
						<p>
							En este apartado puede editar la información de contacto que
							aparecerá en la parte pública del comité (
							<b>los campos Persona de contacto e e-mail son obligatorios.</b>).
							Aparecerá por defecto el contacto del presidente/a.
						</p>
						{this.props.comiteContactData.map((cd, index) => {
							return (
								<Row key={index}>
									<Col lg={6} sm={12}>
										<Form.Group controlId="nameUser">
											<Form.Label>Persona de contacto*</Form.Label>
											<Form.Control
												type="text"
												placeholder="Nombre"
												name="comiteNameContact"
												defaultValue={cd.name}
												required
											/>
										</Form.Group>
									</Col>
									<Col lg={6} sm={12}>
										<Form.Group controlId="PhoneUser">
											<Form.Label>Teléfono</Form.Label>
											<Form.Control
												type="text"
												placeholder="Telefono"
												name="comitePhoneContact"
												defaultValue={cd.phone}
											/>
										</Form.Group>
									</Col>
									<Col lg={6} sm={12}>
										<Form.Group controlId="emailUser1">
											<Form.Label>Email*</Form.Label>
											<Form.Control
												type="email"
												name="comiteEmailContact"
												placeholder="email"
												defaultValue={cd.email}
												required
											/>
										</Form.Group>
									</Col>
									<Col lg={6} sm={12} hidden>
										<Form.Group controlId="addressComite">
											<Form.Label>Dirección</Form.Label>
											<Form.Control
												type="text"
												name="comiteAddressContact"
												placeholder="Dirección"
												defaultValue={cd.address}
											/>
										</Form.Group>
									</Col>
									<Col lg={6} sm={12}>
										<Form.Group controlId="facebookLink">
											<Form.Label>Link a Facebook del comité</Form.Label>
											<Form.Control
												type="url"
												name="faComiteData"
												placeholder="Link a Facebook"
												defaultValue={cd.fb}
											/>
										</Form.Group>
									</Col>
									<Col lg={6} sm={12}>
										<Form.Group controlId="twitterLink">
											<Form.Label>Link a Twitter del comité</Form.Label>
											<Form.Control
												type="url"
												name="twComiteData"
												placeholder="Link a Twitter"
												defaultValue={cd.tw}
											/>
										</Form.Group>
									</Col>
									<Col lg={6} sm={12}>
										<Form.Group controlId="instagramLink">
											<Form.Label>Link a Instagram del comité</Form.Label>
											<Form.Control
												type="url"
												name="igComiteData"
												placeholder="Link a Instagram"
												defaultValue={cd.ig}
											/>
										</Form.Group>
									</Col>
									<Col lg={6} sm={12} hidden>
										<Form.Group controlId="idComite">
											<Form.Control
												type="text"
												placeholder="Nombre"
												name="idComite"
												defaultValue={cd.id}
											/>
										</Form.Group>
									</Col>
									<Col sm={12} xs={12}>
										<Button ref="btnPersonal" variant="primary" type="submit">
											Guardar información
										</Button>
									</Col>
								</Row>
							);
						})}
					</Form>
				</Col>
			</Row>
		);
	}
}

export default TabResume;
