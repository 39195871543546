import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import logoWhite from "../components/img/logo-white.svg";
import axios from "axios";
import ReactGA from "react-ga";
import { Accordion } from "react-bootstrap";

class Registro extends React.Component {
	state = {
		email: "",
		password: "",
		password2: "",
		checkbox: "",
		checkedSelector: false,
		checkedPersona: false,
	};
	handleEmailChange = (evt) => {
		this.setState({ email: evt.target.value });
	};

	handlePasswordChange = (evt) => {
		this.setState({ password: evt.target.value });
	};

	handlePassword2Change = (evt) => {
		this.setState({ password2: evt.target.value });
	};
	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	handleSubmit = (event) => {
		event.preventDefault();
	};
	handleRegister(e) {
		e.preventDefault();
		this.refs.btnRegister.setAttribute("disabled", "disabled");
		const checkEmail = new RegExp(
			"^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$"
		);

		if (checkEmail.test(this.refs.email.value) === true) {
			if (this.refs.passw1.value === this.refs.passw2.value) {
				if (this.refs.passw1.value.length >= 8) {
					if (/[^0-9a-zA-Z]/.test(this.refs.passw1.value)) {
						alert(
							"La contraseña introducida contiene caracteres no permitidos. Recuerde que la contraseña solo puede contener numeros y letras."
						);
						this.refs.btnRegister.removeAttribute("disabled");
					} else {
						let formData = new FormData();
						formData.append("email", this.refs.email.value);
						formData.append("passw", this.refs.passw1.value);
						formData.append("id_type", e.target.typeClient.value);

						axios
							.post("/api/users_create", formData)
							.then((res) => {
								console.log(res);
								this.setState({
									message: res.data,
								});

								if (res.status === 200) {
									alert(
										"Se ha registrado correctamente. Le hemos enviado un email a su correo para que valide su cuenta. Una vez que lo haga, podrá acceder a su cuenta e iniciar sesión en la web. El tiempo de recepción del email puede variar según los casos. Revise su carpeta cada cierto tiempo. Aconsejamos también revisar su carpeta de spam."
									);
									this.refs.btnRegister.removeAttribute("disabled");
									this.props.history.push({
										pathname: "/login",
									});
								} else {
									alert(
										"Lo sentimos. No se ha podido crear su usuario. La cuenta de correo que nos ha facilitado ya se encuentra en nuestra base de datos. Entre en MI CUENTA/ “usuario registrado”, ponga su email, y si no recuerda la contraseña pinche en “Recordar contraseña”."
									);
									this.refs.btnRegister.removeAttribute("disabled");
								}
							})
							.catch((err) => {
								console.log(err.response);
								alert(
									"Lo sentimos. No se ha podido crear su usuario. La cuenta de correo que nos ha facilitado ya se encuentra en nuestra base de datos. Entre en MI CUENTA/ “usuario registrado”, ponga su email, y si no recuerda la contraseña pinche en “Recordar contraseña”."
								);
								this.refs.btnRegister.removeAttribute("disabled");
							});
					}
				} else {
					alert("La contraseña tiene que tener por lo menos 8 caracteres.");
					this.refs.btnRegister.removeAttribute("disabled");
				}
			} else if (this.refs.passw1.value !== this.refs.passw2.value) {
				alert("Asegúrese de que las contraseñas son iguales.");
				this.refs.btnRegister.removeAttribute("disabled");
			}
		} else {
			alert(
				"El email introducido no es válido. No debe usar caracteres especiales o espacios en blanco, y el email debe contener una @ y por lo menos un punto."
			);
			this.refs.btnRegister.removeAttribute("disabled");
		}

		/*
		if (this.refs.passw1.value === this.refs.passw2.value) {
			let formData = new FormData();
			formData.append("email", this.refs.email.value);
			formData.append("passw", this.refs.passw1.value);
			axios
				.post("/api/users_create", formData)
				.then((res) => {
					this.setState({
						message: res.data,
					});
					var result = res.data.message;
					console.log(result);
					if (res.status === 200) {
						alert("Usuario creado correctamente");
						this.props.history.push({
							pathname: "/login",
						});
					} else {
						alert(
							"No se ha podido crear su usuario. Si ya tiene una cuenta y no recuerda el email puede usar la opción recordar contraseña."
						);
						window.location.reload();
					}
				})
				.catch((err) => {
					alert(
						"No se ha podido crear su usuario. Si ya tiene una cuenta y no recuerda el email puede usar la opción recordar contraseña."
					);
					window.location.reload();
				});
		} else if (this.refs.passw1.value !== this.refs.passw2.value) {
			alert("Asegúrese de que las contraseñas son iguales");
		}
		*/
	}
	changeSelector = () => {
		if (this.state.checkedSelector === false) {
			this.setState({
				checkedSelector: true,
				checkedPersona: false,
			});
		} else {
			this.setState({
				checkedSelector: false,
				checkedPersona: false,
			});
		}
	};
	clickPersona = () => {
		if (this.state.checkedSelector === true) {
			this.setState({
				checkedPersona: true,
				checkedSelector: false,
			});
		} else {
			this.setState({
				checkedPersona: true,
			});
		}
	};
	changeSelectorPersona = () => {
		if (this.state.checkedSelector === true) {
			this.setState({
				checkedSelector: false,
			});
		}
	};
	render() {
		const { email, password, password2 } = this.state;
		const isEnabled =
			email.length > 0 && password.length > 0 && password2.length;

		return (
			<div>
				<hr className="whiteSpace"></hr>
				<Container className="width-100 nopadding maxWidth-100 ">
					<Row>
						<Col md={5} sm={12}>
							<div className="align-items-center h-sm-100-vh d-flex bg-grad-blue ">
								<div className="width-100 textWhite login-padding">
									<img src={logoWhite} alt="Fes login logo" width="230px"></img>
									<h2 className="textWhite">Te damos la bienvenida a FES</h2>
									<div className="listCheck" hidden>
										<ul>
											<li className="textWhite">Punto 1</li>
											<li className="textWhite">Punto 2</li>
											<li className="textWhite">Punto 3</li>
										</ul>
									</div>
								</div>
							</div>
						</Col>
						<Col md={7} sm={12}>
							<div className="align-items-center h-sm-100-vh d-flex mx-auto form-login">
								<div className="width-100  login-padding ">
									<h2>Registro de Cuenta!!</h2>
									<div className="listCheck">
										<Form onSubmit={this.handleRegister.bind(this)}>
											<Form.Group>
												<Form.Label>Email</Form.Label>
												<Form.Control
													type="text"
													placeholder="E-mail"
													id="email"
													name="email"
													ref="email"
													value={this.state.email}
													onChange={this.handleEmailChange}
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label>Seleccione el tipo de usuario*</Form.Label>

												<Accordion>
													<Card>
														<Accordion.Toggle
															as={Card.Header}
															eventKey="2"
															onClick={this.changeSelectorPersona}
														>
															<Form.Check
																type="radio"
																label="Persona física"
																name="typeClient"
																value="1"
																required
																onClick={this.clickPersona}
																checked={this.state.checkedPersona}
															/>
														</Accordion.Toggle>
														<Accordion.Toggle
															as={Card.Header}
															eventKey="0"
															onClick={this.changeSelector}
														>
															<Form.Check
																type="radio"
																value="open"
																label="Colectivo"
																name="openSelector"
																style={{ display: "inline-block" }}
																checked={this.state.checkedSelector}
															/>
														</Accordion.Toggle>
														<Accordion.Collapse eventKey="0">
															<Card.Body>
																{" "}
																<label>
																	<Form.Check
																		type="radio"
																		value="10"
																		name="typeClient"
																		style={{ display: "inline-block" }}
																	/>
																	Asociación territorial
																</label>
																<hr></hr>
																<label>
																	<Form.Check
																		type="radio"
																		value="14"
																		name="typeClient"
																		style={{ display: "inline-block" }}
																	/>
																	Instituciones Universitarias
																</label>
																<hr></hr>
																<label>
																	<Form.Check
																		type="radio"
																		value="12"
																		name="typeClient"
																		style={{ display: "inline-block" }}
																	/>
																	Instituciones Cientificas
																</label>
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</Accordion>
											</Form.Group>
											<Form.Group className="fieldUnderText">
												<Form.Label>Contraseña</Form.Label>
												<Form.Control
													type="password"
													placeholder="Contraseña"
													id="password1"
													name="passw1"
													ref="passw1"
													value={this.state.password}
													onChange={this.handlePasswordChange}
												/>
											</Form.Group>
											<p className="smallTextExplanation">
												La contraseña debe tener al menos 8 caracteres y
												contener solo letras y números.
											</p>
											<Form.Group>
												<Form.Label>Confirmar Contraseña</Form.Label>
												<Form.Control
													type="password"
													placeholder="Contraseña"
													id="password2"
													name="passw2"
													ref="passw2"
													value={this.state.password2}
													onChange={this.handlePassword2Change}
												/>
											</Form.Group>
											<Form.Group controlId="formBasicCheckbox">
												<Form.Check
													type="checkbox"
													label={
														<label>
															<a
																href="https://fes-sociologia.com/uploads/privacy/Politica_de_privacidad.pdf"
																target="_blank"
																rel="noopener noreferrer"
															>
																Acepto los terminos y condiciones
															</a>
														</label>
													}
													required
												/>
											</Form.Group>

											<span></span>
											<p>
												¿Ya tienes una cuenta?{" "}
												<a href="/Login">Acceder ahora</a>
												<br></br>
												¿Has olvidado la contraseña?
												<a href="/recordar-password">Recordar Contraseña</a>
											</p>
											<Button
												disabled={!isEnabled}
												//onClick={this.loginFES}
												variant="primary"
												className="Acceder"
												type="submit"
												ref="btnRegister"
											>
												Registro
											</Button>
										</Form>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Registro;
