import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import NoticiaResume from "../../objects/noticiaResume";
import ReactGA from "react-ga";

class OtrasPublicacionesTemplate01 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			news: [],
			array_post: props.array_post,
			array_page: props.array_page,
			newList: [],
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		axios.get("/api/get_all_news").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				news: res.data.records,
			});
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { array_post } = this.state;
		const { news } = this.state;
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});

		const notice = array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});
		const newsList = news.length ? (
			news.map((news, index) => {
				return (
					<Col
						lg={4}
						sm={6}
						key={news.id}
						className={
							"new-cat d-flex category-filter category-" +
							news.categories.map((categories) => categories.id)
						}
					>
						<NoticiaResume
							key={index}
							title={news.title}
							img={news.cover_image}
							categories={news.categories}
							link={news.permalink}
							short_description={
								!news.short_description ? news.content : news.short_description
							}
							meta_description={news.meta_description}
						></NoticiaResume>
					</Col>
				);
			})
		) : (
			<div className="center">No hay noticias</div>
		);
		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col sm={12}>{notice}</Col>
					</Row>
					<Row>{newsList}</Row>
				</Container>
			</div>
		);
	}
}
export default OtrasPublicacionesTemplate01;
