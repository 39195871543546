import React, { Component } from "react";
import { Row, Col, Container } from "react-grid-system";
import "../styles.css";
import "../style/styles_bannerAD.css";
import axios from "axios";

class BannerAd extends Component {
	//State For banner home
	state = {
		bannerAd: [],
		bannerAdError: 0,
	};
	componentDidMount() {
		//Call webservice get banner
		axios
			.get("/api/design_home_banner_get")
			.then((res) => {
				//Banner data

				this.setState({
					bannerAd: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					bannerAdError: 1,
				});
			});
	}

	render() {
		const { bannerAd } = this.state;
		//Banner display
		const bannerAdver = bannerAd.map((banner, index) => {
			if (banner.title) {
				return (
					<Row className="bannerAD" key={index}>
						<Col sm={12} lg={8} className="align_center">
							<div className="textBannerAd">
								<h3 className="titleAD">{banner.title}</h3>
								<p className="subtitleAD">{banner.subtitle}</p>
							</div>
						</Col>
						<Col sm={12} lg={4} className="align_center">
							<div className="buttonBannerAD">
								<a
									href={banner.btn_url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<button className="buttonAD">{banner.btn_text}</button>
								</a>
							</div>
						</Col>
					</Row>
				);
			} else if (banner.img) {
				return (
					<Row key={banner.id}>
						<Col sm={12} className="imageBanner">
							<a
								href={banner.btn_url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={banner.img} alt={banner.title}></img>
							</a>
						</Col>
					</Row>
				);
			} else {
				return null;
			}
		});

		const bannerAdList = bannerAd.length ? (
			bannerAd.map((banner) => {
				return <section key={banner.id}>{bannerAdver}</section>;
			})
		) : (
			//banner not set
			<div className="center"></div>
		);
		return <Container>{bannerAdList}</Container>;
	}
}

export default BannerAd;
