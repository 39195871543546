import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import ButtonFES from "../../objects/button";
import Contacto from "../../WidgetsSidebar/contacto";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import ContactImage from "../../WidgetsSidebar/contactImage";
import ReactGA from "react-ga";
import axios from "axios";

class PageTemplate02 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			permalink: props.permalink,
			menu: [],
		};
	}
	componentDidMount() {
		const { permalink } = this.state;
		ReactGA.pageview(window.location.pathname + window.location.search);
		if (permalink) {
			let formData2 = new FormData();
			formData2.append("permalink", permalink);
			axios
				.post("/api/pages_get_by_permalink", formData2)
				.then((res) => {
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							menu: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		} else {
			return null;
		}
	}
	render() {
		const { array_post } = this.state;
		const { menu } = this.state;

		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});

		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					key={index}
					menu_title="Menú"
					page_array={menu}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
				></MenuPageSidebar>
			);
		});

		const notice = array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		const ButtonDownloadDoc = array_post.map((post) => {
			if (!post.doc) {
				return null;
			} else {
				return (
					<ButtonFES
						button_text="Descargar Documento"
						button_class="button"
						button_link={post.doc}
					></ButtonFES>
				);
			}
		});
		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col className="sidebar" lg={3} md={12}>
							<ContactImage />
							<Contacto />
							{ButtonDownloadDoc}
							{sidebarMenuPage}
						</Col>
						<Col lg={9} md={12}>
							{notice}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default PageTemplate02;
