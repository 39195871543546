import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";

class Topics2 extends Component {
	state = {
		boxes: [],
		title: [],
	};
	componentDidMount() {
		//Call to webservice to get all news
		axios.get("/api/design_home_additional_boxes_get").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				boxes: res.data.records.addi_boxes.slice(0, 2),
				title: res.data.records.design,
			});
		});
	}

	render() {
		const { boxes } = this.state;
		const { title } = this.state;

		const titleSection = title.length ? (
			title.map((title, index) => {
				return (
					<Col sm={12} lg={12} key={index}>
						<div className="center bottom-20">
							<h4 className="titleItalic">{title.section_title}</h4>
						</div>
					</Col>
				);
			})
		) : (
			<div className="center">Temas de interes</div>
		);

		const boxList = boxes.length ? (
			boxes.map((box, index) => {
				if (box.published_date) {
					var date = box.published_date;
					date = date.split(" ")[0];
					var yyyy = date.split("-")[0];
					var mmmm = date.split("-")[1];
					var dddd = date.split("-")[2];
					date = dddd + "-" + mmmm + "-" + yyyy;
				}
				return (
					<Col sm={12} lg={6} key={box.id}>
						<div className="topic">
							<div className="inner">
								<p className="number-under-content" hidden>
									{("0" + (index + 1)).slice(-2)}
								</p>
								<h2 className="process">{box.title}</h2>
								<div>
									<h4>{box.title_news}</h4>
									<p>{box.short_description}</p>
									<h6>{date}</h6>
								</div>

								<Link to={"/noticia" + box.permalink}>+Explorar ahora</Link>

								{/* {!box.type === 1 ? (
									<Link to={"/noticia" + box.permalink}>+Explorar ahora</Link>
								) : (
									<Link to={"/actualidad" + box.permalink}>
										+Explorar ahora
									</Link>
								)} */}
							</div>
						</div>
					</Col>
				);
			})
		) : (
			<div className="center">No hay tema</div>
		);
		return (
			<div>
				<Container>
					{titleSection}
					<Row>{boxList}</Row>
				</Container>
			</div>
		);
	}
}

export default Topics2;
