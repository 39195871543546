import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
//import { Link } from 'react-router-dom';
class Candidature extends Component {
	constructor(props) {
		super(props);
		this.state = {
			candidatures: [],
			candidatureDocRef: "",
			candidatureInfo: [],
			candidatureAlreadySent: true,
			candidatureAvailiable: [],
			titleCandidatureSent: "",
			dateCandidatureSent: "",
		};
	}
	comitesAvailiables = async () => {
		let formData = new FormData();

		formData.append("id_votes", this.props.idVotation);
		formData.append("id_user", this.props.idUser);
		await axios
			.post("/api/votes_comites_candidature_available_by_id_user", formData)
			.then((res) => {
				this.setState({
					candidatures: res.data.records,
				});
			})
			.catch((error) => {});
	};
	getInformation = async () => {
		await axios
			.get("/api/votes_config_get")
			.then((res) => {
				this.setState({
					candidatureInfo: res.data.records,
					candidatureDocRef: res.data.records[res.data.records.length - 1].doc,
				});
			})
			.catch((error) => {});
	};
	checkCandidature = async () => {
		let formData = new FormData();

		formData.append("id_user", this.props.idUser);

		await axios
			.post("/api/votes_check_candidate_has_voted", formData)
			.then((res) => {
				console.log(res);
				if (res.data.records[0].date) {
					var date = res.data.records[0].date;
					date = date.split(" ")[0];
					var yyyy = date.split("-")[0];
					var mmmm = date.split("-")[1];
					var dddd = date.split("-")[2];
					date = dddd + "-" + mmmm + "-" + yyyy;
				}
				this.setState({
					candidatureAlreadySent: true,
					titleCandidatureSent: res.data.records[0].title,
					dateCandidatureSent: date,
				});
			})
			.catch((error) => {
				this.setState({
					candidatureAlreadySent: false,
				});
			});
	};

	componentDidMount() {
		(async () => {
			await this.comitesAvailiables();
			await this.getInformation();
			await this.checkCandidature();
		})();
	}
	PresentarCandidatura = (event) => {
		event.preventDefault();
		/*
		console.log(event.target.idComite.value);
		console.log(this.props.idUser);
		console.log(event.target.docCan.files[0]);
		*/
		let formData = new FormData();
		formData.append("id_votes_comites", event.target.idComite.value);
		formData.append("id_user", this.props.idUser);
		formData.append("doc", event.target.docCan.files[0]);

		axios
			.post("/api/votes_candidates_set", formData)
			.then((res) => {
				alert("Ha enviado su candidatura correctamente.");
				window.location.reload();
			})

			.catch((error) => {
				alert(
					"Hemos encontrado un problema y su candidatura no se ha enviado."
				);
			});
	};
	render() {
		const cadidateuresAvailiabel = this.state.candidatures.map(
			(cand, index) => {
				return (
					<Form
						onSubmit={this.PresentarCandidatura}
						className="BoxComitePresentCan"
						key={index}
					>
						<Row>
							<Col lg={6} sm={12}>
								<p className="titleComitePresentCan">{cand.title}</p>
							</Col>
							<Col lg={6} sm={12}>
								<Form.Group controlId="docCan">
									<Form.Label>Suba su candidatura*</Form.Label>
									<Form.Control
										onChange={(e) => console.log(e.target.files)}
										type="file"
										name="docCan"
										placeholder="Fichero a subir"
										required
									/>
								</Form.Group>
							</Col>
							<Col lg={6} sm={12} hidden>
								<Form.Group controlId="idComite">
									<Form.Control
										type="text"
										name="idComite"
										placeholder="id"
										defaultValue={cand.id}
									/>
								</Form.Group>
							</Col>

							<Col sm={12} xs={12} className="center">
								<Button
									ref="btnCandidatura"
									variant="primary"
									type="submit"
									className="center"
								>
									Enviar Candidatura
								</Button>
							</Col>
						</Row>
					</Form>
				);
			}
		);
		return (
			<div>
				<h3 className="center">Abierto proceso para enviar candidaturas</h3>
				<h5 className="center">
					El proceso de candidaturas estará abierto del {this.props.dateBegin}{" "}
					al {this.props.dateEnd}.
				</h5>
				<Row>
					<Col lg={12} sm={12}>
						{" "}
						<p>Intrucciones para presentar su candidatura</p>
					</Col>
					<Col lg={8} sm={12}>
						<ul>
							<li>
								Las elecciones se realizan a candidaturas cerradas. La persona
								que presenta la candidatura deberá ser el que se presente para{" "}
								<u>presidente/a</u>.
							</li>
							<li>Sólo podrá presentar una candidatura.</li>
							<li>
								Deberá descargar y rellenar el documento que encontrará en este
								apartado (se pide un plan de trabajo para los próximos cuatro
								años, y el listado de miembros que componen la candidatura).
							</li>
							<li>
								Cuando haya completado y subido el documento deberá dar a
								“Enviar Candidatura”
							</li>
							<li>
								Una vez emitida la candidatura le llegará un email con la
								confirmación.
							</li>
						</ul>
					</Col>
					<Col lg={4} sm={12}>
						<p className="center">
							{" "}
							<a
								href={this.state.candidatureDocRef}
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={Icons.faFileAlt} size="5x" />
							</a>
						</p>
					</Col>
				</Row>
				<hr></hr>
				{this.state.candidatureAlreadySent === true ? (
					<h4 className="center">
						Ya has presentado tu candidatura al siguiente comité:{" "}
						<b>{this.state.titleCandidatureSent}</b>, la fecha en la que lo
						presentaste es: <b>{this.state.dateCandidatureSent}</b>. <br></br>
						Podrás ver la lista preliminar de candidatos a partir del{" "}
						<b>{this.props.dateEnd}</b>
					</h4>
				) : (
					<h4 className="center">
						Comités a los que puede presentar la candidatura
					</h4>
				)}

				{this.state.candidatureAlreadySent === true
					? ""
					: cadidateuresAvailiabel}
			</div>
		);
	}
}

export default Candidature;
