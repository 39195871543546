import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Contacto from "../../WidgetsSidebar/contacto";
import PaginationwithoutCAT from "../../objects/Pagination_withoutCAT";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import equal from "fast-deep-equal";
import ContactImage from "../../WidgetsSidebar/contactImage";
import ReactGA from "react-ga";

class PracticaSociologicaTemplate01 extends Component {
	constructor(props) {
		super();
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			filterCat: "",
			videosFetching: false,
			estudiosFetching: false,
			herramientasFetching: false,
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
			infoTabs: [],
			videos: [],
			estudios: [],
			herramientas: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	getVideos = async () => {
		this.setState({
			videosFetching: true,
		});
		let formData = new FormData();
		formData.append("id_categ", 7);
		let res = await axios.post("/api/multimedia_get_by_id_categ", formData);
		this.setState({ videos: res.data.records, videosFetching: false });
	};

	getEstudios = async () => {
		this.setState({
			estudiosFetching: true,
		});
		let formData2 = new FormData();
		formData2.append("id_categ", 13);
		let res = await axios.post("/api/topicality_get_by_id_categ", formData2);
		this.setState({
			estudios: res.data.records,
			estudiosFetching: false,
		});
	};

	getHerramientas = async () => {
		this.setState({
			herramientasFetching: true,
		});
		let formData3 = new FormData();
		formData3.append("id_categ", 14);
		let res = await axios.post("/api/topicality_get_by_id_categ", formData3);
		this.setState({
			herramientas: res.data.records,
			herramientasFetching: false,
		});
	};
	componentDidMount() {
		//Call to webservice to get the new
		(async () => {
			await this.getVideos();
			await this.getEstudios();
			await this.getHerramientas();
		})();

		axios
			.get("/api/socio_practice_get")
			.then((res) => {
				//console.log(res);
				this.setState({
					infoTabs: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});

		let formData2 = new FormData();
		formData2.append("id_categ", 13);
		axios
			.post("/api/topicality_get_by_id_categ", formData2)
			.then((res) => {
				//console.log(res);
				this.setState({
					estudios: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		if (this.props !== prevProps) {
			(async () => {
				await this.getVideos();
				await this.getEstudios();
				await this.getHerramientas();
			})();
			axios
				.get("/api/socio_practice_get")
				.then((res) => {
					//console.log(res);
					this.setState({
						infoTabs: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
		if (!equal(this.props.array_page, prevProps.array_page)) {
			this.setState({
				array_page: this.props.array_page,
			});
		}
	}

	render() {
		const { statusPage } = this.state;
		const { array_page } = this.state;
		const { array_post } = this.state;
		const { infoTabs } = this.state;
		const { videos } = this.state;
		const { estudios } = this.state;
		const { herramientas } = this.state;
		const { videosFetching } = this.state;
		const { estudiosFetching } = this.state;
		const { herramientasFetching } = this.state;

		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					menu_title="Menú"
					page_array={array_page}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					key={index}
				></MenuPageSidebar>
			);
		});
		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});
		const catVideos = ["7"];
		const multimediaPagination = !videosFetching ? (
			<PaginationwithoutCAT
				array_post={videos}
				type="multimedia"
				itemsPerPage="6"
				onlyThisCat={catVideos}
				hideCat="1"
			/>
		) : (
			<p>Cargando videos</p>
		);
		const catEstudios = ["13"];
		const estudiosPagination = !estudiosFetching ? (
			<PaginationwithoutCAT
				array_post={estudios}
				type="actualidad"
				itemsPerPage="6"
				onlyThisCat={catEstudios}
				hideCat="1"
			/>
		) : (
			<p>Cargando Estudios</p>
		);

		const catHerramientas = ["14"];
		const herramientasPagination = !herramientasFetching ? (
			<PaginationwithoutCAT
				array_post={herramientas}
				type="actualidad"
				itemsPerPage="6"
				onlyThisCat={catHerramientas}
				hideCat="1"
			/>
		) : (
			<p>Cargando Estudios</p>
		);
		const tabs = infoTabs.map((tab, index) => {
			return (
				<div key={index}>
					<TabPanel>
						<div
							dangerouslySetInnerHTML={{
								__html: tab.readings
									? tab.readings
									: "<p>No hay informacion</p>",
							}}
						/>
					</TabPanel>
					<TabPanel>
						<div
							dangerouslySetInnerHTML={{
								__html: tab.diagnostic_studies
									? tab.diagnostic_studies
									: "<p>No hay informacion</p>",
							}}
						/>
						<Row>{estudiosPagination}</Row>
					</TabPanel>
					<TabPanel>
						<div
							dangerouslySetInnerHTML={{
								__html: tab.videos ? tab.videos : "<p>No hay informacion</p>",
							}}
						/>
						<Row>{multimediaPagination}</Row>
					</TabPanel>
					<TabPanel>
						<div
							dangerouslySetInnerHTML={{
								__html: tab.tools ? tab.tools : "<p>No hay informacion</p>",
							}}
						/>
						<Row>{herramientasPagination}</Row>
					</TabPanel>
				</div>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col lg={9} md={12}>
							<p>
								Esta sección esta dedicada a facilitar la difusión de los usos
								prácticos de la sociología. Está dirigida a estudiantes,
								trabajadores del ámbito sociológico, profesores y profesionales
								de las ciencias sociales en general interesados en conocer la
								aplicación de la sociología para orientar la toma de decisiones
								en lugares concretos y, en general, para informar la acción
								social de la ciudadanía.
							</p>

							<Tabs>
								<TabList>
									<Tab>Lecturas</Tab>
									<Tab>Estudios</Tab>
									<Tab>Videos</Tab>
									<Tab>Herramientas</Tab>
								</TabList>
								{tabs}
							</Tabs>
						</Col>
						<Col className="sidebar" lg={3} md={12}>
							<ContactImage />
							<Contacto />
							{sidebarMenuPage}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default PracticaSociologicaTemplate01;
