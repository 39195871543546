import React, { Component } from "react";
import ImageDefault from "../img/noticias_default.jpg";
import { Link } from "react-router-dom";

class PostResume extends Component {
	constructor(props) {
		super(props);
		this.state = {
			short_description: props.short_description,
			title: props.title,
			img: props.img,
			categoryID: props.categoryID,
			categoryName: props.categoryName,
			link: props.link,
			button_txt: props.button_txt,
			published_date: props.date,
			categories: props.categories,
		};
	}

	render() {
		const { short_description } = this.state;
		const { title } = this.state;
		const { img } = this.state;
		const { link } = this.state;
		const { button_txt } = this.state;
		const { published_date } = this.state;
		//const { categories } = this.state;

		var titleFinal;

		if (published_date) {
			var date = published_date;
			date = date.split(" ")[0];
			var yyyy = date.split("-")[0];
			var mmmm = date.split("-")[1];
			var dddd = date.split("-")[2];
			date = dddd + "-" + mmmm + "-" + yyyy;
		}

		var customTitle;
		var patternUrl = /^((http|https|ftp):\/\/)/;
		if (title) {
			customTitle = title
				.replace(/(<([^>]+)>)/gi, "")
				.split(" ")
				.splice(0, 20)
				.join(" ");
			if (customTitle.length < title.length) {
				titleFinal = title + "...";
			} else {
				titleFinal = title;
			}
		}
		/*
		const notWantedCategories = ["51", "52"];
		let listCategories;
		if (categories === undefined) {
			listCategories = null;
		} else {
			listCategories = categories.map((cat, index) => {
				if (notWantedCategories.some((r) => cat.id.includes(r))) {
					return null;
				} else {
					return (
						<Link to={"/categoria/" + cat.id} key={index} className="button">
							{cat.name}
						</Link>
					);
				}
			});
		}
		*/
		return (
			<div className="inner boxResumeNews bg-white shadow border-radius-3 separation-15 inner padding-20">
				<img
					src={!img ? ImageDefault : img}
					alt={`${customTitle}`}
					className="imgTop3"
				></img>
				<h6>{!date ? "" : date}</h6>
				<h5>{titleFinal}</h5>
				<div className="shortDescription">
					{!short_description ? null : short_description}
				</div>
				<div className="customButtonArea">
					<button className="button">
						{!patternUrl.test(link) ? (
							<Link to={"/" + link}>
								{!button_txt ? "+Explorar ahora" : button_txt}
							</Link>
						) : (
							<a href={link} target="_blank" rel="noopener noreferrer">
								+Explorar ahora
							</a>
						)}
					</button>{" "}
				</div>
			</div>
		);
	}
}
export default PostResume;
