import React from "react";
import { Helmet } from "react-helmet-async";
//import MetaTags from "react-meta-tags";
import favicon from "./icon/favicon.png";

const SEO = (props) => {
	return (
		<div className="wrapper">
			<Helmet>
				<title>{props.title}</title>
				{/*<meta name="description" content={props.description} />
				{props.keywords && <meta name="keywords" content={props.keywords} />}
				 <meta property="og:title" content={props.title} />
				<meta
					name="og:url"
					content={props.url ? props.url : "https://fes-sociologia.com/"}
				/>
				<meta name="og:description" content={props.description} />
				<meta
					name="og:image"
					content={
						props.cover_image
							? props.cover_image
							: "https://fes-sociologia.com/images/fes_logo.jpg"
					}
				/> */}

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="http://fes-sociologia.com/" />
				<meta name="twitter:creator" content="@rrybak01"></meta>
				<meta name="twitter:title" content={props.title} />
				<meta name="twitter:description" content={props.description} />
				<meta name="twitter:image" content={props.cover_image ? props.cover_image : "https://fes-sociologia.com/images/fes_logo.jpg"} />


				{/* <meta name="og:type" content="website" /> */}

				<link rel="icon" type="image/png" href={favicon}></link>
			</Helmet>
		</div>
	);
};
export default SEO;
