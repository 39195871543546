import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { withRouter } from "react-router-dom";
//import { Link } from 'react-router-dom';
class TabDoc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkbox1: false,
			checkbox2: false,
			checkbox3: false,
			checkbox4: false,
			checkbox5: false,
			current_doc_1: "",
			current_doc_2: "",
			current_doc_3: "",
			current_doc_4: "",
			current_doc_5: "",
			current_btn_1: "",
			current_btn_2: "",
			current_btn_3: "",
			current_btn_4: "",
			current_btn_5: "",
			doc1isSelected: [],
			doc2isSelected: [],
			doc3isSelected: [],
			doc4isSelected: [],
			doc5isSelected: [],
		};
	}

	getLinkCurrentDoc = () => {
		if (this.props.docComite) {
			this.props.docComite.map((doc, index) => {
				if (doc.doc_1 !== "") {
					this.setState({
						current_doc_1: doc.doc_1,
						current_btn_1: doc.btn_text_1,
					});
				}
				if (doc.doc_2 !== "") {
					this.setState({
						current_doc_2: doc.doc_2,
						current_btn_2: doc.btn_text_2,
					});
				}
				if (doc.doc_3 !== "") {
					this.setState({
						current_doc_3: doc.doc_3,
						current_btn_3: doc.btn_text_3,
					});
				}
				if (doc.doc_4 !== "") {
					this.setState({
						current_doc_4: doc.doc_4,
						current_btn_4: doc.btn_text_4,
					});
				}
				if (doc.doc_5 !== "") {
					this.setState({
						current_doc_5: doc.doc_5,
						current_btn_5: doc.btn_text_5,
					});
				}
				return null;
			});
		}
	};
	componentDidMount() {
		this.getLinkCurrentDoc();
	}

	handleUploadFile = (event, idDoc) => {
		event.preventDefault();

		let formData = new FormData();
		formData.append("id_comite", this.props.idComite);

		formData.append("file1", event.target.file1.files[0]);
		formData.append("file2", event.target.file2.files[0]);
		formData.append("file3", event.target.file3.files[0]);
		formData.append("file4", event.target.file4.files[0]);
		formData.append("file5", event.target.file5.files[0]);

		formData.append(
			"current_doc_1",
			event.target.file1.files[0] ? "" : this.state.current_doc_1
		);
		formData.append(
			"current_doc_2",
			event.target.file2.files[0] ? "" : this.state.current_doc_2
		);
		formData.append(
			"current_doc_3",
			event.target.file3.files[0] ? "" : this.state.current_doc_3
		);
		formData.append(
			"current_doc_4",
			event.target.file4.files[0] ? "" : this.state.current_doc_4
		);
		formData.append(
			"current_doc_5",
			event.target.file5.files[0] ? "" : this.state.current_doc_5
		);
		formData.append(
			"btn_text_1",
			event.target.file1.files[0]
				? event.target.file1.files[0].name.split(".").slice(0, -1).join(".")
				: this.state.current_btn_1
				? this.state.current_btn_1
				: ""
		);
		formData.append(
			"btn_text_2",
			event.target.file2.files[0]
				? event.target.file2.files[0].name.split(".").slice(0, -1).join(".")
				: this.state.current_btn_2
				? this.state.current_btn_2
				: ""
		);
		formData.append(
			"btn_text_3",
			event.target.file3.files[0]
				? event.target.file3.files[0].name.split(".").slice(0, -1).join(".")
				: this.state.current_btn_3
				? this.state.current_btn_3
				: ""
		);
		formData.append(
			"btn_text_4",
			event.target.file4.files[0]
				? event.target.file4.files[0].name.split(".").slice(0, -1).join(".")
				: this.state.current_btn_4
				? this.state.current_btn_4
				: ""
		);
		formData.append(
			"btn_text_5",
			event.target.file5.files[0]
				? event.target.file5.files[0].name.split(".").slice(0, -1).join(".")
				: this.state.current_btn_5
				? this.state.current_btn_5
				: ""
		);
		formData.append(
			"id_comites_doc",
			this.props.docComite.length > 0 ? this.props.docComite[0].id : ""
		);

		axios
			.post("/api/comites_upload_docs", formData)
			.then((res) => {
				alert("Se han actualizado los documentos");
				this.props.checkDocChild();
			})

			.catch((error) => {
				alert(
					"Hemos encontrado un problema y no se han actualizado los documentos"
				);
			});
	};
	deleteDoc = (event) => {
		let formData = new FormData();
		//formData.append("id_comite", this.props.idComite);
		formData.append("doc_01", this.state.checkbox1 ? 1 : 0);
		formData.append("doc_02", this.state.checkbox2 ? 1 : 0);
		formData.append("doc_03", this.state.checkbox3 ? 1 : 0);
		formData.append("doc_04", this.state.checkbox4 ? 1 : 0);
		formData.append("doc_05", this.state.checkbox5 ? 1 : 0);
		formData.append(
			"id_comites_doc",
			this.props.docComite.length > 0 ? this.props.docComite[0].id : ""
		);
		axios
			.post("/api/comites_delete_docs", formData)
			.then((res) => {
				alert("Se han borrado los documentos");
				this.props.checkDocChild();
				this.props.history.push({
					pathname: "/perfil/admin/comite/" + this.props.idComite,
					state: {
						key: "docComite",
					},
				});
			})
			.catch((error) => {
				alert(
					"Hemos encontrado un problema y no se han borrado los documentos"
				);
			});
	};
	onClick = (event) => {
		if (event.target.value === "doc_01") {
			this.setState({ checkbox1: !this.state.checkbox1 });
		}
		if (event.target.value === "doc_02") {
			this.setState({ checkbox2: !this.state.checkbox2 });
		}
		if (event.target.value === "doc_03") {
			this.setState({ checkbox3: !this.state.checkbox3 });
		}
		if (event.target.value === "doc_04") {
			this.setState({ checkbox4: !this.state.checkbox4 });
		}
		if (event.target.value === "doc_05") {
			this.setState({ checkbox5: !this.state.checkbox5 });
		}
	};
	deletOneDoc = (doc) => {
		let formData = new FormData();
		//formData.append("id_comite", this.props.idComite);
		formData.append("doc_01", doc === 1 ? 1 : 0);
		formData.append("doc_02", doc === 2 ? 1 : 0);
		formData.append("doc_03", doc === 3 ? 1 : 0);
		formData.append("doc_04", doc === 4 ? 1 : 0);
		formData.append("doc_05", doc === 5 ? 1 : 0);
		formData.append(
			"id_comites_doc",
			this.props.docComite.length > 0 ? this.props.docComite[0].id : ""
		);
		axios
			.post("/api/comites_delete_docs", formData)
			.then((res) => {
				alert("Se ha borrado el documento");
				this.props.checkDocChild();
				this.props.history.push({
					pathname: "/perfil/admin/comite/" + this.props.idComite,
					state: {
						key: "docComite",
					},
				});
			})
			.catch((error) => {
				alert("Hemos encontrado un problema y no se ha borrado el documento");
			});
	};
	render() {
		const { checkbox1 } = this.state;
		const { checkbox2 } = this.state;
		const { checkbox3 } = this.state;
		const { checkbox4 } = this.state;
		const { checkbox5 } = this.state;
		let buttonDeleteActive;
		if (
			checkbox1 === true ||
			checkbox2 === true ||
			checkbox3 === true ||
			checkbox4 === true ||
			checkbox5 === true
		) {
			buttonDeleteActive = false;
		} else buttonDeleteActive = true;
		return (
			<Form onSubmit={this.handleUploadFile} id="form-doc">
				<Row>
					<Col lg={6} sm={12}>
						<Form.Group controlId="doc_01">
							<Form.Label>Seleccionar Documento:</Form.Label>
							<Form.Control
								onChange={(e) =>
									this.setState({ doc1isSelected: e.target.files })
								}
								type="file"
								name="file1"
								placeholder="Fichero a subir"
							/>
						</Form.Group>
						<Button
							ref="btnDoc"
							variant="primary"
							name="formSubmit"
							value="update"
							type="submit"
							disabled={this.state.doc1isSelected.length > 0 ? false : true}
						>
							Subir Documento
						</Button>
					</Col>
					{!this.props.docComite
						? null
						: this.props.docComite.map((cd, index) => {
								if (cd.doc_1) {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>
												<a
													href={cd.doc_1}
													target="_blank"
													rel="noreferrer noopener"
												>
													Link al Documento
												</a>
											</div>
											<Form.Group controlId="delteDoc_01" hidden>
												<Form.Check
													type="checkbox"
													onChange={this.onClick}
													value="doc_01"
													label="Eliminar Documento 1"
												/>
											</Form.Group>
											<Form.Group>
												<p
													className="button marginButtonDelete"
													onClick={() => this.deletOneDoc(1)}
												>
													{" "}
													Borrar{" "}
												</p>
											</Form.Group>
										</Col>
									);
								} else {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>No hay documento disponible</div>
										</Col>
									);
								}
						  })}

					<Col lg={12}>
						<hr></hr>
					</Col>
					<Col lg={6} sm={12}>
						<Form.Group controlId="doc_02">
							<Form.Label>Seleccionar Documento:</Form.Label>
							<Form.Control
								onChange={(e) =>
									this.setState({ doc2isSelected: e.target.files })
								}
								type="file"
								name="file2"
								placeholder="Fichero a subir"
							/>
						</Form.Group>
						<Button
							ref="btnDoc"
							variant="primary"
							name="formSubmit"
							value="update"
							type="submit"
							disabled={this.state.doc2isSelected.length > 0 ? false : true}
						>
							Subir Documento
						</Button>
					</Col>
					{!this.props.docComite
						? null
						: this.props.docComite.map((cd, index) => {
								if (cd.doc_2) {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>
												<a
													href={cd.doc_2}
													target="_blank"
													rel="noreferrer noopener"
												>
													Link al Documento
												</a>
											</div>
											<Form.Group controlId="delteDoc_02" hidden>
												<Form.Check
													type="checkbox"
													onChange={this.onClick}
													value="doc_02"
													label="Eliminar Documento 2"
												/>
											</Form.Group>

											<Form.Group>
												<p
													className="button marginButtonDelete"
													onClick={() => this.deletOneDoc(2)}
												>
													{" "}
													Borrar{" "}
												</p>
											</Form.Group>
										</Col>
									);
								} else {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>No hay documento disponible</div>
										</Col>
									);
								}
						  })}
					<Col lg={12}>
						<hr></hr>
					</Col>
					<Col lg={6} sm={12}>
						<Form.Group controlId="doc_03">
							<Form.Label>Seleccionar Documento:</Form.Label>
							<Form.Control
								onChange={(e) =>
									this.setState({ doc3isSelected: e.target.files })
								}
								type="file"
								name="file3"
								placeholder="Fichero a subir"
							/>
						</Form.Group>
						<Button
							ref="btnDoc"
							variant="primary"
							name="formSubmit"
							value="update"
							type="submit"
							disabled={this.state.doc3isSelected.length > 0 ? false : true}
						>
							Subir Documento
						</Button>
					</Col>
					{!this.props.docComite
						? null
						: this.props.docComite.map((cd, index) => {
								if (cd.doc_3) {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>
												<a
													href={cd.doc_3}
													target="_blank"
													rel="noreferrer noopener"
												>
													Link al Documento
												</a>
											</div>
											<Form.Group controlId="delteDoc_03" hidden>
												<Form.Check
													type="checkbox"
													value="doc_03"
													onChange={this.onClick}
													label="Eliminar Documento 3"
												/>
											</Form.Group>

											<Form.Group>
												<p
													className="button marginButtonDelete"
													onClick={() => this.deletOneDoc(3)}
												>
													{" "}
													Borrar{" "}
												</p>
											</Form.Group>
										</Col>
									);
								} else {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>No hay documento disponible</div>
										</Col>
									);
								}
						  })}
					<Col lg={12}>
						<hr></hr>
					</Col>
					<Col lg={6} sm={12}>
						<Form.Group controlId="doc_04">
							<Form.Label>Seleccionar Documento:</Form.Label>
							<Form.Control
								onChange={(e) =>
									this.setState({ doc4isSelected: e.target.files })
								}
								type="file"
								name="file4"
								placeholder="Fichero a subir"
							/>
						</Form.Group>
						<Button
							ref="btnDoc"
							variant="primary"
							name="formSubmit"
							value="update"
							type="submit"
							disabled={this.state.doc4isSelected.length > 0 ? false : true}
						>
							Subir Documento
						</Button>
					</Col>
					{!this.props.docComite
						? null
						: this.props.docComite.map((cd, index) => {
								if (cd.doc_4) {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>
												<a
													href={cd.doc_4}
													target="_blank"
													rel="noreferrer noopener"
												>
													Link al Documento
												</a>
											</div>
											<Form.Group controlId="delteDoc_04" hidden>
												<Form.Check
													type="checkbox"
													value="doc_04"
													onChange={this.onClick}
													label="Eliminar Documento 4"
												/>
											</Form.Group>

											<Form.Group>
												<p
													className="button marginButtonDelete"
													onClick={() => this.deletOneDoc(4)}
												>
													{" "}
													Borrar{" "}
												</p>
											</Form.Group>
										</Col>
									);
								} else {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>No hay documento disponible</div>
										</Col>
									);
								}
						  })}
					<Col lg={12}>
						<hr></hr>
					</Col>
					<Col lg={6} sm={12}>
						<Form.Group controlId="doc_05">
							<Form.Label>Seleccionar Documento:</Form.Label>
							<Form.Control
								onChange={(e) =>
									this.setState({ doc5isSelected: e.target.files })
								}
								type="file"
								name="file5"
								placeholder="Fichero a subir"
							/>
						</Form.Group>
						<Button
							ref="btnDoc"
							variant="primary"
							name="formSubmit"
							value="update"
							type="submit"
							disabled={this.state.doc5isSelected.length > 0 ? false : true}
						>
							Subir Documento
						</Button>
					</Col>
					{!this.props.docComite
						? null
						: this.props.docComite.map((cd, index) => {
								if (cd.doc_5) {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>
												<a
													href={cd.doc_5}
													target="_blank"
													rel="noreferrer noopener"
												>
													Link al Documento
												</a>
											</div>
											<Form.Group controlId="delteDoc_05" hidden>
												<Form.Check
													type="checkbox"
													value="doc_05"
													onChange={this.onClick}
													label="Eliminar Documento 5"
												/>
											</Form.Group>

											<Form.Group>
												<p
													className="button marginButtonDelete"
													onClick={() => this.deletOneDoc(5)}
												>
													{" "}
													Borrar{" "}
												</p>
											</Form.Group>
										</Col>
									);
								} else {
									return (
										<Col lg={6} sm={12} key={index}>
											<Form.Label>Ver documento subido:</Form.Label>
											<div>No hay documento disponible</div>
										</Col>
									);
								}
						  })}
					<Col lg={12}>
						<hr></hr>
					</Col>

					<Col lg={6} xs={12} hidden>
						<Button
							ref="btnDoc"
							variant="primary"
							name="formSubmit1"
							onClick={this.deleteDoc}
							type="button"
							disabled={buttonDeleteActive}
						>
							Eliminar los documentos seleccionados
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default withRouter(TabDoc);
