import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../img/FES_logotipo_color.png";
import { Row, Col, Container } from "react-grid-system";
import classnames from "classnames";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { logoutFES } from "../utils/JWTAuth";

// class component
class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			prevScrollpos: window.pageYOffset,
			visible: true,
			menu: [],
			usernameShow: 0,
		};
	}
	async logoutFES() {
		await logoutFES();
	}

	// Adds an event listener when the component is mount.
	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
		(async () => {
			await this.getUserName();
		})();
		//Call to webservice to get The Menu
		axios.get("/api/menu_with_pages_get").then((res) => {
			//console.log(res);
			this.setState({
				menu: res.data.records,
			});
		});
	}

	getUserName = async () => {
		const jwt = localStorage.getItem("access_token");

		if (!jwt) {
			this.setState.usernameShow = 0;
		} else if (localStorage.getItem("usernameShow")) {
			this.setState.usernameShow = 1;
		} else {
			this.setState.usernameShow = 1;
			let formData = new FormData();
			formData.append("jwt", jwt);
			await axios
				.post("/api/validate_token", formData)
				.then((res) => {
					//console.log(res);
					localStorage.setItem("usernameShow", res.data.data.name);
				})
				.catch((error) => { });
		}
	};

	// Remove the event listener when the component is unmount.
	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	// Hide or show the menu.
	handleScroll = () => {
		const { prevScrollpos } = this.state;

		const currentScrollPos = window.pageYOffset;
		const visible = prevScrollpos > currentScrollPos;
		var googleBar = document.getElementsByClassName("goog-te-banner-frame");
		if (visible) {
			if (googleBar.length) {
				document
					.getElementsByClassName("goog-te-banner-frame")[0]
					.classList.remove("changeTop");
				document.getElementsByClassName("goog-te-banner-frame")[0].style.top =
					"140px";
			}
		} else {
			if (googleBar.length) {
				document
					.getElementsByClassName("goog-te-banner-frame")[0]
					.classList.add("changeTop");
				document.getElementsByClassName("goog-te-banner-frame")[0].style.top =
					"100px";
			}
		}
		this.setState({
			prevScrollpos: currentScrollPos,
			visible,
		});
	};

	render() {
		const { menu } = this.state;

		var patternUrl = /^((http|https|ftp):\/\/)/;

		const menuTopItem = menu
			.sort((a, b) => a.position - b.position)
			.map((menu) => {
				if (menu.pages.length > 0 && +menu.id !== 8 && +menu.id !== 5) {

					if (menu.name === "Noticias") {
						return (
							<Nav.Link
								key={menu.id}
								href={"/noticias"}
								className="menuItem"
							>
								NOTICIAS
							</Nav.Link>
						)
					} else {
						return (
							<NavDropdown
								key={menu.id}
								title={menu.name}
								className="menuItem"
								id="basic-nav-dropdown"
							>
								{
									menu.pages.map((pages) => {
										if (pages.sub_pages.length > 0) {
											return (
												<NavDropdown
													key={pages.id}
													className="InsideDropdown"
													to={"/" + menu.name + "/" + pages.permalink}
													title={pages.title}
													id="basic-nav-dropdown"
												>
													{pages.sub_pages.map((subpages) => (
														<Nav.Link
															key={subpages.id}
															href={
																!patternUrl.test(subpages.permalink)
																	? "/" +
																	menu.name +
																	"/" +
																	pages.permalink +
																	"/" +
																	subpages.permalink
																	: subpages.permalink
															}
															className="linkMenuTop popupDropdown"
															target={
																!patternUrl.test(subpages.permalink) ? "" : "_blank"
															}
														>
															{subpages.title}
														</Nav.Link>
													))}
												</NavDropdown>
											);
										} else {
											if (pages.title === "Multimedia") {
												return null;
											} else {
												return (
													<Nav.Link
														key={pages.id}
														href={
															!patternUrl.test(pages.permalink)
																? "/" + menu.name + "/" + pages.permalink
																: pages.permalink
														}
														className="linkMenuTop"
														target={!patternUrl.test(pages.permalink) ? "" : "_blank"}
													>
														{pages.title}
													</Nav.Link>
												);
											}
										}
									})
								}
							</NavDropdown>
						);
					}
				} else {
					return null;
				}
			});
		
		const textoCuenta = "Mi Cuenta";

		const menuCuenta = localStorage.getItem("access_token") ? (
			<NavDropdown
				title={
					<Fragment>
						<FontAwesomeIcon icon={Icons.faUser} size="1x" />{" "}
						{localStorage.getItem("usernameShow") ? (
							<span>
								Hola
								<span className="nameTopDesktop">
									, {localStorage.getItem("usernameShow")}
								</span>
							</span>
						) : (
							textoCuenta
						)}
					</Fragment>
				}
				id="basic-nav-dropdown"
				className="dropdownOptionsMenu"
			>
				<NavDropdown.Item>
					<Link to="/perfil">Mi Perfil</Link>
				</NavDropdown.Item>
				<NavDropdown.Item>
					<Link to="/" onClick={this.logoutFES}>
						Cerrar Sesión
					</Link>
				</NavDropdown.Item>
			</NavDropdown>
		) : (
			<NavDropdown
				title={
					<Fragment>
						<FontAwesomeIcon icon={Icons.faUser} size="1x" />{" "}
						<span>{textoCuenta}</span>
					</Fragment>
				}
				id="basic-nav-dropdown"
				className="dropdownOptionsMenu"
			>
				<NavDropdown.Item>
					<Link to="/login">Usuario registrado</Link>
				</NavDropdown.Item>
				<NavDropdown.Item>
					<Link to="/registro">Nuevo usuario</Link>
				</NavDropdown.Item>
			</NavDropdown>
		);

		return (
			<header>
				<link
					href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
					rel="stylesheet"
				/>
				<div
					className={classnames("topmenuScroll", {
						"topmenuScroll--hidden": !this.state.visible,
					})}
				>
					<Container className="width-100 max-width-100 topMenu">
						<Row className="max-width-site customPaddingTop">
							<Col lg={6} sm={6} xs={6} className="topMenuLeft">
								<div className="contactInfoTop">
									<p className="textWhite">
										<span className="hideInMobile">Tel.:</span>{" "}
										<a href="tel:+34629271961" className="textWhite">
											+34 629 27 19 61
										</a>{" "}
										<span className="hideInMobile">
											&nbsp;&nbsp;&nbsp; Email:&nbsp;
										</span>
										<a
											href="mailto:info@fes-sociologia.com"
											className="textWhite mailTop"
										>
											info@fes-sociologia.com
										</a>
									</p>
								</div>
							</Col>
							<Col lg={6} sm={6} xs={6}>
								<Navbar
									expanded="true"
									expand="true"
									className="navbar-expand topMenuRight"
								>
									<Navbar.Collapse
										id="basic-navbar-nav"
										className="submenuOptions"
									>
										<Nav className="ml-auto">
											{menuCuenta}
											<Nav.Link href="/contacto">Contacto</Nav.Link>
											<Nav.Link
												target="_blank"
												href="https://www.linkedin.com/company/federacion-espa%C3%B1ola-de-sociologia/"
												className="topHeaderSocial"
											>
												<FontAwesomeIcon
													icon={fab.faLinkedin}
													className="socialIconsTop"
												/>
											</Nav.Link>
											<Nav.Link
												target="_blank"
												href="https://www.facebook.com/federacionespanoladesociologia"
												className="topHeaderSocial"
											>
												<FontAwesomeIcon
													icon={fab.faFacebookF}
													className="socialIconsTop"
												/>
											</Nav.Link>
											<Nav.Link
												target="_blank"
												href="https://twitter.com/FES_Sociologia"
												className="topHeaderSocial"
											>
												<FontAwesomeIcon
													icon={fab.faTwitter}
													className="socialIconsTop"
												/>
											</Nav.Link>
											<Nav.Link
												target="_blank"
												href="https://www.youtube.com/channel/UCwpCH36H2KCeyXrzeygUr-A"
												className="topHeaderSocial"
											>
												<FontAwesomeIcon
													icon={fab.faYoutube}
													className="socialIconsTop"
												/>
											</Nav.Link>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="headerMenu width-100 max-width-100">
					<Navbar expand="lg" className="menuTopFES max-width-site">
						<Link to="/">
							<Navbar.Brand>
								{" "}
								<img
									src={logo}
									alt="FES - Federación Española de Sociología"
									className="logoHeader"
									width="180px"
								></img>
							</Navbar.Brand>
						</Link>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="ml-auto">{menuTopItem}</Nav>
							<span className="ctaHeader">
								<button className="button">
									<a href="/afiliate">¡AFÍLIATE!</a>
								</button>
							</span>
						</Navbar.Collapse>
					</Navbar>
					<div
						className="max-width-site google_transaltor"
						style={{
							textAlign: "right",
							marginTop: "-16px",
						}}
					>
						<div
							id="google_translate_element"
							style={{
								backgroundColor: "#ffffff",
							}}
						></div>
					</div>
				</Container>
			</header>
		);
	}
}

export default Header;
