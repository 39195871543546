import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
//import {Link} from "react-router-dom";
//import Homepage from '../../Scenes/homepage';
import ImageDefault from "../img/noticias_default.jpg";
import "../../../../node_modules/react-modal-video/scss/modal-video.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 1,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

class MediaContent extends Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			multimedia: [],
		};
		this.openModal = this.openModal.bind(this);
	}

	openModal(customState) {
		this.setState({ [customState]: true });
	}
	componentDidMount() {
		//Call to webservice to get all news
		axios.post("/api/multimedia_get_all").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				multimedia: res.data.records.slice(0, 3),
			});
		});
	}

	render() {
		const { multimedia } = this.state;

		const postList = multimedia.length ? (
			multimedia.map((multi) => {
				if (multi.published_date) {
					var date = multi.published_date;
					date = date.split(" ")[0];
					var yyyy = date.split("-")[0];
					var mmmm = date.split("-")[1];
					var dddd = date.split("-")[2];
					date = dddd + "-" + mmmm + "-" + yyyy;
				}

				var showModal = multi.id;
				return (
					<Row key={multi.id}>
						<Modal
							show={this.state[showModal]}
							onHide={() => this.setState({ [showModal]: false })}
							size="lg"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header closeButton></Modal.Header>
							<Modal.Body>
								<div className="video-responsive">
									<iframe
										width="853"
										height="480"
										src={`https://www.youtube.com/embed/${multi.video_url}`}
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
										title="Embedded youtube"
									/>
								</div>
							</Modal.Body>
						</Modal>
						<Col sm={12} lg={6}>
							<div className="inner">
								<h4>{multi.title}</h4>
								<p>{multi.short_description}</p>
								<h6>{date}</h6>
							</div>
						</Col>
						<Col sm={12} lg={6}>
							<div className="seeVideo">
								<button
									className="button"
									onClick={() => this.setState({ [showModal]: true })}
								>
									Ver video
								</button>
							</div>
							<img
								key={multi.id}
								className="width-100"
								src={!multi.cover_image ? ImageDefault : multi.cover_image}
								alt="alt"
							></img>
						</Col>
					</Row>
				);
			})
		) : (
			<div className="center">No hay contenido multimedia</div>
		);
		return (
			<section className="lighGrayBG">
				<Container>
					<Row>
						<Col sm={12} lg={12}>
							<div className="center">
								<h4 className="titleItalic">Contenido Multimedia</h4>
								<h2>Vídeos, actualidad y entrevistas</h2>
								<Link to="/Divulgación/Multimedia">Explorar todas +</Link>
							</div>
						</Col>
					</Row>
					<Carousel
						responsive={responsive}
						infinite={true}
						className="canalNoticias"
					>
						{postList}
					</Carousel>
				</Container>
			</section>
		);
	}
}

export default MediaContent;
