import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../components/objects/topImageTitle";
import SEO from "../components/objects/seo";
import ActualidadResume from "../components/objects/actualidadResume";
import ReactGA from "react-ga";
import { Pagination } from "antd";
class ActualidadCategoria extends React.Component {
	constructor() {
		super();
		this.state = {
			news: [],
			newsList: [],
			minValue: 0,
			maxValue: 9,
			current: 1,
		};
	}

	componentDidMount() {
		//Call to webservice to get all news
		this.getNews();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	getNews = async () => {
		const { id } = this.props.match.params;
		let formData = new FormData();
		formData.append("id_categ", id);
		axios
			.post("/api/topicality_get_by_id_categ", formData)
			.then((res) => {
				this.setState({
					news: res.data.records,
				});
			})
			.catch(function (error) {});
	};
	handleChange = (value) => {
		console.log(value);
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 9,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 9 - 9,
				maxValue: value * 9,
				current: value,
			});
		}
	};
	render() {
		const title = (
			<TopImageTitle
				key="0001"
				key_id="00003"
				title="Actualidad"
				cover_image=""
				subtitle="Listado de noticias de actualidad"
				bread01="Actualidad"
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="Conoce toda la actualidad del FES"
				description="Todas las noticias del FES"
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);

		const { news } = this.state;
		const newsList = news.length ? (
			news
				.slice(+this.state.minValue, +this.state.maxValue)
				.map((news, index) => {
					return (
						<Col
							lg={4}
							sm={6}
							key={news.id}
							className={
								"new-cat d-flex category-filter category-" +
								news.categories.map((categories) => categories.id)
							}
						>
							<ActualidadResume
								key={index}
								title={news.title}
								img={news.cover_image}
								categories={news.categories}
								link={news.permalink}
								short_description={news.short_description}
								meta_description={news.meta_description}
								published_date={news.published_date}
								date={news.published_date}
							></ActualidadResume>
						</Col>
					);
				})
		) : (
			<div className="center">No hay noticias</div>
		);
		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>{newsList}</Row>
					<div>
						<div>
							<Pagination
								key={this.state.news}
								defaultCurrent={1}
								current={this.state.current}
								pageSize={9}
								onChange={this.handleChange}
								total={this.state.news.length}
							/>
						</div>
					</div>
				</Container>
			</section>
		);
	}
}

export default ActualidadCategoria;
