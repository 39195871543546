import React from "react";
import TopImageTitle from "../../components/objects/topImageTitle";
import { Row, Col, Container } from "react-grid-system";
import ReactGA from "react-ga";
import axios from "axios";

class PaymentOK extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		(async () => {
			await this.updatePayments();
		})();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	updatePayments = async () => {
		await axios
			.post("/api/cron_invoices_pdf")
			.then((res) => {})
			.catch((error) => {});
		await axios
			.post("/api/cron_comites_members_set")
			.then((res) => {})
			.catch((error) => {});
	};
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;
		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	render() {
		const title = (
			<TopImageTitle
				title="Confirmacion de Pago"
				bread01="Pago"
			></TopImageTitle>
		);

		return (
			<section>
				{title}
				<Container>
					<Row className="afiliationPage">
						<Col lg={12} sm={12}>
							<div>
								<h1 className=" center">
									Su suscripción a FES se ha realizado correctamente
								</h1>
								<h6 className="center">
									<a href="/perfil">
										Puede consultarlo desde su perfil, en la pestaña afiliación
									</a>
								</h6>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default PaymentOK;
