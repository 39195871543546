import React from 'react';
import ImageTopDefault from '../img/FES_background.jpg';
//import { Link } from 'react-router-dom';

const Image01 = props => {
  if (!props.cover_image) {
    return (
      <img
        key={props.key_id}
        src={ImageTopDefault}
        alt={props.alt_title}
        className={props.class}
      ></img>
    );
  } else {
    return (
      <img
        key={props.key_id}
        src={props.cover_image}
        alt={props.alt_title}
        className={props.class}
      ></img>
    );
  }
};

export default Image01;
