import React from "react";
import TopImageTitle from "../../objects/topImageTitle";
import { Row, Col, Container } from "react-grid-system";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { Form, Checkbox, Radio } from "antd";
import { Table, Button } from "react-bootstrap";
//import ProductResume from "../components/objects/ProductResume";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import Loader from "react-loader-spinner";

class ProductAddComites extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prod_info: props.prod_info,
			products: [],
			renovationTime: false,
			type: "",
			comites: [],
			checked: [],
			checkedPay: [],
			checkedOld: [],
			checkedPayOld: [],
			acepto: "",
			paymentMethod: "",
			paymentMethodChossen: "",
			price: "",
			doc_required: "",
			id_product: "",
			islogged: "",
			userInformation: [],
			userInfoPayments: [],
			signaturesRedsys: "",
			titleChecked: [],
			numberofTry: 0,
			checkedItemsFree: new Map(),
			checkedItemsPaid: new Map(),
			renov_id: "",
			readyToShow: false,
			fetchRenovation: false,
			iva: "",
			oldPayment: "",
			rePay: "",
			idPaymentOld: "",
			originalFreeComites: "",
		};
		this.onChangeFreeName = this.onChangeFreeName.bind(this);
		this.onChangePayName = this.onChangePayName.bind(this);
	}
	getComitesUserActual = async () => {
		var userId = this.state.userInformation.id;
		let formData = new FormData();
		formData.append("id", userId);

		await axios
			.post("/api/users_comites_current_year_get_by_id", formData)
			.then((res) => {
				this.setState({
					comitesUserGet: res.data.records,
				});
				this.checkComitesPayment(res.data.records);
			})
			.catch((error) => {});
	};
	checkComitesPayment = (data) => {
		var comitesPayRenovation = [];

		data.map((comite) => {
			comitesPayRenovation.push(comite.id);
			return null;
		});

		this.setState(() => ({
			checkedPayOld: comitesPayRenovation,

			readyToShow: true,
		}));
	};
	componentDidMount() {
		window.scrollTo(0, 0);
		(async () => {
			await this.getUserInformation();
			await this.getProductsByID();
			await this.getPaymentMethods();
			await this.getComitesUserActual();
		})();
		if (this.props.location.state === undefined) {
			return null;
		} else if (this.props.location.state.id_payment) {
			this.setState({
				renovationTime: false,
				renov_id: this.props.location.state.renov_id,
				oldPayment: this.props.location.state.oldPayment,
				rePay: this.props.location.state.rePay,
			});

			if (this.props.location.state.id_payment) {
				this.setState({
					idPaymentOld: this.props.location.state.id_payment,
				});

				this.setState({
					idPaymentOld: this.props.location.state.id_payment,
				});
			}
			this.setState({
				idPaymentOld: this.props.location.state.id_payment,
			});
		}

		if (!this.props.location.state.id_payment) {
			return <Redirect to="/404" />;
		}
		if (this.props.location.state.rePay) {
			this.setState({
				rePay: this.props.location.state.rePay,
			});
		}

		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	getUserInformation = async () => {
		const jwt = localStorage.getItem("access_token");
		//console.log("Aqui esta el jwt: ");
		//console.log(jwt);
		if (!localStorage.getItem("access_token")) {
			this.setState({
				islogged: 0,
			});
		} else {
			this.setState({
				islogged: 1,
			});
			let formData = new FormData();
			formData.append("jwt", jwt);
			axios
				.post("/api/validate_token", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						userInformation: res.data.data,
					});

					this.getComitesInOrder(res.data.data.id);
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	};
	getProductsByID = async () => {
		const id = this.props.match.params.id;
		let formData = new FormData();
		formData.append("id", id);
		await axios.post("/api/products_get_by_id", formData).then((res) => {
			this.setState({
				products: res.data.records,
				doc: res.data.records[0].doc_required,
				idtemplate: res.data.records[0].id_template,
			});
			var price00 = res.data.records[0].price;
			var doc_required00 = res.data.records[0].doc_required;
			var idProduct00 = res.data.records[0].id;
			var iva = res.data.records[0].iva;
			this.setState({
				price: price00,
				doc_required: doc_required00,
				id_product: idProduct00,
				iva: iva,
			});
		});
		axios.get("/api/comites_workgroups_get", formData).then((res) => {
			this.setState({
				comites: res.data.records,
			});
		});
	};
	getPaymentMethods = async () => {
		const { userInformation } = this.state;

		if (!localStorage.getItem("access_token")) {
		} else {
			let formData = new FormData();

			formData.append("id_user", userInformation.id);
			await axios
				.post("/api/payment_get_exceptions_by_id_user", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						userInfoPayments: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						userInfoPayments: "",
					});
				});
		}
	};
	getComitesInOrder = async (idUser) => {
		this.setState({
			fetchRenovation: true,
		});
		let formData = new FormData();

		formData.append("id", idUser);
		await axios
			.post("/api/users_comites_current_year_get_by_id", formData)
			.then((res) => {
				this.setState({
					comitesRenovation: res.data.records,
					fetchRenovation: false,
				});
				this.checkComites(res.data.records);
			})
			.catch((error) => {
				this.setState({
					fetchRenovation: false,
				});
			});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params !== prevProps.match.params) {
			(async () => {
				await this.getUserInformation();
				await this.getProductsByID();
				await this.getPaymentMethods();
			})();
		}
	}
	checkComites = (data) => {
		var comitesPayRenovation = [];
		var comitesFreeRenovation = [];

		data.map((comite) => {
			comitesFreeRenovation.push(comite.id);
			const item = comite.title;
			const isChecked = true;
			this.setState((prevState) => ({
				checkedItemsFree: prevState.checkedItemsFree.set(item, isChecked),
			}));
			//comitesPayRenovation.push(comite.idComite);
			return null;
		});

		this.setState(() => ({
			checkedOld: comitesFreeRenovation,
			checked: comitesFreeRenovation,
			checkedPayOld: comitesPayRenovation,
			originalFreeComites: comitesFreeRenovation,
			readyToShow: true,
		}));
	};

	addFreeComitesToOrder = async () => {
		if (this.state.originalFreeComites.length >= this.state.checked.length) {
			alert("Por favor añada por lo menos un comite más para continuar");
		} else {
			const myArray = this.state.checked;
			const toRemove = this.state.originalFreeComites;
			const filteredArray = myArray.filter(function (x) {
				return toRemove.indexOf(x) < 0;
			});

			var i;
			var addOk = [];
			var addKo = [];
			for (i = 0; i < filteredArray.length; i++) {
				if ((await this.addComiteWS(filteredArray[i])) === 1) {
					addOk.push(filteredArray[i]);
				} else {
					addKo.push(filteredArray[i]);
				}
			}
			if (typeof addKo !== "undefined" && addKo.length > 0) {
				alert(
					"Nos hemos encontrado con un problema y alguno de sus comites no se han añadido correctamente, intentelo de nuevo más tarde"
				);
				window.location.href = "/perfil";
			} else {
				alert(
					"Se han añadido los comites correctamente, será redirigido a su perfil."
				);
				window.location.href = "/perfil";
			}
		}
	};
	addComiteWS = async (idComite) => {
		let formData = new FormData();

		formData.append("id_comite", idComite);
		formData.append("id_user", this.state.userInformation.id);
		formData.append("id_rol", 1);
		formData.append("admin", 0);
		var status = "";
		await axios
			.post("/api/comites_members_add_comite_to_user", formData)
			.then((res) => {
				status = 1;
			})
			.catch((error) => {
				status = 0;
			});
		return status;
	};
	onChangeFree = (checkedValues) => {
		this.setState(() => {
			return { checked: checkedValues };
		});
	};
	onChangeFreeName(event) {
		const item = event.target.datatitle;
		const isChecked = event.target.checked;
		this.setState((prevState) => ({
			checkedItemsFree: prevState.checkedItemsFree.set(item, isChecked),
		}));
	}
	onChangePayName(event) {
		const item = event.target.datatitle;
		const isChecked = event.target.checked;
		this.setState((prevState) => ({
			checkedItemsPaid: prevState.checkedItemsPaid.set(item, isChecked),
		}));
	}
	onChangePayment = (checkedValues) => {
		this.setState(() => {
			return { checkedPay: checkedValues };
		});
	};
	isDisabledFree = (id) => {
		if (this.state.checkedOld.includes(id)) {
			return true;
		} else {
			return (
				this.state.checked.length > 3 && this.state.checked.indexOf(id) === -1
			);
		}
	};
	checkIfalreadyorderPay = (idComite) => {
		if (this.state.checkedPayOld.includes(idComite)) {
			return true;
		} else {
			return false;
		}
	};
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;
		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	handlePaymentProduct = (event) => {
		this.refs.btnPayment.setAttribute("disabled", "disabled");
		const free_comites = this.state.checked;
		const paid_comites = this.state.checkedPay;
		const comites_duplicate = free_comites.filter((element) =>
			paid_comites.includes(element)
		);

		if (comites_duplicate === "" || comites_duplicate.length === 0) {
			if (+this.state.paymentMethodChossen === 1) {
				const id_product = this.props.match.params.id;
				const free_comites = this.state.checked;
				const paid_comites = this.state.checkedPay;

				const { userInformation } = this.state;
				const totalAmout =
					(+this.state.price +
						global.priceSingleComite * this.state.checkedPay.length) *
					100;
				const idPaymentMethod = +this.state.paymentMethodChossen;
				const product_description = this.props.match.params.id;
				let formData = new FormData();

				formData.append("id_user", userInformation.id);
				formData.append("id_product", id_product);
				formData.append("amount", totalAmout);
				formData.append("payment_type", idPaymentMethod);
				formData.append("product_description", product_description);
				formData.append("free_comites", JSON.stringify(free_comites));
				formData.append("paid_comites", JSON.stringify(paid_comites));
				formData.append("renov_id", this.state.renov_id);
				formData.append("rePay", this.state.rePay);

				axios
					.post("/api/redsys_full_set_get_signature", formData)
					.then((res) => {
						this.setState({
							signaturesRedsys: res.data,
						});
						this.refs.btnPayment.removeAttribute("disabled");
						this.props.history.push({
							pathname: "/confirmacion-de-pago",
							state: {
								signatureRedsys: this.state.signaturesRedsys,
								checked: this.state.checked,
								checkedPay: this.state.checkedPay,
								totalAmout: totalAmout,
								paymentMethodChossen: this.state.paymentMethodChossen,
								products: this.state.products,
								goPayment: 1,
								listFreeComites: this.state.checkedItemsFree,
								listPaidComites: this.state.checkedItemsPaid,
								payment_type: 1,
								userInfo: userInformation,
								id_user: userInformation.id,
								idTemplate: this.state.idtemplate,
								renov_id: this.state.renov_id,
								iva: this.state.iva,
								oldPayment: this.state.oldPayment,
								rePay: this.state.rePay,
							},
						});
					});
			} else if (+this.state.paymentMethodChossen === 2) {
				const id_product = this.props.match.params.id;
				const { userInformation } = this.state;
				const totalAmout =
					(+this.state.price +
						global.priceSingleComite * this.state.checkedPay.length) *
					100;
				this.refs.btnPayment.removeAttribute("disabled");
				this.props.history.push({
					pathname: "/confirmacion-de-pago",
					state: {
						id_product: id_product,
						checked: this.state.checked,
						checkedPay: this.state.checkedPay,
						totalAmount01: totalAmout,
						paymentMethodChossen: this.state.paymentMethodChossen,
						products: this.state.products,
						goPayment: 2,
						payment_type: 2,
						userInfo: userInformation,
						id_user: userInformation.id,
						idTemplate: this.state.idtemplate,
						listFreeComites: this.state.checkedItemsFree,
						listPaidComites: this.state.checkedItemsPaid,
						renov_id: this.state.renov_id,
						iva: this.state.iva,
						oldPayment: this.state.oldPayment,
						rePay: this.state.rePay,
					},
				});
			} else if (+this.state.paymentMethodChossen === 3) {
				const id_product = this.props.match.params.id;
				const { userInformation } = this.state;
				const totalAmout =
					(+this.state.price +
						global.priceSingleComite * this.state.checkedPay.length) *
					100;
				this.refs.btnPayment.removeAttribute("disabled");
				this.props.history.push({
					pathname: "/confirmacion-de-pago",
					state: {
						id_product: id_product,
						checked: this.state.checked,
						checkedPay: this.state.checkedPay,
						totalAmount01: totalAmout,
						paymentMethodChossen: this.state.paymentMethodChossen,
						products: this.state.products,
						goPayment: 3,
						payment_type: 3,
						userInfo: userInformation,
						id_user: userInformation.id,
						idTemplate: this.state.idtemplate,
						listFreeComites: this.state.checkedItemsFree,
						listPaidComites: this.state.checkedItemsPaid,
						renov_id: this.state.renov_id,
						iva: this.state.iva,
						oldPayment: this.state.oldPayment,
						rePay: this.state.rePay,
					},
				});
			}
		} else {
			alert(
				"Por lo menos tiene un comité seleccionado a la vez en la opción gratuita y de pago. Compruebe los datos y vuela a intentarlo."
			);
			this.refs.btnPayment.removeAttribute("disabled");
		}
		//console.log(+this.state.price + 18 * this.state.checkedPay.length);
	};
	handleAcepto = (evt) => {
		this.setState({ acepto: evt.target.checked });
	};
	handlePaymentMethod = (evt) => {
		this.setState({ paymentMethodChossen: evt.target.value });
		this.setState({ paymentMethod: evt.target.checked });
	};
	checkIfalreadyorderFree = (idComite) => {
		if (this.state.checkedOld.includes(idComite)) {
			return true;
		} else {
			return false;
		}
	};

	render() {
		this.collapsible();

		//const { acepto, paymentMethod } = this.state;
		//const isEnabled = acepto === true && paymentMethod === true;

		const { paymentMethod } = this.state;
		const isEnabled = paymentMethod === true;
		const { products } = this.state;
		const { comites } = this.state;
		const { checkedPay } = this.state;
		const { islogged } = this.state;
		const { userInfoPayments } = this.state;
		const { userInformation } = this.state;
		//console.log(this.state.checked);
		//console.log(this.state.checkedPay);

		const listComitesGratuitos = comites.map((comite) => {
			if (+comite.active === 1 || +comite.active === 2) {
				return (
					<Col sm={12} className="comiteCheckbox" key={comite.id}>
						<Checkbox
							checked="true"
							id={"gratuito-" + comite.id}
							datatitle={comite.title}
							value={comite.id}
							disabled={this.isDisabledFree(comite.id)}
							ref={comite.title}
							onChange={this.onChangeFreeName}
						>
							{+comite.active === 2
								? comite.title + " (En construcción)"
								: comite.title}
						</Checkbox>
					</Col>
				);
			} else {
				return null;
			}
		});
		const listComitesExtra = comites.map((comite) => {
			if (+comite.active === 1 || +comite.active === 2) {
				return (
					<Col sm={12} className="comiteCheckbox" key={comite.id}>
						<Checkbox
							id={"extra-" + comite.id}
							value={comite.id}
							ref={comite.title}
							datatitle={comite.title}
							onChange={this.onChangePayName}
							checked="true"
							disabled={this.checkIfalreadyorderPay(comite.id)}
						>
							{+comite.active === 2
								? comite.title + " (En construcción)"
								: comite.title}
						</Checkbox>
					</Col>
				);
			} else {
				return null;
			}
		});
		const productInfo = products.map((prod) => {
			if (prod.type !== null) {
				return (
					<Col sm={12} key={prod.id}>
						<span>
							{this.state.renovationTime === true ? (
								<Link
									to={{
										pathname: `/perfil`,
										state: {
											key: "afiliationData",
										},
									}}
									className="buttonGoBack button"
								>
									Volver atrás
								</Link>
							) : (
								<a href="/afiliate" className="buttonGoBack button">
									Volver atrás
								</a>
							)}
						</span>
						<h1 className="product-Title">{prod.name}</h1>
						<div dangerouslySetInnerHTML={{ __html: prod.content }} />
					</Col>
				);
			} else {
				return <Redirect to="/404" />;
			}
		});
		const precioTotal = products.map((prod) => {
			return (
				<Table
					striped
					key={prod.id}
					hidden={this.props.location.state.typeOfAdd === 0 ? true : false}
				>
					<tbody>
						<tr>
							<th>Concepto</th>
							<th>Cantidad</th>
							<th>Precio unidad (IVA incluido)</th>
							<th>IVA</th>
							<th>Total</th>
						</tr>
						<tr>
							<td>{prod.name}</td>
							<td>1</td>
							<td>{prod.price}€</td>
							<td>
								{prod.iva && +prod.iva !== 0 ? prod.iva + "%" : "Exento de IVA"}
							</td>
							<td>{prod.price}€</td>
						</tr>
						<tr>
							<td>Comités/Grupos de trabajo Extras</td>
							<td>{checkedPay.length}</td>
							<td>{global.priceSingleComite}€</td>
							<td>
								{prod.iva && +prod.iva !== 0 ? prod.iva + "%" : "Exento de IVA"}
							</td>
							<td>{global.priceSingleComite * checkedPay.length}€</td>
						</tr>
						<tr>
							<td>Total</td>
							<td></td>
							<td></td>
							<td>
								{prod.iva && +prod.iva !== 0 ? prod.iva + "%" : "Exento de IVA"}
							</td>
							<td>
								{" "}
								{+prod.price + global.priceSingleComite * checkedPay.length}€
							</td>
						</tr>
					</tbody>
				</Table>
			);
		});
		const title = products.map((prod) => {
			if (prod.type !== null) {
				return <TopImageTitle title={prod.name} key={prod.id}></TopImageTitle>;
			} else {
				return null;
			}
		});

		let methodPaymentAvailable;
		if (!userInfoPayments || userInfoPayments === "") {
			methodPaymentAvailable = (
				<span>
					<Radio.Button
						value="1"
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
					>
						Tarjeta
					</Radio.Button>
					<Radio.Button
						value="2"
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
					>
						Transferencia
					</Radio.Button>
					<Radio.Button
						value="3"
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
					>
						Domiciliación (SEPA)
					</Radio.Button>
				</span>
			);
		} else if (userInfoPayments) {
			methodPaymentAvailable = userInfoPayments.map((pay) => {
				return (
					<Radio.Button
						value={pay.id}
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
						key={pay.id}
					>
						{pay.name}
					</Radio.Button>
				);
			});
		}
		let messageLogin;
		let buttonPay;
		if (+islogged === 0) {
			buttonPay = (
				<p>
					Debe hacer{" "}
					<Link
						to={{
							pathname: `/login`,
							state: {
								origin: 0,
								url_permalink: "producto/" + this.props.match.params.id,
							},
						}}
					>
						Login/Registrarse
					</Link>{" "}
					para poder afiliarse. Si ya tiene una cuenta de usuario, acceda con
					sus datos de registro. Si es nuevo en la web de FES, dese de alta.
				</p>
			);
			messageLogin = (
				<div className="alert alert-warning" role="alert">
					<p>
						Debe hacer{" "}
						<Link
							to={{
								pathname: `/login`,
								state: {
									origin: 0,
									url_permalink: "producto/" + this.props.match.params.id,
								},
							}}
						>
							Login/Registrarse
						</Link>{" "}
						para poder afiliarse. Si ya tiene una cuenta de usuario, acceda con
						sus datos de registro. Si es nuevo en la web de FES, dese de alta.
					</p>
				</div>
			);
		} else if (+islogged === 1) {
			if (
				+userInformation.id_type === +4 ||
				+userInformation.id_type === +5 ||
				+userInformation.id_type === +7 ||
				+userInformation.id_type === +6 ||
				+userInformation.id_type === +12 ||
				+userInformation.id_type === +14 ||
				+userInformation.id_type === +10
			) {
				buttonPay = (
					<Button
						disabled={!isEnabled}
						className="button"
						variant="primary"
						type="submit"
						ref="btnPayment"
					>
						Ir a pago
					</Button>
				);
			} else {
				if (
					!userInformation.name ||
					!userInformation.lastname ||
					!userInformation.nif ||
					!userInformation.city ||
					!userInformation.org ||
					!userInformation.region ||
					!userInformation.education ||
					!userInformation.academic_degree
				) {
					buttonPay = (
						<div className="alert alert-warning" role="alert">
							<p>
								Si desea realizar una afiliación, debe primero completar los
								datos requeridos como obligatorios en su cuenta de perfil:
								<Link to="/perfil"> ir a Mi cuenta.</Link>
							</p>
						</div>
					);
					messageLogin = (
						<div className="alert alert-warning" role="alert">
							<p>
								Si desea realizar una afiliación, debe primero completar los
								datos requeridos como obligatorios en su cuenta de perfil:
								<Link to="/perfil"> ir a Mi cuenta.</Link>
							</p>
						</div>
					);
				} else {
					buttonPay = (
						<Button
							disabled={!isEnabled}
							className="button"
							variant="primary"
							type="submit"
							ref="btnPayment"
						>
							Ir a pago
						</Button>
					);
				}
			}
		}
		let allProductInfo;
		if (+islogged === 0) {
			allProductInfo = null;
		} else {
			allProductInfo = (
				<Form onFinish={this.handlePaymentProduct}>
					<div>
						<Checkbox.Group
							style={{ width: "100%" }}
							onChange={this.onChangeFree}
							value={this.state.checked}
							hidden={this.props.location.state.typeOfAdd === 1 ? true : false}
						>
							<Row className="listcomites">{listComitesGratuitos}</Row>
						</Checkbox.Group>
						<hr></hr>
						<p
							hidden={this.props.location.state.typeOfAdd === 0 ? true : false}
						>
							<b>
								{" "}
								A continuación puede escoger más comités / grupos de trabajo,
								tenga en cuenta que a los comites / grupos de trabajo que ya
								pertenezca no serán seleccionables (se pagará{" "}
								{global.priceSingleComite}€ por comité)
							</b>
						</p>
						<Checkbox.Group
							style={{ width: "100%" }}
							onChange={this.onChangePayment}
							value={this.state.checkedPay}
							hidden={this.props.location.state.typeOfAdd === 0 ? true : false}
						>
							<Row className="listcomites">{listComitesExtra}</Row>
						</Checkbox.Group>
						<hr></hr>
						{precioTotal}
						<div
							hidden={this.props.location.state.typeOfAdd === 0 ? true : false}
						>
							<p>Elija el método de pago que prefiera:</p>
							<Radio.Group buttonStyle="solid">
								{methodPaymentAvailable}
							</Radio.Group>
							<hr></hr>
						</div>
						{this.props.location.state.typeOfAdd === 0 ? "" : buttonPay}
					</div>
					{this.props.location.state.typeOfAdd === 0 ? (
						<Button
							className="button"
							variant="primary"
							ref="btnPayment"
							onClick={this.addFreeComitesToOrder}
						>
							Añadir comites gratuitos
						</Button>
					) : (
						""
					)}
				</Form>
			);
		}
		return (
			<div>
				{title}
				{this.state.fetchRenovation === false ? (
					<Container>
						<Row>
							<Col sm={12}>{messageLogin}</Col>
						</Row>
						<Row>
							<Col>
								{this.state.renovationTime === true ? (
									<div className="alert alert-info" role="alert">
										<p>
											Está en proceso de renovación de afiliación: le aparecen
											marcados los comités a los que se afilió el año anterior.
											Puede cambiarlos si lo desea. Elija después el método de
											pago con el que quiere renovar y pulse el botón "IR AL
											PAGO"
										</p>
									</div>
								) : null}
							</Col>
						</Row>
						<Row>{this.state.idPaymentOld === "" ? "" : productInfo}</Row>

						{this.state.idPaymentOld === "" ? (
							<p>
								Para poder comprar más comités es necesario que vaya a la
								sección pedidos en su perfil y haga click en añadir mas comités
								en el pedido que tenga la opción disponible{" "}
							</p>
						) : (
							allProductInfo
						)}
					</Container>
				) : (
					<div className="centerloader">
						<Loader type="Oval" color="#00BFFF" height={100} width={100} />
						<h3>Cargando información</h3>
					</div>
				)}
			</div>
		);
	}
}
export default withRouter(ProductAddComites);
