import React, { Component } from "react";
import { Row, Col, Table } from "react-bootstrap";
import axios from "axios";

//import { Link } from 'react-router-dom';
class BetCanVot extends Component {
	constructor(props) {
		super(props);
		this.state = { availiableComites: [] };
	}
	getAvailiableComites = async () => {
		let formData = new FormData();
		formData.append("id_votes", this.props.idVotation);
		formData.append("id_user", this.props.idUser);
		await axios
			.post("/api/votes_comites_and_candidates_current_year_get", formData)
			.then((res) => {
				console.log(res.data.records);
				this.setState({
					availiableComites: res.data.records,
				});
			})
			.catch((error) => {});
	};
	componentDidMount() {
		(async () => {
			await this.getAvailiableComites();
		})();
	}

	render() {
		const allComitesToVote = this.state.availiableComites.map((AC, index) => {
			if (AC.candidates) {
				return (
					<Row key={index} className="BoxComitePresentCan">
						<Col lg={12} sm={12}>
							<p className="titleComitePresentCan center">{AC.title}</p>
						</Col>
						<Table striped bordered hover className="width-100">
							<thead>
								<tr>
									<th>Candidato/a</th>
									<th>Programa de trabajo</th>
								</tr>
							</thead>
							<tbody>
								{AC.candidates.map((cand, index) => {
									return (
										<tr key={index}>
											<td>{cand.fullname}</td>
											<td>
												<a
													href={cand.doc}
													target="_blank"
													rel="noopener noreferrer"
												>
													Ver Programa
												</a>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Row>
				);
			}
			return null;
		});
		return (
			<Row>
				<Col lg={12} sm={12}>
					<h4 className="center">
						La lista preliminar estará disponible del {this.props.dateBegin} al{" "}
						{this.props.dateEnd}.
					</h4>
					<p className="center">
						A partir del {this.props.dateEnd} podrá votar a los candidatos en
						esta página
					</p>
				</Col>
				<Col lg={12}>{allComitesToVote}</Col>
			</Row>
		);
	}
}

export default BetCanVot;
