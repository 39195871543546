import React, { Component } from "react";
import "./css/frontal.css";

import Actividades from "../components/Actividades";
import Top3 from "../components/HomePage/top3";
//import Introduction from "../components/HomePage/introduction";
import FeaturedNews from "../components/HomePage/featuredNews";
//import LastMinuteNews from "../components/HomePage/lastMinuteNews";
import LastMinutesNews from "../components/HomePage/lastMinutesNews";
import TopicOfTheMonth from "../components/HomePage/topicOfTheMonth";
import Topics2 from "../components/HomePage/topics2";
import MediaContent from "../components/HomePage/mediaContent";
import BannerAd from "../components/HomePage/bannerAd";
import "../components/style/styles.css";
//import 'react-animated-slider/build/horizontal.css';
import SeoHome from "../components/HomePage/seoHome";
import ReactGA from "react-ga";

class Homepage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	render() {
		return (
			<section>
				<SeoHome />
				<Actividades />
				<Top3 />
				<BannerAd />
				{/*<Introduction/>*/}
				<FeaturedNews />
				<LastMinutesNews />
				<TopicOfTheMonth />
				<Topics2 />
				<MediaContent />
			</section>
		);
	}
}
export default Homepage;
