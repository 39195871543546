import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
//import { Link } from 'react-router-dom';
import axios from "axios";

class TabInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentNew: this.props.contetComite,
			moreInfo: this.props.moreInfoComite,
		};
	}
	handleMoreInfo = (event) => {
		event.preventDefault();
		let formData = new FormData();

		formData.append("id", this.props.idComite);
		formData.append("content", this.state.contentNew);
		formData.append("more_info_html", this.state.moreInfo);
		axios
			.post("/api/comites_info_update", formData)
			.then((res) => {
				alert("Informacion actualizada correctamente");
			})
			.catch((error) => {
				alert("No se ha podido actualizar la información");
			});
	};
	handleEditorChangeNewContent = (content, editor) => {
		this.setState({
			contentNew: content,
		});
	};
	handleEditorChangeMoreInfo = (content, editor) => {
		this.setState({
			moreInfo: content,
		});
	};
	render() {
		return (
			<div>
				<Form onSubmit={this.handleMoreInfo}>
					<Row>
						<Col lg={12} sm={12}>
							<Form.Group controlId="contentNews">
								<Form.Label>Contenido del apartado Inicio</Form.Label>
								<Editor
									apiKey={global.editorApiKey}
									init={{
										image_title: true,
										/* enable automatic uploads of images represented by blob or data URIs*/
										automatic_uploads: true,
										/*
										URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
										images_upload_url: 'postAcceptor.php',
										here we add custom filepicker only to Image dialog
									*/
										file_picker_types: "image",
										images_upload_handler: function (
											blobInfo,
											success,
											failure
										) {
											setTimeout(function () {
												//console.log(this);
												//	console.log(blobInfo.blob());
												//console.log(blobInfo.filename());
												let formData = new FormData();
												formData.append("image", blobInfo.blob());
												axios
													.post("/api/upload_image.php", formData)
													.then((res) => {
														//console.log(res);
														success(res.data);
													})
													.catch((error) => {
														//	console.log(error.response);
														failure("Error al subir la imagen");
													});
											}, 3000);
										},
										height: 500,
										menubar: false,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount link image code",
										],
										contextmenu: false,
										toolbar:
											"undo redo | formatselect | link bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | link image",
									}}
									initialValue={this.props.contetComite}
									onEditorChange={this.handleEditorChangeNewContent}
								/>
							</Form.Group>
						</Col>
					</Row>
					<hr></hr>
					<Row>
						<Col lg={12} sm={12}>
							<Form.Group controlId="contentNews">
								<Form.Label>Contenido del apartado Más información</Form.Label>
								<Editor
									apiKey={global.editorApiKey}
									init={{
										image_title: true,
										/* enable automatic uploads of images represented by blob or data URIs*/
										automatic_uploads: true,
										/*
										URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
										images_upload_url: 'postAcceptor.php',
										here we add custom filepicker only to Image dialog
									*/
										file_picker_types: "image",
										images_upload_handler: function (
											blobInfo,
											success,
											failure
										) {
											setTimeout(function () {
												//console.log(this);
												//	console.log(blobInfo.blob());
												//console.log(blobInfo.filename());
												let formData = new FormData();
												formData.append("image", blobInfo.blob());
												axios
													.post("/api/upload_image.php", formData)
													.then((res) => {
														//console.log(res);
														success(res.data);
													})
													.catch((error) => {
														//	console.log(error.response);
														failure("Error al subir la imagen");
													});
											}, 3000);
										},
										height: 500,
										menubar: false,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste code help wordcount link image code",
										],
										contextmenu: false,
										toolbar:
											"undo redo | formatselect | link bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | link image",
									}}
									onEditorChange={this.handleEditorChangeMoreInfo}
									initialValue={this.props.moreInfoComite}
								/>
							</Form.Group>
						</Col>
						<Col sm={12} xs={12}>
							<Button ref="btnMoreInfo" variant="primary" type="submit">
								Actualizar información
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		);
	}
}

export default TabInfo;
