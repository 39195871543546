import React, { Component } from "react";
import { Row, Col, Container } from "react-grid-system";
import { Form, Button } from "react-bootstrap";
import contactImage from "../components/img/contact_us_fes.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import ReactGA from "react-ga";

class Contacto extends Component {
	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	handleSubmitContact = (event) => {
		event.preventDefault();

		var nameError = 0;
		var emailError = 0;
		var messageError = 0;
		var tycError = 0;
		let formData = new FormData();
		if (event.target.nameUser.value === "") {
			nameError = "Necesita rellenar el nombre";
			//alert(nameError);
		} else if (event.target.emailUser.value === "") {
			emailError = "Necesita rellenar email";
			//alert(dniError);
		} else if (event.target.messageUser.value === "") {
			messageError = "Necesita rellenar el mensaje";
			//alert(bornCountryUserError);
		} else if (event.target.tyc.value === "") {
			tycError = "Necesita confirmar los terminos y condiciones";
			//alert(cityUserError);
		}

		if (
			nameError !== 0 ||
			emailError !== 0 ||
			messageError !== 0 ||
			tycError !== 0
		) {
			alert("Hay campos obligatorios sin rellenar");
		} else {
			formData.append("name", event.target.nameUser.value);
			formData.append("email", event.target.emailUser.value);
			formData.append("msg", event.target.messageUser.value);
			formData.append("tyc", event.target.tyc.value);

			axios
				.post("/api/contact_form_set.php", formData)
				.then((response) => {
					//console.log(res);
					if (response.status === 200) {
					}
					alert("Se ha enviado su mensaje correctamente;");
				})
				.catch((error) => {
					alert("No se ha podido enviar el mensaje");
				});
		}
	};
	render() {
		return (
			<div className="margin-header">
				<hr className="whiteSpace"></hr>
				<Container style={{ marginBottom: "45px" }}>
					<Row>
						<Col lg={6} sm={12}>
							<img
								className="width-100"
								src={contactImage}
								alt="Contacto del FES"
							></img>
						</Col>
						<Col lg={6} sm={12}>
							<h2 className="center">Formulario de Contacto</h2>

							<Form onSubmit={this.handleSubmitContact}>
								<Form.Group controlId="emailUser">
									<Form.Label>Email*</Form.Label>
									<Form.Control
										require="true"
										type="email"
										name="emailUser"
										placeholder="email"
									/>
								</Form.Group>
								<Form.Group controlId="nameUser">
									<Form.Label>Nombre*</Form.Label>
									<Form.Control
										type="text"
										placeholder="Nombre"
										name="nameUser"
										require="true"
									/>
								</Form.Group>
								<Form.Group controlId="messageUser">
									<Form.Label>Mensaje*</Form.Label>
									<Form.Control
										require="true"
										as="textarea"
										rows="3"
										name="messageUser"
										placeholder="email"
									/>
								</Form.Group>
								<Form.Group controlId="tyc">
									<Form.Check
										name="tyc"
										type="checkbox"
										value="1"
										label="Acepto los terminos y condiciones"
										require="true"
									/>
								</Form.Group>
								<Button variant="primary" type="submit" className="button">
									Enviar
								</Button>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col lg={6} sm={12}>
							<h3>Federación Española de Sociología</h3>
							<p>
								<b>Dirección:</b> Centro de Investigaciones Sociológicas (CIS)
								<br></br>
								C/ Montalbán, 8<br></br>
								28014 Madrid
							</p>
							<p>
								<b>Teléfono:</b>{" "}
								<a href="tel:+34 629 27 19 61">+34 629 27 19 61</a>
							</p>
							<p>
								<b>Email:</b>{" "}
								<a href="mailto:info@fes-sociologia.com">
									info@fes-sociologia.com
								</a>
							</p>
							<p>
								<b>Horario de atención telefónica:</b> 9-14:00 h.
							</p>
							<h3>Redes Sociales</h3>
							<a href="https://www.facebook.com/federacionespanoladesociologia/">
								<FontAwesomeIcon
									icon={fab.faFacebookF}
									size="2x"
									className="socialIconsComite"
								/>
							</a>
							<a href="https://twitter.com/fes_sociologia">
								<FontAwesomeIcon
									icon={fab.faTwitter}
									size="2x"
									className="socialIconsComite"
								/>
							</a>
							<a href="https://www.youtube.com/channel/UCwpCH36H2KCeyXrzeygUr-A">
								<FontAwesomeIcon
									icon={fab.faYoutube}
									size="2x"
									className="socialIconsComite"
								/>
							</a>
						</Col>
						<Col lg={6} sm={12}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1518.795718195458!2d-3.6910791280649744!3d40.417900913515325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42289c7d76717b%3A0xca78a4bbd9657683!2sFederaci%C3%B3n%20Espa%C3%B1ol%20de%20Sociolog%C3%ADa!5e0!3m2!1ses!2ses!4v1582300631784!5m2!1ses!2ses"
								width="420"
								height="300"
								frameBorder="0"
								allowFullScreen=""
								title="Mapa de donde esta el FES"
							></iframe>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Contacto;
