import React from "react";
import axios from "axios";
import PageTemplate01 from "../components/templates/pages/pageTemplates01";
import PageTemplate02 from "../components/templates/pages/pageTemplates02";
import PageTemplate03 from "../components/templates/pages/pageTemplates03";
import ListUserTemplate01 from "../components/templates/pages/listUserTemplate01";
import FormationTemplate01 from "../components/templates/pages/formationTemplates01";
import ConstructionTemplate01 from "../components/templates/pages/constructionTemplates01";
import AsociacionTemplate01 from "../components/templates/pages/asociacionTemplates01";
import OtrasPublicacionesTemplate01 from "../components/templates/pages/otraspublicacionesTemplate01";
import ConferenciasEventosTemplate01 from "../components/templates/pages/conferenciaseventosTemplate01";
import MultimediaTemplate from "../components/templates/pages/multimediaTemplate";
import { Redirect } from "react-router-dom";
import ActualidadTemplate from "../components/templates/pages/actualidadTemplate";
import NoticesTemplate01 from "../components/templates/pages/noticesTemplate01";
import EstudiosTemplate01 from "../components/templates/pages/estudiosTemplate";
import ProfesionTemplate01 from "../components/templates/pages/profesionTemplate";
import PracticaSociologicaTemplate01 from "../components/templates/pages/practicaSociologicaTemplate";
import EscuelaDoctoradoTemplate01 from "../components/templates/pages/escuelaDoctoradoTemplate01";
import FormacionActividades01 from "../components/templates/pages/formacionActividades";
import Internacionalizacion01 from "../components/templates/pages/internacionalizacion01";
import SerieSurEuropa from "../components/templates/pages/serieSurEuropa";
import Comites from "../Scenes/comites";
import TesisDoctoral from "../components/templates/pages/tesisDoctoralTemplate";
import SeriesMonografiaTemplate from "../components/templates/pages/seriesMonografiaTemplate";
import TabTemplate from "../components/templates/pages/tabTemplate";
import TabsTemplate from "../components/templates/pages/tabsTemplate";
import TwoColumnsTemplate from "../components/templates/pages/twoColumnTemplate";
import DefaultNews from "../components/templates/pages/defaultNews";
import Afiliate from "../Scenes/afiliate";
class Page01 extends React.Component {
	state = {
		posts: [],
		page: [],
		statusPage: 0,
		permalink: "",
		istab: false,
	};
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const { page } = this.props.match.params;
		const { subpage } = this.props.match.params;

		let formData = new FormData();
		if (subpage) {
			formData.append("permalink", subpage);
			this.setState({
				permalink: subpage,
			});
		} else if (page) {
			formData.append("permalink", page);
			this.setState({
				permalink: page,
			});
		}

		//Call to webservice to get the new

		axios
			.post("/api/pages_get_by_permalink", formData)
			.then((res) => {
				this.setState({
					posts: res.data.records.slice(0, 1),
				});
				var id_menu = res.data.records[0].id_menu;
				var id_menu_call = new FormData();
				id_menu_call.append("id_menu", id_menu);
				axios
					.post("/api/pages_get_by_id_menu", id_menu_call)
					.then((res) => {
						this.setState({
							page: res.data.records,
						});
					})
					.catch(function (error) {});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);

		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";

		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			const { page } = this.props.match.params;
			const { subpage } = this.props.match.params;
			let formData = new FormData();
			if (subpage) {
				formData.append("permalink", subpage);
				this.setState({
					permalink: subpage,
				});
			} else if (page) {
				formData.append("permalink", page);
				this.setState({
					permalink: page,
				});
			}
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			//Call to webservice to get the new
			axios
				.post("/api/pages_get_by_permalink", formData)
				.then((res) => {
					this.setState({
						posts: res.data.records.slice(0, 1),
					});
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							page: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	}

	render() {
		const { posts } = this.state;
		const { page } = this.state;
		const { statusPage } = this.state;
		const { permalink } = this.state;

		if (statusPage === 1) {
			return <Redirect to="/404" />;
		} else {
			const templateSelection = posts.map((post, index) => {

				//console.log(post.id_template)

				//Template 5 this is the left side sidebar
				if (+post.id_template === 5) {
					return (
						<PageTemplate02
							key={"template02" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></PageTemplate02>
					);
				} //Template default
				else if (+post.id_template === 1) {
					return (
						<PageTemplate01
							key={"template01" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></PageTemplate01>
					);
				} else if (+post.id_template === 3) {
					return (
						<PageTemplate03
							key={"template03" + post.id}
							array_post={posts}
							array_page={page}
						></PageTemplate03>
					);
				} else if (+post.id_template === 6) {
					return (
						<ListUserTemplate01
							key={"listUserTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></ListUserTemplate01>
					);
				} else if (+post.id_template === 7) {
					return (
						<FormationTemplate01
							key={"formationTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></FormationTemplate01>
					);
				} else if (+post.id_template === 8) {
					return (
						<ConstructionTemplate01
							key={"constructionTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></ConstructionTemplate01>
					);
				} else if (+post.id_template === 9) {
					return (
						<AsociacionTemplate01
							key={"asociationTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></AsociacionTemplate01>
					);
				} else if (+post.id_template === 10) {
					return (
						<OtrasPublicacionesTemplate01
							key={"asociationTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></OtrasPublicacionesTemplate01>
					);
				} else if (+post.id_template === 11) {
					return (
						<ConferenciasEventosTemplate01
							key={"asociationTemplate12" + post.id}
							array_post={posts}
							pages={page}
							permalink={permalink}
						></ConferenciasEventosTemplate01>
					);
				} else if (+post.id_template === 12) {
					return (
						<MultimediaTemplate
							key={"asociationTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></MultimediaTemplate>
					);
				} else if (+post.id_template === 13) {
					return (
						<ActualidadTemplate
							key={"asociationTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></ActualidadTemplate>
					);
				} else if (+post.id_template === 14) {
					return (
						<NoticesTemplate01
							key={"asociationTemplate" + post.id}
							array_post={posts}
							array_page={page}
						></NoticesTemplate01>
					);
				} else if (+post.id_template === 16) {
					return (
						<EstudiosTemplate01
							key={"RecursosTemplate01" + post.id}
							array_post={posts}
							array_page={page}
						></EstudiosTemplate01>
					);
				} else if (+post.id_template === 17) {
					return (
						<ProfesionTemplate01
							key={"RecursosTemplate01" + post.id}
							array_post={posts}
							array_page={page}
						></ProfesionTemplate01>
					);
				} else if (+post.id_template === 18) {
					return (
						<PracticaSociologicaTemplate01
							key={"RecursosTemplate01" + post.id}
							array_post={posts}
							array_page={page}
						></PracticaSociologicaTemplate01>
					);
				} else if (+post.id_template === 19) {
					return (
						<EscuelaDoctoradoTemplate01
							key={"RecursosTemplate01" + post.id}
							array_post={posts}
							array_page={page}
						></EscuelaDoctoradoTemplate01>
					);
				} else if (+post.id_template === 20) {
					return (
						<FormacionActividades01
							key={"FormacionActividades01" + post.id}
							array_post={posts}
							array_page={page}
						></FormacionActividades01>
					);
				} else if (+post.id_template === 21) {
					return (
						<Internacionalizacion01
							key={"Internacionalizacion01" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></Internacionalizacion01>
					);
				} else if (+post.id_template === 22) {
					return (
						<SerieSurEuropa
							key={"Internacionalizacion01" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></SerieSurEuropa>
					);
				} else if (+post.id_template === 30) {
					return (
						<Comites
							key={"Comites" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></Comites>
					);
				} else if (+post.id_template === 24) {
					return (
						<TesisDoctoral
							key={"Comites" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></TesisDoctoral>
					);
				} else if (+post.id_template === 25) {
					return (
						<SeriesMonografiaTemplate
							key={"Comites" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></SeriesMonografiaTemplate>
					);
				} else if (+post.id_template === 29) {
					return (
						<TwoColumnsTemplate
							key={"Comites" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></TwoColumnsTemplate>
					);
				} else if (+post.id_template === 27) {
					return (
						<DefaultNews
							key={"Comites" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></DefaultNews>
					);
				} else if (+post.id_template === 28) {
					return (
						<TabsTemplate
							key={"Comites" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></TabsTemplate>
					);
				} else if (+post.id_template === 31) {
					return (
						<Afiliate
							key={"Comites" + post.id}
							array_post={posts}
							array_page={page}
							permalink={permalink}
						></Afiliate>
					);
				} else {
					return (
						<TabTemplate
							key={"tabTemplate" + post.id}
							prod_info={page}
							array_post={posts}
							array_page={page}
							permalink={permalink}
							templateID={post.id_template}
						></TabTemplate>
					);
				}
			});

			this.collapsible();
			return <section>{templateSelection}</section>;
		}
	}
}

export default Page01;
