import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ImageDefault from "../img/noticias_default.jpg";

class LastNews extends Component {
	state = {
		posts: [],
	};
	componentDidMount() {
		window.scrollTo(0, 0);

		//Call to webservice to get all news
		axios.get("/api/lectures_get_all").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				posts: res.data.records.slice(0, 3),
			});
		});
	}

	render() {
		const { posts } = this.state;
		const postList = posts.length ? (
			posts.map((post) => {
				if (!post.cover_image) {
					return (
						<div key={post.id} className="inner center widget">
							<div>
								<div className="widgetImage">
									<img src={`${ImageDefault}`} alt={`${post.title}`} />
								</div>
								<div className="widgetDetails">
									<Link to={`/conferencias-eventos${post.permalink}`}>
										{post.title}
									</Link>
								</div>
							</div>
						</div>
					);
				} else {
					return (
						<div key={post.id} className="inner center widget">
							<div>
								<div className="widgetImage">
									<img src={`${post.cover_image}`} alt={`${post.title}`} />
								</div>
								<div className="widgetDetails">
									<Link to={`/conferencias-eventos${post.permalink}`}>
										{post.title}
									</Link>
								</div>
							</div>
						</div>
					);
				}
			})
		) : (
			<div className="center">No hay noticias de última hora</div>
		);
		return (
			<section>
				<div>
					<div className="lastNews">
						<h5 className="titleSidebar">Noticias más recientes</h5>
					</div>
					<div>{postList}</div>
				</div>
			</section>
		);
	}
}

export default LastNews;
