import React from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import Contacto from "../components/WidgetsSidebar/contacto";
import SEO from "../components/objects/seo";
import MenuPageSidebar from "../components/objects/menuPageSidebar";
import TopImageTitle from "../components/objects/topImageTitle";
import ButtonFES from "../components/objects/button";

class Page extends React.Component {
	state = {
		posts: [],
		page: [],
	};
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		const { permalink } = this.props.match.params;
		let formData = new FormData();
		formData.append("permalink", "/page/" + permalink);
		//Call to webservice to get the new
		axios
			.post("/api/pages_get_by_permalink", formData)
			.then((res) => {
				//console.log(res);
				this.setState({
					posts: res.data.records.slice(0, 1),
				});
				var id_menu = res.data.records[0].id_menu;
				var id_menu_call = new FormData();
				id_menu_call.append("id_menu", id_menu);
				axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
					this.setState({
						page: res.data.records,
					});
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const { permalink } = this.props.match.params;
		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();
			formData.append("permalink", "/page/" + permalink);
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			//Call to webservice to get the new
			axios
				.post("/api/pages_get_by_permalink", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						posts: res.data.records.slice(0, 1),
					});
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							page: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	}

	render() {
		const { posts } = this.state;
		const { page } = this.state;

		const sidebarMenuPage = (
			<MenuPageSidebar menu_title="Menú" page_array={page}></MenuPageSidebar>
		);

		const title = posts.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.cover_image}
					subtitle={post.subtitle}
					bread01={post.title}
				></TopImageTitle>
			);
		});

		const notice = posts.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});

		const MetaTagsSeo = posts.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.cover_image}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		const ButtonDownloadDoc = posts.map((post) => {
			if (!post.doc) {
				return null;
			} else {
				return (
					<ButtonFES
						button_text="Descargar Documento"
						button_class="button"
						button_link={post.doc}
					></ButtonFES>
				);
			}
		});

		const ChooseTemplate = posts.map((post) => {
			//Template default sidebar left side
			if (+post.id_template === 5) {
				return (
					<div key={post.id}>
						{MetaTagsSeo}
						{title}
						<hr className="whiteSpace"></hr>
						<Container>
							<Row className="lastMinuteNews">
								<Col className="sidebar" lg={3} md={12}>
									<Contacto />
									{ButtonDownloadDoc}
									{sidebarMenuPage}
								</Col>
								<Col lg={9} md={12}>
									{notice}
								</Col>
							</Row>
						</Container>
					</div>
				);
			} else {
				return (
					<div key={post.id}>
						{MetaTagsSeo}
						{title}
						<hr className="whiteSpace"></hr>
						<Container>
							<Row className="lastMinuteNews">
								<Col lg={9} md={12}>
									{notice}
								</Col>
								<Col className="sidebar" lg={3} md={12}>
									{ButtonDownloadDoc}
									<Contacto />
									{sidebarMenuPage}
								</Col>
							</Row>
						</Container>
					</div>
				);
			}
		});
		this.collapsible();
		return <section>{ChooseTemplate}</section>;
	}
}

export default Page;
