import React, { Component } from "react";
import { Container } from "react-grid-system";
import "../styles.css";
import "../style/styles_bannerAD.css";
import axios from "axios";
import SEO from "../objects/seo";

class SeoHome extends Component {
	//State For banner home
	state = {
		seoHome: [],
	};
	componentDidMount() {
		//Call webservice get banner
		axios.get("/api/home_seo_get").then((res) => {
			//Banner data
			this.setState({
				seoHome: res.data.records,
			});
		});
	}

	render() {
		const { seoHome } = this.state;
		//Banner display
		const seolist = seoHome.length ? (
			seoHome.map((seo) => {
				return (
					<SEO
						title={seo.meta_title}
						description={seo.meta_description}
						keywords={seo.keywords}
						key={"SEO" + seo.id}
					></SEO>
				);
			})
		) : (
			//banner not set
			<SEO
				title="FES - Federación Española de Sociología"
				description="La FES es la sociedad científica de la sociología española que congrega, de uno u otro modo, a más de 3.000 sociólogos españoles."
				key={"SEO"}
			></SEO>
		);
		return <Container>{seolist}</Container>;
	}
}

export default SeoHome;
