import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import "../style/styles_top3.css";

class ActividadesSidebar extends Component {
	state = {
		categories: [],
	};
	componentDidMount() {
		//Call to webservice to get all news
		axios.get("/api/get_all_categories").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
	}

	render() {
		const { categories } = this.state;
		var listing = 0;
		const listCategory = categories.length ? (
			categories.map((categories) => {
				if (categories.id === "40" || categories.id === "41") {
					listing = listing + 1;

					return (
						<li key={categories.id}>
							<div className="numberListing"> {("0" + listing).slice(-2)}</div>
							<Link to={"/actividades/"}>{categories.name}</Link>
						</li>
					);
				} else {
					return <span></span>;
				}
			})
		) : (
			<div className="center">No hay post</div>
		);
		return (
			<div className="categoryListing">
				<h5 className="titleSidebar">Menú de Actividades</h5>
				<div>
					<ul className="sidebarListingNumber">{listCategory}</ul>
				</div>
			</div>
		);
	}
}

export default ActividadesSidebar;
