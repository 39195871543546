import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import { Redirect } from "react-router-dom";
import MultimediaResume from "../../objects/multimediaResume";
import ReactGA from "react-ga";
import { Pagination } from "antd";
class MultimediaTemplate extends Component {
	constructor(props) {
		super();
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			filterCat: [],
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 10,
			years: [],
			activeId: null,
			minValue: 0,
			maxValue: 10,
			current: 1,
			currentTodos: [],
			_news: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}
	handleChange = (value) => {
		console.log(value);
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 10,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 10 - 10,
				maxValue: value * 10,
				current: value,
			});
		}
	};
	changeFilterCat = (e) => {
		console.log(e.target.value);
		var catSelected = e.target.value;
		const notWantedCategories = global.notWantedCategoriesMulti;
		var GoodNews = [];
		this.state.news.map((ne, index) => {
			var idcat = ne.categories.map((categories) => categories.id);

			if (notWantedCategories.some((r) => idcat.includes(r))) {
				let intersection = notWantedCategories.filter((x) => idcat.includes(x));
				if (intersection.length !== idcat.length) {
					GoodNews.push(ne);
					return null;
				}
				return null;
			} else {
				GoodNews.push(ne);
				return null;
			}
		});
		let _news = GoodNews;

		if (catSelected) {
			_news = _news.filter(
				(item) =>
					+item.categories.map((categories) => categories.id) === +catSelected
			);
		}
		console.log(_news);
		this.setState({
			filterCat: e.target.value,
			minValue: 0,
			maxValue: 9,
			current: 1,
			activeId: e.target.value,
			_news: _news,
		});
	};

	componentDidMount() {
		//Call to webservice to get the new

		axios
			.get("/api/multimedia_get_all")
			.then((res) => {
				//console.log(res);
				this.setState({
					news: res.data.records,
					_news: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});

		axios.get("/api/multimedia_categories_get_all").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { statusPage } = this.state;
		const { array_post } = this.state;

		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}

		//	const { years } = this.state;
		//const { news } = this.state;

		const { categories } = this.state;
		//const { news } = this.state;

		var catCount = 0;
		const categoriesList = categories.length ? (
			categories.map((categories) => {
				if (+categories.id === 7) {
					return null;
				} else {
					catCount++;
					return (
						<Col
							lg={4}
							sm={6}
							key={categories.id}
							className={"categories-button category-" + categories.id}
						>
							<button
								className={
									"filterCategories " +
									(this.state.activeId === categories.id && "button")
								}
								onClick={this.changeFilterCat}
								value={categories.id}
							>
								{categories.name}
							</button>
						</Col>
					);
				}
			})
		) : (
			<div className="center">No hay categorias</div>
		);
		const newsList = this.state._news
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((news, index) => {
				var idcat = news.categories.map((categories) => categories.id);
				//Array de categorias que no queremos mostrar
				const catWant = ["6"];
				if (catWant.some((r) => idcat.includes(r)) || idcat.length < 1) {
					return (
						<Col
							lg={4}
							sm={6}
							key={news.id}
							className={
								"new-cat d-flex category-filter category-" +
								news.categories.map((categories) => categories.id)
							}
						>
							<MultimediaResume
								key={index}
								title={news.title}
								img={news.cover_image}
								categories={news.categories}
								link={news.permalink}
								short_description={news.short_description}
								date={news.published_date}
								meta_description={news.meta_description}
							></MultimediaResume>
						</Col>
					);
				} else {
					return null;
				}
			});

		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col sm={12} xs={12}>
							<Col sm={12}>
								{catCount <= 1 ? null : (
									<Col sm={4} className={"categories-button category-"}>
										<h5 className="center">Selecciona una categoría</h5>
									</Col>
								)}
							</Col>
							<Row>
								{" "}
								{catCount <= 1 ? null : (
									<Col sm={4} className={"categories-button category-"}>
										<button
											className={"filterCategories cleanFilter false "}
											onClick={this.changeFilterCat}
											value={""}
										>
											Limpiar filtros
										</button>
									</Col>
								)}
								{catCount <= 1 ? null : categoriesList}
							</Row>
							<Row className="listCategory">{newsList}</Row>
							<div>
								<Pagination
									key={this.state._news}
									defaultCurrent={1}
									current={this.state.current}
									pageSize={10}
									onChange={this.handleChange}
									total={this.state._news.length}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default MultimediaTemplate;
