import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col, Container } from 'react-grid-system';
//import Homepage from '../../Scenes/homepage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { Form, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

class Cta extends Component {
  state = {
    cta: [],
    newsletter: [],
    gRecaptcha: '',
    expired: '',
  };
  componentDidMount() {
    //Call to webservice to get all news
    axios.get('/api/settings_cta_get').then((res) => {
      //console.log(res);
      this.setState({
        //Just peak the last 3
        cta: res.data.records.slice(0, 1),
      });
    });
    axios.get('/api/settings_newsletter_get').then((res) => {
      //console.log(res);
      this.setState({
        //Just peak the last 3
        newsletter: res.data.records.slice(0, 1),
      });
    });
  }

  handleChange = (value) => {
    this.setState({ gRecaptcha: value, expired: false });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: true });
  };

  handleSubmitNewsletter = (event) => {
    event.preventDefault();

    if (this.state.gRecaptcha !== '' && this.state.expired === false) {
      var emailError = 0;
      var tycError = 0;
      let formData = new FormData();
      let formData2 = new FormData();
      if (event.target.emailUser.value === '') {
        emailError = 'Necesita rellenar email';
        //alert(dniError);
      } else if (event.target.tyc.value === '') {
        tycError = 'Necesita confirmar los terminos y condiciones';
        //alert(cityUserError);
      }

      if (emailError !== 0 || tycError !== 0) {
        alert('Hay campos obligatorios sin rellenar');
      } else {
        //this.runScripts(event.target.emailUser.value);
        formData.append('email', event.target.emailUser.value);
        formData.append('name', event.target.nameUser.value);
        formData.append('tyc', event.target.tyc.value);
        formData.append('source', 'web');
        formData2.append('email', event.target.emailUser.value);
        axios
          .post('/api/addEmailToBoletin', formData2)
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {});
        axios
          .post('/api/newsletter_set', formData)
          .then((response) => {
            //console.log(res);
            if (response.status === 200) {
            }
            alert('Te has dado de alta en nuestro boletin de noticias');
          })
          .catch((error) => {
            alert(
              'Hemos encontrado un problema y no has sido añadido al boletin de noticias'
            );
          });
      }
    } else {
      alert(
        'Hemos encontrado un problema y no has sido añadido al boletin de noticias'
      );
    }
  };

  runScripts = async (mail) => {
    console.log('Dentro de la funcion runScripts con el mail: ' + mail);
    const getResponseApi = axios.create({
      baseURL: 'https://api.getresponse.com/v3',
      headers: {
        'X-Auth-Token': 'api-key mr478kb8ecpfosl8aku35wwxbr9rtrld',
      },
    });

    const campaingLists = await this.getCampaignLists(getResponseApi);
    console.log(campaingLists);

    const campaingList = await this.getCampaignList(getResponseApi, 'Kmakr');
    console.log(campaingList);

    this.addEmailToList(getResponseApi, mail, 'Kmakr');
  };

  getCampaignLists = async (getResponseApi) => {
    const campaingLists = await getResponseApi.get('/campaigns');

    return campaingLists.data;
  };

  getCampaignList = async (getResponseApi, campaignId) => {
    const campaingList = await getResponseApi.get(`/campaigns/${campaignId}`);

    return campaingList.data;
  };

  addEmailToList = async (getResponseApi, emailToAdd, campaignId) => {
    const response = await getResponseApi.post('/contacts', {
      email: emailToAdd,
      campaign: {
        campaignId: campaignId,
      },
    });
    console.log(response);
  };

  render() {
    const { cta } = this.state;
    const { newsletter } = this.state;
    const recaptchaRef = React.createRef();
    const isEnabled = this.state.expired === false;

    const leftBox = newsletter.map((news, index) => {
      return (
        <Col sm={6} lg={6} xs={12} key={news.id} className="noPadding d-flex">
          <div className="newsletterBox width-100">
            <Row>
              <Col sm={3} className="align-self-center center">
                <FontAwesomeIcon
                  icon={Icons.faEnvelopeOpen}
                  style={{ color: '#e89f14' }}
                  size="5x"
                />
              </Col>

              <Col lg={9} md={12}>
                <h3 className="mb-3">{news.title}</h3>
                <Form
                  onSubmit={this.handleSubmitNewsletter}
                  className="formSubscriptionNews"
                >
                  <Form.Group controlId="emailUser">
                    <Form.Control
                      required
                      type="email"
                      name="emailUser"
                      placeholder="¿Nos dices tu email?..."
                      className="fieldFormNL"
                    />
                  </Form.Group>
                  <Form.Group controlId="nameUser">
                    <Form.Control
                      required
                      type="text"
                      name="nameUser"
                      placeholder="Nombre y apellidos"
                      className="fieldFormNL"
                    />
                  </Form.Group>
                  <Form.Group controlId="tyc">
                    <Form.Check
                      name="tyc"
                      type="checkbox"
                      value="1"
                      label={
                        <label>
                          <a
                            href={news.tyc_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {news.tyc_text}
                          </a>
                        </label>
                      }
                      required
                    />
                  </Form.Group>
                  <div className="captcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={global.captcha}
                      onChange={this.handleChange}
                    />
                  </div>

                  <Button
                    disabled={!isEnabled}
                    variant="primary"
                    type="submit"
                    className="btn btn-dark"
                  >
                    {news.btn_text}
                  </Button>
                  <hr className="customSmallSpace"></hr>
                  <p className="smallText">
                    Información semanal con noticias de FES, sus asociados/as y
                    otras entidades de interés.
                  </p>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      );
    });
    const rightBox = cta.map((cta, index) => {
      return (
        <Col
          sm={6}
          lg={6}
          xs={12}
          key={cta.id}
          className="noPadding d-flex"
          id="subscribeNewsletter"
        >
          <div className="ctaBox width-100">
            <div className="inner">
              <div className=" d-flex">
                <div>
                  <div>
                    <h3 className="textWhite">{cta.title}</h3>
                    <p className="textWhite">{cta.subtitle}</p>
                  </div>
                  <div className="textWhite">
                    <button className="btn btn-dark mb-0">
                      <a
                        href={cta.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ctaButton"
                      >
                        {cta.btn_text}
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      );
    });

    return (
      <section className="ctaSection">
        <Container>
          <Row>
            {leftBox}
            {rightBox}
          </Row>
        </Container>
      </section>
    );
  }
}

export default Cta;
