import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import ReactGA from "react-ga";
import { Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
class SendNew extends Component {
	constructor(props) {
		super();
		this.state = {
			gRecaptcha: "",
			expired: "",
			allCategories: [],
			islogged: 1,
			categoriesComitesId: [],
			userInfo: [],
			msgCompleteProfile: null,
		};
	}

	componentDidMount() {
		//Call to webservice to get the new
		(async () => {
			await this.checkTokken();
			await this.getCategoriesNews();
			this.setMessageWarning();
		})();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	checkTokken = async () => {
		const jwt = localStorage.getItem("access_token");
		if (!jwt) {
			this.setState({
				islogged: 0,
				msgNotLogIn:
					"Para poder afiliarse necesita tener una cuenta de usuario/a. ",
			});
		} else {
			let formData = new FormData();
			formData.append("jwt", jwt);
			await axios
				.post("/api/validate_token", formData)
				.then((res) => {
					this.setState({
						userInfo: res.data.data,
						user_type: res.data.data.id_type,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
			this.setState({
				islogged: 1,
				msgNotLogIn: "",
			});
		}
	};
	setMessageWarning() {
		const { userInfo } = this.state;
		//Message user Personal data
		if (userInfo) {
			if (!userInfo.name || !userInfo.lastname) {
				this.setState({
					msgCompleteProfile:
						"Para enviar a FES necesitamos que complete sus datos personales en su perfil, gracias.",
				});
			} else {
				this.setState({
					msgCompleteProfile: null,
				});
			}
		}
	}
	componentDidUpdate(prevProps) { }
	getCategoriesNews = async () => {
		await axios
			.get("/api/get_associated_categories")
			.then((res) => {
				this.setState({
					allCategories: res.data.records,
					fetchCatNews: false,
				});
			})
			.catch((error) => {
				this.setState({
					fetchCatNews: false,
				});
			});
	};
	handleChange = (value) => {
		this.setState({ gRecaptcha: value, expired: false });
		// if value is null recaptcha expired
		if (value === null) this.setState({ expired: true });
	};
	handleEditorChangeNewContent = (content, editor) => {
		this.setState({
			contentNew: content,
		});
	};
	catSelected = (e) => {
		this.state.allCategories.map((cat, index) => {
			if (+e.target.value === +cat.id) {
				this.setState({
					categoriesComitesId: [{ label: cat.name, value: cat.id }],
				});
				return null;
			} else return null;
		});
	};
	checkImage = (e) => {
		if (e.target.files[0]) {
			if (e.target.files[0].size < 500000) {
				if (
					e.target.files[0].type === "image/jpeg" ||
					e.target.files[0].type === "image/png"
				) {
				} else {
					alert(
						"El formato de la imagen no está permitido por favor seleccione otra imagen."
					);
				}
			} else
				alert(
					"La imagen que ha seleccionado supera el tamaño máximo permitido, por lo que la imagen no se guardara, por favor elija otra imagen"
				);
		} else {
		}
	};
	handleSubmitNews = (event) => {
		event.preventDefault();
		var month = new Date().getMonth() + 1;
		var year = new Date().getFullYear();
		var date = new Date().getDate();
		var FullDate = year + "-" + month + "-" + date;

		let formData = new FormData();

		//formData.append(	"categories",JSON.stringify(event.target.categoryNew.value)	);
		formData.append(
			"categories",
			JSON.stringify(this.state.categoriesComitesId)
		);
		formData.append("source", 1);
		formData.append("title", event.target.titleNew.value);
		formData.append("content", this.state.contentNew);
		formData.append(
			"username",
			this.state.userInfo.name + " " + this.state.userInfo.lastname
		);

		if (+this.state.user_type === 1) {
			formData.append("user_type", "usuario");
		} else if (+this.state.user_type > 1) {
			formData.append("user_type", "socio");
		}

		formData.append("user_email", this.state.userInfo.email);
		formData.append("meta_title", event.target.titleNew.value);
		formData.append(
			"meta_description",
			event.target.short_descriptionNew.value
		);
		formData.append(
			"short_description",
			event.target.short_descriptionNew.value
		);
		if (event.target.newsImage.files[0]) {
			if (event.target.newsImage.files[0].size > 500000) {
				formData.append("img", "");
			} else {
				if (
					event.target.newsImage.files[0].type === "image/jpeg" ||
					event.target.newsImage.files[0].type === "image/png"
				) {
					formData.append("img", event.target.newsImage.files[0]);
				} else {
					formData.append("img", "");
				}
			}
		} else {
			formData.append("img", "");
		}

		formData.append("sendMail", 0);
		formData.append("display", true);
		formData.append("date", FullDate);
		formData.append("external_url", "");
		formData.append("keywords", "");
		formData.append("tags", "");

		if (formData.get("user_type")) {
			axios
				.post("/api/create_news", formData)
				.then((res) => {
					alert(
						"Se ha enviado la noticia. Si cumple todos los requisitos será publicada."
					);
					window.scrollTo(0, 0);
					window.location.reload();
				})
				.catch((error) => {
					alert(
						"Hemos encontrado un problema y enviado la noticia, intentelo de nuevo más tarde."
					);
				});
		} else {
			alert(
				"Hemos encontrado un problema y enviado la noticia, intentelo de nuevo más tarde o recargue la página."
			);
		}
	};

	handleSubmitPersonalInfo = (event) => {
		event.preventDefault();
		let formData = new FormData();
		formData.append("jwt", localStorage.getItem("access_token"));
		formData.append("name", event.target.nameUser.value);
		formData.append("lastname", event.target.lastNameUser.value);
		formData.append("nif", this.state.userInfo.nif);
		formData.append("birthdate", this.state.userInfo.birthdate);
		formData.append("country", this.state.userInfo.country);
		formData.append("gender", this.state.userInfo.gender);
		formData.append("address_1", this.state.userInfo.address_1);
		formData.append("city", this.state.userInfo.city);
		formData.append("region", this.state.userInfo.region);
		formData.append("country_residence", this.state.userInfo.country_residence);
		formData.append("landline", this.state.userInfo.landline);
		formData.append("phone", this.state.userInfo.phone);
		formData.append("postal_code", this.state.userInfo.postal_code);
		formData.append("id_type", this.state.userInfo.id_type);
		localStorage.setItem("usernameShow", event.target.nameUser.value);
		axios
			.post("/api/users_update_personal_data", formData)
			.then((response) => {
				if (response.status === 200) {
					let jwt = response.data.jwt;
					localStorage.setItem("access_token", jwt);
				}
				alert("Se han guardado correctamente sus datos");
				(async () => {
					await this.checkTokken();
				})();
				window.scrollTo(0, 0);
				window.location.reload();
			})
			.catch((error) => {
				alert("No se ha podido modificar los datos");
			});
	};
	render() {
		const MetaTagsSeo = <SEO title="Enviar notica a FES" cover_image=""></SEO>;
		const recaptchaRef = "news";
		const isEnabled = this.state.expired === false;
		const { allCategories } = this.state;

		let categoriesNews;
		if (allCategories) {
			categoriesNews = allCategories.map((cat, index) => {
				return (
					<option key={index} value={cat.id} name={cat.name}>
						{cat.name}
					</option>
				);
			});
		}
		const title = (
			<TopImageTitle
				title="Formulario para presentar noticia a FES"
				cover_image=""
				subtitle="Desde esta página podrá enviar una noticia para ser publicada en el Canal de Noticias"
				id_parentMenu={null}
			></TopImageTitle>
		);

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>

				<Container>
					<Row>
						{+this.state.islogged === 0 ? (
							<Col lg={12} md={12}>
								<p>
									Para poder enviar una noticia es necesario que se loguee con
									su cuenta de FES. Puede hacer login haciendo click aqui{" "}
									<Link
										to={{
											pathname: `/login`,
											state: {
												origin: 0,
												url_permalink: "enviar-noticia-FES",
											},
										}}
									>
										click aqui
									</Link>
								</p>
							</Col>
						) : (
							<Col lg={12} md={12}>
								{this.state.msgCompleteProfile !== null ? (
									<span>
										Para enviar una noticia a FES es necesario que cumplimente
										los siguientes dados personales.
										<Form onSubmit={this.handleSubmitPersonalInfo}>
											<Row>
												<Col lg={6} sm={12}>
													<Form.Group controlId="nameUser">
														<Form.Label>Nombre*</Form.Label>
														<Form.Control
															type="text"
															placeholder="Nombre"
															name="nameUser"
															required
															defaultValue={this.state.userInfo.name}
														/>
													</Form.Group>
												</Col>
												<Col lg={6} sm={12}>
													<Form.Group controlId="lastNameUser">
														<Form.Label>Apellidos*</Form.Label>
														<Form.Control
															type="text"
															placeholder="Apellidos"
															name="lastNameUser"
															required
															defaultValue={this.state.userInfo.lastname}
														/>
													</Form.Group>
												</Col>

												<Col lg={12} sm={12}>
													<Button
														ref="btnSettings"
														variant="primary"
														type="submit"
													>
														Guardar información
													</Button>
												</Col>
											</Row>
										</Form>
									</span>
								) : (
									<div>
										<p>
											Rellenando el siguiente formulario podrá enviar la noticia
											a FES. La noticia será revisada y, si cumple todos los{" "}
											<a href="https://fes-sociologia.com/paginas/canal-de-noticias-fes">
												criterios
											</a>
											, será publicada en la web.
										</p>
										<div>
											{" "}
											<Form onSubmit={this.handleSubmitNews}>
												<Row>
													<Col lg={12} sm={12}>
														<Form.Group controlId="titleNew">
															<Form.Label>Título de la noticia*</Form.Label>
															<Form.Control
																type="text"
																placeholder="Título"
																name="titleNew"
																ref="titleNew"
																required
															/>
														</Form.Group>
													</Col>
													<Col lg={12} sm={12}>
														<Form.Group controlId="short_descriptionNew">
															<Form.Label>
																Entradilla (Esto es un pequeño texto que aparece
																antes de ver la noticia al completo, longitud
																máxima 170 caracteres)*
															</Form.Label>
															<Form.Control
																type="text"
																placeholder="Entradilla"
																name="short_descriptionNew"
																ref="short_descriptionNew"
																maxLength="170"
																required
															/>
														</Form.Group>
													</Col>

													<Col lg={12} sm={12}>
														<Form.Group controlId="contentNews">
															<Form.Label>Contenido de la noticia*</Form.Label>
															<Editor
																apiKey={global.editorApiKey}
																init={{
																	height: 500,
																	menubar: false,
																	force_br_newlines: true,
																	force_p_newlines: false,
																	forced_root_block: "",

																	setup: function (editor) {
																		editor.ui.registry.addButton(
																			"insertCustomDrop",
																			{
																				text: "Desplegable",
																				onAction: function () {
																					console.log(
																						editor.selection.getContent()
																					);

																					editor.windowManager.open({
																						title: "Desplegable",
																						body: {
																							type: "panel",
																							items: [
																								{
																									type: "input",
																									name: "titulo",

																									label:
																										"Titulo del desplegable",
																								},
																								{
																									type: "textarea",
																									name: "contenido",
																									label:
																										"Contenido del desplegable",
																								},
																							],
																						},
																						buttons: [
																							{
																								type: "cancel",
																								name: "closeButton",
																								text: "Cancelar",
																							},
																							{
																								type: "submit",
																								name: "submitButton",
																								text: "Insertar desplegable",
																								primary: true,
																							},
																						],
																						initialData: {
																							titulo: "",
																							contenido: "",
																						},

																						onSubmit: function (api) {
																							var data = api.getData();

																							editor.execCommand(
																								"mceInsertContent",
																								false,
																								"<button class='collapsible noCollapsed' type='button'>" +
																								data.titulo +
																								"</button> <div class='content_collapsible'><br><p>" +
																								data.contenido +
																								"</p></div><br>"
																							);
																							api.close();
																						},
																					});
																				},
																			}
																		);
																	},

																	plugins: [
																		"advlist autolink lists link image charmap print preview anchor ",
																		"searchreplace visualblocks code fullscreen",
																		"insertdatetime media table paste code help wordcount",
																	],

																	contextmenu: false,
																	toolbar:
																		" listItems undo redo | formatselect | link bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
																}}
																onEditorChange={
																	this.handleEditorChangeNewContent
																}
															/>
														</Form.Group>
													</Col>

													<Col lg={12} sm={12}>
														<Form.Group controlId="imageNews">
															<Form.Label>
																Imagen de la noticia (Tamaño de imagen
																recomendado de 800X300px, un peso máximo de
																500kb y en formato jpg o png):
															</Form.Label>
															<Form.Label
																className="image-warning"
															>
																Por favor, suba una imagen que no contenga letras, ni carteles con texto. Resulta ilegible. Los carteles se podrán subir en la noticia como adjunto. La FES desechará la imagen si considera que no cumple los criterios
															</Form.Label>
															<Form.Control
																onChange={(e) => this.checkImage(e)}
																type="file"
																name="newsImage"
																placeholder="Fichero a subir"
																accept="image/x-png,image/jpeg,image/jpg"
															/>
														</Form.Group>
													</Col>
													<Col lg={12} sm={12}>
														<Form.Group controlId="Categoryselect">
															<Form.Label>Seleccione la categoria*</Form.Label>
															<Form.Control
																as="select"
																name="categoryNew"
																ref="categoryNew"
																onChange={this.catSelected}
															>
																<option value="01">
																	-Seleccione una opción-
																</option>
																{categoriesNews}
															</Form.Control>
														</Form.Group>
													</Col>

													<Col sm={12} xs={12}>
														{/* <div className="captcha">
															<ReCAPTCHA
																ref={recaptchaRef}
																sitekey={global.captcha}
																onChange={this.handleChange}
															/>
														</div> */}
														<Button
															ref="btnNews"
															variant="primary"
															type="submit"
														//disabled={!isEnabled}
														>
															Enviar noticia
														</Button>
													</Col>
												</Row>
											</Form>
										</div>
									</div>
								)}
							</Col>
						)}
					</Row>
				</Container>
			</div>
		);
	}
}
export default SendNew;
