import React from 'react';
import TopImageTitle from '../../objects/topImageTitle';
import { Row, Col, Container } from 'react-grid-system';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Form, Checkbox, Radio } from 'antd';
import { Table, Button } from 'react-bootstrap';
//import ProductResume from "../components/objects/ProductResume";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

class ProductComiteAndDoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      type: '',
      comites: [],
      checkedPay: [],
      acepto: '',
      paymentMethod: '',
      paymentMethodChossen: '',
      price: '',
      doc_required: '',
      id_product: '',
      islogged: '',
      userInformation: [],
      userInfoPayments: [],
      signaturesRedsys: '',
      titleChecked: [],
      file: '',
      asociaciones: [],
      asociacionValue: '',
      statusDoc: 0,
      productID_confirmation: '',
      payment_id: '',
      checkedItemsFree: new Map(),
      checkedItemsPaid: new Map(),
      renov_id: '',
      id_product_renov: '',
      iva: '',
      oldPayment: '',
    };
    this.onChangePayName = this.onChangePayName.bind(this);
  }
  getUserInformation = async () => {
    const jwt = localStorage.getItem('access_token');
    //console.log("Aqui esta el jwt: ");
    //console.log(jwt);
    if (!jwt) {
      this.setState({
        islogged: 0,
      });
    } else {
      this.setState({
        islogged: 1,
      });
      let formData = new FormData();
      formData.append('jwt', jwt);
      axios
        .post('/api/validate_token', formData)
        .then((res) => {
          //console.log(res);
          this.setState({
            userInformation: res.data.data,
          });
        })
        .catch((error) => {
          this.setState({
            statusPage: 1,
          });
        });
    }
  };
  getProductsByID = async () => {
    const id = this.props.match.params.id;
    let formData = new FormData();
    formData.append('id', id);
    await axios.post('/api/products_get_by_id', formData).then((res) => {
      this.setState({
        products: res.data.records,
        doc: res.data.records[0].doc_required,
        idtemplate: res.data.records[0].id_template,
      });
      var price00 = res.data.records[0].price;
      var doc_required00 = res.data.records[0].doc_required;
      var idProduct00 = res.data.records[0].id;
      var idTemplate = res.data.records[0].id_template;
      var iva = res.data.records[0].iva;
      this.setState({
        price: price00,
        doc_required: doc_required00,
        id_product: idProduct00,
        idTemplate: idTemplate,
        iva: iva,
      });
    });
    axios.get('/api/comites_workgroups_get', formData).then((res) => {
      //console.log(res);
      this.setState({
        comites: res.data.records,
      });
    });
  };
  getPaymentMethods = async () => {
    const { userInformation } = this.state;

    if (!localStorage.getItem('access_token')) {
    } else {
      let formData = new FormData();

      formData.append('id_user', userInformation.id);
      await axios
        .post('/api/payment_get_exceptions_by_id_user', formData)
        .then((res) => {
          //console.log(res);
          this.setState({
            userInfoPayments: res.data.records,
          });
        })
        .catch((error) => {
          if (this.state.numberofTry === 0) {
            this.getPaymentMethods();
            this.setState({
              numberofTry: 1,
            });
          } else {
            this.setState({
              userInfoPayments: '',
            });
          }
        });
    }
  };
  getAsociations = async () => {
    const id = this.props.match.params.id;
    let formData = new FormData();
    formData.append('id', id);
    axios.get('/api/products_get', formData).then((res) => {
      //console.log(res);
      this.setState({
        asociaciones: res.data.records,
      });
    });
  };
  getComitesRenovation = async (id_payment) => {
    if (+id_payment === 0) {
      return null;
    } else {
      let formData = new FormData();
      console.log(id_payment);
      formData.append('id_payment_user', id_payment);
      await axios
        .post('/api/payment_products_get_by_id_payment_user', formData)
        .then((res) => {
          console.log(res);
          this.setState({
            comitesRenovation: res.data.records,
          });
          this.checkComites(res.data.records);
        })
        .catch((error) => {});
    }
  };
  checkComites = (data) => {
    var comitesPayRenovation = [];
    var comitesFreeRenovation = [];

    data.map((comite) => {
      if (+comite.type === 1) {
        comitesPayRenovation.push(comite.idComite);
        this.setState((prevState) => ({
          checkedItemsPaid: prevState.checkedItemsPaid.set(comite.title, true),
        }));
        return null;
      } else {
        comitesFreeRenovation.push(comite.idComite);
        this.setState((prevState) => ({
          checkedItemsFree: prevState.checkedItemsFree.set(comite.title, true),
        }));
        return null;
      }
    });

    this.setState(() => ({
      checked: comitesFreeRenovation,
      checkedPay: comitesPayRenovation,

      readyToShow: true,
    }));
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    (async () => {
      await this.getUserInformation();
      await this.getProductsByID();
      await this.getPaymentMethods();
      await this.getAsociations();
    })();
    if (this.props.location.state === undefined) {
      return null;
    } else if (
      this.props.location.state.statusDoc === 1 ||
      this.props.location.state.statusDoc === 3
    ) {
      this.setState({
        statusDoc: this.props.location.state.statusDoc,
        productID_confirmation:
          this.props.location.state.productID_confirmation,
        payment_id: this.props.location.state.id_payment,
        renov_id: this.props.location.state.renov_id,
        id_product_renov: this.props.location.state.id_product_renov,
        asociacionValue: this.props.location.state.id_product_renov,
        oldPayment: this.props.location.state.oldPayment,
      });
      if (this.props.location.state.rePay) {
        this.getComitesRenovation(this.props.location.state.rePay);
      }
      this.getComitesRenovation(this.props.location.state.renov_id);
    }
    if (this.props.location.state === undefined) {
      return null;
    } else if (this.props.location.state.renovationTime === true) {
      console.log(this.props.location.state);
      this.setState({
        renovationTime: true,
        renov_id: this.props.location.state.renov_id,
        id_product_renov: this.props.location.state.id_product_renov,
        asociacionValue: this.props.location.state.id_product_renov,
      });
      if (this.props.location.state.rePay) {
        this.getComitesRenovation(this.props.location.state.rePay);
      }
      this.getComitesRenovation(this.props.location.state.renov_id);
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params !== prevProps.match.params) {
      (async () => {
        await this.getUserInformation();
        await this.getProductsByID();
        await this.getPaymentMethods();
      })();
      if (this.props.location.state === undefined) {
        return null;
      } else if (
        this.props.location.state.statusDoc === 1 ||
        this.props.location.state.statusDoc === 3
      ) {
        this.setState({
          statusDoc: this.props.location.state.statusDoc,
          productID_confirmation:
            this.props.location.state.productID_confirmation,
          payment_id: this.props.location.state.id_payment,
        });
      }
    }
  }
  onChangePayment = (checkedValues) => {
    this.setState(() => {
      return { checkedPay: checkedValues };
    });
  };
  onChangePayName(event) {
    const item = event.target.datatitle;
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      checkedItemsPaid: prevState.checkedItemsPaid.set(item, isChecked),
    }));
  }
  collapsible() {
    var coll = document.getElementsByClassName('collapsible');
    var i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener('click', function () {
        this.classList.toggle('active');
        this.classList.toggle('collapsed');
        var content = this.nextElementSibling;
        if (content.style.display === 'block') {
          content.style.display = 'none';
        } else {
          content.style.display = 'block';
        }
      });
    }
  }
  handlePaymentProduct = (event) => {
    this.refs.btnPayment.setAttribute('disabled', 'disabled');

    if (
      +this.state.paymentMethodChossen === 1 &&
      this.state.checkedPay.length > 0
    ) {
      const id_product = this.state.payment_id;

      const totalAmout =
        global.priceSingleComite * this.state.checkedPay.length * 100;

      const product_description = this.props.match.params.id;

      let formData = new FormData();

      formData.append('id_payment', id_product);
      const id_payment = this.state.payment_id;
      formData.append('amount', totalAmout);
      formData.append('product_description', product_description);
      formData.append('rePay', this.state.rePay);
      axios
        .post('/api/redsys_get_signature_by_id_payment', formData)
        .then((res) => {
          //console.log(res);
          this.setState({
            signaturesRedsys: res.data,
          });
          this.refs.btnPayment.removeAttribute('disabled');
          this.props.history.push({
            pathname: '/confirmacion-de-pago',
            state: {
              signatureRedsys: this.state.signaturesRedsys,
              checked: this.state.checked,
              checkedPay: this.state.checkedPay,
              totalAmount01: totalAmout,
              paymentMethodChossen: this.state.paymentMethodChossen,
              goPayment: 1,
              products: this.state.products,
              id_payment: id_payment,
              customPrice: '0',
              listPaidComites: this.state.checkedItemsPaid,
              renov_id: this.state.renov_id,
              iva: this.state.iva,
              oldPayment: this.state.oldPayment,
              rePay: this.state.rePay,
            },
          });
        });
    } else if (
      +this.state.paymentMethodChossen === 2 &&
      this.state.checkedPay.length > 0
    ) {
      const id_payment = this.state.payment_id;
      const id_product = this.props.match.params.id;
      const { userInformation } = this.state;
      const totalAmout =
        global.priceSingleComite * this.state.checkedPay.length * 100;
      this.refs.btnPayment.removeAttribute('disabled');
      this.props.history.push({
        pathname: '/confirmacion-de-pago',
        state: {
          id_product: id_product,
          checked: this.state.checked,
          checkedPay: this.state.checkedPay,
          totalAmount01: totalAmout,
          paymentMethodChossen: this.state.paymentMethodChossen,
          products: this.state.products,
          goPayment: 2,
          payment_type: 2,
          userInfo: userInformation,
          id_user: userInformation.id,
          idTemplate: this.state.idtemplate,
          id_payment: id_payment,
          customPrice: '0',
          listPaidComites: this.state.checkedItemsPaid,
          renov_id: this.state.renov_id,
          iva: this.state.iva,
          oldPayment: this.state.oldPayment,
          rePay: this.state.rePay,
        },
      });
    } else if (
      +this.state.paymentMethodChossen === 3 &&
      this.state.checkedPay.length > 0
    ) {
      const id_product = this.props.match.params.id;
      const id_payment = this.state.payment_id;
      const { userInformation } = this.state;
      const totalAmout =
        (+this.state.price +
          global.priceSingleComite * this.state.checkedPay.length) *
        100;
      this.refs.btnPayment.removeAttribute('disabled');
      this.props.history.push({
        pathname: '/confirmacion-de-pago',
        state: {
          id_product: id_product,
          checked: this.state.checked,
          checkedPay: this.state.checkedPay,
          totalAmount01: totalAmout,
          paymentMethodChossen: this.state.paymentMethodChossen,
          products: this.state.products,
          goPayment: 3,
          payment_type: 3,
          userInfo: userInformation,
          id_user: userInformation.id,
          id_payment: id_payment,
          idTemplate: this.state.idtemplate,
          listFreeComites: this.state.checkedItemsFree,
          listPaidComites: this.state.checkedItemsPaid,
          renov_id: this.state.renov_id,
          iva: this.state.iva,
          oldPayment: this.state.oldPayment,
          rePay: this.state.rePay,
        },
      });
    } else if (this.state.checkedPay.length === 0) {
      alert('Debe seleccionar por lo menos un comité para poder continuar');
      this.refs.btnPayment.removeAttribute('disabled');
    }
  };
  handleSendDoc = (event) => {
    this.refs.btnDoc.setAttribute('disabled', 'disabled');
    if (this.state.file) {
      const { statusDoc } = this.state;

      const { userInformation } = this.state;

      if (+statusDoc === 3) {
        let formData = new FormData();
        const id_product = this.state.payment_id;
        formData.append('id_payment_user', id_product);
        formData.append('file', this.state.file);

        axios
          .post('/api/payment_documentation_update.php', formData)
          .then((res) => {
            alert('Su documentación ha sido actualizada correctamente.');
            this.refs.btnDoc.removeAttribute('disabled');
            this.props.history.push({
              pathname: '/perfil',
              state: {
                key: 'afiliationData',
              },
            });
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        let formData = new FormData();

        formData.append('id_user', userInformation.id);
        formData.append('id_product', this.state.asociacionValue);
        formData.append('amount', '0');
        formData.append('file', this.state.file);
        formData.append('renov_id', this.state.renov_id);
        axios
          .post('/api/payment_documentation_initial_petition_set', formData)
          .then((res) => {
            alert('Su documentación ha sido enviada correctamente.');
            this.refs.btnDoc.removeAttribute('disabled');
            this.props.history.push({
              pathname: '/perfil',
              state: {
                key: 'afiliationData',
              },
            });
          });
      }
    } else {
      alert(
        'Compruebe que ha seleccionado un documento y aceptado los terminos y condiciones de servicio.'
      );
      this.refs.btnDoc.removeAttribute('disabled');
    }

    //console.log(+this.state.price + 18 * this.state.checkedPay.length);
  };
  handleAcepto = (evt) => {
    this.setState({ acepto: evt.target.checked });
  };
  handleFile = (evt) => {
    this.setState({ file: evt.target.files[0] });
  };
  handlePaymentMethod = (evt) => {
    this.setState({ paymentMethodChossen: evt.target.value });
    this.setState({ paymentMethod: evt.target.checked });
  };
  onChangeAsociacionTerritoria = (e) => {
    this.setState({
      asociacionValue: e.target.value,
    });
  };
  render() {
    this.collapsible();

    //const { acepto, paymentMethod, file, asociacionValue } = this.state;

    //const isEnabled = acepto === true && paymentMethod === true;
    //const isEnabledDoc = acepto === true && file && asociacionValue !== "";
    const { paymentMethod, file, asociacionValue } = this.state;

    const isEnabled = paymentMethod === true;
    const { asociaciones } = this.state;
    const isEnabledDoc = file && asociacionValue !== '';
    const isEnabledDoc3 = file;
    const { products } = this.state;
    const { idTemplate } = this.state;
    const { comites } = this.state;
    const { checkedPay } = this.state;
    const { islogged } = this.state;
    const { userInfoPayments } = this.state;
    const { userInformation } = this.state;
    const { statusDoc } = this.state;
    const listAsociacionesTerritoriales = asociaciones.map((asoc) => {
      if (asoc.type === '3') {
        return (
          <Col sm={12} key={asoc.id}>
            <Radio value={asoc.id} name={asoc.name}>
              {'Socio/a Territorial inscrito/a a ' + asoc.name}
            </Radio>
          </Col>
        );
      } else {
        return null;
      }
    });
    const listComitesExtra = comites.map((comite) => {
      if (+comite.active === 1 || +comite.active === 2) {
        return (
          <Col sm={12} className="comiteCheckbox" key={comite.id}>
            <Checkbox
              id={'extra-' + comite.id}
              value={comite.id}
              ref={comite.title}
              datatitle={comite.title}
              onChange={this.onChangePayName}
            >
              {+comite.active === 2
                ? comite.title + ' (En construcción)'
                : comite.title}
            </Checkbox>
          </Col>
        );
      } else {
        return null;
      }
    });
    const productInfo = products.map((prod) => {
      if (+prod.id === 3) {
        return (
          <Col sm={12} key={prod.id}>
            <span>
              {this.state.renovationTime === true ? (
                <Link
                  to={{
                    pathname: `/perfil`,
                    state: {
                      key: 'afiliationData',
                    },
                  }}
                  className="buttonGoBack button"
                >
                  Volver atrás
                </Link>
              ) : (
                <a href="/afiliate" className="buttonGoBack button">
                  Volver atrás
                </a>
              )}
            </span>
            <h1 className="product-Title">{prod.name}</h1>

            {+statusDoc === 1 ? (
              <div>
                {' '}
                <p>
                  <FontAwesomeIcon icon={Icons.faCheck} size="2x" /> Su
                  documentación ha sido aceptada. Puede realizar la afiliación.
                </p>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: prod.content }} />
            )}
          </Col>
        );
      } else {
        return (
          <Col sm={12} key={prod.id}>
            <h1 className="product-Title">
              {'Socio Territorial inscrito/a a comité'}
            </h1>

            {+statusDoc === 1 ? (
              <div>
                {' '}
                <p>
                  <FontAwesomeIcon icon={Icons.faCheck} size="2x" /> Su
                  documentación ha sido aceptada. Puede realizar la afiliación.
                </p>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: prod.content }} />
            )}
          </Col>
        );
      }
    });
    const precioTotal = products.map((prod) => {
      return (
        <Table striped key={prod.id}>
          <tbody>
            <tr>
              <th>Concepto</th>
              <th>Cantidad</th>
              <th>Precio unidad (IVA incluido)</th>
              <th>IVA</th>
              <th>Total</th>
            </tr>
            <tr>
              <td>{'Socio/a de Territorial inscrito/a a comités'}</td>
              <td>1</td>
              <td>{prod.price * 0}€</td>
              <td>
                {prod.iva && +prod.iva !== 0 ? prod.iva + '%' : 'Exento de IVA'}
              </td>
              <td>{prod.price * 0}€</td>
            </tr>
            <tr>
              <td>Comités/Grupos de trabajo</td>
              <td>{checkedPay.length}</td>
              <td>{global.priceSingleComite}€</td>
              <td>
                {prod.iva && +prod.iva !== 0 ? prod.iva + '%' : 'Exento de IVA'}
              </td>
              <td>{global.priceSingleComite * checkedPay.length}€</td>
            </tr>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>
                {prod.iva && +prod.iva !== 0 ? prod.iva + '%' : 'Exento de IVA'}
              </td>
              <td> {global.priceSingleComite * checkedPay.length}€</td>
            </tr>
          </tbody>
        </Table>
      );
    });
    const title = products.map((prod) => {
      if (prod.type !== null) {
        return <TopImageTitle title={prod.name} key={prod.id}></TopImageTitle>;
      } else {
        return null;
      }
    });

    let methodPaymentAvailable;
    if (!userInfoPayments || userInfoPayments === '') {
      methodPaymentAvailable = (
        <span>
          <Radio.Button
            value="1"
            name="paymentMethod"
            onChange={this.handlePaymentMethod}
          >
            Tarjeta
          </Radio.Button>
          <Radio.Button
            value="2"
            name="paymentMethod"
            onChange={this.handlePaymentMethod}
          >
            Transferencia
          </Radio.Button>
          {/*
					<Radio.Button
						value="3"
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
					>
						Domiciliación (SEPA)
					</Radio.Button
				
					>	*/}
        </span>
      );
    } else if (userInfoPayments) {
      methodPaymentAvailable = userInfoPayments.map((pay) => {
        return (
          <Radio.Button
            value={pay.id}
            name="paymentMethod"
            onChange={this.handlePaymentMethod}
            key={pay.id}
          >
            {pay.name}
          </Radio.Button>
        );
      });
    }
    let messageLogin;
    let buttonPay;
    if (+islogged === 0) {
      buttonPay = (
        <p>
          Debe hacer{' '}
          <Link
            to={{
              pathname: `/login`,
              state: {
                origin: 0,
                url_permalink: 'producto/' + this.props.match.params.id,
              },
            }}
          >
            Login/Registrarse
          </Link>{' '}
          para poder afiliarse. Si ya tiene una cuenta de usuario, acceda con
          sus datos de registro. Si es nuevo en la web de FES, dese de alta.
        </p>
      );
      messageLogin = (
        <div className="alert alert-warning" role="alert">
          <p>
            Debe hacer{' '}
            <Link
              to={{
                pathname: `/login`,
                state: {
                  origin: 0,
                  url_permalink: 'producto/' + this.props.match.params.id,
                },
              }}
            >
              Login/Registrarse
            </Link>{' '}
            para poder afiliarse. Si ya tiene una cuenta de usuario, acceda con
            sus datos de registro. Si es nuevo en la web de FES, dese de alta.
          </p>
        </div>
      );
    } else if (+islogged === 1) {
      if (
        +userInformation.id_type === +4 ||
        +userInformation.id_type === +5 ||
        +userInformation.id_type === +7 ||
        +userInformation.id_type === +6 ||
        +userInformation.id_type === +12 ||
        +userInformation.id_type === +14 ||
        +userInformation.id_type === +10
      ) {
        buttonPay = (
          <Button
            disabled={!isEnabled}
            className="button"
            variant="primary"
            type="submit"
            ref="btnPayment"
          >
            Ir a pago
          </Button>
        );
      } else {
        if (
          !userInformation.name ||
          !userInformation.lastname ||
          !userInformation.nif ||
          !userInformation.city ||
          !userInformation.org ||
          !userInformation.region ||
          !userInformation.education ||
          !userInformation.academic_degree
        ) {
          buttonPay = (
            <div className="alert alert-warning" role="alert">
              <p>
                Si desea realizar una afiliación, debe primero completar los
                datos requeridos como obligatorios en su cuenta de perfil:
                <Link to="/perfil"> ir a Mi cuenta.</Link>
              </p>
            </div>
          );
          messageLogin = (
            <div className="alert alert-warning" role="alert">
              <p>
                Si desea realizar una afiliación, debe primero completar los
                datos requeridos como obligatorios en su cuenta de perfil:
                <Link to="/perfil"> ir a Mi cuenta.</Link>
              </p>
            </div>
          );
        } else {
          buttonPay = (
            <Button
              disabled={!isEnabled}
              className="button"
              variant="primary"
              type="submit"
              ref="btnPayment"
            >
              Ir a pago
            </Button>
          );
        }
      }
    }

    let buttonSendDoc;
    if (+islogged === 0) {
      buttonSendDoc = (
        <p>
          Debe hacer{' '}
          <Link
            to={{
              pathname: `/login`,
              state: {
                origin: 0,
                url_permalink: 'producto/' + this.props.match.params.id,
              },
            }}
          >
            Login/Registrarse
          </Link>{' '}
          para poder afiliarse. Si ya tiene una cuenta de usuario, acceda con
          sus datos de registro. Si es nuevo en la web de FES, dese de alta.
        </p>
      );
    } else if (+islogged === 1 && +statusDoc === 0) {
      buttonSendDoc = (
        <Button
          disabled={!isEnabledDoc}
          className="button"
          variant="primary"
          type="submit"
          ref="btnDoc"
        >
          Enviar documentación
        </Button>
      );
    } else if (+islogged === 1 && +statusDoc === 3) {
      buttonSendDoc = (
        <Button
          disabled={!isEnabledDoc3}
          className="button"
          variant="primary"
          type="submit"
          ref="btnDoc"
        >
          Enviar documentación
        </Button>
      );
    }
    let doc_required_form;
    if (+statusDoc === 0 || +statusDoc === 3) {
      if (+islogged === 0) {
        doc_required_form = null;
      } else if (+statusDoc === 0) {
        doc_required_form = (
          <Form onFinish={this.handleSendDoc}>
            <p>Seleccione la asociación a la que pertenece.</p>
            <Radio.Group
              onChange={this.onChangeAsociacionTerritoria}
              value={this.state.asociacionValue}
            >
              {listAsociacionesTerritoriales}
            </Radio.Group>
            <hr></hr>
            <input
              type="file"
              name="documentation"
              onChange={this.handleFile}
            ></input>
            <hr></hr>
            <p>
              Si no dispone de la documentación requerida, adjunte un documento
              dando las razones y el equipo de FES le responderá lo antes
              posible
            </p>
            <hr></hr>

            {buttonSendDoc}
          </Form>
        );
      } else if (+statusDoc === 3) {
        doc_required_form = (
          <Form onFinish={this.handleSendDoc}>
            <p>
              <b>Adjuntar fichero</b>
            </p>
            <input
              type="file"
              name="documentation"
              onChange={this.handleFile}
            ></input>
            <hr></hr>
            <p>
              Si no dispone de la documentación requerida, adjunte un documento
              dando las razones y el equipo de FES le responderá lo antes
              posible
            </p>

            {buttonSendDoc}
          </Form>
        );
      }
    }
    if (+statusDoc === 0 && +idTemplate === 4) {
      if (+islogged === 0) {
        doc_required_form = null;
      } else {
        doc_required_form = (
          <Form onFinish={this.handleSendDoc}>
            <p>Seleccione la asociación a la que pertenece.</p>
            <Radio.Group
              onChange={this.onChangeAsociacionTerritoria}
              value={this.state.asociacionValue}
            >
              {listAsociacionesTerritoriales}
            </Radio.Group>
            <hr></hr>
            <input
              type="file"
              name="documentation"
              onChange={this.handleFile}
            ></input>
            <hr></hr>
            <p>
              Si no dispone de la documentación requerida, adjunte un documento
              dando las razones y el equipo de FES le responderá lo antes
              posible
            </p>

            {buttonSendDoc}
          </Form>
        );
      }
    } else if (+statusDoc === 0 && +idTemplate === 6) {
      if (+islogged === 0) {
        doc_required_form = null;
      } else {
        doc_required_form = (
          <p>
            Debe escoger la opcion Socio/a de Territorial inscrito/a a comités
            en el menu <a href="/afiliate">afiliate</a>
          </p>
        );
      }
    } else if (+statusDoc === 1) {
      if (+islogged === 0) {
        doc_required_form = null;
      } else {
        doc_required_form = (
          <Form onFinish={this.handlePaymentProduct}>
            <div>
              <p>
                A continuación puede escoger los comités (se pagará{' '}
                {global.priceSingleComite}€ por comité)
              </p>
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={this.onChangePayment}
                value={this.state.checkedPay}
              >
                <Row className="listcomites">{listComitesExtra}</Row>
              </Checkbox.Group>
              <hr></hr>
              {precioTotal}
              <p>Elija el método de pago que prefiera:</p>
              <Radio.Group buttonStyle="solid">
                {methodPaymentAvailable}
              </Radio.Group>
              <hr></hr>
            </div>

            {buttonPay}
          </Form>
        );
      }
    }
    console.log(this.state.asociacionValue);
    return (
      <div>
        {title}
        <Container>
          <Row>
            <Col sm={12}>{messageLogin}</Col>
          </Row>
          <Row>
            <Col>
              {this.state.renovationTime === true &&
              this.state.statusDoc !== 1 ? (
                <div className="alert alert-info" role="alert">
                  <p>
                    Está en proceso de renovación de afiliación: le aparece
                    marcado la asociación a la que pertenecía en anterior
                    afiliación; si hay algún cambio puede elegir otra
                    asociación. A continuación adjunte la documentación
                    requerida para este tipo de afiliación.
                  </p>
                </div>
              ) : this.state.renovationTime === true &&
                this.state.statusDoc === 1 ? (
                <div className="alert alert-info" role="alert">
                  <p>
                    Está en proceso de renovación de afiliación: le aparecen
                    marcados los comités a los que se afilió el año anterior.
                    Puede cambiarlos si lo desea. Elija después el método de
                    pago con el que quiere renovar y pulse el botón "IR AL PAGO"
                  </p>
                </div>
              ) : null}
            </Col>
          </Row>
          <Row>{productInfo}</Row>
          {doc_required_form}
        </Container>
      </div>
    );
  }
}
export default withRouter(ProductComiteAndDoc);
