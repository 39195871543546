import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import ButtonFES from "../../objects/button";
import Contacto from "../../WidgetsSidebar/contacto";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import ContactImage from "../../WidgetsSidebar/contactImage";
import ReactGA from "react-ga";
import axios from "axios";
import ActualidadResume from "../../objects/actualidadResume";
import NoticiaResume from "../../objects/noticiaResume";
import MultimediaResume from "../../objects/multimediaResume";
import { Pagination } from "antd";

class Defaultnews extends Component {
	constructor(props) {
		super(props);
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			permalink: props.permalink,
			menu: [],
			minValue: 0,
			maxValue: 9,
			current: 1,
		};
	}
	componentDidMount() {
		const { permalink } = this.state;
		ReactGA.pageview(window.location.pathname + window.location.search);
		if (permalink) {
			let formData2 = new FormData();
			formData2.append("permalink", permalink);
			axios
				.post("/api/pages_get_by_permalink", formData2)
				.then((res) => {
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							menu: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		} else {
			return null;
		}
	}
	handleChange = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 9,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 9 - 9,
				maxValue: value * 9,
				current: value,
			});
		}
	};
	render() {
		const { array_post } = this.state;
		const { menu } = this.state;

		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});

		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					key={index}
					menu_title="Menú"
					page_array={menu}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
				></MenuPageSidebar>
			);
		});

		const notice = array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		const ButtonDownloadDoc = array_post.map((post, index) => {
			if (!post.doc) {
				return null;
			} else {
				return (
					<div key={index}>
						<h5 className="titleSidebar">Documentos adjuntos</h5>
						<ButtonFES
							button_text={post.doc}
							icon="faEnvelope"
							button_class="button"
							button_link={post.doc}
							key={post.id}
						></ButtonFES>
					</div>
				);
			}
		});

		// eslint-disable-next-line
		let relatedNews;
		if (array_post.length > 0 && array_post[0].extra_default.news.length > 0) {
			relatedNews = array_post[0].extra_default.news
				.sort(function (a, b) {
					a = a.published_date.split("/").reverse().join("");
					b = b.published_date.split("/").reverse().join("");
					return b > a ? 1 : b < a ? -1 : 0;
					// return a.localeCompare(b);         // <-- alternative
				})
				.slice(+this.state.minValue, +this.state.maxValue)
				.map((news, index) => {
					if (+array_post[0].extra_default.type === 0) {
						return (
							<Col lg={4} sm={6} key={news.id}>
								<NoticiaResume
									key={index}
									title={news.title}
									link={news.permalink}
									date={news.published_date}
									img={news.cover_image}
									short_description={news.short_description}
								></NoticiaResume>
							</Col>
						);
					} else if (+array_post[0].extra_default.type === 1) {
						return (
							<Col lg={4} sm={6} key={news.id}>
								<ActualidadResume
									key={index}
									title={news.title}
									link={news.permalink}
									date={news.published_date}
									img={news.cover_image}
									short_description={news.short_description}
								></ActualidadResume>
							</Col>
						);
					} else if (+array_post[0].extra_default.type === 3) {
						return (
							<Col lg={4} sm={6} key={news.id}>
								<MultimediaResume
									key={index}
									title={news.title}
									link={news.permalink}
									date={news.published_date}
									img={news.cover_image}
									short_description={news.short_description}
								></MultimediaResume>
							</Col>
						);
					} else return null;
				});
		} else {
			relatedNews = (
				<Col lg={12} sm={12}>
					<p>No hay noticias</p>
				</Col>
			);
		}

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col lg={9} md={12}>
							{notice}
							<h3 className="center">Noticias</h3>
							<Row>{this.state.array_post.length ? relatedNews : null}</Row>
							<div className="pagination">
								<Pagination
									key={this.state.relatedNews}
									defaultCurrent={1}
									current={this.state.current}
									pageSize={9}
									onChange={this.handleChange}
									total={array_post[0].extra_default.news.length}
								/>
							</div>
						</Col>

						<Col className="sidebar" lg={3} md={12}>
							<ContactImage />
							<Contacto />
							{ButtonDownloadDoc}
							{sidebarMenuPage}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Defaultnews;
