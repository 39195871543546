import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import "../style/styles_top3.css";

class Top3 extends Component {
	state = {
		posts: [],
	};
	componentDidMount() {
		//Call to webservice to get all news
		axios.get("/api/design_home_featured_section_get").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				posts: res.data.records.slice(0, 6),
			});
		});
	}

	render() {
		const { posts } = this.state;
		const postList = posts.length ? (
			posts.map((post) => {
				if (post.title === null) {
					return null;
				} else {
					return (
						<Col key={post.id} xs={12} sm={4} className="marginBottom-15">
							<div className="inner center">
								<img
									src={`${post.img_url}`}
									alt={`${post.title}`}
									className="imgTop3"
								></img>
								<h3 className="title-feature">{post.title}</h3>
								<p className="center">{post.summary}</p>
								<button className="button">
									<a
										href={post.btn_url}
										target="_blank"
										rel="noopener noreferrer"
									>
										{post.btn_text}
									</a>
								</button>
							</div>
						</Col>
					);
				}
			})
		) : (
			<div className="center">No hay post</div>
		);
		return (
			<div className="top3">
				<Container>
					<Row className="bg-white top3-row shadow border-radius-3">
						{postList}
					</Row>
				</Container>
			</div>
		);
	}
}

export default Top3;
