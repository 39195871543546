import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../../components/objects/topImageTitle";
import SEO from "../../components/objects/seo";
import NoticiaResume from "../../components/objects/noticiaResume";
class SalaDePrensa extends React.Component {
	constructor() {
		super();
		this.state = {
			news: [],
			newsList: [],
		};
	}

	componentDidMount() {
		//Call to webservice to get all news
		let formData = new FormData();
		formData.append("id_categ", "21");
		axios.post("/api/news_get_by_id_categ", formData).then((res) => {
			this.setState({
				news: res.data.records,
			});
		});
	}

	render() {
		const title = (
			<TopImageTitle
				key="0001"
				key_id="00003"
				title="Sala de Prensa"
				cover_image=""
				subtitle="Listado de noticias de Sala de Prensa"
				bread01="Sala de Prensa"
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="Conoce toda la asctualizad del FES"
				description="Todas las noticias del FES"
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);
		const { news } = this.state;
		const newsList = news.length ? (
			news.map((news, index) => {
				return (
					<Col
						lg={4}
						sm={6}
						key={news.id}
						className={
							"new-cat d-flex category-filter category-" +
							news.categories.map((categories) => categories.id)
						}
					>
						<NoticiaResume
							key={index}
							title={news.title}
							img={news.cover_image}
							categories={news.categories}
							link={news.permalink}
							meta_description={news.meta_description}
							published_date={news.published_date}
						></NoticiaResume>
					</Col>
				);
			})
		) : (
			<div className="center">No hay noticias</div>
		);

		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row>{newsList}</Row>
				</Container>
			</section>
		);
	}
}

export default SalaDePrensa;
