import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Admin from "./admin/admin";
import Header from "./frontal/components/header/";
import Footer from "./frontal/components/footer/";
import Homepage from "./frontal/Scenes/homepage";
import Actividades from "./frontal/Scenes/actividades";
import Actividad from "./frontal/Scenes/actividad";
import Agenda from "./frontal/Scenes/agenda";
import Login from "./frontal/Scenes/login";
import Comite from "./frontal/Scenes/comite";
import Contacto from "./frontal/Scenes/contacto";
import Informate from "./frontal/Scenes/informate";
import Miembros from "./frontal/Scenes/miembros";
import Registro from "./frontal/Scenes/registro";
import Noticias3 from "./frontal/Scenes/noticias3";
import Page from "./frontal/Scenes/page";
import Pages01 from "./frontal/Scenes/page01";
import Categoria from "./frontal/Scenes/categoria";
import Noticia from "./frontal/Scenes/noticia";
import Congreso from "./frontal/Scenes/congreso";
import Formacion from "./frontal/Scenes/formacion";
import Cta from "./frontal/components/footer/cta";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AsociacionesInsituciones from "./frontal/Scenes/asociaciones-e-insituciones";
import Perfil from "./frontal/Scenes/perfil";
import RecordarPassword from "./frontal/Scenes/recordar-password";
import CambiarPassword from "./frontal/Scenes/cambiarPassword";
import Publicaciones from "./frontal/Scenes/noticias/publicaciones";
import SalaDePrensa from "./frontal/Scenes/noticias/sala-de-prensa";
import Page404 from "./frontal/Scenes/page404";
import GTrabajo from "./frontal/Scenes/gTrabajo";
import ConferenciaEvento from "./frontal/Scenes/conferenciasEventos";
import HistoricoNews from "./frontal/Scenes/historicoNews";
import Products01 from "./frontal/Scenes/products01";
import ConfirmationPayment from "./frontal/Scenes/confirmationPayment";
import ActualidadNotice from "./frontal/Scenes/actualidadNotice";
import MultimediaNotice from "./frontal/Scenes/multimediaNotice";
import ActualidadCategoria from "./frontal/Scenes/categoriaActualidad";
import MultimediaCategoria from "./frontal/Scenes/categoriaMultimedia";
import ConferenciasEventos from "./frontal/Scenes/categoriaConfEventos";
import ConferenciaEventoNotice from "./frontal/Scenes/conferenciasEventosNotice";
import HistoricoActividadesFES from "./frontal/Scenes/historicoActividadesFES";
import EscuelaDoctoradoNotice from "./frontal/Scenes/escuelaDeDoctoradoNotice";
import ActividadesNotice from "./frontal/Scenes/actividadesNotice";
import ActividadesFESCategoria from "./frontal/Scenes/categoriaActividadesFES";
import HistoricoEscuelaDoctorado from "./frontal/Scenes/historicoEscuelaDoctorado";
import HistoricoActualidad from "./frontal/Scenes/historicoActualidad";
import PaymentOK from "./frontal/Scenes/confirmationsPayment/paymentOk";
import PaymentKO from "./frontal/Scenes/confirmationsPayment/paymentKO";
import Unsuscribe from "./frontal/Scenes/unsuscribe";
import UsersPublicProfile from "./frontal/Scenes/user";
import AdminComites from "./frontal/Scenes/UserProfiles/AdminFromProfile/adminComites";
import ElectionComite from "./frontal/Scenes/UserProfiles/elecction-comites";
import SendNew from "./frontal/components/templates/pages/sendNew";
import Search from "./frontal/components/templates/pages/search";
import SearchPages from "./frontal/components/templates/pages/searchPages";
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";
import VotesAssembly from "./frontal/Scenes/UserProfiles/votes-assembly";
import VotesComiteEjecutivo from "./frontal/Scenes/UserProfiles/votes-comites";
import { HelmetProvider } from 'react-helmet-async';

//console.log(Cookies.get());

if (Cookies.get("fesAgreeCookie") === "false") {
	Cookies.remove("fesAgreeCookie");
}

const App = () => {
	return (
		<div>
			<HelmetProvider>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<Header />
				<Switch>
					<Route path="/" exact component={Homepage} />
					<Route
						path="/perfil/eleccion-comites"
						exact
						render={() =>
							!localStorage.getItem("access_token") ? (
								<Login />
							) : (
								<ElectionComite />
							)
						}
					/>
					<Route
						path="/perfil/votacion-asamblea"
						exact
						render={() =>
							!localStorage.getItem("access_token") ? (
								<Login />
							) : (
								<VotesAssembly />
							)
						}
					/>
					<Route
						path="/perfil/eleccion-comite-ejecutivo"
						exact
						render={() =>
							!localStorage.getItem("access_token") ? (
								<Login />
							) : (
								<VotesComiteEjecutivo />
							)
						}
					/>
					<Route path="/contacto" exact component={Contacto} />
					<Route path="/usuario/:id" exact component={UsersPublicProfile} />

					<Route path="/newsletter/bajas" exact component={Unsuscribe} />
					<Route path="/actividades" exact component={Actividades} />
					<Route
						path="/categoria-actualidad/:id"
						exact
						component={ActualidadCategoria}
					/>
					<Route
						path="/categoria-actividades-fes/:id"
						exact
						component={ActividadesFESCategoria}
					/>
					<Route
						path="/categoria-escuela-doctorado/:id"
						exact
						component={ConferenciasEventos}
					/>
					<Route
						path="/conferencias-eventos/:permalink"
						exact
						component={ConferenciaEventoNotice}
					/>
					<Route
						path="/historico-actividades-fes"
						exact
						component={HistoricoActividadesFES}
					/>
					<Route
						path="/multimedia/:permalink"
						exact
						component={MultimediaNotice}
					/>

					<Route
						path="/categoria-multimedia/:id"
						exact
						component={MultimediaCategoria}
					/>
					<Route path="/producto/:id" exact component={Products01} />

					<Route
						path="/actualidad/:permalink"
						exact
						component={ActualidadNotice}
					/>
					<Route
						path="/actividades-fes/:permalink"
						exact
						component={ActividadesNotice}
					/>
					<Route
						path="/escuela-doctorado/:permalink"
						exact
						component={EscuelaDoctoradoNotice}
					/>

					<Route
						path="/confirmacion-de-pago"
						exact
						render={() =>
							!localStorage.getItem("access_token") ? (
								<Login />
							) : (
								<ConfirmationPayment />
							)
						}
					/>
					<Route
						path="/informate/Publicaciones"
						exact
						component={Publicaciones}
					/>

					<Route path="/informate/sala_prensa" exact component={SalaDePrensa} />
					<Route path="/actividad/:permalink" exact component={Actividad} />
					<Route
						path="/conferencias-eventos/:permalink"
						exact
						component={ConferenciaEvento}
					/>
					<Route path="/formacion/:permalink" exact component={Formacion} />

					<Route path="/noticias_fes" exact component={Noticias3} />
					<Route
						path="/historico-noticias/:year"
						exact
						component={HistoricoNews}
					/>
					<Route
						path="/historico-actualidad/:year"
						exact
						component={HistoricoActualidad}
					/>
					<Route
						path="/historico-actividades-fes/:year"
						exact
						component={HistoricoActividadesFES}
					/>
					<Route
						path="/historico-escuela-doctorado/"
						exact
						component={HistoricoEscuelaDoctorado}
					/>
					<Route
						path="/login"
						exact
						render={() =>
							!localStorage.getItem("access_token") ? <Login /> : <Perfil />
						}
					/>
					<Route path="/registro" exact component={Registro} />
					<Route
						path="/grupo-de-trabajo/:permalink"
						exact
						component={GTrabajo}
					/>
					<Route path="/buscador" exact component={Search} />
					<Route path="/buscador-paginas" exact component={SearchPages} />
					<Route path="/enviar-noticia-FES" exact component={SendNew} />
					<Route path="/recordar-password" exact component={RecordarPassword} />
					<Route
						path="/pago-realizado-correctamente"
						exact
						component={PaymentOK}
					/>
					<Route path="/error-en-el-pago" exact component={PaymentKO} />
					<Route
						path="/cambiar-password-olvidada/:tokkenPSW"
						exact
						component={CambiarPassword}
					/>
					<Route path="/categoria/:id" exact component={Categoria} />
					<Route
						exact
						path="/perfil"
						render={() =>
							!localStorage.getItem("access_token") ? (
								<Redirect to="/login" />
							) : (
								<Perfil />
							)
						}
					/>
					<Route
						exact
						path="/perfil/:status"
						render={() =>
							!localStorage.getItem("access_token") ? (
								<Redirect to="/login" />
							) : (
								<Perfil />
							)
						}
					/>
					<Route
						exact
						path="/perfil/admin/comite/:id"
						render={() =>
							!localStorage.getItem("access_token") ? (
								<Redirect to="/login" />
							) : (
								<AdminComites />
							)
						}
					/>
					<Route path="/404" exact component={Page404} />

					<Route
						path="/noticia/:permalink/:cat/:id"
						exact
						component={Noticia}
					/>
					<Route path="/noticia/:permalink" exact component={Noticia} />
					<Route path="/comite/:permalink" exact component={Comite} />

					<Route path="/:page" exact component={Pages01} />
					<Route path="/:menu/:page/:subpage" exact component={Pages01} />
					<Route path="/:menu/:page" exact component={Pages01} />
					<Route path="/:menu" exact component={Pages01} />
					<Route path="/admin" exact component={Admin} />

					<Route path="/miembros" exact component={Miembros} />

					<Route path="/informate" exact component={Informate} />

					<Route path="/agenda" exact component={Agenda} />

					<Route
						path="/asociaciones-e-insituciones/:permalink"
						exact
						component={AsociacionesInsituciones}
					/>
					<Route path="/formacion" exact component={Formacion} />

					<Route path="/page/:permalink" exact component={Page} />

					<Route
						path="/congreso/:permalink/:cat/:id"
						exact
						component={Congreso}
					/>
				</Switch>
				<Cta />
				<Footer />
				<CookieConsent
					enableDeclineButton
					hideOnAccept={true}
					buttonText="Acepto"
					declineButtonText="Rechazo"
					cookieName="fesAgreeCookie"
					expires={999}
					onAccept={() => {}}
					buttonStyle={{ backgroundColor: "#e79f18" }}
					onDecline={() => {
						alert(
							"No ha aceptado el uso de cookies, vamos a proceder a cerrar esta página."
						);
						window.open("about:blank", "_self");
						window.close();
					}}
				>
					Esta página depende del uso de cookies para un buen funcionamiento, si
					no las acepta no podrá hacer uso de la web.
				</CookieConsent>
			</BrowserRouter>
			</HelmetProvider>
		</div>
	);
};

export default App;
