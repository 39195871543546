import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import Contacto from "../../WidgetsSidebar/contacto";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import ContactImage from "../../WidgetsSidebar/contactImage";
import ActualidadResume from "../../objects/actualidadResume";
import ReactGA from "react-ga";

class TesisDoctoral extends Component {
	constructor(props) {
		super();
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			permalink: props.permalink,
			filterCat: "",
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
			menu: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}
	changeFilterCat = (e) => {
		this.setState({
			filterCat: e.target.value.substring(9, 20),
			currentPage: 1,
			activeId: e.target.value.substring(9, 20),
		});
	};

	getActivities = async () => {
		let formData = new FormData();
		formData.append("id_categ", 15);
		axios
			.post("/api/topicality_get_by_id_categ", formData)
			.then((res) => {
				this.setState({
					news: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
	};

	componentDidMount() {
		(async () => {
			await this.getActivities();
			await this.collapsible();
		})();

		const { permalink } = this.state;

		if (permalink) {
			let formData2 = new FormData();
			formData2.append("permalink", permalink);
			axios
				.post("/api/pages_get_by_permalink", formData2)
				.then((res) => {
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							menu: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		} else {
			return null;
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { array_post } = this.state;

		const { menu } = this.state;
		const { news } = this.state;
		const newsList = news.length ? (
			news.map((news, index) => {
				return (
					<Col
						lg={4}
						sm={6}
						key={news.id}
						className={
							"new-cat d-flex category-filter category-" +
							news.categories.map((categories) => categories.id)
						}
					>
						<ActualidadResume
							key={index}
							title={news.title}
							img={news.cover_image}
							categories={news.categories}
							link={news.permalink}
							short_description={news.short_description}
							meta_description={news.meta_description}
							published_date={news.published_date}
						></ActualidadResume>
					</Col>
				);
			})
		) : (
			<div className="center">
				{" "}
				Estamos trabajando en la programación de nuevas actividades. Os
				mantendremos informados.
			</div>
		);

		const notice = array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});
		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					key={index}
					menu_title="Menú"
					page_array={menu}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
				></MenuPageSidebar>
			);
		});
		this.collapsible();
		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col lg={9} md={12}>
							{notice}
							<Row>{newsList}</Row>
						</Col>
						<Col className="sidebar" lg={3} md={12}>
							<ContactImage />
							<Contacto />
							{sidebarMenuPage}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default TesisDoctoral;
