import React from "react";




const Informate = () => {
 return <div className="margin-header"> 
<p>Esto es el cuerpo de la pagina de Informate</p>
    </div>;
}
;

export default Informate;
