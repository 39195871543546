import React from "react";
import { Row, Col, Container } from "react-grid-system";
import Carousel from "react-multi-carousel";
import axios from "axios";
import "react-multi-carousel/lib/styles.css";
import "../footer/style.css";
import { Link } from "react-router-dom";
import NoticiaResume from "../objects/noticiaResume";
const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

// class component
class LastMinutesNews extends React.Component {
	state = {
		sliderPosts: [],
		newsToavoid: [],
		newsToavoidId: [],
		newsToShow: [],
	};
	componentDidMount() {
		//Call to webservice to get all news

		(async () => {
			await this.getSliderPosts();
			await this.getNewsAvoid();
			await this.getArraynews();
			await this.cleanNews();
		})();
	}
	getSliderPosts = async () => {
		await axios
			.post("/api/news_get_by_last_year")
			.then((res) => {
				this.setState({
					sliderPosts: res.data.records.slice(0, 10),
				});
			})
			.catch((error) => {});
	};
	getNewsAvoid = async () => {
		await axios
			.get("/api/design_home_featured_news_get")
			.then((res) => {
				this.setState({
					//WE make sure to get only the last 3
					newsToavoid: res.data.records.slice(0, 3),
				});
			})
			.catch((error) => {});
	};
	getArraynews = async () => {
		const { newsToavoid } = this.state;
		if (newsToavoid.length > 0) {
			newsToavoid.map((news, index) => {
				this.state.newsToavoidId.push(news.id);
				return null;
			});
		}
	};

	cleanNews = async () => {
		const { sliderPosts } = this.state;
		sliderPosts.map((slider, index) => {
			if (this.state.newsToavoidId.some((r) => slider.id.includes(r))) {
				return null;
			} else {
				var joined = this.state.newsToShow.concat(slider);
				this.setState({ newsToShow: joined });
				return null;
			}
		});
	};
	render() {
		const { newsToShow } = this.state;

		const sliderList = newsToShow.map((slider, index) => {
			var idcat = slider.categories.map((categories) => categories.id);
			//Array de categorias que no queremos mostrar
			const catNotWant = global.notWantedCategoriesNews;
			if (catNotWant.some((r) => idcat.includes(r))) {
				return null;
			} else {
				return (
					<Col
						key={slider.id}
						className={
							"canalNews new-cat d-flex category-filter category-" +
							slider.categories.map((categories) => categories.id)
						}
					>
						<NoticiaResume
							key={index}
							title={slider.title}
							img={slider.cover_image}
							categories={slider.categories}
							link={slider.permalink}
							short_description={slider.short_description}
							meta_description={slider.meta_description}
							date={slider.published_date}
							button_txt="+Explorar"
						></NoticiaResume>
					</Col>
				);
			}
		});

		return (
			<section className="lighGrayBG">
				<Container>
					<Row className="lastMinuteNews">
						<Col className="center introText">
							<h2>Canal de Noticias</h2>
							<Link to="/Divulgación/noticias">Explorar todas +</Link>
						</Col>
					</Row>
					<Row>
						<Col>
							<Carousel
								responsive={responsive}
								infinite={true}
								className="canalNoticias"
							>
								{!sliderList ? "No hay noticias" : sliderList}
							</Carousel>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default LastMinutesNews;
