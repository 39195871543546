import React from "react";
import TopImageTitle from "../../objects/topImageTitle";
import { Row, Col, Container } from "react-grid-system";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { Form, Radio } from "antd";
import { Table, Button } from "react-bootstrap";
//import ProductResume from "../components/objects/ProductResume";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

class ProductBasicAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prod_info: props.prod_info,
			products: [],
			type: "",
			comites: [],
			checked: [],
			checkedPay: [],
			acepto: "",
			paymentMethod: "",
			paymentMethodChossen: "",
			price: "",
			doc_required: "",
			id_product: "",
			islogged: "",
			userInformation: [],
			userInfoPayments: [],
			signaturesRedsys: "",
			titleChecked: [],
			numberofTry: 0,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		(async () => {
			await this.getUserInformation();
			await this.getProductsByID();
			await this.getPaymentMethods();
		})();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	getUserInformation = async () => {
		const jwt = localStorage.getItem("access_token");
		//console.log("Aqui esta el jwt: ");
		//console.log(jwt);
		if (!localStorage.getItem("access_token")) {
			this.setState({
				islogged: 0,
			});
		} else {
			this.setState({
				islogged: 1,
			});
			let formData = new FormData();
			formData.append("jwt", jwt);
			axios
				.post("/api/validate_token", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						userInformation: res.data.data,
					});
					this.checkIfColectivo();
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	};

	checkIfColectivo() {
		const { userInformation } = this.state;
		console.log(userInformation);
		if (
			+userInformation.id_type === 4 ||
			+userInformation.id_type === 5 ||
			+userInformation.id_type === 6 ||
			+userInformation.id_type === 7
		) {
			return null;
		} else {
			this.props.history.push({
				pathname: "/404",
			});
		}
	}
	getProductsByID = async () => {
		const id = this.props.match.params.id;
		let formData = new FormData();
		formData.append("id", id);
		await axios.post("/api/products_get_by_id", formData).then((res) => {
			this.setState({
				products: res.data.records,
				doc: res.data.records[0].doc_required,
				idtemplate: res.data.records[0].id_template,
			});
			var price00 = res.data.records[0].price;
			var doc_required00 = res.data.records[0].doc_required;
			var idProduct00 = res.data.records[0].id;
			this.setState({
				price: price00,
				doc_required: doc_required00,
				id_product: idProduct00,
			});
		});
	};
	getPaymentMethods = async () => {
		const { userInformation } = this.state;

		if (!localStorage.getItem("access_token")) {
		} else {
			let formData = new FormData();

			formData.append("id_user", userInformation.id);
			await axios
				.post("/api/payment_get_exceptions_by_id_user", formData)
				.then((res) => {
					//console.log(res);
					this.setState({
						userInfoPayments: res.data.records,
					});
				})
				.catch((error) => {});
		}
	};
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params !== prevProps.match.params) {
			(async () => {
				await this.getUserInformation();
				await this.getProductsByID();
				await this.getPaymentMethods();
			})();
		}
	}

	onChangePayment = (checkedValues) => {
		this.setState(() => {
			return { checkedPay: checkedValues };
		});
	};
	isDisabledFree = (id) => {
		return (
			this.state.checked.length > 3 && this.state.checked.indexOf(id) === -1
		);
	};
	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;
		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	handlePaymentProduct = (event) => {
		this.refs.btnPayment.setAttribute("disabled", "disabled");
		if (+this.state.paymentMethodChossen === 1) {
			const id_product = this.props.match.params.id;
			const free_comites = this.state.checked;
			const paid_comites = this.state.checkedPay;

			const { userInformation } = this.state;
			const totalAmout =
				(+this.state.price +
					global.priceSingleComite * this.state.checkedPay.length) *
				100;
			const idPaymentMethod = +this.state.paymentMethodChossen;
			const product_description = this.props.match.params.id;
			let formData = new FormData();
			formData.append("id_user", userInformation.id);
			formData.append("id_product", id_product);
			formData.append("amount", totalAmout);
			formData.append("payment_type", idPaymentMethod);
			formData.append("product_description", product_description);
			formData.append("free_comites", JSON.stringify(free_comites));
			formData.append("paid_comites", JSON.stringify(paid_comites));

			axios.post("/api/redsys_full_set_get_signature", formData).then((res) => {
				//console.log(res);
				this.setState({
					signaturesRedsys: res.data,
				});
				this.refs.btnPayment.removeAttribute("disabled");
				this.props.history.push({
					pathname: "/confirmacion-de-pago",
					state: {
						signatureRedsys: this.state.signaturesRedsys,
						totalAmout: totalAmout,
						paymentMethodChossen: this.state.paymentMethodChossen,
						products: this.state.products,
						goPayment: 1,
						listFreeComites: this.state.checkedItemsFree,
						listPaidComites: this.state.checkedItemsPaid,
						payment_type: 1,
						userInfo: userInformation,
						id_user: userInformation.id,
						idTemplate: this.state.idtemplate,
					},
				});
			});
		} else if (+this.state.paymentMethodChossen === 2) {
			const id_product = this.props.match.params.id;
			const { userInformation } = this.state;
			const totalAmout =
				(+this.state.price +
					global.priceSingleComite * this.state.checkedPay.length) *
				100;
			this.refs.btnPayment.removeAttribute("disabled");
			this.props.history.push({
				pathname: "/confirmacion-de-pago",
				state: {
					id_product: id_product,
					totalAmount01: totalAmout,
					paymentMethodChossen: this.state.paymentMethodChossen,
					products: this.state.products,
					goPayment: 2,
					payment_type: 2,
					userInfo: userInformation,
					id_user: userInformation.id,
					idTemplate: this.state.idtemplate,
					listFreeComites: this.state.checkedItemsFree,
					listPaidComites: this.state.checkedItemsPaid,
				},
			});
		} else if (+this.state.paymentMethodChossen === 3) {
			const id_product = this.props.match.params.id;
			const { userInformation } = this.state;
			const totalAmout =
				(+this.state.price +
					global.priceSingleComite * this.state.checkedPay.length) *
				100;
			this.refs.btnPayment.removeAttribute("disabled");
			this.props.history.push({
				pathname: "/confirmacion-de-pago",
				state: {
					id_product: id_product,
					totalAmount01: totalAmout,
					paymentMethodChossen: this.state.paymentMethodChossen,
					products: this.state.products,
					goPayment: 3,
					payment_type: 3,
					userInfo: userInformation,
					id_user: userInformation.id,
					idTemplate: this.state.idtemplate,
					listFreeComites: this.state.checkedItemsFree,
					listPaidComites: this.state.checkedItemsPaid,
				},
			});
		}

		//console.log(+this.state.price + 18 * this.state.checkedPay.length);
	};
	handleAcepto = (evt) => {
		this.setState({ acepto: evt.target.checked });
	};
	handlePaymentMethod = (evt) => {
		this.setState({ paymentMethodChossen: evt.target.value });
		this.setState({ paymentMethod: evt.target.checked });
	};

	render() {
		this.collapsible();
		//const { acepto, paymentMethod } = this.state;
		//const isEnabled = acepto === true && paymentMethod === true;

		const { paymentMethod } = this.state;
		const isEnabled = paymentMethod === true;
		const { products } = this.state;
		const { islogged } = this.state;
		const { userInfoPayments } = this.state;
		const { userInformation } = this.state;

		const productInfo = products.map((prod) => {
			if (prod.type !== null) {
				return (
					<Col sm={12} key={prod.id}>
						<span>
							{this.state.renovationTime === true ? (
								<Link
									to={{
										pathname: `/perfil`,
										state: {
											key: "afiliationData",
										},
									}}
									className="buttonGoBack button"
								>
									Volver atrás
								</Link>
							) : (
								<a href="/afiliate" className="buttonGoBack button">
									Volver atrás
								</a>
							)}
						</span>
						<h1 className="product-Title">{prod.name}</h1>
						<div dangerouslySetInnerHTML={{ __html: prod.content }} />
					</Col>
				);
			} else {
				return <Redirect to="/404" />;
			}
		});

		const precioTotal = products.map((prod) => {
			return (
				<Table striped key={prod.id}>
					<tbody>
						<tr>
							<th>Concepto</th>
							<th>Cantidad</th>
							<th>Precio</th>
							<th>IVA</th>
						</tr>
						<tr>
							<td>{prod.name}</td>
							<td>1</td>
							<td>{prod.price}€</td>
							<td>
								{prod.iva && +prod.iva !== 0
									? "El precio incluye un IVA del " + prod.iva + "%"
									: "Exento de IVA"}
							</td>
						</tr>
						<tr>
							<td>Total</td>
							<td></td>
							<td> {+prod.price}€</td>
							<td>
								{prod.iva && +prod.iva !== 0
									? "El precio incluye un IVA del " + prod.iva + "%"
									: "Exento de IVA"}
							</td>
						</tr>
					</tbody>
				</Table>
			);
		});
		const title = products.map((prod) => {
			if (prod.type !== null) {
				return <TopImageTitle title={prod.name} key={prod.id}></TopImageTitle>;
			} else {
				return null;
			}
		});

		let methodPaymentAvailable;
		console.log(userInfoPayments);
		if (
			!userInfoPayments ||
			userInfoPayments === "" ||
			userInfoPayments.length < 1
		) {
			console.log("Aqui");
			methodPaymentAvailable = (
				<span>
					<Radio.Button
						value="2"
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
					>
						Transferencia
					</Radio.Button>
				</span>
			);
		} else if (userInfoPayments) {
			methodPaymentAvailable = userInfoPayments.map((pay) => {
				return (
					<Radio.Button
						value={pay.id}
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
						key={pay.id}
					>
						{pay.name}
					</Radio.Button>
				);
			});
		}
		let messageLogin;
		let buttonPay;
		if (+islogged === 0) {
			buttonPay = (
				<p>
					Debe hacer{" "}
					<Link
						to={{
							pathname: `/login`,
							state: {
								origin: 0,
								url_permalink: "producto/" + this.props.match.params.id,
							},
						}}
					>
						Login/Registrarse
					</Link>{" "}
					para poder afiliarse. Si ya tiene una cuenta de usuario, acceda con
					sus datos de registro. Si es nuevo en la web de FES, dese de alta.
				</p>
			);
			messageLogin = (
				<div className="alert alert-warning" role="alert">
					<p>
						Debe hacer{" "}
						<Link
							to={{
								pathname: `/login`,
								state: {
									origin: 0,
									url_permalink: "producto/" + this.props.match.params.id,
								},
							}}
						>
							Login/Registrarse
						</Link>{" "}
						para poder afiliarse. Si ya tiene una cuenta de usuario, acceda con
						sus datos de registro. Si es nuevo en la web de FES, dese de alta.
					</p>
				</div>
			);
		} else if (+islogged === 1) {
			if (
				+userInformation.id_type === +4 ||
				+userInformation.id_type === +5 ||
				+userInformation.id_type === +7 ||
				+userInformation.id_type === +6 ||
				+userInformation.id_type === +12 ||
				+userInformation.id_type === +14 ||
				+userInformation.id_type === +10
			) {
				buttonPay = (
					<Button
						disabled={!isEnabled}
						className="button"
						variant="primary"
						type="submit"
						ref="btnPayment"
					>
						Ir a pago
					</Button>
				);
			} else {
				if (
					!userInformation.name ||
					!userInformation.lastname ||
					!userInformation.nif ||
					!userInformation.city ||
					!userInformation.org ||
					!userInformation.region ||
					!userInformation.education
				) {
					buttonPay = (
						<div className="alert alert-warning" role="alert">
							<p>
								Si desea realizar una afiliación, debe primero completar los
								datos requeridos como obligatorios en su cuenta de perfil:
								<Link to="/perfil"> ir a Mi cuenta.</Link>
							</p>
						</div>
					);
					messageLogin = (
						<div className="alert alert-warning" role="alert">
							<p>
								Si desea realizar una afiliación, debe primero completar los
								datos requeridos como obligatorios en su cuenta de perfil:
								<Link to="/perfil"> ir a Mi cuenta.</Link>
							</p>
						</div>
					);
				} else {
					buttonPay = (
						<Button
							disabled={!isEnabled}
							className="button"
							variant="primary"
							type="submit"
							ref="btnPayment"
						>
							Ir a pago
						</Button>
					);
				}
			}
		}
		let allProductInfo;
		if (+islogged === 0) {
			allProductInfo = null;
		} else {
			allProductInfo = (
				<Form onFinish={this.handlePaymentProduct}>
					<div>
						<hr></hr>
						{precioTotal}
						<p>Elija el método de pago que prefiera:</p>
						<Radio.Group buttonStyle="solid">
							{methodPaymentAvailable}
						</Radio.Group>
						<hr></hr>
					</div>

					{buttonPay}
				</Form>
			);
		}

		return (
			<div>
				{title}
				<Container>
					<Row>
						<Col sm={12}>{messageLogin}</Col>
					</Row>
					<Row>{productInfo}</Row>

					{allProductInfo}
				</Container>
			</div>
		);
	}
}
export default withRouter(ProductBasicAdmin);
