import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../style/styles_top3.css";

class CategoriesActualidad extends Component {
	state = {
		categories: [],
	};
	componentDidMount() {
		//Call to webservice to get all news
		axios.get("/api/topicality_categories_get_all").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
	}

	render() {
		const { categories } = this.state;
		const catNotWant = global.notWantedCategoriesActu;
		let listCategory;
		if (categories.length) {
			var i = 0;
			listCategory = categories.map((categories, index) => {
				if (catNotWant.some((r) => categories.id.includes(r))) {
					return null;
				} else {
					i++;
					return (
						<li key={"cat" + index}>
							<div className="numberListing"> {("0" + i).slice(-2)}</div>
							<Link to={"/categoria-actualidad/" + categories.id}>
								{categories.name}
							</Link>
						</li>
					);
				}
			});
		}
		return (
			<div className="categoryListing">
				<h5 className="titleSidebar">Menú de categorias</h5>
				<div>
					<ul className="sidebarListingNumber">
						{listCategory ? (
							listCategory
						) : (
							<p className="center">No hay categorias</p>
						)}
					</ul>
				</div>
			</div>
		);
	}
}

export default CategoriesActualidad;
