import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import ActualidadResume from "../../objects/actualidadResume";
import ReactGA from "react-ga";
import { Pagination } from "antd";

class ActualidadTemplate extends Component {
	constructor(props) {
		super();
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			filterCat: "",
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 9,
			years: [],
			activeId: null,
			minValue: 0,
			maxValue: 9,
			current: 1,
			currentTodos: [],
			_news: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}
	getYears = async () => {
		let res = await axios.get("/api/topicality_get_years");
		this.setState({
			years: res.data.records.reverse(),
		});
	};
	changeFilterCat = (e) => {
		//var catSelected = e.target.value;
		const wanted = [e.target.value];
		var GoodNews = [];
		this.state.news.map((ne, index) => {
			var idcat = ne.categories.map((categories) => categories.id);

			if (wanted.some((r) => idcat.includes(r))) {
				GoodNews.push(ne);
				return null;
			} else {
				//GoodNews.push(ne);
				return null;
			}
		});

		let _news = GoodNews;
		/*if (catSelected) {
			_news = _news.filter(
				(item) =>
					+item.categories.map((categories) => categories.id) === +catSelected
			);
		}*/

		this.setState({
			filterCat: e.target.value,
			minValue: 0,
			maxValue: 9,
			current: 1,
			activeId: e.target.value,
			_news: e.target.value === "" ? this.state.news : _news,
		});
	};
	handleChange = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 9,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 9 - 9,
				maxValue: value * 9,
				current: value,
			});
		}
	};
	getNewsCurrentYear = async () => {
		this.setState({
			newsFetching: true,
		});

		let res = await axios.get("/api/topicality_get_all");
		this.setState({
			news: res.data.records,
			_news: res.data.records,
			newsFetching: false,
		});
	};
	getYears = async () => {
		let res = await axios.get("/api/topicality_get_years");
		this.setState({
			years: res.data.records.reverse(),
		});
	};
	getCategories = async () => {
		let res = await axios.get("/api/topicality_categories_get_all");
		this.setState({
			categories: res.data.records,
		});
	};
	componentDidMount() {
		//Call to webservice to get the new
		(async () => {
			await this.getNewsCurrentYear();
			await this.getCategories();
			await this.getYears();
		})();

		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { statusPage } = this.state;
		const { array_post } = this.state;
		const { years } = this.state;
		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const yearList = years.map((year) => {
			return (
				<li key={year.year}>
					<Link to={"/historico-actualidad/" + year.year}>{year.year}</Link>
				</li>
			);
		});

		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		//	const { years } = this.state;
		//const { news } = this.state;

		//let news = this.state.news;

		const { categories } = this.state;
		//const { news } = this.state;
		const catNotWant = ["12", "13", "14", "15"];
		//clean array of categories that must not appear

		var catCount = 0;

		const categoriesList = categories.length ? (
			categories
			.sort(
				(a, b) => a.order_cat - b.order_cat
			).map((categories) => {
				var idcat = categories.id;
				if (catNotWant.some((r) => idcat.includes(r)) || idcat.length < 1) {
					return null;
				} else {
					catCount++;
					return (
						<Col
							lg={4}
							sm={6}
							key={categories.id}
							className={"categories-button category-" + categories.id}
						>
							<button
								className={
									"filterCategories " +
									(this.state.activeId === categories.id && "button")
								}
								onClick={this.changeFilterCat}
								value={categories.id}
							>
								{categories.name}
							</button>
						</Col>
					);
				}
			})
		) : (
			<div className="center">No hay categorias</div>
		);
		const newsList = this.state._news
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((news, index) => {
				return (
					<Col
						lg={4}
						sm={6}
						key={news.id}
						className={
							"new-cat d-flex category-filter category-" +
							news.categories.map((categories) => categories.id)
						}
					>
						<ActualidadResume
							key={index}
							title={news.title}
							img={news.cover_image}
							categories={news.categories}
							link={news.permalink}
							short_description={news.short_description}
							date={news.published_date}
							meta_description={news.meta_description}
						></ActualidadResume>
					</Col>
				);
			});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col lg={9} sm={12}>
							<Row>
								<Col sm={12}>
									<h5 className="center">Selecciona una categoría</h5>
								</Col>{" "}
								{catCount <= 1 ? null : categoriesList}
							</Row>
							{catCount <= 1 ? null : (
								<Row>
									<Col lg={4} sm={2}>
										<hr></hr>
									</Col>
									<Col lg={4} sm={8} className={"categories-button category-"}>
										<button
											className={"filterCategories cleanFilter false "}
											onClick={this.changeFilterCat}
											value={""}
										>
											Ver todas las categorias
										</button>
									</Col>
									<Col lg={4} sm={2}>
										<hr></hr>
									</Col>
								</Row>
							)}
							{/*CustomPagination*/}
							<Row className="hideInDesktop">
								<Col lg={12} sm={12}>
									<h4 className="center" style={{ marginTop: "10px" }}>
										Buscador
									</h4>
									<form
										className="formSearchSlider"
										action="/buscador"
										method="GET"
									>
										<div className="searchSlider">
											<input
												className="searchBarHome width-100 searchInNews"
												type="text"
												placeholder=""
												name="s"
												autoComplete="off"
											/>
											<br></br>
											<input
												className="button width-100"
												type="submit"
												value="Buscar"
											/>
										</div>
									</form>
								</Col>
							</Row>
							<Row className="listCategory">{newsList}</Row>

							<div>
								<Pagination
									key={this.state._news}
									defaultCurrent={1}
									current={this.state.current}
									pageSize={9}
									onChange={this.handleChange}
									total={this.state._news.length}
								/>
							</div>
						</Col>
						<Col lg={3} sm={12}>
							<h2>Buscador</h2>
							<form
								className="formSearchSlider"
								action="/buscador"
								method="GET"
							>
								<div className="searchSlider">
									<input
										className="searchBarHome width-100 searchInNews"
										type="text"
										placeholder=""
										name="s"
										autoComplete="off"
									/>
									<br></br>
									<input
										className="button width-100"
										type="submit"
										value="Buscar"
									/>
								</div>
							</form>
							<h2>Histórico</h2>
							<div className="yearSidebar">
								<ul>{yearList}</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default ActualidadTemplate;
