import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import Image01 from "../../objects/image01";
import defaulImageTerritorial from "../../img/member_territorial_M.png";
import defaulImageUniversitaria from "../../img/member_universitaria_M.png";
import defaulImageCientifica from "../../img/member_profesional_M.png";
import miniImageTerritorial from "../../img/asociaciones-territoriales-mini.png";
import miniImageUniversitaria from "../../img/comites-investigacion-mini.png";
import miniImageCientificas from "../../img/instituciones-cientificas-profesionales-mini.jpg";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { Pagination } from "antd";

class AsociacionTemplate01 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
			array_post: props.array_post,
			array_page: props.array_page,
			typeAsoc: "",
			current: 1,
			minValue: 0,
			maxValue: 12,
		};
	}

	componentDidMount(props) {
		window.scrollTo(0, 0);

		const { array_post } = this.state;
		array_post.forEach((post) => {
			if (+post.id === 27) {
				(async () => {
					await this.getUsers(2);
					//await this.getProducts(2);
				})();
				this.setState({
					typeAsoc: 2,
				});
			} else if (+post.id === 28) {
				(async () => {
					await this.getUsers(3);
					//await this.getProducts(3);
				})();
				this.setState({
					typeAsoc: 3,
				});
			} else if (+post.id === 29) {
				(async () => {
					await this.getUsers(4);
					//await this.getProducts(4);
				})();
				this.setState({
					typeAsoc: 4,
				});
			} else return null;
		});

		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	getProducts = async (asoc) => {
		await axios.get("/api/products_get").then((res) => {
			var tempArray = [];
			// eslint-disable-next-line
			for (const [index, value] of res.data.records.entries()) {
				if (+value.asociation_type === +asoc) {
					if (+value.state === 1) {
						tempArray.push(value);
					}
				}
			}

			/* this.setState({
				products: tempArray,
			}); */
		});
	};

	getUsers = async (id_type) => {
		let formData = new FormData();
		let formData2 = new FormData();
		let tempArray = [];

		if (id_type === 2) {
			formData.append("id_type", 4);
			formData2.append("id_type", 5);
		} else if (id_type === 3) {
			formData.append("id_type", 7);
		} else if (id_type === 4) {
			formData.append("id_type", 6);
		}

		await axios.post("/api/users_get_by_id_type", formData).then(async (res) => {
			tempArray = res.data.records;

			if (formData2.get("id_type")) {
				await axios.post("/api/users_get_by_id_type", formData2).then((res2) => {
					tempArray = [...tempArray, ...res2.data.records]
				}).catch((error) => {
					console.log("ERROR al recuperar asociaciones")
					console.log(error)
				});
			}

		}).catch((error) => {
			console.log("ERROR al recuperar asociaciones")
			console.log(error)
		});

		this.setState({
			products: tempArray,
		});

	};

	handleChange1 = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 12,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 12 - 12,
				maxValue: value * 12,
				current: value,
			});
		}
	};
	render() {
		const { array_post } = this.state;
		const { products } = this.state;
		const { typeAsoc } = this.state;

		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		const asociationList = products
			.slice(+this.state.minValue, +this.state.maxValue)
			.map((asoc) => {
				if (+typeAsoc === 3 && +asoc.id_type === 7) {
					return (
						<Col
							lg={6}
							sm={12}
							className="padding-10 shadow boxAsociaciones"
							key={asoc.id}
						>
							<Row className="margin-10">
								<Col sm={2}>
									<img
										src={!asoc.img ? defaulImageUniversitaria : asoc.img}
										alt={asoc.name}
									></img>
								</Col>
								<Col sm={10}>
									{asoc.url ? (
										<h6>
											<a
												href={asoc.url}
												rel="noopener noreferrer"
												target="_blank"
											>
												{asoc.lastname}
											</a>
										</h6>
									) : (
										<h6>{asoc.lastname}</h6>
									)}

									<p>{asoc.name}</p>
								</Col>
							</Row>
						</Col>
					);
				} else if (+typeAsoc === 4 && +asoc.id_type === 6) {
					return (
						<Col
							lg={6}
							sm={12}
							className="padding-10 shadow boxAsociaciones"
							key={asoc.id}
						>
							<Row className="margin-10">
								<Col sm={2}>
									<img
										src={!asoc.img ? defaulImageCientifica : asoc.img}
										alt={asoc.name}
										className="imgAsociaciones"
									></img>
								</Col>
								<Col sm={10}>
									{asoc.url ? (
										<h6>
											<a
												href={asoc.url}
												rel="noopener noreferrer"
												target="_blank"
											>
												{asoc.name}
											</a>
										</h6>
									) : (
										<h6>{asoc.name}</h6>
									)}

									<p>{asoc.lastname}</p>
								</Col>
							</Row>
						</Col>
					);
				} else if (+typeAsoc === 2 && +asoc.id_type === 4 || +asoc.id_type === 5) {
					return (
						<Col
							lg={6}
							sm={12}
							className="padding-10 shadow boxAsociaciones"
							key={asoc.id}
						>
							<Row className="margin-10">
								<Col sm={2}>
									<img
										src={!asoc.img ? defaulImageTerritorial : asoc.img}
										alt={asoc.name}
										className="imgAsociaciones"
									></img>
								</Col>
								<Col sm={10}>
									<h6>
										<a
											href={asoc.url}
											rel="noopener noreferrer"
											target="_blank"
										>
											{asoc.name}
										</a>
									</h6>
									<p>{asoc.lastname}</p>
								</Col>
							</Row>
						</Col>
					);
				} else {
					return null;
				}
			});

		const image = array_post.map((post, index) => {
			if (+typeAsoc === 3) {
				return (
					<Image01
						key={index}
						key_id={post.id}
						alt_title={post.title}
						class="imagePlaceholder"
						cover_image={miniImageUniversitaria}
					></Image01>
				);
			} else if (+typeAsoc === 4) {
				return (
					<Image01
						key={index}
						key_id={post.id}
						alt_title={post.title}
						class="imagePlaceholder"
						cover_image={miniImageCientificas}
					></Image01>
				);
			} else if (+typeAsoc === 2) {
				return (
					<Image01
						key={index}
						key_id={post.id}
						alt_title={post.title}
						class="imagePlaceholder"
						cover_image={miniImageTerritorial}
					></Image01>
				);
			} else {
				return null;
			}
		});

		const notice = array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>

				<Container>
					<Row className="lastMinuteNews">
						<Col lg={6} sm={12}>
							{notice}{" "}
							<div>
								<Link to="/afiliate">
									{" "}
									<button className="button margin-top-20 margin-bottom-20">
										¿Cómo afiliarse?
									</button>
								</Link>
							</div>
						</Col>
						<Col lg={6} sm={12}>
							{image}
						</Col>
					</Row>
					<Row>{asociationList}</Row>
					<Row>
						<Col sm={12} xs={12}>
							<div className="center"></div>
							<div>
								<Pagination
									key={this.state.products}
									defaultCurrent={1}
									current={this.state.current}
									pageSize={12}
									onChange={this.handleChange1}
									total={+this.state.products.length}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default AsociacionTemplate01;
