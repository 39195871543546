import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Col } from "react-grid-system";

class ProductResume extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customLink: props.customLink,
			idTemplate: props.idTemplate,
			price: props.price,
			title: props.title,
			link: props.link,
			showPrice: props.showprice,
			idProduct: props.idProduct,
			menuSelector: props.menuSelector,
		};
	}

	render() {
		const { customLink } = this.state;
		const { idTemplate } = this.state;
		const { price } = this.state;
		const { title } = this.state;
		const { link } = this.state;
		const { showPrice } = this.state;
		const { idProduct } = this.state;
		const { menuSelector } = this.state;

		var custom_anchor;
		var custom_text;
		if (+customLink === 1) {
			custom_text = "Ver más Información";
			if (+idTemplate === 1 || +idTemplate === 2) {
				custom_anchor = "#miembrosIndividualesSocioFES";
			}
			if (+idTemplate === 4) {
				custom_anchor = "#miembrosAsociacionTerritorial";
			}
			if (+idTemplate === 5) {
				custom_anchor = "#tipoA";
			}
			if (+idTemplate === 7) {
				custom_anchor = "#tipoB";
			}
			if (+idTemplate === 8) {
				custom_anchor = "#tipoC";
			}
		}
		let textPrice;
		if (+showPrice === +1) {
			textPrice = (
				<p>
					<b>Cuota:</b> {price + "€"}
				</p>
			);
		} else {
			textPrice = <span></span>;
		}
		let producto;
		if (+idProduct === 46 && +menuSelector === +1) {
			return null;
		} else {
			producto = (
				<div className="inner boxResumeProducts">
					<h5>{title}</h5>
					<div className="precio">{textPrice}</div>{" "}
					<Link smooth to={!custom_anchor ? `/producto${link}` : custom_anchor}>
						<button className="button">
							{!custom_anchor ? "Suscribirse ahora" : custom_text}
						</button>
					</Link>{" "}
				</div>
			);
		}

		return (
			<Col lg={4} sm={12}>
				{producto}
			</Col>
		);
	}
}
export default ProductResume;
