import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-grid-system";
import { Link } from "react-router-dom";
import CategoriesConferenciasEventos from "../components/WidgetsSidebar/categoriesConfyEventos";
import Newsletter from "../components/WidgetsSidebar/newsletter";
import ButtonFES from "../components/objects/button";
import ImageDefault from "../components/img/noticias_default.jpg";
import TopImageTitle from "../components/objects/topImageTitle";
import LastNews from "../components/WidgetsSidebar/lastNewsConferencyasYeventos";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import Cookies from "js-cookie";

class ConferenciaEventoNotice extends Component {
	state = {
		posts: [],
		prevPost: [],
		nxtPost: [],
		documents: [],
		postID: "",
	};

	componentDidMount() {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;
		const cat = this.props.match.params.cat;
		const id = this.props.match.params.id;
		//fetch(`noticia/${permalink}`);

		let formData = new FormData();

		if (!id) {
			formData.append("permalink", "/" + permalink);
		} else {
			formData.append(
				"permalink",
				"/" + permalink + "/" + cat + "/" + id + "/"
			);
		}

		//formData.append("permalink", pathurl);

		//Call to webservice to get the new
		axios.post("/api/lectures_get_by_permalink", formData).then((res) => {
			this.setState({
				posts: res.data.records,
				postID: res.data.records[0].id,
				documents: res.data.records[0].docs,
			});

			var prevId = res.data.records[0].id - 1;
			var nextId = +res.data.records[0].id + 1;

			var formDataprev = new FormData();
			formDataprev.append("id", prevId);
			axios
				.post("/api/lectures_get_by_id", formDataprev)
				.then((res) => {
					this.setState({
						prevPost: res.data.records,
					});
				})
				.catch(function (error) {});

			var formDatanext = new FormData();
			formDatanext.append("id", nextId);
			axios
				.post("/api/lectures_get_by_id", formDatanext)
				.then((res) => {
					this.setState({
						nxtPost: res.data.records,
					});
				})
				.catch(function (error) {});
		});
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		//var url = window.location.href.split("/");
		//var pathurl = "/" + url[4] + "/" + url[5] + "/" + url[6] + "/";
		const permalink = this.props.match.params.permalink;
		const cat = this.props.match.params.cat;
		const id = this.props.match.params.id;

		//fetch(`noticia/${permalink}`);
		if (this.props.match.params !== prevProps.match.params) {
			let formData = new FormData();

			if (!id) {
				formData.append("permalink", "/" + permalink);
			} else {
				formData.append(
					"permalink",
					"/" + permalink + "/" + cat + "/" + id + "/"
				);
			}
			//formData.append("permalink", pathurl);

			//Call to webservice to get the new
			axios.post("/api/lectures_get_by_permalink", formData).then((res) => {
				//console.log(res);
				this.setState({
					posts: res.data.records,
					postID: res.data.records[0].id,
				});

				var prevId = res.data.records[0].id - 1;
				var nextId = +res.data.records[0].id + 1;
				let formData = new FormData();
				formData.append("id", prevId);
				axios
					.post("/api/lectures_get_by_id", formData)
					.then((res) => {
						this.setState({
							prevPost: res.data.records,
						});
					})
					.catch(function (error) {});

				var formDatanext = new FormData();
				formDatanext.append("id", nextId);
				axios
					.post("/api/lectures_get_by_id", formDatanext)
					.then((res) => {
						this.setState({
							nxtPost: res.data.records,
						});
					})
					.catch(function (error) {});
			});
		}
	}
	cleanDoc(doc) {
		var getDoc = doc.substring(doc.lastIndexOf("/") + 1);
		var docClean = getDoc.split(".")[0];
		return docClean;
	}
	render() {
		const { posts } = this.state;
		const { documents } = this.state;
		const { prevPost } = this.state;
		const { nxtPost } = this.state;
		let editThisPage;
		if (Cookies.get("isAdmin") === "1") {
			editThisPage = (
				<div>
					<a
						className="button textWhite editEntryButton"
						target="_blank"
						rel="noopener noreferrer"
						href={
							global.urlAdmin + "dashboard/lectures/edit/" + this.state.postID
						}
					>
						Editar entrada
					</a>
				</div>
			);
		} else {
			editThisPage = "";
		}
		const title = posts.map((post, index) => {
			return (
				<TopImageTitle
					key={post.id}
					key_id={post.id + index}
					title={post.title}
					cover_image={post.cover_image}
					bread01={post.categories.map((categories) => categories.name)}
				></TopImageTitle>
			);
		});
		const previousPost = prevPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link
						to={`/conferencias-eventos${post.permalink}`}
						className="post-prev"
					>
						<p className="m-2 m-lg-0">Anterior Noticia</p>
						<h6 className="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const nextPost = nxtPost.map((post) => {
			return (
				<Col sm={5} key={post.id}>
					<Link
						to={`/conferencias-eventos${post.permalink}`}
						className="post-next"
					>
						<p className="m-2 m-lg-0">Siguiente Noticia</p>
						<h6 className="text-truncate d-none d-lg-block">{post.title}</h6>
					</Link>
				</Col>
			);
		});
		const notMorePrevPost = (
			<Col sm={5}>
				<Link to="#" className="post-prev">
					<p className="m-2 m-lg-0">No hay más noticias</p>
				</Link>
			</Col>
		);
		const notMorenextPost = (
			<Col sm={5}>
				<Link to="#" className="post-next">
					<p className="m-2 m-lg-0">No hay más noticias</p>
				</Link>
			</Col>
		);
		const defaultImage = posts.map((post) => {
			if (!post.cover_image) {
				return (
					<img
						key={post.id}
						src={ImageDefault}
						alt="a"
						className="blogImage"
					></img>
				);
			} else {
				return (
					<img
						key={post.id}
						src={post.cover_image}
						alt="a"
						className="blogImage"
					></img>
				);
			}
		});
		const socialShare = posts.map((post, index) => {
			var image = post.cover_image
				? post.cover_image
				: "https://fes-sociologia.com/images/fes_logo.jpg";

			return (
				<div key={index}>
					<FacebookShareButton
						url={
							global.socialShare +
							"?link=/conferencias-eventos" +
							post.permalink +
							"&image=" +
							image +
							"&title=" +
							encodeURIComponent(post.title.trim()) +
							"&description=" +
							encodeURIComponent(post.meta_description.trim()) +
							"&type=4"
						}
						quote="Federacion Española de Sociología "
					>
						<FacebookIcon size={32} round={true} />
					</FacebookShareButton>
					<TwitterShareButton
						title={post.title}
						url={window.location.href}
						hashtags={["FESociologia"]}
					>
						<TwitterIcon size={32} round={true} />
					</TwitterShareButton>
				</div>
			);
		});
		const documentsButtons = documents.map((doc, index) => {
			return (
				<ButtonFES
					button_text={doc.alt}
					button_class="button width-100"
					button_link={doc.doc}
					icon="a"
					key={index}
				></ButtonFES>
			);
		});
		const urlRegistration = posts.map((post, index) => {
			if (post.url_extra !== "") {
				console.log(post);
				return (
					<div key={index}>
						<h5 className="titleSidebar">Registrarse en actividad</h5>
						<ButtonFES
							button_text="Registro"
							button_class="button width-100"
							button_link={post.url_extra}
						></ButtonFES>
					</div>
				);
			}
			return null;
		});
		const notice = posts.map((post) => {
			var date = post.published_date;
			date = date.split(" ")[0];
			var yyyy = date.split("-")[0];
			var mmmm = date.split("-")[1];
			var dddd = date.split("-")[2];
			date = dddd + "-" + mmmm + "-" + yyyy;
			return (
				<Col key={post.id}>
					<div className="inner">
						{defaultImage}
						<span className="categoryNewsTop">
							{post.categories.map((categories, index) => (
								<Link
									className="button"
									key={categories.id + index}
									to={"/categoria-actividades-fes/" + categories.id}
								>
									<span>{categories.name}</span>
								</Link>
							))}
						</span>
						<div className="noticeInfo">
							<div>
								<p>{date}</p>
							</div>
							<div className="socialShare">{socialShare}</div>
						</div>
						<h2>{post.title}</h2>
						<div dangerouslySetInnerHTML={{ __html: post.content }} />
					</div>
					<hr className="separatorNew"></hr>
					<Container>
						<Row className="postNavigation" hidden>
							{!prevPost.length ? notMorePrevPost : previousPost}
							<Col sm={2}>
								<Link to="/actividades/conferencias-y-eventos">
									<i className="fa fa-th allPost"></i>{" "}
								</Link>
							</Col>
							{!nxtPost.length > 0 ? notMorenextPost : nextPost}
						</Row>
					</Container>
				</Col>
			);
		});

		return (
			<section>
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col className="sidebar" lg={3} md={3} sm={12}>
							{editThisPage}
							<CategoriesConferenciasEventos />
							{urlRegistration}
							{this.state.documents.length > 0 ? (
								<h5 className="titleSidebar">Documentos</h5>
							) : (
								""
							)}
							{documentsButtons}
							<Newsletter />
							<LastNews />
						</Col>
						<Col lg={9} md={9} sm={12}>
							{notice}
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default ConferenciaEventoNotice;
