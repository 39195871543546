import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import ConfEventoResume from "../../objects/confEvento";

import MenuPageSidebar from "../../objects/menuPageSidebar";
import ImageDefaultConf from "../../img/conferencias-eventos.jpg";
import Contacto from "../../WidgetsSidebar/contacto";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
class ConferenciasEventoTemplate01 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			news: [],
			categories: [],
			array_page: [],
			array_post: props.array_post,
			newList: [],
			permalink: props.permalink,
			menu: [],
			postID: "",
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let formData = new FormData();
		formData.append("id_categ", 1);
		axios
			.post("/api/lectures_get_by_id_categ", formData)
			.then((res) => {
				//console.log(res);
				this.setState({
					//Just peak the last 3
					news: res.data.records,
					postID: res.data.records[0].id,
				});
			})
			.catch(function (error) {});
		axios.get("/api/lectures_categories_get_all").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
		const { permalink } = this.state;
		if (permalink) {
			let formData2 = new FormData();
			formData2.append("permalink", permalink);
			axios
				.post("/api/pages_get_by_permalink", formData2)
				.then((res) => {
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							menu: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		} else {
			return null;
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { array_post } = this.state;
		const { menu } = this.state;
		const { news } = this.state;
		const { categories } = this.state;
		let editThisPage;
		if (Cookies.get("isAdmin") === "1") {
			editThisPage = (
				<div>
					<a
						className="button textWhite editEntryButton"
						target="_blank"
						rel="noopener noreferrer"
						href={
							global.urlAdmin + "dashboard/lectures/edit/" + this.state.postID
						}
					>
						Editar entrada
					</a>
				</div>
			);
		} else {
			editThisPage = "";
		}
		const sidebarMenuPage = categories.map((cat, index) => {
			if (+cat.id === 2) {
				return (
					<Link to={`/conferencias-y-eventos/${cat.id}`} key={index}>
						{" "}
						<button className="button width-100">{cat.name} </button>
					</Link>
				);
			} else {
				return null;
			}
		});
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});

		const notice = array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});

		const newsList = news.length ? (
			news.map((news, index) => {
				return (
					<Col
						lg={4}
						sm={6}
						key={news.id}
						className={
							"new-cat d-flex category-filter category-" +
							news.categories.map((categories) => categories.id)
						}
					>
						<ConfEventoResume
							key={index}
							title={news.title}
							img={ImageDefaultConf}
							categories={news.categories}
							link={news.permalink}
							date={news.published_date}
							short_description={news.short_description}
						></ConfEventoResume>
					</Col>
				);
			})
		) : (
			<div className="center">No hay Actividades Programadas</div>
		);
		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});
		const sidebarMenuPage1 = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					menu_title="Menú"
					page_array={menu}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					key={index}
				></MenuPageSidebar>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col lg={9} md={12}>
							{notice}
							<h4 className="center padding-20">Actividades Programadas</h4>
							<Row>{newsList}</Row>
						</Col>
						<Col lg={3} md={12} className="sidebar">
							{editThisPage}
							<div className="margin-bottom-20">{sidebarMenuPage}</div>
							{sidebarMenuPage1}
							<Contacto />
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default ConferenciasEventoTemplate01;
