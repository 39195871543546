import React, { Component } from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./slider-animations.css";
import "./styles.css";
import axios from "axios";
import ReactGA from "react-ga";

class Actividades extends Component {
	state = {
		sliders: [],
	};
	componentDidMount() {
		//Call to webservice to get all news
		axios.get("/api/design_home_slider_get").then((res) => {
			var temp_array = [];

			res.data.records.forEach((item) => {
				if (item.img !== "" && item.title !== "") {
					temp_array.push(item);
				}
			});
			this.setState({
				//Just peak the last 3
				sliders: temp_array.slice(0, 15),
			});
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { sliders } = this.state;
		const sliderList = sliders.length ? (
			sliders.map((slider) => {
				if (slider.title) {
					return (
						<div key={slider.id}>
							<div
								className="slider-content"
								style={{
									background: `url('${slider.img_url}') no-repeat center center`,
								}}
							>
								<div className="inner">
									<h2 className="titleSlider">{slider.title}</h2>
									<h3 className="sutitleSlider">{slider.subtitle}</h3>

									<a href={slider.img_href} className="button">
										Seguir leyendo
									</a>
									<form
										className="formSearchSlider"
										action="/buscador-paginas"
										method="GET"
									>
										<div className="searchSlider">
											<input
												className="searchBarHome"
												type="text"
												placeholder=""
												name="s"
												autoComplete="off"
											/>
											<input
												className="button buttonSearchSlider"
												type="submit"
												value="Buscar"
											/>
										</div>
									</form>
								</div>
							</div>
						</div>
					);
				} else {
					return (
						<div key={slider.id} hidden>
							<div
								className="slider-content"
								style={{
									background: `url('${slider.img_url}') no-repeat center center`,
								}}
							>
								<div className="inner">
									<h2 className="titleSlider">{slider.title}</h2>
									<h3 className="sutitleSlider">{slider.subtitle}</h3>

									<a href={slider.img_href} className="button">
										Seguir leyendo
									</a>
									<form
										className="formSearchSlider"
										action="/buscador"
										method="GET"
									>
										<div className="searchSlider">
											<input
												className="searchBarHome"
												type="text"
												placeholder=""
												name="s"
												autoComplete="off"
											/>
											<input
												className="button buttonSearchSlider"
												type="submit"
												value="Buscar"
											/>
										</div>
									</form>
								</div>
							</div>
						</div>
					);
				}
			})
		) : (
			<div className="center">No hay post</div>
		);
		return (
			<div>
				<Slider className="slider-wrapper" autoplay={3000}>
					{sliderList}
				</Slider>
			</div>
		);
	}
}

export default Actividades;
