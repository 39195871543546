import React from "react";

import axios from "axios";
import { Redirect } from "react-router-dom";
import ProductComiteAndNoDoc from "../components/templates/products/productComiteAndNoDoc";
import ProductComiteAndDoc from "../components/templates/products/productComiteAndDoc";
import ProductAsocAndComite from "../components/templates/products/productAsocAndComite";
import { withRouter } from "react-router-dom";
import ProductTypeA from "../components/templates/products/productTypeA";
import ProductBasic from "../components/templates/products/productBasic";
import ProductBasicAdmin from "../components/templates/products/productBasicAdmin";
import ProductCongress from "../components/templates/products/productCongress";
import ProductNormal from "../components/templates/products/productNormal";
import ProductAddComites from "../components/templates/products/addComites";

class Products01 extends React.Component {
	state = {
		products: [],
		statusPage: 0,
	};
	getProductsByID = async () => {
		const id = this.props.match.params.id;
		let formData = new FormData();
		formData.append("id", id);
		await axios
			.post("/api/products_get_by_id", formData)
			.then((res) => {
				this.setState({
					products: res.data.records,
				});
				if (
					+res.data.records[0].state === 0 ||
					+res.data.records[0].is_active === 0
				) {
					this.setState({
						statusPage: 1,
					});
				}
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
	};

	componentDidMount() {
		(async () => {
			await this.getProductsByID();
		})();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params !== prevProps.match.params) {
			(async () => {
				await this.getProductsByID();
			})();
		}
	}

	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;
		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	render() {
		this.collapsible();
		const { products } = this.state;
		const { statusPage } = this.state;
		if (statusPage === 1) {
			return <Redirect to="/404" />;
		} else {
			const templateSelection = products.map((prod, index) => {

				console.log("prod.id_template")
				console.log(prod.id_template)

				if (+prod.id_template === 1) {
					return (
						<ProductComiteAndNoDoc
							key={"ProductComiteAndNoDoc" + prod.id}
							prod_info={products}
						></ProductComiteAndNoDoc>
					);
				} else if (+prod.id_template === 2) {
					return (
						<ProductComiteAndDoc
							key={"ProductComiteAndDoc" + prod.id}
							prod_info={products}
						></ProductComiteAndDoc>
					);
				} else if (+prod.id_template === 4 || +prod.id_template === 6) {
					return (
						<ProductAsocAndComite
							key={"ProductAsocAndComite" + prod.id}
							prod_info={products}
						></ProductAsocAndComite>
					);
				} else if (
					+prod.id_template === 5 ||
					+prod.id_template === 7 ||
					+prod.id_template === 8
				) {
					return (
						<ProductTypeA
							key={"ProductTypeA" + prod.id}
							prod_info={products}
						></ProductTypeA>
					);
				} else if (+prod.id_template === 9) {
					return (
						<ProductBasic
							key={"productBasic" + prod.id}
							prod_info={products}
						></ProductBasic>
					);
				} else if (+prod.id_template === 10) {
					return (
						<ProductBasicAdmin
							key={"productBasicAdmin" + prod.id}
							prod_info={products}
						></ProductBasicAdmin>
					);
				} else if (+prod.id_template === 11) {
					return (
						<ProductCongress
							key={"productCongress" + prod.id}
							prod_info={products}
						></ProductCongress>
					);
				} else if (+prod.id_template === 12) {
					return (
						<ProductNormal
							key={"productCongress" + prod.id}
							prod_info={products}
						></ProductNormal>
					);
				} else if (+prod.id_template === 13) {
					return (
						<ProductAddComites
							key={"productCongress" + prod.id}
							prod_info={products}
						></ProductAddComites>
					);
				} else {
					return null;
				}
			});

			return <section>{templateSelection}</section>;
		}
	}
}
export default withRouter(Products01);
