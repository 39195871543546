import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";

import { Redirect } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import Contacto from "../../WidgetsSidebar/contacto";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import equal from "fast-deep-equal";
import ContactImage from "../../WidgetsSidebar/contactImage";
import ReactGA from "react-ga";
import NoticiaResume from "../../objects/noticiaResume";
import ActualidadResume from "../../objects/actualidadResume";
import MultimediaResume from "../../objects/multimediaResume";
import { Pagination } from "antd";

class tabsTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			array_post: props.array_post,
			tabs: [],
			array_page: props.array_page,
			filterCat: "",
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
			minValue: 0,
			maxValue: 9,
			current: 1,
			tabNews1: [],
			tabNews2: [],
			tabNews3: [],
			tabNews4: [],
			tabNews5: [],
			tabNewsAll: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	handleChange = (value) => {
		if (value <= 1) {
			this.setState({
				minValue: 0,
				maxValue: 9,
				current: 1,
			});
		} else {
			this.setState({
				minValue: value * 9 - 9,
				maxValue: value * 9,
				current: value,
			});
		}
	};

	changeFilterCat = (e) => {
		this.setState({
			filterCat: e.target.value.substring(9, 20),
			currentPage: 1,
			activeId: e.target.value.substring(9, 20),
		});
	};

	componentDidMount() {
		//Call to webservice to get the new
		this.setState({
			tabs: this.props.array_post[0].tabs,
		});
		var tempArray = [];
		this.props.array_post[0].tabs.forEach((element) => {
			if (element.tabNews.length) {
				tempArray.push(
					element.tabNews.sort(function (a, b) {
						a = a.published_date.split("/").reverse().join("");
						b = b.published_date.split("/").reverse().join("");
						return b > a ? 1 : b < a ? -1 : 0;
						// return a.localeCompare(b);         // <-- alternative
					})
				);
			} else {
				tempArray.push([]);
			}
		});

		this.setState({
			tabNewsAll: tempArray,
		});

		(async () => {
			//await this.getTabs(this.props.templateID);
		})();

		this.collapsible();

		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (!equal(this.props.array_page, prevProps.array_page)) {
			this.setState({
				array_page: this.props.array_page,
			});
		}
	}

	paginationReset = () => {
		this.setState({ minValue: 0, maxValue: 9, current: 1 });
	};

	render() {
		const { statusPage } = this.state;
		const { array_post } = this.state;
		const { array_page } = this.state;

		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});

		const content = array_post.map((post, index) => {
			return (
				<div className="contentPage" key={index}>
					<div
						dangerouslySetInnerHTML={{
							__html: post.content ? post.content : "",
						}}
					/>
				</div>
			);
		});

		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					menu_title="Menú"
					page_array={array_page}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					key={index}
				></MenuPageSidebar>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		let tabs;

		if (this.state.tabs.length > 0) {
			tabs = this.state.tabs.map((tab, index) => {
				if (+tab.type === +0) {
					return (
						<Tab eventKey={index} key={index} title={tab.name}>
							<div
								className="tabContent"
								dangerouslySetInnerHTML={{
									__html: tab.description
										? tab.description
										: "<p>No hay informacion</p>",
								}}
							/>
							{tab.tabNews.length > 0 ? "" : ""}
							<Row>
								{this.state.tabNewsAll[index]
									.slice(+this.state.minValue, +this.state.maxValue)
									.map((noticias, index) => (
										<Col lg={4} md={12} key={index}>
											<NoticiaResume
												key={this.state.minValue}
												title={noticias.title}
												link={noticias.permalink}
												date={noticias.published_date}
												img={noticias.cover_image}
												short_description={noticias.short_description}
											></NoticiaResume>
										</Col>
									))}
								{this.state.tabNewsAll[index].length < 1 ? (
									""
								) : (
									<Col lg={12} md={12}>
										<Pagination
											key={this.state.minValue}
											defaultCurrent={1}
											current={this.state.current}
											pageSize={9}
											onChange={this.handleChange}
											total={this.state.tabNewsAll[index].length}
										/>
									</Col>
								)}
							</Row>
						</Tab>
					);
				} else if (+tab.type === 1) {
					return (
						<Tab eventKey={index} key={index} title={tab.name}>
							<div
								className="tabContent"
								dangerouslySetInnerHTML={{
									__html: tab.description
										? tab.description
										: "<p>No hay informacion</p>",
								}}
							/>
							{tab.news.length > 0 ? "" : ""}
							<Row>
								{this.state.tabNewsAll[index]
									.slice(+this.state.minValue, +this.state.maxValue)
									.map((noticias, index) => (
										<Col lg={4} md={12} key={index}>
											<ActualidadResume
												key={this.state.minValue}
												title={noticias.title}
												link={noticias.permalink}
												date={noticias.published_date}
												img={noticias.cover_image}
												short_description={noticias.short_description}
											></ActualidadResume>
										</Col>
									))}
								{this.state.tabNewsAll[index].length < 1 ? (
									""
								) : (
									<Col lg={12} md={12}>
										<Pagination
											key={this.state.minValue}
											defaultCurrent={1}
											current={this.state.current}
											pageSize={9}
											onChange={this.handleChange}
											total={this.state.tabNewsAll[index].length}
										/>
									</Col>
								)}
							</Row>
						</Tab>
					);
				} else if (+tab.type === 2) {
					return (
						<Tab eventKey={index} key={index} title={tab.name}>
							<div
								className="tabContent"
								dangerouslySetInnerHTML={{
									__html: tab.description
										? tab.description
										: "<p>No hay informacion</p>",
								}}
							/>
						</Tab>
					);
				} else if (+tab.type === 3) {
					return (
						<Tab eventKey={index} key={index} title={tab.name}>
							<div
								className="tabContent"
								dangerouslySetInnerHTML={{
									__html: tab.description
										? tab.description
										: "<p>No hay informacion</p>",
								}}
							/>
							{tab.news.length > 0 ? "" : ""}
							<Row>
								{this.state.tabNewsAll[index]
									.slice(+this.state.minValue, +this.state.maxValue)
									.map((noticias, index) => (
										<Col lg={4} md={12} key={index}>
											<MultimediaResume
												key={this.state.minValue}
												title={noticias.title}
												link={noticias.permalink}
												date={noticias.published_date}
												img={noticias.cover_image}
												short_description={noticias.short_description}
											></MultimediaResume>
										</Col>
									))}
								{this.state.tabNewsAll[index].length < 1 ? (
									""
								) : (
									<Col lg={12} md={12}>
										<Pagination
											key={this.state.minValue}
											defaultCurrent={1}
											current={this.state.current}
											pageSize={9}
											onChange={this.handleChange}
											total={this.state.tabNewsAll[index].length}
										/>
									</Col>
								)}
							</Row>
						</Tab>
					);
				} else {
					return (
						<Tab eventKey={index} key={index} title={tab.name}>
							<div
								className="tabContent"
								dangerouslySetInnerHTML={{
									__html: tab.description
										? tab.description
										: "<p>No hay informacion</p>",
								}}
							/>
						</Tab>
					);
				}
			});
		} else {
			return null;
		}

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col lg={9} md={12}>
							{content}
							<Tabs
								defaultActiveKey={0}
								onClick={this.paginationReset}
								className="tabsTemplate"
							>
								{tabs}
							</Tabs>
						</Col>
						<Col className="sidebar" lg={3} md={12}>
							<ContactImage />
							<Contacto />
							{sidebarMenuPage}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default tabsTemplate;
