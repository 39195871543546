import React from 'react';
import TopImageTitle from '../components/objects/topImageTitle';
import { Row, Col, Container } from 'react-grid-system';
import axios from 'axios';
import ProductResume from '../components/objects/ProductResume';
import { HashLink as Link } from 'react-router-hash-link';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
import SEO from '../components/objects/seo';
class Afiliate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      islogged: 0,
      msgNotLogIn: '',
      msgCompleteProfile: null,
      array_post: props.array_post,
      array_page: props.array_page,
      introduction: '',
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      text6: '',
      text7: '',
      text8: '',
      text9: '',
      inVotation: 0,
      dateEndVotation: '',
    };
  }
  checkTokken = async () => {
    const jwt = localStorage.getItem('access_token');
    if (!jwt) {
      this.setState({
        islogged: 0,
        msgNotLogIn:
          'Para poder afiliarse necesita tener una cuenta de usuario/a. ',
      });
    } else {
      let formData = new FormData();
      formData.append('jwt', jwt);
      await axios
        .post('/api/validate_token', formData)
        .then((res) => {
          //console.log(res);
          this.setState({
            userInfo: res.data.data,
          });
        })
        .catch((error) => {
          this.setState({
            statusPage: 1,
          });
        });
      this.setState({
        islogged: 1,
        msgNotLogIn: '',
      });
    }
  };
  checkDateVotationAssembly = async () => {
    this.setState({
      fetchInformation: true,
    });
    await axios
      .post('/api/votes_a_check_dates')

      .then((res) => {
        this.setState({
          inVotation: 1,
          dateEndVotation: res.data.records[0].vote_end,
        });
      })
      .catch((error) => {
        this.setState({
          inVotation: 0,
        });
      });
  };
  componentDidMount() {
    (async () => {
      await this.checkTokken();

      this.setMessageWarning();
      this.checkAlreadyAfiliate();
      await this.checkDateVotationAssembly();
    })();

    axios.get('/api/products_get').then((res) => {
      //console.log(res);
      this.setState({
        products: res.data.records.reverse(),
      });
    });
    if (this.props.array_post.length) {
      this.setState({
        introduction: this.props.array_post[0].content,
        text2: this.props.array_post[0].content2,
        text3: this.props.array_post[0].content3,
        text4: this.props.array_post[0].content4,
        text5: this.props.array_post[0].content5,
        text6: this.props.array_post[0].content6,
        text7: this.props.array_post[0].content7,
        text8: this.props.array_post[0].content8,
        text9: this.props.array_post[0].content9,
      });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  setMessageWarning() {
    const { userInfo } = this.state;
    //Message user Personal data
    if (userInfo) {
      if (
        +userInfo.id_type === +4 ||
        +userInfo.id_type === +5 ||
        +userInfo.id_type === +7 ||
        +userInfo.id_type === +6
      ) {
        this.setState({
          msgCompleteProfile: null,
        });
      } else {
        if (
          !userInfo.name ||
          !userInfo.lastname ||
          !userInfo.nif ||
          !userInfo.country ||
          !userInfo.city ||
          !userInfo.region ||
          !userInfo.country_residence ||
          !userInfo.org ||
          !userInfo.job ||
          !userInfo.education ||
          !userInfo.academic_degree
        ) {
          this.setState({
            msgCompleteProfile:
              'Para poder continuar necesita completar todos los campos obligatorios de su perfil',
          });
        } else {
          this.setState({
            msgCompleteProfile: null,
          });
        }
      }
    } else {
      this.setState({
        msgCompleteProfile: null,
      });
    }
  }
  checkAlreadyAfiliate() {
    const { userInfo } = this.state;
    //Message user Personal data
    if (userInfo) {
      let formData = new FormData();
      formData.append('id_user', userInfo.id);
      axios
        .post('/api/payment_get_by_id_user', formData)
        .then((res) => {
          let uniqueProducts = res.data.records
            .map((item) => item.id_product)
            .filter((value, index, self) => self.indexOf(value) === index);
          this.setState({
            getIdProducts: uniqueProducts,
          });
        })
        .catch((error) => {});
    } else {
      return null;
    }
  }
  checkDatePayment = (dateToconvert) => {
    if (dateToconvert) {
      var date = dateToconvert;
      date = date.split(' ')[0];
      var yyyy = date.split('-')[0];
      var mmmm = date.split('-')[1];
      var dddd = date.split('-')[2];
      date = dddd + '-' + mmmm + '-' + yyyy;
      return date;
    }
  };
  render() {
    const { products } = this.state;
    //const { islogged } = this.state;
    const { msgNotLogIn } = this.state;
    const { msgCompleteProfile } = this.state;
    const { array_post } = this.state;
    const title = array_post.map((post, index) => {
      return (
        <TopImageTitle
          key={index}
          key_id={post.id}
          title={post.title}
          cover_image={post.img}
          subtitle={post.subtitle}
          bread01={post.title}
        ></TopImageTitle>
      );
    });

    const productList = products.map((products, index) => {
      if (products.type === null) {
        return (
          <ProductResume
            key={index}
            idProduct={products.id}
            title={products.name}
            img={products.img}
            link={'/' + products.id}
            showprice={0}
            menuSelector={1}
            price={products.price}
            meta_description={products.meta_description}
            customLink="1"
            idTemplate={products.id_template}
          ></ProductResume>
        );
      } else {
        return null;
      }
    });
    const productListbyType12 = products.map((products, index) => {
      if (+products.id_template === 1 || +products.id_template === 2) {
        return (
          <ProductResume
            key={index}
            title={products.name}
            img={products.img}
            showprice={1}
            menuSelector={0}
            link={'/' + products.id}
            price={!products.price ? '' : products.price}
            meta_description={products.meta_description}
          ></ProductResume>
        );
      } else {
        return null;
      }
    });
    const productListbyType4 = products.map((products, index) => {
      if (+products.id_template === 4) {
        return (
          <ProductResume
            key={index}
            title={products.name}
            img={products.img}
            link={'/' + products.id}
            showprice={1}
            menuSelector={0}
            price={!products.price ? '' : products.price}
            meta_description={products.meta_description}
          ></ProductResume>
        );
      } else {
        return null;
      }
    });
    const productListbyTypeA = products.map((products, index) => {
      if (+products.id_template === 5) {
        return (
          <ProductResume
            key={index}
            title={products.name}
            img={products.img}
            link={'/' + products.id}
            showprice={1}
            menuSelector={0}
            price={!products.price ? '' : products.price}
            meta_description={products.meta_description}
          ></ProductResume>
        );
      } else {
        return null;
      }
    });
    const productListbyTypeB = products.map((products, index) => {
      if (+products.id_template === 7) {
        return (
          <ProductResume
            key={index}
            title={products.name}
            img={products.img}
            link={'/' + products.id}
            showprice={1}
            menuSelector={0}
            price={!products.price ? '' : products.price}
            meta_description={products.meta_description}
          ></ProductResume>
        );
      } else {
        return null;
      }
    });
    const productListbyTypeC = products.map((products, index) => {
      if (+products.id_template === 8) {
        return (
          <ProductResume
            key={index}
            title={products.name}
            img={products.img}
            link={'/' + products.id}
            showprice={1}
            menuSelector={0}
            price={!products.price ? '' : products.price}
            meta_description={products.meta_description}
          ></ProductResume>
        );
      } else {
        return null;
      }
    });
    const listOfProducts = products.map((products, index) => {
      if (+products.id === 1) {
        return (
          <Col lg={4} sm={12} className="columnIndexProducts">
            <div className="inner boxResumeProducts shadow ">
              <h5>{products.name}</h5>
              <div className="precio">
                <b>Cuota:</b> {products.price}€
              </div>{' '}
              <div className="precio">
                <b>Incluye:</b> Incluye inscripción en 4 comités
              </div>{' '}
              <AnchorLink href="#miembrosIndividualesSocioFES">
                <button className="button">Ver mas información</button>
              </AnchorLink>{' '}
            </div>
          </Col>
        );
      } else if (+products.id === 2) {
        return (
          <Col lg={4} sm={12} className="columnIndexProducts">
            <div className="inner boxResumeProducts shadow">
              <h5>{products.name}</h5>
              <div className="precio">
                <b>Dirigida a:</b>  Estudiantes, desempleados/as y personas con discapacidad
              </div>{' '}
              <div className="precio">
                <b>Cuota:</b> {products.price}€
              </div>{' '}
              <div className="precio">
                <b>Incluye:</b> Incluye inscripción en 4 comités
              </div>{' '}
              <AnchorLink href="#miembrosIndividualesSocioFES">
                <button className="button">Ver mas información</button>
              </AnchorLink>{' '}
            </div>
          </Col>
        );
      } else if (+products.id === 3) {
        return (
          <Col lg={4} sm={12} className="columnIndexProducts">
            <div className="inner boxResumeProducts shadow">
              <h5>{products.name}</h5>
              <div className="target">
                <b>Dirigida a:</b> Socios de territoriales
              </div>{' '}
              <div className="precio">
                <b>Cuota:</b> {global.priceSingleComite}€ (por comité)
              </div>{' '}
              <AnchorLink href="#miembrosAsociacionTerritorial">
                <button className="button">Ver mas información</button>
              </AnchorLink>{' '}
            </div>
          </Col>
        );
      } else if (+products.id === 4) {
        return (
          <Col lg={4} sm={12} className="columnIndexProducts">
            <div className="inner boxResumeProducts shadow">
              <h5>{products.name}</h5>
              <h5>Asociaciones Territoriales</h5>
              <div className="precio">
                <b>Cuota:</b> {products.price}€
              </div>{' '}
              <AnchorLink href="#tipoA">
                <button className="button">Ver mas información</button>
              </AnchorLink>{' '}
            </div>
          </Col>
        );
      } else if (+products.id === 46) {
        return (
          <Col lg={4} sm={12} className="columnIndexProducts">
            <div className="inner boxResumeProducts shadow">
              <h5>{products.name}</h5>
              {/*<h5>Asociaciones Territoriales</h5>*/}
              <div className="precio">
                <b>Cuota:</b> {products.price}€
              </div>{' '}
              <AnchorLink href="#tipoA">
                <button className="button">Ver mas información</button>
              </AnchorLink>{' '}
            </div>
          </Col>
        );
      } else if (+products.id === 47) {
        return (
          <Col lg={4} sm={12} className="columnIndexProducts">
            <div className="inner boxResumeProducts shadow">
              <h5>{products.name}</h5>
              <h5>Departamentos universitarios</h5>
              <div className="precio">
                <b>Cuota:</b> {products.price}€
              </div>{' '}
              <AnchorLink href="#tipoB">
                <button className="button">Ver mas información</button>
              </AnchorLink>{' '}
            </div>
          </Col>
        );
      } else if (+products.id === 48) {
        return (
          <Col lg={4} sm={12} className="columnIndexProducts">
            <div className="inner boxResumeProducts shadow">
              <h5>{products.name}</h5>
              {/*<h5>Entidades científicas y profesionales</h5>*/}
              <div className="precio">
                <b>Cuota:</b> {products.price}€
              </div>{' '}
              <AnchorLink href="#tipoC">
                <button className="button">Ver mas información</button>
              </AnchorLink>{' '}
            </div>
          </Col>
        );
      } else {
        return null;
      }
    });
    const MetaTagsSeo = this.state.array_post.map((post, index) => {
      return (
        <SEO
          title={!post.meta_title ? post.title : post.meta_title}
          description={post.meta_description}
          keywords={post.keywords}
          cover_image=""
          key={index}
        ></SEO>
      );
    });
    return (
      <div>
        {MetaTagsSeo}
        {title}
        <Container>
          <Row className="afiliationPage">
            <div lg={12} sm={12}>
              <div>
                {+this.state.inVotation === 1 ? (
                  <div className="alert alert-warning" role="alert">
                    <h3>
                      Actualmente está abierto un período de votación por lo
                      cual no es posible realizar ninguna afiliación hasta el
                      día:{' '}
                      <b>{this.checkDatePayment(this.state.dateEndVotation)}</b>
                    </h3>
                  </div>
                ) : (
                  ''
                )}
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.introduction }}
                ></div>

                <div className="anchor" id="listadoSubscripciones"></div>
                <Row className="RowIndexProducts">
                  <Col lg={12}>
                    {' '}
                    <h2 className="underlinesubtitle">Tipo de cuotas:</h2>
                  </Col>
                  {listOfProducts}
                </Row>
                <Row hidden className="center">
                  {productList}
                </Row>
              </div>
              <hr className="whiteSpace"></hr>

              <div className="miembrosIndividuales">
                <div className="anchor" id="miembrosIndividualesSocioFES"></div>
                <div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.text2 }}
                    ></div>
                    <div>
                      <Row>
                        <Col lg={4} sm={12}>
                          <div className="inner boxResumeProducts shadow">
                            <h5>Socio/a Individual FES (Cuota ordinaria)</h5>

                            <Link smooth to={'/producto/1'}>
                              <button className="button">
                                Afiliarse ahora
                              </button>
                            </Link>

                            <p className="small">
                              <b>{msgNotLogIn}</b>{' '}
                              {msgNotLogIn ? (
                                <span>
                                  Haz{' '}
                                  <Link
                                    to={{
                                      pathname: `/login`,
                                      state: {
                                        origin: 0,
                                        url_permalink: 'afiliate',
                                      },
                                    }}
                                  >
                                    click aquí
                                  </Link>{' '}
                                  para Iniciar sesión con su cuenta o darse de
                                  alta.
                                </span>
                              ) : msgCompleteProfile ? (
                                <span>
                                  Para poder continuar necesita completar todos
                                  los campos obligatorios de
                                  <a href="/perfil"> su perfil.</a>
                                </span>
                              ) : (
                                ''
                              )}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4} sm={12}>
                          <div className="inner boxResumeProducts shadow">
                            <h5>Socio/a Individual FES (Cuota reducida)</h5>

                            <Link smooth to={'/producto/2'}>
                              <button className="button">
                                Afiliarse ahora
                              </button>
                            </Link>

                            <p className="small">
                              {' '}
                              <b>{msgNotLogIn}</b>{' '}
                              {msgNotLogIn ? (
                                <span>
                                  Haz{' '}
                                  <Link
                                    to={{
                                      pathname: `/login`,
                                      state: {
                                        origin: 0,
                                        url_permalink: 'afiliate',
                                      },
                                    }}
                                  >
                                    click aquí
                                  </Link>{' '}
                                  para Iniciar sesión con su cuenta o darse de
                                  alta.
                                </span>
                              ) : msgCompleteProfile ? (
                                <span>
                                  Para poder continuar necesita completar todos
                                  los campos obligatorios de
                                  <a href="/perfil"> su perfil.</a>
                                </span>
                              ) : (
                                ''
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row hidden>{productListbyType12}</Row>

                      <div className="goListing">
                        <AnchorLink href="#listadoSubscripciones">
                          <FontAwesomeIcon icon={Icons.faArrowUp} size="1x" />{' '}
                          Volver al listado
                        </AnchorLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="anchor"
                    id="miembrosAsociacionTerritorial"
                  ></div>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.text3 }}
                  ></div>
                  <Row>
                    <Col lg={4} sm={12}>
                      <div className="inner boxResumeProducts shadow">
                        <h5>Socio/a de Territorial inscrito/a a comités</h5>

                        <Link smooth to={'/producto/3'}>
                          <button className="button">Afiliarse ahora</button>
                        </Link>

                        <p className="small">
                          {' '}
                          <b>{msgNotLogIn}</b>{' '}
                          {msgNotLogIn ? (
                            <span>
                              Haz{' '}
                              <Link
                                to={{
                                  pathname: `/login`,
                                  state: {
                                    origin: 0,
                                    url_permalink: 'afiliate',
                                  },
                                }}
                              >
                                click aquí
                              </Link>{' '}
                              para Iniciar sesión con su cuenta o darse de alta.
                            </span>
                          ) : msgCompleteProfile ? (
                            <span>
                              Para poder continuar necesita completar todos los
                              campos obligatorios de
                              <a href="/perfil"> su perfil.</a>
                            </span>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row hidden>{productListbyType4}</Row>
                  <div className="goListing">
                    <AnchorLink href="#listadoSubscripciones">
                      <FontAwesomeIcon icon={Icons.faArrowUp} size="1x" />{' '}
                      Volver al listado
                    </AnchorLink>
                  </div>
                  <br></br>
                </div>

                <button
                  type="button"
                  className="blueCollapsible collapsible noCollapsed"
                >
                  Beneficios: servicios, descuentos, participación en órganos de
                  gobierno
                </button>
                <div className="content_collapsible">
                  <div className="text_descriptions">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.text4 }}
                    ></div>
                  </div>
                </div>
              </div>
              <hr className="whiteSpace"></hr>
              <div className="anchor" id="tipoA"></div>
              <div className="miembrosColectivos">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.text5 }}
                ></div>

                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.text6 }}
                  ></div>
                  <div>
                    <Row>
                      <Col lg={4} sm={12}>
                        <div className="inner boxResumeProducts shadow">
                          <h5>
                            Socio/a colectivo Tipo A Asociaciones Territoriales
                          </h5>
                          <h6>{'< 100 Socios'}</h6>

                          <Link smooth to={'/producto/4'}>
                            <button className="button">Afiliarse ahora</button>
                          </Link>

                          <p className="small">
                            {' '}
                            <b>{msgNotLogIn}</b>{' '}
                            {msgNotLogIn ? (
                              <span>
                                Haz{' '}
                                <Link
                                  to={{
                                    pathname: `/login`,
                                    state: {
                                      origin: 0,
                                      url_permalink: 'afiliate',
                                    },
                                  }}
                                >
                                  click aquí
                                </Link>{' '}
                                para Iniciar sesión con su cuenta o darse de
                                alta.
                              </span>
                            ) : msgCompleteProfile ? (
                              <span>
                                Para poder continuar necesita completar todos
                                los campos obligatorios de
                                <a href="/perfil"> su perfil.</a>
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4} sm={12}>
                        <div className="inner boxResumeProducts shadow">
                          <h5>
                            Socio/a colectivo Tipo A Asociaciones Territoriales
                          </h5>
                          <h6>{'> 100 Socios'}</h6>

                          <Link smooth to={'/producto/46'}>
                            <button className="button">Afiliarse ahora</button>
                          </Link>

                          <p className="small">
                            {' '}
                            <b>{msgNotLogIn}</b>{' '}
                            {msgNotLogIn ? (
                              <span>
                                Haz{' '}
                                <Link
                                  to={{
                                    pathname: `/login`,
                                    state: {
                                      origin: 0,
                                      url_permalink: 'afiliate',
                                    },
                                  }}
                                >
                                  click aquí
                                </Link>{' '}
                                para Iniciar sesión con su cuenta o darse de
                                alta.
                              </span>
                            ) : msgCompleteProfile ? (
                              <span>
                                Para poder continuar necesita completar todos
                                los campos obligatorios de
                                <a href="/perfil"> su perfil.</a>
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row hidden>{productListbyTypeA}</Row>
                  </div>
                  <div className="goListing">
                    <AnchorLink href="#listadoSubscripciones">
                      <FontAwesomeIcon icon={Icons.faArrowUp} size="1x" />{' '}
                      Volver al listado
                    </AnchorLink>
                  </div>
                </div>
                <div className="anchor" id="tipoB"></div>
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.text7 }}
                  ></div>
                  <div>
                    <Row>
                      <Col lg={4} sm={12}>
                        <div className="inner boxResumeProducts shadow">
                          <h5>
                            Socio/a colectivo Tipo B Departamentos
                            universitarios
                          </h5>

                          <Link smooth to={'/producto/47'}>
                            <button className="button">Afiliarse ahora</button>
                          </Link>

                          <p className="small">
                            {' '}
                            <b>{msgNotLogIn}</b>{' '}
                            {msgNotLogIn ? (
                              <span>
                                Haz{' '}
                                <Link
                                  to={{
                                    pathname: `/login`,
                                    state: {
                                      origin: 0,
                                      url_permalink: 'afiliate',
                                    },
                                  }}
                                >
                                  click aquí
                                </Link>{' '}
                                para Iniciar sesión con su cuenta o darse de
                                alta.
                              </span>
                            ) : msgCompleteProfile ? (
                              <span>
                                Para poder continuar necesita completar todos
                                los campos obligatorios de
                                <a href="/perfil"> su perfil.</a>
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row hidden>{productListbyTypeB}</Row>
                  </div>
                  <div className="goListing">
                    <AnchorLink href="#listadoSubscripciones">
                      <FontAwesomeIcon icon={Icons.faArrowUp} size="1x" />{' '}
                      Volver al listado
                    </AnchorLink>
                  </div>
                </div>
                <div className="anchor" id="tipoC">
                  <p>hola</p>
                </div>
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.text8 }}
                  ></div>
                  <div>
                    <Row>
                      <Col lg={4} sm={12}>
                        <div className="inner boxResumeProducts shadow">
                          <h5>
                            Socio/a colectivo Tipo C Instituciones científicas y
                            profesionales
                          </h5>

                          <Link smooth to={'/producto/48'}>
                            <button className="button">Afiliarse ahora</button>
                          </Link>

                          <p className="small">
                            {' '}
                            <b>{msgNotLogIn}</b>{' '}
                            {msgNotLogIn ? (
                              <span>
                                Haz{' '}
                                <Link
                                  to={{
                                    pathname: `/login`,
                                    state: {
                                      origin: 0,
                                      url_permalink: 'afiliate',
                                    },
                                  }}
                                >
                                  click aquí
                                </Link>{' '}
                                para Iniciar sesión con su cuenta o darse de
                                alta.
                              </span>
                            ) : msgCompleteProfile ? (
                              <span>
                                Para poder continuar necesita completar todos
                                los campos obligatorios de
                                <a href="/perfil"> su perfil.</a>
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row hidden>{productListbyTypeC}</Row>
                  </div>
                  <div className="goListing">
                    <AnchorLink href="#listadoSubscripciones">
                      <FontAwesomeIcon icon={Icons.faArrowUp} size="1x" />{' '}
                      Volver al listado
                    </AnchorLink>
                  </div>
                </div>

                <div
                  className="anchor margin-top-20"
                  id="beneficiosMiembrosColectivos"
                ></div>
                <button
                  type="button"
                  className="blueCollapsible collapsible noCollapsed"
                >
                  Beneficios: servicios, descuentos, participación en órganos de
                  gobierno
                </button>
                <div className="content_collapsible">
                  <br></br>
                  <div className="text_descriptions">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.text9 }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <Col lg={4} md={12} hidden>
              <Row>
                <Col sm={12}>
                  <h2>Conoce nuestras suscripciones actuales</h2>
                </Col>
                {productList}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Afiliate;
