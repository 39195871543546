import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../style/styles_top3.css";

class Categories extends Component {
	state = {
		categories: [],
	};
	componentDidMount() {
		//Call to webservice to get all news
		axios.get("/api/get_all_categories").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
	}

	render() {
		const { categories } = this.state;

		const catNotWant = ["51", "52"];
		let listCategory;
		if (categories.length) {
			var i = 0;
			listCategory = categories
				.sort(
					(a, b) =>
						(+a.order_cat === 0) - (+b.order_cat === 0) ||
						+(+a.order_cat > +b.order_cat) ||
						-(+a.order_cat < +b.order_cat)
				)
				.map((categories, index) => {
					if (catNotWant.some((r) => categories.id.includes(r))) {
						return null;
					} else {
						i++;
						return (
							<li key={"cat" + index}>
								<div className="numberListing"> {("0" + i).slice(-2)}</div>
								<Link to={"/categoria/" + categories.id}>
									{categories.name}
								</Link>
							</li>
						);
					}
				});
		}
		return (
			<div className="categoryListing">
				<h5 className="titleSidebar">Menú de categorias</h5>
				<div>
					<ul className="sidebarListingNumber">
						{listCategory ? (
							listCategory
						) : (
							<p className="center">No hay categorias</p>
						)}
					</ul>
				</div>
			</div>
		);
	}
}

export default Categories;
