import React from "react";
import TopImageTitle from "../../objects/topImageTitle";
import { Row, Col, Container } from "react-grid-system";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { Form, Radio } from "antd";
import { Table, Button } from "react-bootstrap";
//import ProductResume from "../components/objects/ProductResume";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import Loader from "react-loader-spinner";

class ProductNormal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prod_info: props.prod_info,
			products: [],
			renovationTime: false,
			type: "",
			comites: [],
			checked: [],
			checkedPay: [],
			acepto: "",
			paymentMethod: "",
			paymentMethodChossen: "",
			price: "",
			price_condition: "",
			doc_required: 0,
			id_product: "",
			islogged: "",
			userInformation: [],
			userInfoPayments: [],
			signaturesRedsys: "",
			titleChecked: [],
			numberofTry: 0,
			renov_id: "",
			readyToShow: false,
			fetchRenovation: false,
			iva: "",
			idOfUser: "",
			id_condition: "",
			id_type_user: "",
			fetchDataProd: true,
			fetchDataPay: true,
			fetchDataUser: true,
			checkedCondition: false,
			conditionState: "",
			statusDoc: 0,
			payment_id: "",
			rePay: "",
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		this.getUserInformation();
		this.getProductsByID();
		this.getPaymentMethods();

		if (this.props.location.state === undefined) {
			return null;
		} else if (
			this.props.location.state.statusDoc === 1 ||
			this.props.location.state.statusDoc === 3
		) {
			this.setState({
				statusDoc: this.props.location.state.statusDoc,
				productID_confirmation:
					this.props.location.state.productID_confirmation,
				payment_id: this.props.location.state.id_payment,
			});
		}
		if (this.props.location.state.rePay) {
			this.setState({
				rePay: this.props.location.state.rePay,
			});
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	getUserInformation = () => {
		this.setState({
			fetchDataUser: true,
		});
		const jwt = localStorage.getItem("access_token");
		if (!localStorage.getItem("access_token")) {
			this.setState({
				islogged: 0,
			});
		} else {
			this.setState({
				islogged: 1,
			});
			let formData = new FormData();
			formData.append("jwt", jwt);
			axios
				.post("/api/validate_token", formData)
				.then((res) => {
					this.setState({
						userInformation: res.data.data,
						id_type_user: res.data.data.id_type,
						idOfUser: res.data.data.id,
						fetchDataUser: false,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
						fetchDataUser: false,
					});
				});
		}
	};
	getProductsByID = () => {
		this.setState({
			fetchDataProd: true,
		});
		const id = this.props.match.params.id;
		let formData = new FormData();
		formData.append("id", id);
		axios.post("/api/products_get_by_id", formData).then((res) => {
			this.setState({
				products: res.data.records,
				doc: res.data.records[0].doc_required,
				idtemplate: res.data.records[0].id_template,
				id_condition: res.data.records[0].id_condition,
				fetchDataProd: false,
			});
			var price00 = res.data.records[0].price;
			var price01 = res.data.records[0].price_condition;
			var doc_required00 = res.data.records[0].doc_required;
			var idProduct00 = res.data.records[0].id;
			var iva = res.data.records[0].iva;
			this.setState({
				price: price00,
				price_condition: price01,
				doc_required: doc_required00,
				id_product: idProduct00,
				iva: iva,
			});
		});
		axios.get("/api/comites_workgroups_get", formData).then((res) => {
			this.setState({
				comites: res.data.records,
				fetchDataProd: false,
			});
		});
	};
	getPaymentMethods = () => {
		this.setState({
			fetchDataPay: true,
		});
		const { userInformation } = this.state;

		if (!localStorage.getItem("access_token")) {
		} else {
			let formData = new FormData();

			formData.append("id_user", userInformation.id);
			axios
				.post("/api/payment_get_exceptions_by_id_user", formData)
				.then((res) => {
					this.setState({
						userInfoPayments: res.data.records,
						fetchDataPay: false,
					});
				})
				.catch((error) => {
					this.setState({
						userInfoPayments: "",
						fetchDataPay: false,
					});
				});
		}
	};
	checkUserCondition = () => {
		if (
			this.state.id_condition &&
			this.state.id_type_user &&
			this.state.idOfUser
		) {
			let formData = new FormData();
			formData.append("id_condition", this.state.id_condition);
			formData.append("user_type", this.state.id_type_user);
			formData.append("id_user", this.state.idOfUser);
			axios
				.post("/api/products_check_condition", formData)
				.then((res) => {
					this.setState({
						conditionState: true,
						checkedCondition: true,
					});
				})
				.catch((error) => {
					this.setState({
						conditionState: false,
						checkedCondition: true,
					});
				});
		} else {
			this.setState({
				conditionState: false,
				checkedCondition: true,
			});
		}
	};
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.state.fetchDataProd === false &&
			this.state.fetchDataUser === false &&
			this.state.fetchDataPay === false &&
			this.state.checkedCondition === false
		) {
			this.checkUserCondition();
		}

		if (this.props.match.params !== prevProps.match.params) {
			(async () => {
				await this.getUserInformation();
				await this.getProductsByID();
				await this.getPaymentMethods();
			})();
			if (this.props.location.state === undefined) {
				return null;
			} else if (this.props.location.state.statusDoc === 1) {
				this.setState({
					statusDoc: this.props.location.state.statusDoc,
					productID_confirmation:
						this.props.location.state.productID_confirmation,
					payment_id: this.props.location.state.id_payment,
				});
			}
		}
	}

	collapsible() {
		var coll = document.getElementsByClassName("collapsible");
		var i;
		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener("click", function () {
				this.classList.toggle("active");
				this.classList.toggle("collapsed");
				var content = this.nextElementSibling;
				if (content.style.display === "block") {
					content.style.display = "none";
				} else {
					content.style.display = "block";
				}
			});
		}
	}
	handlePaymentProduct = (event) => {
		this.refs.btnPayment.setAttribute("disabled", "disabled");
		const free_comites = this.state.checked;
		const paid_comites = this.state.checkedPay;
		const comites_duplicate = free_comites.filter((element) =>
			paid_comites.includes(element)
		);

		if (comites_duplicate === "" || comites_duplicate.length === 0) {
			if (+this.state.paymentMethodChossen === 1) {
				if (this.state.payment_id) {
					const totalAmout =
						(this.state.conditionState === false
							? +this.state.price_condition +
							  global.priceSingleComite * this.state.checkedPay.length
							: +this.state.price +
							  global.priceSingleComite * this.state.checkedPay.length) * 100;
					const product_description = this.props.match.params.id;
					let formData = new FormData();
					const id_payment = this.state.payment_id;
					formData.append("id_payment", this.props.location.state.id_payment);
					formData.append("amount", totalAmout);
					formData.append("product_description", product_description);
					formData.append("rePay", this.state.rePay);

					axios
						.post("/api/redsys_get_signature_by_id_payment", formData)
						.then((res) => {
							this.setState({
								signaturesRedsys: res.data,
							});
							this.refs.btnPayment.removeAttribute("disabled");

							this.props.history.push({
								pathname: "/confirmacion-de-pago",
								state: {
									signatureRedsys: this.state.signaturesRedsys,
									checked: this.state.checked,
									checkedPay: this.state.checkedPay,
									totalAmount01: totalAmout,
									paymentMethodChossen: this.state.paymentMethodChossen,
									products: this.state.products,
									goPayment: 1,
									listFreeComites: this.state.checkedItemsFree,
									listPaidComites: this.state.checkedItemsPaid,
									id_payment: id_payment,
									renov_id: this.state.renov_id,
									iva: this.state.iva,
									oldPayment: this.state.oldPayment,
									conditionCheck: this.state.conditionState,
									rePay: this.state.rePay,
								},
							});
						});
				} else {
					const id_product = this.props.match.params.id;
					const free_comites = this.state.checked;
					const paid_comites = this.state.checkedPay;

					const { userInformation } = this.state;
					const totalAmout =
						(this.state.conditionState === false
							? +this.state.price_condition +
							  global.priceSingleComite * this.state.checkedPay.length
							: +this.state.price +
							  global.priceSingleComite * this.state.checkedPay.length) * 100;
					const idPaymentMethod = +this.state.paymentMethodChossen;
					const product_description = this.props.match.params.id;
					let formData = new FormData();
					formData.append("id_user", userInformation.id);
					formData.append("id_product", id_product);
					formData.append("id_payment", id_product);
					formData.append("amount", totalAmout);
					formData.append("payment_type", idPaymentMethod);
					formData.append("product_description", product_description);
					formData.append("free_comites", JSON.stringify(free_comites));
					formData.append("paid_comites", JSON.stringify(paid_comites));
					formData.append("renov_id", this.state.renov_id);
					formData.append("rePay", this.state.rePay);
					const id_payment = this.state.payment_id;
					axios
						.post("/api/redsys_full_set_get_signature", formData)
						.then((res) => {
							this.setState({
								signaturesRedsys: res.data,
							});
							this.refs.btnPayment.removeAttribute("disabled");
							this.props.history.push({
								pathname: "/confirmacion-de-pago",
								state: {
									signatureRedsys: this.state.signaturesRedsys,
									checked: this.state.checked,
									checkedPay: this.state.checkedPay,
									totalAmount01: totalAmout,
									paymentMethodChossen: this.state.paymentMethodChossen,
									products: this.state.products,
									goPayment: 1,
									listFreeComites: this.state.checkedItemsFree,
									listPaidComites: this.state.checkedItemsPaid,
									payment_type: 1,
									userInfo: userInformation,
									id_user: userInformation.id,
									idTemplate: this.state.idtemplate,
									renov_id: this.state.renov_id,
									iva: this.state.iva,
									conditionCheck: this.state.conditionState,
									id_payment: id_payment,
									rePay: this.state.rePay,
								},
							});
						});
				}
			} else if (+this.state.paymentMethodChossen === 2) {
				const id_payment = this.state.payment_id;
				const id_product = this.props.match.params.id;
				const { userInformation } = this.state;
				const totalAmout =
					(this.state.conditionState === false
						? +this.state.price_condition +
						  global.priceSingleComite * this.state.checkedPay.length
						: +this.state.price +
						  global.priceSingleComite * this.state.checkedPay.length) * 100;

				this.refs.btnPayment.removeAttribute("disabled");
				this.props.history.push({
					pathname: "/confirmacion-de-pago",
					state: {
						id_product: id_product,
						checked: this.state.checked,
						checkedPay: this.state.checkedPay,
						totalAmount01: totalAmout,
						paymentMethodChossen: this.state.paymentMethodChossen,
						products: this.state.products,
						goPayment: 2,
						payment_type: 2,
						userInfo: userInformation,
						id_user: userInformation.id,
						idTemplate: this.state.idtemplate,
						listFreeComites: this.state.checkedItemsFree,
						listPaidComites: this.state.checkedItemsPaid,
						renov_id: this.state.renov_id,
						iva: this.state.iva,
						conditionCheck: this.state.conditionState,
						id_payment: id_payment,
						rePay: this.state.rePay,
					},
				});
			} else if (+this.state.paymentMethodChossen === 3) {
				const id_payment = this.state.payment_id;
				const id_product = this.props.match.params.id;
				const { userInformation } = this.state;
				const totalAmout =
					(this.state.conditionState === false
						? +this.state.price_condition +
						  global.priceSingleComite * this.state.checkedPay.length
						: +this.state.price +
						  global.priceSingleComite * this.state.checkedPay.length) * 100;
				this.refs.btnPayment.removeAttribute("disabled");
				this.props.history.push({
					pathname: "/confirmacion-de-pago",
					state: {
						id_product: id_product,
						checked: this.state.checked,
						checkedPay: this.state.checkedPay,
						totalAmount01: totalAmout,
						paymentMethodChossen: this.state.paymentMethodChossen,
						products: this.state.products,
						goPayment: 3,
						payment_type: 3,
						userInfo: userInformation,
						id_user: userInformation.id,
						idTemplate: this.state.idtemplate,
						listFreeComites: this.state.checkedItemsFree,
						listPaidComites: this.state.checkedItemsPaid,
						renov_id: this.state.renov_id,
						iva: this.state.iva,
						conditionCheck: this.state.conditionState,
						id_payment: id_payment,
						rePay: this.state.rePay,
					},
				});
			}
		} else {
			alert(
				"Por lo menos tiene un comité seleccionado a la vez en la opción gratuita y de pago. Compruebe los datos y vuela a intentarlo."
			);
			this.refs.btnPayment.removeAttribute("disabled");
		}
	};
	handleSendDoc = (event) => {
		this.refs.btnDoc.setAttribute("disabled", "disabled");
		if (this.state.file) {
			const { statusDoc } = this.state;

			const { userInformation } = this.state;

			if (+statusDoc === 3) {
				let formData = new FormData();
				const id_product = this.state.payment_id;
				formData.append("id_payment_user", id_product);
				formData.append("file", this.state.file);

				axios
					.post("/api/payment_documentation_update.php", formData)
					.then((res) => {
						alert("Su documentación ha sido actualizada correctamente.");
						this.refs.btnDoc.removeAttribute("disabled");
						this.props.history.push({
							pathname: "/perfil",
							state: {
								key: "afiliationData",
							},
						});
					});
			} else {
				let formData = new FormData();

				const id_product = this.props.match.params.id;
				formData.append("id_user", userInformation.id);
				formData.append("id_product", id_product);
				formData.append("amount", "0");
				formData.append("file", this.state.file);
				formData.append("renov_id", this.state.renov_id);

				axios
					.post("/api/payment_documentation_initial_petition_set", formData)
					.then((res) => {
						this.refs.btnDoc.removeAttribute("disabled");
						alert("Su documentación ha sido enviada correctamente.");
						this.props.history.push({
							pathname: "/perfil",
							state: {
								key: "afiliationData",
							},
						});
					});
			}
		} else {
			alert(
				"Compruebe que ha seleccionado un documento y aceptado los terminos y condiciones de servicio."
			);
			this.refs.btnDoc.removeAttribute("disabled");
		}
	};
	handleFile = (evt) => {
		this.setState({ file: evt.target.files[0] });
	};
	handleAcepto = (evt) => {
		this.setState({ acepto: evt.target.checked });
	};
	handlePaymentMethod = (evt) => {
		this.setState({ paymentMethodChossen: evt.target.value });
		this.setState({ paymentMethod: evt.target.checked });
	};

	render() {
		this.collapsible();
		//const { acepto, paymentMethod } = this.state;
		//const isEnabled = acepto === true && paymentMethod === true;

		const { paymentMethod, file } = this.state;
		const isEnabled = paymentMethod === true;
		const { products } = this.state;
		const isEnabledDoc = file;
		const { islogged } = this.state;
		const { userInfoPayments } = this.state;
		const { userInformation } = this.state;
		const { statusDoc } = this.state;

		const productInfo = products.map((prod) => {
			if (prod.type !== null) {
				return (
					<Col sm={12} key={prod.id}>
						<span>
							{this.state.renovationTime === true ? (
								<Link
									to={{
										pathname: `/perfil`,
										state: {
											key: "afiliationData",
										},
									}}
									className="buttonGoBack button"
								>
									Volver atrás
								</Link>
							) : (
								<a href="/afiliate" className="buttonGoBack button">
									Volver atrás
								</a>
							)}
						</span>
						<h1 className="product-Title">{prod.name}</h1>
						<div dangerouslySetInnerHTML={{ __html: prod.content }} />
					</Col>
				);
			} else {
				return <Redirect to="/404" />;
			}
		});
		const precioTotal = products.map((prod) => {
			return (
				<Table striped key={prod.id}>
					<tbody>
						<tr>
							<th>Concepto</th>
							<th>Cantidad</th>
							<th>Precio unidad (IVA incluido)</th>
							<th>IVA</th>
							<th>Total</th>
						</tr>
						<tr>
							<td>{prod.name}</td>
							<td>1</td>
							<td>
								{this.state.conditionState === false
									? prod.price_condition
									: prod.price}
								€
							</td>
							<td>{prod.iva ? prod.iva + "%" : "Exento de IVA"}</td>
							<td>
								{this.state.conditionState === false
									? prod.price_condition
									: prod.price}
								€
							</td>
						</tr>
						<tr>
							<td>Total</td>
							<td></td>
							<td></td>
							<td>{prod.iva ? prod.iva + "%" : "Exento de IVA"}</td>
							<td>
								{this.state.conditionState === false
									? prod.price_condition
									: prod.price}
								€
							</td>
						</tr>
					</tbody>
				</Table>
			);
		});
		const title = products.map((prod) => {
			if (prod.type !== null) {
				return (
					<TopImageTitle
						title={prod.name}
						subtitle={prod.subtitle}
						key={prod.id}
						cover_image={prod.img}
					></TopImageTitle>
				);
			} else {
				return null;
			}
		});

		let methodPaymentAvailable;
		if (!userInfoPayments || userInfoPayments === "") {
			methodPaymentAvailable = (
				<span>
					<Radio.Button
						value="1"
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
					>
						Tarjeta
					</Radio.Button>
					<Radio.Button
						value="2"
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
					>
						Transferencia
					</Radio.Button>
				</span>
			);
		} else if (userInfoPayments) {
			methodPaymentAvailable = userInfoPayments.map((pay) => {
				return (
					<Radio.Button
						value={pay.id}
						name="paymentMethod"
						onChange={this.handlePaymentMethod}
						key={pay.id}
					>
						{pay.name}
					</Radio.Button>
				);
			});
		}
		let messageLogin;
		let buttonPay;
		if (+islogged === 0) {
			buttonPay = (
				<p>
					Debe hacer{" "}
					<Link
						to={{
							pathname: `/login`,
							state: {
								origin: 0,
								url_permalink: "producto/" + this.props.match.params.id,
							},
						}}
					>
						Login/Registrarse
					</Link>{" "}
					para poder realizar la compra. Si ya tiene una cuenta de usuario,
					accede con sus datos de registro. Si eres nuevo en la web de fes, date
					de alta.
				</p>
			);
			messageLogin = (
				<div className="alert alert-warning" role="alert">
					<p>
						Debe hacer{" "}
						<Link
							to={{
								pathname: `/login`,
								state: {
									origin: 0,
									url_permalink: "/producto/" + this.props.match.params.id,
								},
							}}
						>
							Login/Registrarse
						</Link>{" "}
						para poder realizar la compra. Si ya tiene una cuenta de usuario,
						accede con sus datos de registro. Si eres nuevo en la web de fes,
						date de alta.
					</p>
				</div>
			);
		} else if (
			this.state.conditionState === false &&
			+this.state.price_condition === 0
		) {
			buttonPay = (
				<div className="alert alert-warning" role="alert">
					<p>No cumple las condiciones para comprar este producto.</p>
				</div>
			);
			messageLogin = (
				<div className="alert alert-warning" role="alert">
					<p>No cumple las condiciones para compra este producto</p>
				</div>
			);
		} else if (+islogged === 1) {
			if (
				+userInformation.id_type === +4 ||
				+userInformation.id_type === +5 ||
				+userInformation.id_type === +7 ||
				+userInformation.id_type === +6 ||
				+userInformation.id_type === +12 ||
				+userInformation.id_type === +14 ||
				+userInformation.id_type === +10
			) {
				buttonPay = (
					<Button
						disabled={!isEnabled}
						className="button"
						variant="primary"
						type="submit"
						ref="btnPayment"
					>
						Ir a pago
					</Button>
				);
			} else {
				if (
					!userInformation.name ||
					!userInformation.lastname ||
					!userInformation.nif
				) {
					buttonPay = (
						<div className="alert alert-warning" role="alert">
							<p>
								Si desea realizar una afiliación, debe primero completar los
								datos requeridos como obligatorios en su cuenta de perfil:
								<Link to="/perfil"> ir a Mi cuenta.</Link>
							</p>
						</div>
					);
					messageLogin = (
						<div className="alert alert-warning" role="alert">
							<p>
								Si desea realizar una afiliación, debe primero completar los
								datos requeridos como obligatorios en su cuenta de perfil:
								<Link to="/perfil"> ir a Mi cuenta.</Link>
							</p>
						</div>
					);
				} else {
					buttonPay = (
						<Button
							disabled={!isEnabled}
							className="button"
							variant="primary"
							type="submit"
							ref="btnPayment"
						>
							Ir a pago
						</Button>
					);
				}
			}
		}
		let allProductInfo;
		if (+islogged === 0) {
			allProductInfo = null;
		} else if (
			this.state.conditionState === false &&
			+this.state.price_condition === 0
		) {
			allProductInfo = (
				<Form onFinish={this.handlePaymentProduct}>
					<div>
						<hr></hr>
						{precioTotal}

						<hr></hr>
					</div>
					{buttonPay}
				</Form>
			);
		} else {
			allProductInfo = (
				<Form onFinish={this.handlePaymentProduct}>
					<div>
						<hr></hr>
						{precioTotal}
						<p>Elija el método de pago que prefiera:</p>
						<Radio.Group buttonStyle="solid">
							{methodPaymentAvailable}
						</Radio.Group>
						<hr></hr>
					</div>
					{buttonPay}
				</Form>
			);
		}

		let buttonSendDoc;
		if (+islogged === 0) {
			buttonSendDoc = (
				<p>
					Debe hacer{" "}
					<Link
						to={{
							pathname: `/login`,
							state: {
								origin: 0,
								url_permalink: "producto/" + this.props.match.params.id,
							},
						}}
					>
						Login/Registrarse
					</Link>{" "}
					para poder realizar la compra. Si ya tiene una cuenta de usuario,
					accede con sus datos de registro. Si eres nuevo en la web de fes, date
					de alta.
				</p>
			);
		} else if (+islogged === 1) {
			buttonSendDoc = (
				<Button
					disabled={!isEnabledDoc}
					className="button"
					variant="primary"
					type="submit"
					ref="btnDoc"
				>
					Enviar documentación
				</Button>
			);
		}
		let doc_required_form;
		if (+statusDoc === 0 || +statusDoc === 3) {
			if (
				this.state.conditionState === false &&
				+this.state.price_condition === 0
			) {
				doc_required_form = "";
			} else {
				doc_required_form = (
					<div>
						<Form onFinish={this.handleSendDoc}>
							<p>
								<b>Adjuntar fichero</b>
							</p>
							<input
								type="file"
								name="documentation"
								onChange={this.handleFile}
							></input>
							<hr></hr>
							<p>
								Si no dispone de la documentación requerida, adjunte un
								documento dando las razones y el equipo de FES le responderá lo
								antes posible
							</p>

							<hr></hr>
							{buttonSendDoc}
						</Form>
					</div>
				);
			}
		} else if (+statusDoc === 1) {
			if (+islogged === 0) {
				doc_required_form = null;
			} else {
				doc_required_form = <div>{allProductInfo}</div>;
			}
		}

		return (
			<div>
				{title}
				{this.state.fetchRenovation === false ? (
					<Container>
						<Row>
							<Col sm={12}>{messageLogin}</Col>
						</Row>

						<Row>
							<Col>
								{this.state.renovationTime === true ? (
									<div className="alert alert-info" role="alert">
										<p>
											Está en proceso de renovación de afiliación, le aparecen
											marcados los comités a los que se afilió el año anterior.
											Puede cambiarlos si lo desea. Elija después el método de
											pago con el que quiere renovar y pulse el botón "IR AL
											PAGO"
										</p>
									</div>
								) : null}
							</Col>
						</Row>
						<Row>{productInfo}</Row>

						{+this.state.doc_required === 0
							? allProductInfo
							: doc_required_form}
					</Container>
				) : (
					<div className="centerloader">
						<Loader type="Oval" color="#00BFFF" height={100} width={100} />
						<h3>Cargando información</h3>
					</div>
				)}
			</div>
		);
	}
}
export default withRouter(ProductNormal);
