import React, { Fragment, Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
//import { Divider } from "antd";

class SidebarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newSidebar: this.props.newSidebar ? this.props.newSidebar : [],
      orderForSidebar: this.props.orderForSidebar
        ? this.props.orderForSidebar
        : [],
      renovableOrder: this.props.renovableOrder
        ? this.props.renovableOrder
        : [],
      userOrders: this.props.userOrders ? this.props.userOrders : [],
      isColective: this.props.isColective ? this.props.isColective : [],
      newonSidebar: [],
      alreadyRenovate: 1,
    };
  }
  getNewSidebar = async () => {
    let formData = new FormData();
    this.setState({
      fetchingAdminOrder: true,
    });
    formData.append('id_categ', 21);
    await axios
      .post('/api/news_get_by_id_categ', formData)
      .then((res) => {
        this.setState({
          newonSidebar: res.data.records,
          fetchingAdminOrder: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingAdminOrder: false,
        });
      });
  };
  checkIfAlreadyRenovate = async () => {
    let formData = new FormData();
    this.setState({
      fetchInformation: true,
    });
    formData.append('id_user', this.props.userInfo.id);

    await axios
      .post('/api/payment_renov_show', formData)
      .then((res) => {
        this.setState({
          alreadyRenovate: 0,
          fetchInformation: false,
        });
      })
      .catch((error) => {
        this.setState({
          alreadyRenovate: 1,
          fetchInformation: false,
        });
      });
  };

  componentDidMount() {
    (async () => {
      await this.getNewSidebar();
      await this.checkIfAlreadyRenovate();
    })();
  }
  checkIfRenovable = (id) => {
    //console.log(id);
    if (this.state.orderForSidebar.includes(id)) {
      //console.log(id);
      return true;
    } else {
      //console.log(id);
      return false;
    }
  };
  checkIfRenovableMessage = (id) => {
    //console.log(id);
    if (this.state.renovableOrder.includes(id)) {
      if (+this.state.alreadyRenovate === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    let comunicados;
    if (this.state.newonSidebar && this.state.newonSidebar.length) {
      var count = 0;
      comunicados = this.state.newonSidebar.map((com, index) => {
        var date = com.published_date;
        date = date.split(' ')[0];
        var yyyy = date.split('-')[0];
        var mmmm = date.split('-')[1];
        var dddd = date.split('-')[2];
        date = dddd + '-' + mmmm + '-' + yyyy;

        if (+com.display === 0 && +count === 0) {
          count++;
          return (
            <div key={com.id} className="inner center widget">
              <div>
                <div className="widgetImageSidebar">
                  <img
                    src={
                      com.cover_image
                        ? com.cover_image
                        : global.urlHome + 'images/noticias_default_news.jpg'
                    }
                    alt={`${com.title}`}
                  />
                </div>
                <div className="widgetDetailsSidebar">
                  <Link to={`/noticia${com.permalink}`}>{com.title}</Link>
                  <p>{date}</p>
                </div>
              </div>
            </div>
          );
        }
        return null;
      });
    } else {
      comunicados = <p>No hay comunicados FES</p>;
    }

    let afiliacionSidebar;
    let countAfiliations = 0;

    afiliacionSidebar = this.props.userOrders.map((UO, index) => {
      if (+this.props.userInfo.id_type !== 1) {
        let preName;
        if (countAfiliations > 0) {
          return null;
        } else {
          if (UO.id_template === '6') {
            preName = 'Socio/a  de Territorial inscrito/a a comités - ';
          } else {
            preName = null;
          }
          if (
            +UO.id_state === 3 &&
            (+UO.id_template === 1 ||
              +UO.id_template === 2 ||
              +UO.id_template === 4 ||
              +UO.id_template === 5 ||
              +UO.id_template === 6 ||
              +UO.id_template === 7 ||
              +UO.id_template === 8 ||
              +UO.id_template === 10)
          ) {
            countAfiliations++;
            return (
              <li key={index}>
                <Fragment>
                  {!preName
                    ? UO.product_name + ' año ' + UO.payment_year
                    : preName + ' año ' + UO.payment_year}
                  {this.checkIfRenovableMessage(UO.id) === true ? (
                    <strong className="remarkText">
                      {' '}
                      <a href="/perfil/renovation">
                        (Pendiente renovar año actual)
                      </a>
                    </strong>
                  ) : null}
                </Fragment>
              </li>
            );
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    });

    return (
      <div>
        <Row className="profileSidebar">
          <Col lg={12} xs={12} className="center">
            <FontAwesomeIcon icon={Icons.faUser} size="5x" color="#0058a1" />
          </Col>
          <Col lg={12} xs={12} className="margin-top-sidebar">
            <p>
              {this.props.userInfo.name} {this.props.userInfo.lastname}
            </p>
            <p>{this.props.userInfo.email}</p>
          </Col>
          {this.props.votationActive ? (
            <Col lg={12} xs={12}>
              <hr></hr>
              <a href="/perfil/eleccion-comites">
                <button className="button center width-100">
                  Elecciones Comités
                </button>
              </a>
            </Col>
          ) : (
            ''
          )}

          <Col lg={12}></Col>
          <Col lg={12} xs={12}>
            <hr></hr>
            <h5>Afiliación</h5>
          </Col>

          <Col lg={12} xs={12}>
            <ul>
              {countAfiliations < 1 ? (
                <li>No tiene Afiliaciones</li>
              ) : (
                afiliacionSidebar
              )}
            </ul>
          </Col>

          <Col lg={12} xs={12}>
            {/*renovationButton*/}
            <hr></hr>
            <h5>Comités elegidos</h5>
          </Col>

          <Col lg={12} xs={12}>
            <ul>
              {!this.props.comitesUserGet ? (
                <li>No tiene comités</li>
              ) : this.props.comitesUserGet.length < 1 ? (
                <li>No tiene comités</li>
              ) : (
                this.props.comitesUserGet.map((CS, index) => {
                  return <li key={index}>{CS.title}</li>;
                })
              )}
            </ul>
          </Col>
        </Row>

        <hr></hr>
        {this.state.isColective === '1' ? (
          ''
        ) : (
          <a href="/afiliate" hidden>
            <button className="button buttonSidebar width-100">Afíliate</button>
          </a>
        )}

        {/*this.props.userOrders.length > 0 ? (
					""
				) : (
					<a href="/afiliate">
						<button className="button buttonSidebar width-100">Afíliate</button>
					</a>
				)*/}

        {this.props.userComitesAdmin ? (
          this.props.userComitesAdmin.length > 0 ? (
            <div>
              <div className="adminComitesSidebar">
                <h5>Administrar Comités</h5>
                {this.props.userComitesAdmin.map((UC, index) => {
                  return (
                    <p key={index}>
                      <FontAwesomeIcon
                        icon={Icons.faArrowRight}
                        size="1x"
                        color="#0058a1"
                      />
                      <a href={'/perfil/admin/comite/' + UC.id}> {UC.title}</a>
                    </p>
                  );
                })}
              </div>
              <hr></hr>
            </div>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {this.props.votationActiveAssembly ? (
          <Col lg={12} xs={12}>
            <hr></hr>
            <a href="/perfil/votacion-asamblea">
              <button className="button center width-100">
                Elecciones Asamblea
              </button>
            </a>
          </Col>
        ) : (
          ''
        )}
        {+this.props.candidatureComiteExecutive === 1 ? (
          <Col lg={12} xs={12}>
            <hr></hr>
            <a href="/perfil/eleccion-comite-ejecutivo">
              <button className="button center width-100">
                Candidaturas Comite Ejecutivo
              </button>
            </a>
          </Col>
        ) : (
          ''
        )}
        <div key={comunicados}>
          <h5>Comunicados FES</h5>
          {comunicados}
        </div>
      </div>
    );
  }
}

export default SidebarProfile;
