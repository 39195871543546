import React, { Component } from "react";
//import axios from "axios";
import contactImage from "../img/contacto.jpg";

class ContactImage extends Component {
	render() {
		return (
			<div>
				<div className="contactoSidebar width-100">
					<img
						src={contactImage}
						alt="Contacto FES"
						className=" width-100"
					></img>
				</div>
			</div>
		);
	}
}

export default ContactImage;
