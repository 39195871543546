import React, { Component } from 'react';
import axios from 'axios';
//import Homepage from '../../Scenes/homepage';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import * as Icons from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

class Newsletter extends Component {
  state = {
    newsletter: [],
    gRecaptcha: '',
    expired: '',
  };
  componentDidMount() {
    axios.get('/api/settings_newsletter_get').then((res) => {
      //console.log(res);
      this.setState({
        //Just peak the last 3
        newsletter: res.data.records.slice(0, 1),
      });
    });
  }

  handleSubmitNewsletter = (event) => {
    event.preventDefault();

    var emailError = 0;
    var tycError = 0;
    let formData = new FormData();
    let formData2 = new FormData();
    if (this.state.gRecaptcha !== '' && this.state.expired === false) {
      if (event.target.emailUser.value === '') {
        emailError = 'Necesita rellenar email';
        //alert(dniError);
      } else if (event.target.tyc.value === '') {
        tycError = 'Necesita confirmar los terminos y condiciones';
        //alert(cityUserError);
      }

      if (emailError !== 0 || tycError !== 0) {
        alert('Hay campos obligatorios sin rellenar');
      } else {
        formData.append('email', event.target.emailUser.value);
        formData.append('name', event.target.nameUser.value);
        formData.append('tyc', event.target.tyc.value);
        formData.append('source', 'web');
        formData2.append('email', event.target.emailUser.value);
        axios
          .post('/api/addEmailToBoletin', formData2)
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((error) => {});
        axios
          .post('/api/newsletter_set', formData)
          .then((response) => {
            //console.log(response);
            if (response.status === 200) {
            }
            alert('Te has dado de alta en nuestro boletin de noticias');
          })
          .catch((error) => {
            alert(
              'Hemos encontrado un problema y no has sido añadido al boletin de noticias.'
            );
          });
      }
    } else {
      alert(
        'Hemos encontrado un problema y no has sido añadido al boletin de noticias.'
      );
    }
  };
  handleChange = (value) => {
    this.setState({ gRecaptcha: value, expired: false });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: true });
  };
  render() {
    const { newsletter } = this.state;
    const recaptchaRef = React.createRef();
    const isEnabled = this.state.expired === false;
    const newsletterSubs = newsletter.map((news, index) => {
      return (
        <div key={index}>
          <h5 className="titleSidebar">Newsletter</h5>
          <Form
            onSubmit={this.handleSubmitNewsletter}
            className="formSubscriptionNews"
          >
            <Form.Group controlId="emailUser">
              <Form.Control
                required
                type="email"
                name="emailUser"
                placeholder="¿Nos dices tu email?..."
                className="fieldFormNL"
              />
            </Form.Group>
            <Form.Group controlId="nameUser">
              <Form.Control
                required
                type="text"
                name="nameUser"
                placeholder="Nombre y apellidos"
                className="fieldFormNL"
              />
            </Form.Group>
            <Form.Group controlId="tyc">
              <Form.Check
                name="tyc"
                type="checkbox"
                value="1"
                label={
                  <label>
                    <a
                      href={news.tyc_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {news.tyc_text}
                    </a>
                  </label>
                }
                required
              />
            </Form.Group>
            <div className="captcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={global.captcha}
                onChange={this.handleChange}
              />
            </div>

            <Button
              disabled={!isEnabled}
              variant="primary"
              type="submit"
              className="btn btn-dark"
            >
              {news.btn_text}
            </Button>
            <hr className="customSmallSpace"></hr>
            <p className="smallText">
              Información semanal con noticias de FES, sus asociados/as y otras
              entidades de interés.
            </p>
          </Form>
        </div>
      );
    });

    return <div className="newsletterSidebar">{newsletterSubs}</div>;
  }
}

export default Newsletter;
