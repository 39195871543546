import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import { Redirect } from "react-router-dom";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import Contacto from "../../WidgetsSidebar/contacto";
import ActualidadResume from "../../objects/actualidadResume";
import ReactGA from "react-ga";

class Internacionalizacion01 extends Component {
	constructor(props) {
		super();
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			permalink: props.permalink,
			menu: [],
			newsEdgar: [],
			newsInternalization: [],
		};
	}

	componentDidMount() {
		const { permalink } = this.state;

		let formData3 = new FormData();
		formData3.append("id_categ", 12);
		axios
			.post("/api/topicality_get_by_id_categ", formData3)
			.then((res) => {
				//console.log(res);
				this.setState({
					//Just peak the last 3
					newsInternalization: res.data.records,
				});
			})
			.catch(function (error) {});
		if (permalink) {
			let formData2 = new FormData();
			formData2.append("permalink", permalink);
			axios
				.post("/api/pages_get_by_permalink", formData2)
				.then((res) => {
					var id_menu = res.data.records[0].id_menu;
					var id_menu_call = new FormData();
					id_menu_call.append("id_menu", id_menu);
					axios.post("/api/pages_get_by_id_menu", id_menu_call).then((res) => {
						this.setState({
							menu: res.data.records,
						});
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		} else {
			return null;
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { statusPage } = this.state;
		const { array_post } = this.state;
		const { menu } = this.state;
		const { newsInternalization } = this.state;

		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		const notice = array_post.map((post) => {
			return (
				<Col key={post.id}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					key={index}
					menu_title="Menú"
					page_array={menu}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
				></MenuPageSidebar>
			);
		});

		const newsInternalizationList = newsInternalization.length ? (
			newsInternalization.map((news, index) => {
				return (
					<Col
						lg={4}
						sm={6}
						key={news.id}
						className={
							"new-cat d-flex category-filter category-" +
							news.categories.map((categories) => categories.id)
						}
					>
						<ActualidadResume
							key={index}
							title={news.title}
							link={news.permalink}
							date={news.published_date}
							img={news.cover_image}
							short_description={news.short_description}
						></ActualidadResume>
					</Col>
				);
			})
		) : (
			<div className="center">No hay Actividades Programadas</div>
		);
		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="lastMinuteNews">
						<Col lg={9} sm={12}>
							{notice}
							<Row>
								<Col sm={12}>
									<h3 className="center"> Noticias </h3>
								</Col>
								{newsInternalizationList}
							</Row>
						</Col>
						<Col lg={3} md={12} className="sidebar">
							<Contacto></Contacto>
							{sidebarMenuPage}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Internacionalizacion01;
