import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import { Redirect } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import equal from "fast-deep-equal";
import ReactGA from "react-ga";
import ImageDefault from "../../img/noticias_default.jpg";

class TwoColumnsTemplate extends Component {
	constructor(props) {
		super();
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			filterCat: "",
			videosFetching: false,
			estudiosFetching: false,
			herramientasFetching: false,
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
			infoTabs: [],
			videos: [],
			estudios: [],
			herramientas: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	componentDidMount() {
		//Call to webservice to get the new
		(async () => {})();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		window.scrollTo(0, 0);
		if (this.props !== prevProps) {
			(async () => {})();
		}
		if (!equal(this.props.array_page, prevProps.array_page)) {
			this.setState({
				array_page: this.props.array_page,
			});
		}
	}

	render() {
		const { statusPage } = this.state;
		const { array_post } = this.state;

		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});

		const notice = array_post.map((post) => {
			return (
				<Col key={post.id} lg={6} md={12}>
					<div className="inner">
						<div className="noticeInfo">
							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
					<hr></hr>
				</Col>
			);
		});
		const imageSide = array_post.map((post) => {
			if (post.img) {
				return (
					<Col key={post.id} lg={6} md={12}>
						<img
							className="width-100"
							src={post.lateral_img ? post.lateral_img : ImageDefault}
							alt={post.title}
						></img>
					</Col>
				);
			} else {
				return (
					<Col key={post.id} lg={6} md={12}>
						<img
							className="width-100"
							src={ImageDefault}
							alt={post.title}
						></img>
					</Col>
				);
			}
		});
		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col lg={12} md={12}>
							<Row>
								{notice}
								{imageSide}
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default TwoColumnsTemplate;
