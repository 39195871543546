import React, { Component } from "react";
import axios from "axios";

class MenuPageSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page_array: [],
			menu_title: "",
			menuLateral: [],
			id_parent: props.id_parent,
		};
	}

	getParentInformation = async () => {
		const { id_parent } = this.state;

		if (id_parent !== undefined && id_parent !== null) {
			let formData = new FormData();
			formData.append("id_page", id_parent);

			axios
				.post("/api/subpages_get_by_id_parent", formData)
				.then((res) => {
					this.setState({
						menuLateral: res.data.records,
					});
				})
				.catch((error) => {
					this.setState({
						statusPage: 1,
					});
				});
		}
	};

	componentDidMount() {
		if (this.props.page_array !== undefined) {
			this.setState({
				page_array: this.props.page_array,
			});
		} else {
			return null;
		}

		if (this.props.menu_title !== undefined) {
			this.setState({
				menu_title: this.props.menu_title,
			});
		} else {
			return null;
		}

		if (this.props.id_parent !== undefined) {
			this.setState({
				id_parent: this.props.id_parent,
			});
		} else {
			return null;
		}

		(async () => {
			await this.getParentInformation();
		})();
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.page_array !== prevProps.page_array) {
			this.setState({
				page_array: this.props.page_array,
			});
		}
	}
	render() {
		const { page_array } = this.state;
		const { menu_title } = this.state;
		const { menuLateral } = this.state;

		let menu;
		if (menuLateral.length > 0) {
			menu = (
				<div className="sidebarMenuOnPage">
					<h5 className="titleSidebar">{menu_title}</h5>
					<ul className="sidebarListingNumber">
						{" "}
						{menuLateral.map((page, index) => {
							return (
								<li key={index}>
									<div className="numberListing">
										{" "}
										{("0" + (index + 1)).slice(-2)}
									</div>
									<a href={page.permalink}>{page.title}</a>
								</li>
							);
						})}
					</ul>
				</div>
			);
		} else {
			menu = (
				<div className="sidebarMenuOnPage">
					<h5 className="titleSidebar">{menu_title}</h5>
					<ul className="sidebarListingNumber">
						{" "}
						{page_array.map((page, index) => {
							return (
								<li key={"page" + index + page.id_menu}>
									<div className="numberListing">
										{" "}
										{("0" + (index + 1)).slice(-2)}
									</div>
									<a href={page.permalink}>{page.title}</a>
								</li>
							);
						})}
					</ul>
				</div>
			);
		}

		return <div>{menu}</div>;
	}
}
export default MenuPageSidebar;
