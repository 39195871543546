import React from "react";
import axios from "axios";
import { Container, Row, Col } from "react-grid-system";
import TopImageTitle from "../components/objects/topImageTitle";
import SEO from "../components/objects/seo";
import NoticiaResume from "../components/objects/noticiaResume";
import { Link, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
class HistoricoNews extends React.Component {
	constructor() {
		super();
		this.state = {
			filterCat: "",
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	changeFilterCat = (e) => {
		this.setState({
			filterCat: e.target.value.substring(9, 20),
			currentPage: 1,
			activeId: e.target.value.substring(9, 20),
		});
	};

	componentDidMount() {
		//Call to webservice to get the new

		axios
			.post("/api/news_get_by_last_year")
			.then((res) => {
				//console.log(res);
				this.setState({
					news: res.data.records,
				});
			})
			.catch((error) => {
				this.setState({
					statusPage: 1,
				});
			});
		axios
			.get("/api/news_get_years")
			.then((res) => {
				//console.log(res);
				this.setState({
					years: res.data.records.reverse(),
				});
			})
			.catch((error) => {});
		//Call to webservice to get all news
		axios.get("/api/get_all_categories").then((res) => {
			//console.log(res);
			this.setState({
				//Just peak the last 3
				categories: res.data.records,
			});
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() {
		const { statusPage } = this.state;
		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const { years } = this.state;
		//const { news } = this.state;
		let _news = this.state.news;
		const { currentPage, todosPerPage } = this.state;
		//let news = this.state.news;
		const indexOfLastTodo = currentPage * todosPerPage;

		const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
		const { categories } = this.state;
		//const { news } = this.state;

		if (this.state.filterCat) {
			_news = _news.filter(
				(item) =>
					+item.categories.map((categories) => categories.id) ===
					+this.state.filterCat
			);
		}
		const currentTodos = _news.slice(indexOfFirstTodo, indexOfLastTodo);
		const yearList = years.map((year) => {
			return (
				<li key={year.year}>
					<Link to={"/historico-noticias/" + year.year}>{year.year}</Link>
				</li>
			);
		});
		const categoriesList = categories.length ? (
			categories.map((categories) => {
				return (
					<Col
						lg={4}
						sm={6}
						key={categories.id}
						className={"categories-button category-" + categories.id}
					>
						<button
							className={
								"filterCategories " +
								(this.state.activeId === categories.id && "button")
							}
							onClick={this.changeFilterCat}
							value={"category-" + categories.id}
						>
							{categories.name}
						</button>
					</Col>
				);
			})
		) : (
			<div className="center">No hay categorias</div>
		);
		const newsList = currentTodos.map((news, index) => {
			return (
				<Col
					lg={4}
					sm={6}
					key={news.id}
					className={
						"new-cat d-flex category-filter category-" +
						news.categories.map((categories) => categories.id)
					}
				>
					<NoticiaResume
						key={index}
						title={news.title}
						img={news.cover_image}
						categories={news.categories}
						link={news.permalink}
						short_description={news.short_description}
						date={news.published_date}
						meta_description={news.meta_description}
					></NoticiaResume>
				</Col>
			);
		});

		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(_news.length / todosPerPage); i++) {
			pageNumbers.push(i);
		}
		const renderPageNumbers = pageNumbers.map((number) => {
			return (
				<span key={number} id={number} onClick={this.handleClick}>
					{number}
				</span>
			);
		});
		const title = (
			<TopImageTitle
				title="Nuestras noticias"
				subtitle="Noticias de FES"
				bread01="Noticias"
			></TopImageTitle>
		);

		const seo = (
			<SEO
				title="Conoce toda la asctualizad del FES"
				description="Todas las noticias del FES"
				keywords="FES, Federacion, Española, Sociologia"
				key={"SEO"}
			></SEO>
		);

		return (
			<section>
				{seo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col sm={9} xs={12}>
							<Row>
								<Col sm={12}>
									<h5 className="center">Selecciona una categoría</h5>
								</Col>
								{categoriesList}
							</Row>
							<Row className="hideInDesktop">
								<Col lg={12} sm={12}>
									<h4 className="center" style={{ marginTop: "10px" }}>
										Buscador
									</h4>
									<form
										className="formSearchSlider"
										action="/buscador"
										method="GET"
									>
										<div className="searchSlider">
											<input
												className="searchBarHome width-100 searchInNews"
												type="text"
												placeholder=""
												name="s"
												autoComplete="off"
											/>
											<br></br>
											<input
												className="button width-100"
												type="submit"
												value="Buscar"
											/>
										</div>
									</form>
								</Col>
							</Row>
							<Row className="listCategory">{newsList}</Row>
							<div className="center">
								<div id="page-numbers">{renderPageNumbers}</div>
							</div>
						</Col>
						<Col sm={3} xs={12}>
							<h2>Buscador</h2>
							<form
								className="formSearchSlider"
								action="/buscador"
								method="GET"
							>
								<div className="searchSlider">
									<input
										className="searchBarHome width-100 searchInNews"
										type="text"
										placeholder=""
										name="s"
										autoComplete="off"
									/>
									<br></br>
									<input
										className="button width-100"
										type="submit"
										value="Buscar"
									/>
								</div>
							</form>
							<h2>Histórico</h2>
							<div className="yearSidebar">
								<ul>{yearList}</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default HistoricoNews;
