import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import logoWhite from "../components/img/logo-white.svg";
import axios from "axios";
import ReactGA from "react-ga";
class RecordarPassword extends React.Component {
	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	handleRememberPass = (event) => {
		event.preventDefault();

		var email = 0;
		var formcheckboxTYC = 0;

		let formData = new FormData();
		if (event.target.email.value === "") {
			email = "Campo perfil publico";
		} else if (event.target.formcheckboxTYC.value === "") {
			formcheckboxTYC = "Campo newsletter";
		}
		if (email !== 0 || formcheckboxTYC !== 0) {
			alert("Hay campos obligatorios sin rellenar");
		} else {
			const checkEmail = new RegExp(
				"^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$"
			);
			if (checkEmail.test(event.target.email.value) === true) {
				formData.append("email", event.target.email.value);

				axios
					.post("/api/users_restore_passw_petition", formData)
					.then((response) => {
						//console.log(res);
						alert("Se ha enviado un mail a su dirección de correo.");
						this.props.history.push({
							pathname: "/",
						});
					})
					.catch((error) => {
						if (error.response.data.message === "User is not verified") {
							axios
								.post("/api/users_resend_verification", formData)
								.then((response) => {
									alert(
										"Esta cuenta no esta verificada, es necesario que vaya al email que ha recibido y haga click en el enlace de verificación."
									);
									window.location.reload();
								})
								.catch((error) => {
									window.location.reload();
								});
						} else {
							alert(
								"No hemos encontrado una cuenta con ese email, si lo desea puede crear una cuenta nueva."
							);
							window.location.reload();
						}
					});
			} else {
				alert(
					"El email introducido no es válido. No debe usar caracteres especiales o espacios en blanco, y el email debe contener una @ y por lo menos un punto."
				);
			}
		}
	};
	render() {
		return (
			<div>
				<hr className="whiteSpace"></hr>
				<Container className="width-100 nopadding maxWidth-100 ">
					<Row>
						<Col md={5} sm={12}>
							<div className="align-items-center h-sm-100-vh d-flex bg-grad-blue ">
								<div className="width-100 textWhite login-padding">
									<img src={logoWhite} alt="Fes login logo" width="230px"></img>
									<h2 className="textWhite">Únete a FES para estar al dia.</h2>
									<div className="listCheck" hidden>
										<ul>
											<li>Punto 1</li>
											<li>Punto 2</li>
											<li>Punto 3</li>
										</ul>
									</div>
								</div>
							</div>
						</Col>
						<Col md={7} sm={12}>
							<div className="align-items-center h-sm-100-vh d-flex mx-auto form-login">
								<div className="width-100  login-padding ">
									<h2>Recordar Contraseña</h2>
									<p>Le enviaremos un mail con la informacion a seguir.</p>
									<div className="listCheck">
										<Form onSubmit={this.handleRememberPass}>
											<Form.Group controlId="email">
												<Form.Label>Email</Form.Label>
												<Form.Control
													type="text"
													placeholder="E-mail"
													name="email"
													required
												/>
											</Form.Group>
											<Form.Group controlId="formcheckboxTYC">
												<Form.Check
													type="checkbox"
													value="1"
													name="formcheckboxTYC"
													label={
														<label>
															<a
																href="https://fes-sociologia.com/uploads/privacy/Politica_de_privacidad.pdf"
																target="_blank"
																rel="noopener noreferrer"
															>
																Acepto los terminos y condiciones
															</a>
														</label>
													}
													required
												/>
											</Form.Group>
											<p>
												¿Ya tienes una cuenta?{" "}
												<a href="/login">Acceder ahora</a>
												<br></br>
												¿Aun no tienes una cuenta?
												<a href="/registro">Registrar cuenta</a>
											</p>
											<Button
												variant="primary"
												type="submit"
												className="Acceder"
											>
												Recordar Contraseña
											</Button>
										</Form>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default RecordarPassword;
