import React, { Fragment } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import TopImageTitle from '../components/objects/topImageTitle';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import ReactGA from 'react-ga';

class ConfirmationPayment extends React.Component {
  state = {
    userInfo: [],
    islogged: 0,
    statusPage: 0,
    products: [],
    checked: '',
    checkedPay: '',
    goPayment: 0,
    customPrice: '',
    id_user: '',
    id_product: '',
    totalAmount01: '',
    paymentMethodChossen: '',
    payment_type: '',
    listFreeComites: new Map(),
    listPaidComites: new Map(),
    listFreeTrue: [],
    listPaidTrue: [],
    beginIban: '',
    endIban: '',
    SepaUniqueString: '0',
    conditionCheck: '',
    oldPayment: '',
  };

  checkTokken = async () => {
    const jwt = localStorage.getItem('access_token');

    if (!jwt) {
      this.setState.islogged = 0;
    } else {
      this.setState.islogged = 1;
      let formData = new FormData();
      formData.append('jwt', jwt);
      await axios
        .post('/api/validate_token', formData)
        .then((res) => {
          //console.log(res);
          this.setState({
            userInfo: res.data.data,
          });
        })
        .catch((error) => {
          this.setState({
            statusPage: 1,
          });
        });
    }
  };
  setFreeComites = async () => {
    var FreeComitesTrue = [];
    if (this.props.history.location.state.listFreeComites) {
      for (const [
        index,
        value,
      ] of this.props.history.location.state.listFreeComites.entries()) {
        if (value === true) {
          FreeComitesTrue.push(index);
        }
      }
      this.setState({
        listFreeTrue: FreeComitesTrue,
      });
    } else {
      this.setState({
        listFreeTrue: FreeComitesTrue,
      });
    }
  };

  setPaidComites = async () => {
    var PaidComitesTrue = [];
    if (this.props.history.location.state.listPaidComites) {
      for (const [
        index,
        value,
      ] of this.props.history.location.state.listPaidComites.entries()) {
        if (value === true) {
          PaidComitesTrue.push(index);
        }
      }
      this.setState({
        listPaidTrue: PaidComitesTrue,
      });
    } else {
      this.setState({
        listPaidTrue: PaidComitesTrue,
      });
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.history.location.state === undefined) {
      return null;
    } else if (this.props.history.location.state.products) {
      this.setState({
        products: this.props.history.location.state.products,
        checkedPay: this.props.location.state.checkedPay,
        checked: this.props.location.state.checked,
        goPayment: this.props.history.location.state.goPayment,
        idTemplate: this.props.history.location.state.idTemplate,
        listFreeComites: this.props.history.location.state.listFreeComites,
        listPaidComites: this.props.history.location.state.listPaidComites,
        id_payment: this.props.history.location.state.id_payment,
        renov_id: this.props.history.location.state.renov_id,
        iva: this.props.history.location.state.iva,
        conditionCheck: this.props.history.location.state.conditionCheck,
        oldPayment: this.props.history.location.state.oldPayment,
        rePay: this.props.history.location.state.rePay,
      });
    }

    if (+this.props.history.location.state.customPrice === undefined) {
      return null;
    } else {
      this.setState({
        customPrice: this.props.history.location.state.customPrice,
      });
    }
    (async () => {
      await this.checkTokken();
      await this.setFreeComites();
      await this.setPaidComites();
      this.transformIban();
      this.getUniqueSepaCode();
    })();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  handleTransferencia = (event) => {
    event.preventDefault();
    this.refs.btnPayment.setAttribute('disabled', 'disabled');
    const free_comites = this.props.history.location.state.checked;
    const paid_comites = this.props.history.location.state.checkedPay;

    if (
      +this.props.history.location.state.idTemplate === 1 ||
      +this.props.history.location.state.idTemplate === 9 ||
      +this.props.history.location.state.idTemplate === 10 ||
      +this.props.history.location.state.idTemplate === 11 ||
      +this.props.history.location.state.idTemplate === 12 ||
      +this.props.history.location.state.idTemplate === 13
    ) {
      if (this.props.history.location.state.id_payment) {
        let formData = new FormData();
        formData.append('id_user', this.props.history.location.state.id_user);
        formData.append(
          'id_product',
          this.props.history.location.state.id_product
        );
        formData.append(
          'amount',
          this.props.history.location.state.totalAmount01
        );
        formData.append('free_comites', JSON.stringify(free_comites));
        formData.append('paid_comites', JSON.stringify(paid_comites));
        formData.append('payment_type', 2);
        formData.append('email', this.state.userInfo.email);
        formData.append('renov_id', this.state.renov_id);
        formData.append('sepa_command', '');
        formData.append(
          'id_payment',
          this.props.history.location.state.id_payment
        );

        axios
          .post('/api/payment_documentation_ok_update', formData)
          .then((response) => {
            //console.log(res);
            if (response.status === 200) {
              if (
                +this.props.history.location.state.idTemplate === 11 ||
                +this.props.history.location.state.idTemplate === 12
              ) {
                alert(
                  "Estimado/a socio/a:  Ha realizado un pedido en FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su compra. Gracias"
                );
              } else {
                alert(
                  "Estimado/a socio/a:  Ha realizado un pedido a FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su pedido. Gracias"
                );
              }

              this.refs.btnPayment.removeAttribute('disabled');
              this.props.history.push({
                pathname: '/perfil',
                state: {
                  key: 'afiliationData',
                  userToColectivo: 0,
                },
              });
            }
          })
          .catch((error) => {
            alert(
              'Hemos encontrado un problema y no podemos realizar la operación'
            );
            this.refs.btnPayment.removeAttribute('disabled');
          });
      } else {
        let formData = new FormData();
        formData.append('id_user', this.props.history.location.state.id_user);
        formData.append(
          'id_product',
          this.props.history.location.state.id_product
        );
        formData.append(
          'amount',
          this.props.history.location.state.totalAmount01
        );
        formData.append('free_comites', JSON.stringify(free_comites));
        formData.append('paid_comites', JSON.stringify(paid_comites));
        formData.append('payment_type', 2);
        formData.append('email', this.state.userInfo.email);
        formData.append('renov_id', this.state.renov_id);
        formData.append('rePay', this.state.rePay);
        axios
          .post('/api/payment_full_set', formData)
          .then((response) => {
            //console.log(res);
            if (response.status === 200) {
              if (
                +this.props.history.location.state.idTemplate === 11 ||
                +this.props.history.location.state.idTemplate === 12
              ) {
                alert(
                  "Estimado/a socio/a:  Ha realizado un pedido en FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su compra. Gracias"
                );
              } else {
                alert(
                  "Estimado/a socio/a:  Ha realizado un pedido a FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su pedido. Gracias"
                );
              }

              this.refs.btnPayment.removeAttribute('disabled');
              this.props.history.push({
                pathname: '/perfil',
                state: {
                  key: 'afiliationData',
                  userToColectivo: 0,
                },
              });
            }
          })
          .catch((error) => {
            //console.log(error.response.data);
            alert(
              'Hemos encontrado un problema y no podemos realizar la operación'
            );
            this.refs.btnPayment.removeAttribute('disabled');
          });
      }
    } else if (
      +this.props.history.location.state.idTemplate === 2 ||
      +this.props.history.location.state.idTemplate === 6 ||
      +this.props.history.location.state.idTemplate === 7 ||
      +this.props.history.location.state.idTemplate === 8
    ) {
      let formData2 = new FormData();
      formData2.append(
        'id_payment',
        this.props.history.location.state.id_payment
      );
      formData2.append(
        'amount',
        this.props.history.location.state.totalAmount01
      );
      formData2.append('free_comites', JSON.stringify(free_comites));
      formData2.append('paid_comites', JSON.stringify(paid_comites));
      formData2.append('payment_type', 2);
      formData2.append('email', this.state.userInfo.email);
      formData2.append('renov_id', this.state.renov_id);

      axios
        .post('/api/payment_documentation_ok_update', formData2)
        .then((response) => {
          //console.log(res);
          if (response.status === 200) {
            if (
              +this.props.history.location.state.idTemplate === 11 ||
              +this.props.history.location.state.idTemplate === 12
            ) {
              alert(
                "Estimado/a socio/a:  Ha realizado un pedido en FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su compra. Gracias"
              );
            } else {
              alert(
                "Estimado/a socio/a:  Ha realizado un pedido  a FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su pedido. Gracias"
              );
            }
            this.refs.btnPayment.removeAttribute('disabled');
            this.props.history.push({
              pathname: '/perfil',
              state: {
                key: 'afiliationData',
                userToColectivo: 0,
              },
            });
          }
        })
        .catch((error) => {
          alert(
            'Hemos encontrado un problema y no podemos realizar la operación'
          );
          this.refs.btnPayment.removeAttribute('disabled');
        });
    } else if (+this.props.history.location.state.idTemplate === 5) {
      let formData2 = new FormData();
      formData2.append(
        'id_payment',
        this.props.history.location.state.id_payment
      );
      formData2.append(
        'amount',
        this.props.history.location.state.totalAmount01
      );

      formData2.append('payment_type', 2);
      formData2.append('email', this.state.userInfo.email);
      formData2.append('renov_id', this.state.renov_id);

      axios
        .post('/api/payment_documentation_ok_no_comites', formData2)
        .then((response) => {
          //console.log(res);
          if (response.status === 200) {
            if (
              +this.props.history.location.state.idTemplate === 11 ||
              +this.props.history.location.state.idTemplate === 12
            ) {
              alert(
                "Estimado/a socio/a:  Ha realizado un pedido en FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su compra. Gracias"
              );
            } else {
              alert(
                "Estimado/a socio/a:  Ha realizado un pedido  a FES con 'transferencia' como forma de pago, en cuanto el pago sea efectivo recibirá un email confirmando su pedido. Gracias"
              );
            }

            this.refs.btnPayment.removeAttribute('disabled');
            this.props.history.push({
              pathname: '/perfil',
              state: {
                key: 'afiliationData',
                userToColectivo: 0,
              },
            });
          }
        })
        .catch((error) => {
          alert(
            'Hemos encontrado un problema y no podemos realizar la operación'
          );
          this.refs.btnPayment.removeAttribute('disabled');
        });
    }
  };
  handleSepa = (event) => {
    event.preventDefault();
    this.refs.btnPayment.setAttribute('disabled', 'disabled');
    const jwt = localStorage.getItem('access_token');
    const { SepaUniqueString } = this.state;

    var ibanBegin = 0;
    var ibanEnd = 0;
    var swift = 0;
    if (event.target.ibanBegin.value === '') {
      ibanBegin = 'Campo Iban';
      //alert(nameError);
    } else if (event.target.swift.value === '') {
      swift = 'Campo swift';
      //alert(dniError);
    } else if (event.target.ibanEnd.value === '') {
      ibanEnd = 'Campo Cuenta bancaria';
      //alert(dniError);
    }
    if (ibanBegin !== 0 || ibanEnd !== 0 || swift !== 0) {
      alert('Hay campos obligatorios sin rellenar');
      this.refs.btnPayment.removeAttribute('disabled');
    } else if (event.target.ibanBegin.value.length < 4) {
      alert('El Iban tiene que tener 4 caracteres');
      this.refs.btnPayment.removeAttribute('disabled');
    } else if (event.target.ibanEnd.value.length < 20) {
      alert('El número de cuenta tiene que tener 20 digitos');
      this.refs.btnPayment.removeAttribute('disabled');
    } else {
      var FullIban = event.target.ibanBegin.value + event.target.ibanEnd.value;
      let formDatabank = new FormData();
      formDatabank.append('jwt', jwt);
      formDatabank.append('iban', FullIban);
      formDatabank.append('swift', event.target.swift.value);
      axios
        .post('/api/users_update_bank_data', formDatabank)
        .then((response) => {
          let jwt = response.data.jwt;
          localStorage.setItem('access_token', jwt);
        });
      const free_comites = this.props.history.location.state.checked;
      const paid_comites = this.props.history.location.state.checkedPay;
      if (+this.props.history.location.state.idTemplate === 1) {
        let formData = new FormData();
        formData.append('id_user', this.props.history.location.state.id_user);
        formData.append(
          'id_product',
          this.props.history.location.state.id_product
        );
        formData.append(
          'amount',
          this.props.history.location.state.totalAmount01
        );
        formData.append('free_comites', JSON.stringify(free_comites));
        formData.append('paid_comites', JSON.stringify(paid_comites));
        formData.append('payment_type', 3);
        formData.append('sepa_command', SepaUniqueString);
        formData.append('renov_id', this.state.renov_id);
        formData.append('rePay', this.state.rePay);
        axios
          .post('/api/payment_full_set', formData)
          .then((response) => {
            alert(
              'Ha solicitado una afiliación a FES con “Domiciliación”. Tan pronto como el pago sea efectivo recibirá un email con la aprobación definitiva.'
            );
            this.refs.btnPayment.removeAttribute('disabled');
            this.props.history.push({
              pathname: '/perfil',
              state: {
                key: 'afiliationData',
                userToColectivo: 0,
              },
            });
          })
          .catch((error) => {
            alert(
              'Hemos encontrado un problema y no podemos realizar la operación'
            );
            this.refs.btnPayment.removeAttribute('disabled');
          });
      } else if (
        +this.props.history.location.state.idTemplate === 2 ||
        +this.props.history.location.state.idTemplate === 6
      ) {
        let formData2 = new FormData();

        formData2.append(
          'id_payment',
          this.props.history.location.state.id_payment
        );
        formData2.append(
          'amount',
          this.props.history.location.state.totalAmount01
        );
        formData2.append('free_comites', JSON.stringify(free_comites));
        formData2.append('paid_comites', JSON.stringify(paid_comites));
        formData2.append('payment_type', 3);
        formData2.append('email', this.state.userInfo.email);
        formData2.append('sepa_command', SepaUniqueString);
        formData2.append('renov_id', this.state.renov_id);

        axios
          .post('/api/payment_documentation_ok_update', formData2)
          .then((response) => {
            //console.log(res);
            if (response.status === 200) {
              alert(
                'Ha solicitado una afiliación a FES con “Domiciliación”. Tan pronto como el pago sea efectivo recibirá un email con la aprobación definitiva.'
              );
              this.refs.btnPayment.removeAttribute('disabled');
              this.props.history.push({
                pathname: '/perfil',
                state: {
                  key: 'afiliationData',
                  userToColectivo: 0,
                },
              });
            }
          })
          .catch((error) => {
            alert(
              'Hemos encontrado un problema y no podemos realizar la operación'
            );
          });
      } else if (+this.props.history.location.state.idTemplate === 5) {
        let formData2 = new FormData();
        formData2.append(
          'id_payment',
          this.props.history.location.state.id_payment
        );
        formData2.append(
          'amount',
          this.props.history.location.state.totalAmount01
        );

        formData2.append('payment_type', 3);
        formData2.append('email', this.state.userInfo.email);
        formData2.append('sepa_command', SepaUniqueString);
        formData2.append('renov_id', this.state.renov_id);
        axios
          .post('/api/payment_documentation_ok_no_comites', formData2)
          .then((response) => {
            //console.log(res);
            if (response.status === 200) {
              alert(
                'Ha solicitado una afiliación a FES con “Domiciliación”. Tan pronto como el pago sea efectivo recibirá un email con la aprobación definitiva.'
              );
              this.refs.btnPayment.removeAttribute('disabled');
              this.props.history.push({
                pathname: '/perfil',
                state: {
                  key: 'afiliationData',
                  userToColectivo: 0,
                },
              });
            }
          })
          .catch((error) => {
            alert(
              'Hemos encontrado un problema y no podemos realizar la operación'
            );
            this.refs.btnPayment.removeAttribute('disabled');
          });
      }
    }
  };
  handlePayment = (event) => {
    event.preventDefault();
    this.refs.btnPayment.setAttribute('disabled', 'disabled');
    if (
      +this.props.history.location.state.idTemplate === +11 ||
      +this.props.history.location.state.idTemplate === +12
    ) {
      this.refs.btnPayment.removeAttribute('disabled');
      if (this.props.history.location.state.id_payment) {
        let formDataComites = new FormData();
        formDataComites.append(
          'id_payment',
          this.props.history.location.state.id_payment
        );
        formDataComites.append(
          'free_comites',
          JSON.stringify(this.props.location.state.checked)
        );
        formDataComites.append(
          'paid_comites',
          JSON.stringify(this.props.location.state.checkedPay)
        );
        formDataComites.append(
          'amount',
          this.props.history.location.state.totalAmount01
        );

        formDataComites.append('payment_type', 1);
        formDataComites.append('renov_id', this.state.renov_id);
        axios
          .post('/api/payment_documentation_ok_update', formDataComites)
          .then((res) => {
            //	console.log(res);
            this.refs.btnPayment.removeAttribute('disabled');
            document.getElementById('RedsysForm').submit();
          })
          .catch((error) => {
            //console.log(error.response.data);
            alert(
              'Se ha encontrado un problema y no se ha podido redireccionar a la página de pago'
            );
            this.refs.btnPayment.removeAttribute('disabled');
          });
      } else {
        document.getElementById('RedsysForm').submit();
      }
    } else if (+this.props.history.location.state.idTemplate !== +1) {
      let formDataComites = new FormData();
      formDataComites.append(
        'id_payment',
        this.props.history.location.state.id_payment
      );
      formDataComites.append(
        'free_comites',
        JSON.stringify(this.props.location.state.checked)
      );
      formDataComites.append(
        'paid_comites',
        JSON.stringify(this.props.location.state.checkedPay)
      );
      formDataComites.append(
        'amount',
        this.props.history.location.state.totalAmount01
      );

      formDataComites.append('payment_type', 1);
      formDataComites.append('renov_id', this.state.renov_id);
      axios
        .post('/api/payment_documentation_ok_update', formDataComites)
        .then((res) => {
          //	console.log(res);
          this.refs.btnPayment.removeAttribute('disabled');
          document.getElementById('RedsysForm').submit();
        })
        .catch((error) => {
          //console.log(error.response.data);
          alert(
            'Se ha encontrado un problema y no se ha podido redireccionar a la página de pago'
          );
          this.refs.btnPayment.removeAttribute('disabled');
        });
    } else {
      this.refs.btnPayment.removeAttribute('disabled');
      document.getElementById('RedsysForm').submit();
    }
  };
  transformIban() {
    const { userInfo } = this.state;
    if (userInfo) {
      if (userInfo.iban) {
        var beginIban;
        var endIban;
        beginIban = userInfo.iban.toString();
        beginIban = beginIban.slice(0, 4);
        endIban = userInfo.iban.toString();
        endIban = endIban.slice(4, 30);
        this.setState({
          beginIban: beginIban,
          endIban: endIban,
        });
      } else {
        this.setState({
          beginIban: 'ES',
          endIban: '',
        });
      }
    }
  }

  getUniqueSepaCode() {
    const { SepaUniqueString } = this.state;
    if (SepaUniqueString === '0') {
      var ts = Date.now();

      var d = new Date(ts),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      var actualDate = year + day + month;
      var prefix = 'MNDT';
      var result = '';
      var characters = '0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 3; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      var SepaUnique = prefix + actualDate + result;
      this.setState({
        SepaUniqueString: SepaUnique,
      });
    }
  }
  handleChangeFocus(e) {
    if (e.target.value.length > 3) {
      document.getElementById('ibanEnd').focus();
    }
  }
  render() {
    const { statusPage } = this.state;
    const { products } = this.state;
    const { goPayment } = this.state;
    const { customPrice } = this.state;
    const { listFreeTrue } = this.state;
    const { listPaidTrue } = this.state;
    const { userInfo } = this.state;
    const { beginIban } = this.state;
    const { endIban } = this.state;
    const { SepaUniqueString } = this.state;

    if (+statusPage === 1 || +goPayment === '0') {
      return (
        <section>
          <Container>
            <Row>
              <Col sm={12}>
                <h2 className="center">Error en el proceso de compra</h2>
                <p className="center">
                  No hemos podido indetificar que suscripción desea comprar,
                  vuelva a intentarlo.
                </p>
                <p className="center">
                  <a href="/afiliate" className="center">
                    {' '}
                    <button className="button center">Ir a afiliaciones</button>
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      );
    }
    let resumeComitesFree;
    if (listFreeTrue.length > 0) {
      resumeComitesFree = listFreeTrue.map((lft, index) => {
        return (
          <tr key={index}>
            <td>{lft}</td>
          </tr>
        );
      });
    }

    let resumeComitesPaid;
    if (listPaidTrue.length > 0) {
      resumeComitesPaid = listPaidTrue.map((lft, index) => {
        return (
          <tr key={index}>
            <td>{lft}</td>
          </tr>
        );
      });
    }
    const title = (
      <TopImageTitle
        title="Confirmación  de pago"
        subtitle="Asegúrese de que todo es correcto"
      ></TopImageTitle>
    );
    const tableSeeFreeComites = (
      <Col lg={6} sm={12}>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th>Comités/Grupos de trabajo Gratuitos</th>
            </tr>
          </thead>
          <tbody>{resumeComitesFree}</tbody>
        </Table>
      </Col>
    );

    const tableSeePaidComites = (
      <Col lg={6} sm={12}>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th>Comités/Grupos de trabajo extra</th>
            </tr>
          </thead>
          <tbody>{resumeComitesPaid}</tbody>
        </Table>
      </Col>
    );
    const { checkedPay } = this.state;
    const resumen = products.map((prod) => {
      let preName;
      if (prod.id_template === '6') {
        preName = 'Socio/a  de Territorial inscrito/a a comités';
        //preName = null;
      } else {
        preName = null;
      }
      return (
        <Table striped key={prod.id}>
          <tbody>
            <tr>
              <th>Concepto</th>
              <th>Cantidad</th>
              <th>Precio unidad (IVA incluido)</th>
              <th>IVA</th>
              <th>Total</th>
            </tr>
            <tr>
              <td>
                {' '}
                <Fragment>{!preName ? prod.name : preName}</Fragment>
              </td>
              <td>1</td>
              <td>
                {!customPrice
                  ? this.state.conditionCheck === false
                    ? prod.price_condition
                    : prod.price
                  : customPrice}
                €
              </td>
              <td>
                {' '}
                {this.state.iva && +this.state.iva !== 0
                  ? this.state.iva + '%'
                  : 'Exento de IVA'}
              </td>
              <td>
                {!customPrice
                  ? this.state.conditionCheck === false
                    ? prod.price_condition
                    : prod.price
                  : customPrice}
                €
              </td>
            </tr>
            {!checkedPay ? (
              ''
            ) : +this.state.idTemplate === 11 ||
              +this.state.idTemplate === 12 ? null : !checkedPay.length ||
              !checkedPay ? null : (
              <tr>
                <td>Comités/Grupos de trabajo </td>
                <td>{!checkedPay ? 'No Aplica' : checkedPay.length}</td>
                <td>{global.priceSingleComite}€</td>
                <td>
                  {' '}
                  {this.state.iva && +this.state.iva !== 0
                    ? this.state.iva + '%'
                    : 'Exento de IVA'}
                </td>
                <td>{global.priceSingleComite * checkedPay.length}€</td>
              </tr>
            )}

            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>
                {' '}
                {this.state.iva && +this.state.iva !== 0
                  ? this.state.iva + '%'
                  : 'Exento de IVA'}
              </td>
              <td>
                {' '}
                {!customPrice
                  ? this.state.conditionCheck === false
                    ? +prod.price_condition +
                      global.priceSingleComite *
                        (!checkedPay ? 0 : checkedPay.length)
                    : +prod.price +
                      global.priceSingleComite *
                        (!checkedPay ? 0 : checkedPay.length)
                  : +customPrice +
                    global.priceSingleComite *
                      (!checkedPay ? 0 : checkedPay.length)}
                €
              </td>
            </tr>
          </tbody>
        </Table>
      );
    });

    let form;
    if (+statusPage === 1 || +goPayment === 1) {
      // -> Pruebas -> https://sis-t.redsys.es:25443/sis/realizarPago
      // -> Real ->  https://sis.redsys.es/sis/realizarPago
      form = (
        <Form
          action="https://sis.redsys.es/sis/realizarPago"
          method="POST"
          onSubmit={this.handlePayment}
          id="RedsysForm"
        >
          <Form.Group controlId="signatureVersion">
            <Form.Control
              type="text"
              name="Ds_SignatureVersion"
              defaultValue="HMAC_SHA256_V1"
              hidden
            />
          </Form.Group>
          <Form.Group controlId="dsMerchant">
            <Form.Control
              type="text"
              name="Ds_MerchantParameters"
              defaultValue={
                this.props.history.location.state.signatureRedsys.params
              }
              hidden
            />
          </Form.Group>
          <Form.Group controlId="Ds_Signature">
            <Form.Control
              type="text"
              name="Ds_Signature"
              defaultValue={
                this.props.history.location.state.signatureRedsys.signature
              }
              hidden
            />
          </Form.Group>
          <p>Su método de pago seleccionado es Tarjeta</p>
          {resumen}
          <Row>
            {!resumeComitesFree
              ? null
              : +this.state.idTemplate === 11 || +this.state.id_template === 12
              ? null
              : tableSeeFreeComites}
            {!resumeComitesPaid
              ? null
              : +this.state.idTemplate === 11 || +this.state.id_template === 12
              ? null
              : tableSeePaidComites}
          </Row>
          <Button variant="primary" type="submit" ref="btnPayment">
            CONFIRMAR Y REALIZAR PEDIDO
          </Button>
        </Form>
      );
    } else if (+statusPage === 1 || +goPayment === 2) {
      form = (
        <Form onSubmit={this.handleTransferencia}>
          <p>Su método de pago seleccionado es Transferencia</p>
          <p>
            Este es el número de cuenta donde deberá realizar la transferencia:{' '}
          </p>
          <p>
            - <b>FEDERACIÓN ESPAÑOLA DE SOCIOLOGIA</b>
          </p>
          <p>
            - <b>ES81 2100 3100 5013 0019 1562 [CAIXABANK]</b>
            <br></br>- <b>SWIFT/BIC: CAIXESBBXXX</b>
          </p>
          {resumen}
          <Row>
            {!resumeComitesFree
              ? null
              : +this.state.idTemplate === 11 || +this.state.id_template === 12
              ? null
              : tableSeeFreeComites}
            {!resumeComitesPaid
              ? null
              : +this.state.idTemplate === 11 || +this.state.id_template === 12
              ? null
              : tableSeePaidComites}
          </Row>
          <Button variant="primary" type="submit" ref="btnPayment">
            CONFIRMAR Y REALIZAR PEDIDO
          </Button>
        </Form>
      );
    } else if (+statusPage === 1 || +goPayment === 3) {
      form = (
        <Form onSubmit={this.handleSepa}>
          <p>Su método de pago seleccionado es Domiciliación</p>
          {resumen}
          <Row>
            {!resumeComitesFree
              ? null
              : +this.state.idTemplate === 11 || +this.state.id_template === 12
              ? null
              : tableSeeFreeComites}
            {!resumeComitesPaid
              ? null
              : +this.state.idTemplate === 11 || +this.state.id_template === 12
              ? null
              : tableSeePaidComites}
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <Row>
                <Col lg={3}>
                  {' '}
                  <Form.Group
                    controlId="ibanBegin"
                    name="ibanBegin"
                    ref="ibanBegin"
                  >
                    <Form.Label>1* IBAN*:</Form.Label>
                    <Form.Control
                      type="input"
                      placeholder="iban"
                      name="ibanBegin"
                      ref="ibanBegin"
                      maxLength="4"
                      required
                      defaultValue={beginIban}
                      onChange={this.handleChangeFocus.bind(this)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={9}>
                  {' '}
                  <Form.Group>
                    <Form.Label>2* Número de cuenta*:</Form.Label>
                    <Form.Control
                      id="ibanEnd"
                      type="input"
                      placeholder="iban"
                      name="ibanEnd"
                      pattern="[0-9]*"
                      ref="ibanEnd"
                      maxLength="20"
                      required
                      defaultValue={endIban}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col lg={3} sm={12}>
              {' '}
              <Form.Group controlId="bicUser">
                <Form.Label>3* BIC*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="swift"
                  ref="swift"
                  placeholder="BIC"
                  defaultValue={userInfo.swift ? userInfo.swift : null}
                />
              </Form.Group>
            </Col>
            <Col lg={3} sm={12}>
              {' '}
              <Form.Group controlId="formcheckboxTYC">
                <Form.Check
                  type="checkbox"
                  value="1"
                  name="formcheckboxTYC"
                  defaultChecked={+this.state.oldPayment === 3 ? true : false}
                  label="Acepto acuerdo SEPA de cargo en cuenta bancaria"
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <p className="small">
                1* -> El IBAN se compone de 4 caracteres. Los dos Primeros
                corresponden al código del país(ES es el código de España) y los
                2 últimos son los digitos de control.{' '}
              </p>
              <p className="small">
                2* -> El número de cuenta se compone de un total de 20 digitos.
              </p>
              <p className="small">
                3* -> El código SWIFT contiene de 8 a 11 dígitos e identifica tu
                sucursal bancaria
              </p>
            </Col>
            <h6 className="center">ACUERDO SEPA DE CARGO EN CUENTA</h6>
            <Col lg={12} sm={12}>
              {' '}
              <Table responsive striped hover>
                <tbody>
                  <tr>
                    <td align="right">
                      Referencia de la orden de domiciliación
                    </td>
                    <td>{SepaUniqueString}</td>
                  </tr>
                  <tr>
                    <td align="right">Identificador de acreedor</td>
                    <td>ES80000G28918514</td>
                  </tr>
                  <tr>
                    <td align="right">Nombre de acreedor</td>
                    <td>FES</td>
                  </tr>
                  <tr>
                    <td align="right">Dirección</td>
                    <td>C/ Montalbán, 8</td>
                  </tr>
                  <tr>
                    <td align="right">Código postal</td>
                    <td>28014</td>
                  </tr>
                  <tr>
                    <td align="right">Poclación</td>
                    <td>Madrid</td>
                  </tr>
                  <tr>
                    <td align="right">Provincia</td>
                    <td>Madrid</td>
                  </tr>
                  <tr>
                    <td align="right">Pais</td>
                    <td>España</td>
                  </tr>
                  <tr>
                    <td align="right">Nombre del deudor</td>
                    <td>{userInfo.name ? userInfo.name : null}</td>
                  </tr>
                  <tr>
                    <td align="right">Dirección del deudor</td>
                    <td>
                      {userInfo.billing_address_1
                        ? userInfo.billing_address_1
                        : null}
                    </td>
                  </tr>
                  <tr>
                    <td align="right">Código postal</td>
                    <td>
                      {userInfo.postal_code ? userInfo.postal_code : null}
                    </td>
                  </tr>
                  <tr>
                    <td align="right">Población</td>
                    <td>{userInfo.city ? userInfo.city : null}</td>
                  </tr>
                  <tr>
                    <td align="right">Provincia</td>
                    <td>{userInfo.region ? userInfo.region : null}</td>
                  </tr>
                  <tr>
                    <td align="right">Pais del deudor</td>
                    <td>
                      {userInfo.country_residence
                        ? userInfo.country_residence
                        : null}
                    </td>
                  </tr>
                  <tr>
                    <td align="right">IBAN</td>
                    <td>{userInfo.iban ? userInfo.iban : null}</td>
                  </tr>
                  <tr>
                    <td align="right">SWIFT</td>
                    <td>{userInfo.swift ? userInfo.swift : null}</td>
                  </tr>
                  <tr>
                    <td align="right">Fecha</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td align="right">Lugar</td>
                    <td>Madrid, España</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Button variant="primary" type="submit" ref="btnPayment">
            CONFIRMAR Y REALIZAR PEDIDO
          </Button>
        </Form>
      );
    }
    return (
      <div>
        <hr className="whiteSpace"></hr>
        {title}
        <section>
          <Container>
            <Row>
              <Col>
                {' '}
                <button
                  className="button icon-left"
                  onClick={this.props.history.goBack}
                >
                  Volver atrás
                </button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>{form}</Col>
            </Row>
            <hr></hr>
          </Container>
        </section>
      </div>
    );
  }
}
export default withRouter(ConfirmationPayment);
