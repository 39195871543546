import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import TopImageTitle from "../../objects/topImageTitle";
import SEO from "../../objects/seo";
import { Col, Row, Container } from "react-grid-system";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import Contacto from "../../WidgetsSidebar/contacto";
import MenuPageSidebar from "../../objects/menuPageSidebar";
import equal from "fast-deep-equal";
import ContactImage from "../../WidgetsSidebar/contactImage";
import ReactGA from "react-ga";

class tabTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			array_post: props.array_post,
			array_page: props.array_page,
			filterCat: "",
			categories: [],
			news: [],
			page: [],
			statusPage: 0,
			currentPage: 1,
			todosPerPage: 12,
			years: [],
			activeId: null,
			infoTabsTemplate: [],
		};
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	changeFilterCat = (e) => {
		this.setState({
			filterCat: e.target.value.substring(9, 20),
			currentPage: 1,
			activeId: e.target.value.substring(9, 20),
		});
	};
	getTabs = (id) => {
		let formData = new FormData();
		formData.append("id", id);
		axios
			.post("/api/design_templates_get_tabs", formData)
			.then((res) => {
				this.setState({
					infoTabsTemplate: res.data.records,
					istab: true,
				});
				return true;
			})
			.catch((error) => {
				this.setState({
					istab: false,
					statusPage: 1,
				});
				return false;
			});
	};
	componentDidMount() {
		//Call to webservice to get the new

		(async () => {
			await this.getTabs(this.props.templateID);
		})();

		ReactGA.pageview(window.location.pathname + window.location.search);
	}
	componentDidUpdate(prevProps) {
		if (!equal(this.props.array_page, prevProps.array_page)) {
			this.setState({
				array_page: this.props.array_page,
			});
		}
	}
	render() {
		const { statusPage } = this.state;
		const { array_post } = this.state;
		const { array_page } = this.state;
		const { infoTabsTemplate } = this.state;
		console.log(this.state.infoTabsTemplate);
		if (statusPage === 1) {
			return <Redirect to="/404" />;
		}
		const title = array_post.map((post, index) => {
			return (
				<TopImageTitle
					key={index}
					key_id={post.id}
					title={post.title}
					cover_image={post.img}
					subtitle={post.subtitle}
					bread01={post.title}
					id_parentMenu={!post.id_menu ? null : post.id_menu}
				></TopImageTitle>
			);
		});
		//	const { years } = this.state;
		const sidebarMenuPage = array_post.map((post, index) => {
			return (
				<MenuPageSidebar
					menu_title="Menú"
					page_array={array_page}
					id_parent={!post.id_page_parent ? null : post.id_page_parent}
					key={index}
				></MenuPageSidebar>
			);
		});

		const MetaTagsSeo = array_post.map((post) => {
			return (
				<SEO
					title={!post.meta_title ? post.title : post.meta_title}
					description={post.meta_description}
					keywords={post.keywords}
					cover_image={post.img}
					key={"SEO" + post.id}
				></SEO>
			);
		});
		const tabs = infoTabsTemplate.map((tab, index) => {
			return (
				<Tab eventKey={index} key={index} title={tab.name}>
					<div
						dangerouslySetInnerHTML={{
							__html: tab.description
								? tab.description
								: "<p>No hay informacion</p>",
						}}
					/>
				</Tab>
			);
		});

		return (
			<div>
				{MetaTagsSeo}
				{title}
				<hr className="whiteSpace"></hr>
				<Container>
					<Row className="categoryBlock">
						<Col lg={9} md={12}>
							<Tabs defaultActiveKey={0} id="uncontrolled-tab-example">
								{tabs}
							</Tabs>
						</Col>
						<Col className="sidebar" lg={3} md={12}>
							<ContactImage />
							<Contacto />
							{sidebarMenuPage}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default tabTemplate;
